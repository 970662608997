import React from 'react';
import VendorDetailsMenu, { useGetVendor } from './menu';
import AccountUserIndex from '../../accounts/user';

/**
 * 벤더사 계정 상세
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const VendorsDetailsAccounts = (props) => {
  const vendor = useGetVendor();
  return (
    <div>
      <VendorDetailsMenu index={2} />
      {!!vendor?.id && <AccountUserIndex vendorId={vendor?.id} />}
    </div>
  );
};

export default VendorsDetailsAccounts;
