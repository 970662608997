import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import { Button, Descriptions, PageHeader, Table, Typography } from 'antd';
import moment from 'moment';
import Loading from '../../../../components/Loading';
import { couponTargettingTypes, deleteCoupons as deleteCouponsApi, getCoupon } from '../../../../apis/coupons';
import { getUserFrom, phoneFormat, toCommaNumber } from '../../../../common/utils';
import styles from '../../../work/review/details/reviewDetails.module.less';
import { getAPIHost, restApi } from '../../../../apis';
import TableButtonWrap from '../../../../components/TableButtonWrap';
import fileDownload from 'js-file-download';

const CODE_COLUMNS = [
  {
    title: '코드',
    align: 'center',
    key: 'code',
    dataIndex: 'code',
    width: 90,
  },
  {
    title: '사용유저',
    align: 'center',
    key: 'account',
    dataIndex: 'account',
    width: 120,
    render: (r) => (!r?.id ? '미사용' : r.nickname),
  },
  {
    title: '사용일시',
    align: 'center',
    key: 'used_at',
    dataIndex: 'used_at',
    width: 120,
    render: (r) => (!r ? '-' : moment(r).format('YYYY-MM-DD HH:mm')),
  },
];

const SelectedColumns = [
  {
    title: '번호',
    align: 'center',
    key: 'id',
    dataIndex: 'id',
    width: 90,
  },
  {
    title: '이름',
    key: 'nickname',
    dataIndex: 'nickname',
    width: 100,
    align: 'center',
    render: (r) => (
      <Typography.Text ellipsis={true} style={{ width: 100 }}>
        {r}
      </Typography.Text>
    ),
  },
  {
    title: '연락처',
    dataIndex: 'phone',
    key: 'phone',
    width: 150,
    align: 'center',
    render: (r) => phoneFormat(r),
  },
  {
    title: '가입수단',
    dataIndex: 'platform',
    key: 'platform',
    width: 90,
    align: 'center',
    render: (data, row) => {
      return getUserFrom(row.signname, data);
    },
  },
  {
    title: '멤버십등급',
    dataIndex: 'membership',
    key: 'membership',
    width: 100,
    align: 'center',
    render: (_, __, index) => {
      return _?.name || '-';
    },
  },
  {
    title: '포인트',
    dataIndex: 'point',
    key: 'point',
    width: 100,
    align: 'center',
    render: (data) => toCommaNumber(data),
  },
  {
    title: '주문건수',
    dataIndex: 'purchase_count',
    key: 'purchase_count',
    width: 100,
    align: 'center',
    render: (data) => toCommaNumber(data),
  },
  {
    title: '주소',
    dataIndex: 'address',
    key: 'address',
    align: 'left',
  },

  {
    title: '가입일자',
    dataIndex: 'created_at',
    key: 'created_at',
    align: 'center',
    width: 200,
    render: (data) => {
      return moment(data).format('YYYY-MM-DD HH:mm');
    },
  },
];

/**
 * 쿠폰관리 상세 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const CouponDetails = () => {
  const { id: couponId } = useParams();
  const coupon = useAxios();
  const deleteCoupon = useAxios();
  const navigate = useNavigate();
  const [targetUsers, setTargetUsers] = React.useState();
  const [codes, setCodes] = useState();

  const getCouponCodes = async (page = 0) => {
    const { data } = await restApi.get(`/coupons/${couponId}/codes`, { params: { limit: 10, page } });
    setCodes(data);
  };

  const loadData = async () => {
    try {
      await coupon.loadData(getCoupon(couponId));
      const { data } = await restApi.get(`/coupons/${couponId}/accounts`, { params: { limit: 999 } });
      setTargetUsers(data);
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
      navigate('/settings/coupon', { replace: true });
    }
  };

  const handleDeleteCoupon = async () => {
    try {
      await deleteCoupon.loadData(deleteCouponsApi(couponId));
      alert('쿠폰이 삭제되었습니다.');
      navigate('/settings/coupon', { replace: true });
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
    }
  };

  const onDelete = () => {
    const result = window.confirm('쿠폰을 삭제하시겠습니까 ?');
    if (result) {
      handleDeleteCoupon();
    }
  };

  React.useEffect(() => {
    loadData();
    getCouponCodes();
  }, []);

  if (coupon.loading) {
    return <Loading />;
  }

  const userData = [
    {
      label: '이미지',
      content: coupon.data?.image && (
        <img
          style={{ width: 200, height: 100, objectFit: 'contain', border: '1px solid #ddd' }}
          src={`${getAPIHost()}/files/${coupon.data?.image}`}
        />
      ),
      span: 24,
    },
    { label: '쿠폰명', content: coupon.data?.title },
    { label: '생성날짜', content: moment(coupon.data?.created_at).format('YYYY-MM-DD HH:mm') },
    { label: '타겟', content: couponTargettingTypes[coupon.data?.targeting] },

    // { label: '적용상태', content: coupon.data?.status === 'active' ? '적용중' : '적용안함', span: 1 },
    // { label: '적용상태', content: couponStatusTypes[coupon.data?.status || 'WAIT'], span: 1 },
    { label: '할인율', content: `${coupon.data?.sale_per}%`, span: 2 },
    { label: '최대할인금액', content: `${toCommaNumber(coupon.data?.sale_max_price)}`, span: 2 },
  ];

  const xlsxSave = () => {
    restApi
      .get(`/coupons/${couponId}/codes/xlsx`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `쿠폰코드_${coupon.data.title}_${moment().format('YYYYMMDDHHmmss')}.xlsx`);
      });
  };

  return (
    <>
      <PageHeader className="site-page-header" title="쿠폰 조회" />
      <Descriptions bordered>
        {userData.map((data, index) => {
          return (
            <Descriptions.Item key={index} label={data.label} span={data.span ? data.span : 2}>
              {data.content}
            </Descriptions.Item>
          );
        })}
      </Descriptions>

      {coupon?.data?.targeting === 'CODE' ? (
        <div style={{ position: 'relative' }}>
          <h2 style={{ marginTop: 20 }}>쿠폰코드</h2>
          <Table
            columns={CODE_COLUMNS}
            pagination={{
              total: codes?.total_elements,
              pageSize: 10,
              showSizeChanger: false,
              current: codes?.page + 1,
            }}
            onChange={({ current }) => {
              getCouponCodes(current - 1).catch(console.warn);
            }}
            scroll={{ x: 'max-content' }}
            bordered
            dataSource={codes?.items}
          />
          <div>
            <TableButtonWrap
              position="left"
              buttons={[
                { icon: require('../../../../assets/icon_save.png'), label: '엑셀 저장', onClick: xlsxSave },
                // { icon: require('../../../assets/icon_upload.png'), label: '엑셀 업로드', onChange: handleExcelUpload },
              ]}
            />
          </div>
        </div>
      ) : (
        <div>
          <h2 style={{ marginTop: 20 }}>보유 유저</h2>
          <Table
            columns={SelectedColumns}
            pagination={false}
            scroll={{ x: 'max-content' }}
            bordered
            dataSource={targetUsers?.items}
          />
        </div>
      )}
      <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        <Link to={'/settings/coupon/update/' + couponId}>
          <Button size={'large'} className={styles.basicButton} style={{ height: 40, width: 100, marginRight: 5 }}>
            수정
          </Button>
        </Link>

        <Button
          onClick={onDelete}
          size={'large'}
          className={styles.basicButton}
          style={{ height: 40, width: 100, marginRight: 5 }}
        >
          삭제
        </Button>
        <Button
          onClick={() => navigate('/settings/coupon', { replace: true })}
          size={'large'}
          className={styles.basicButton}
          style={{ marginRight: 5, height: 40, width: 100, backgroundColor: '#FF7200', color: 'white' }}
        >
          목록
        </Button>
        {/*<Button size="large" className="basicButton dark" style={{ height: 40, width: 100 }}>*/}
        {/*  <Link to={`/settings/coupon/update/${couponId}`}>수정</Link>*/}
        {/*</Button>*/}
      </div>
    </>
  );
};

export default CouponDetails;
