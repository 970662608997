import React, { useState } from 'react';
import { Button, Card, DatePicker, Input, PageHeader, Radio, Space, Table, Typography } from 'antd';
import useAxios from '../../../hooks/useAxios';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import LabelRow from '../../../components/LabelRow';
import useInput from '../../../hooks/useInput';
import moment from 'moment';
import { createPush as createPushApi, pushTargettingType, pushTargettingTypes } from '../../../apis/pushes';
import { getAccounts } from '../../../apis/accounts';
import { AccountType } from '../../../common/defines';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './coupon.module.less';
import SearchForm from '../../../components/datalist/SearchForm';
import { accountSearchFields } from '../../../common/searchFields';
import { getUserFrom, phoneFormat, toCommaNumber } from '../../../common/utils';
import qs from 'query-string';
import style from '../../work/review/details/reviewDetails.module.less';
import FileUpload from '../../../components/FileUpload';

/**
 * 알림 생성 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const PushCreate = () => {
  const accounts = useAxios();
  const createCoupons = useAxios();
  const [type, setType] = useState('U');
  const title = useInput('');
  const contents = useInput('');
  const uri = useInput('');
  const [mainImage, setMainImage] = useState();
  const [targeting, setTargeting] = React.useState(pushTargettingType.ALL);
  const [sendingAt, setSendingAt] = useState(moment());
  const [page, setPage] = React.useState(0);
  const [limit] = React.useState(10);
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = React.useState([]);
  const location = useLocation();

  const handleClick = (t) => {
    setTargeting(t);
  };

  const handleSubmit = async () => {
    if (targeting === pushTargettingType.TARGETING) {
      if (selectedUser.length === 0) {
        alert('타켓팅 유저를 선택해주세요.');
        return;
      }
    }

    if (!title.value.length > 0) {
      alert('제목을 입력해주세요.');
      return;
    }

    try {
      const requestBody = {
        type,
        title: title.value,
        contents: contents.value,
        uri: uri.value,
        image: mainImage,
        method: targeting,
        user_ids: selectedUser?.map((v) => v.id),
        sending_at: sendingAt.format('YYYY-MM-DD HH:mm:ss'),
      };
      const { data: responseData } = await createCoupons.loadData(createPushApi(requestBody));
      if (!responseData) return;

      alert('푸시가 등록 되었습니다.');
      navigate('/settings/push', { replace: true });
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
    }
  };

  const loadData = async () => {
    try {
      await accounts.loadData(getAccounts({ params: { type, page, limit } }));
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
    }
  };

  React.useEffect(() => {
    loadData().catch(console.warn);
  }, [page]);

  const pagination = {
    position: ['bottomCenter'],
    total: accounts.data?.total_elements,
    pageSize: limit,
    current: page + 1,
    onChange: (page) => {
      setPage(page - 1);
    },
  };

  React.useEffect(() => {
    const loadAccount = async () => {
      const query = qs.parse(location?.search);
      const params = {
        ...query,
        type,
        limit: 10,
      };
      params.dir = query.sort === 'id' ? 'asc' : 'desc';
      if (query.field === 'name') {
        params.name = query.keyword;
      } else if (query.field === 'phone') {
        params.phone = query.keyword;
      }
      try {
        await accounts.loadData(getAccounts({ params: params }));
      } catch (e) {
        console.warn(e);
      }
    };
    loadAccount().catch(console.warn);
  }, [location?.search, type]);

  const columns = React.useMemo(
    () => [
      {
        title: '번호',
        align: 'center',
        key: 'id',
        dataIndex: 'id',
        width: 90,
      },
      {
        title: '이름',
        key: 'nickname',
        dataIndex: 'nickname',
        width: 100,
        align: 'center',
        render: (r) => (
          <Typography.Text ellipsis={true} style={{ width: 100 }}>
            {r}
          </Typography.Text>
        ),
      },
      {
        title: '연락처',
        dataIndex: 'phone',
        key: 'phone',
        width: 150,
        align: 'center',
        render: (r) => phoneFormat(r),
      },
      {
        title: '가입수단',
        dataIndex: 'platform',
        key: 'platform',
        width: 90,
        align: 'center',
        render: (data, row) => {
          return getUserFrom(row.signname, data);
        },
      },
      {
        title: '멤버십등급',
        dataIndex: 'membership',
        key: 'membership',
        width: 100,
        align: 'center',
        render: (_, __, index) => {
          return _?.name || '-';
        },
      },
      {
        title: '포인트',
        dataIndex: 'point',
        key: 'point',
        width: 100,
        align: 'center',
        render: (data) => toCommaNumber(data),
      },
      {
        title: '주문건수',
        dataIndex: 'purchase_count',
        key: 'purchase_count',
        width: 100,
        align: 'center',
        render: (data) => toCommaNumber(data),
      },
      {
        title: '주소',
        dataIndex: 'address',
        key: 'address',
        align: 'left',
      },

      {
        title: '가입일자',
        dataIndex: 'created_at',
        key: 'created_at',
        align: 'center',
        width: 200,
        render: (data) => {
          return moment(data).format('YYYY-MM-DD HH:mm');
        },
      },
      {
        title: '추가',
        dataIndex: 'id',
        key: 'add',
        align: 'center',
        width: 100,
        render: (_, record) => (
          <Button
            onClick={() =>
              setSelectedUser((prev) =>
                [...prev, record].filter((e, i, a) => a.findIndex((e2) => e2.id === e.id) === i),
              )
            }
          >
            추가
          </Button>
        ),
      },
    ],
    [],
  );

  const SelectedColumns = React.useMemo(
    () => [
      {
        title: '번호',
        align: 'center',
        key: 'id',
        dataIndex: 'id',
        width: 90,
      },
      {
        title: '이름',
        key: 'nickname',
        dataIndex: 'nickname',
        width: 100,
        align: 'center',
        render: (r) => (
          <Typography.Text ellipsis={true} style={{ width: 100 }}>
            {r}
          </Typography.Text>
        ),
      },
      {
        title: '연락처',
        dataIndex: 'phone',
        key: 'phone',
        width: 150,
        align: 'center',
        render: (r) => phoneFormat(r),
      },
      {
        title: '가입수단',
        dataIndex: 'platform',
        key: 'platform',
        width: 90,
        align: 'center',
        render: (data, row) => {
          return getUserFrom(row.signname, data);
        },
      },
      {
        title: '멤버십등급',
        dataIndex: 'membership',
        key: 'membership',
        width: 100,
        align: 'center',
        render: (_, __, index) => {
          return _?.name || '-';
        },
      },
      {
        title: '포인트',
        dataIndex: 'point',
        key: 'point',
        width: 100,
        align: 'center',
        render: (data) => toCommaNumber(data),
      },
      {
        title: '주문건수',
        dataIndex: 'purchase_count',
        key: 'purchase_count',
        width: 100,
        align: 'center',
        render: (data) => toCommaNumber(data),
      },
      {
        title: '주소',
        dataIndex: 'address',
        key: 'address',
        align: 'left',
      },

      {
        title: '가입일자',
        dataIndex: 'created_at',
        key: 'created_at',
        align: 'center',
        width: 200,
        render: (data) => {
          return moment(data).format('YYYY-MM-DD HH:mm');
        },
      },
      {
        title: '삭제',
        dataIndex: 'id',
        key: 'delete',
        align: 'center',
        width: 100,
        render: (data, record) => (
          <Button
            onClick={() => {
              if (!window.confirm('삭제하시겠습니끼?')) return;
              setSelectedUser((prev) => prev.filter((x) => x !== record));
            }}
          >
            삭제
          </Button>
        ),
      },
    ],
    [],
  );

  const format = 'HH:mm';

  return (
    <div>
      <PageHeader className="site-page-header" title="푸시 등록" />
      <div className="labelrowWrapper">
        <LabelRow label="제목">
          <Radio.Group
            value={type}
            onChange={(e) => {
              setType(e.target.value);
            }}
          >
            <Radio.Button value={'U'}>사용자</Radio.Button>
            <Radio.Button value={'T'}>기사, 센터</Radio.Button>
            <Radio.Button value={'M'}>장인</Radio.Button>
          </Radio.Group>
        </LabelRow>
        <LabelRow label="제목">
          <Input {...title} placeholder="최대 30자 이내로 입력하세요." style={{ width: 500 }} />
        </LabelRow>
        <LabelRow label="내용">
          <Input {...contents} placeholder="최대 30자 이내로 입력하세요." style={{ width: 500 }} />
        </LabelRow>
        <LabelRow label="클릭링크">
          <Input
            {...uri}
            placeholder="클릭시 연결될 링크를 입력해주세요. (웹인경우 https포함)"
            style={{ width: 500 }}
          />
        </LabelRow>

        <LabelRow label="이미지" heightOver>
          <div>
            <FileUpload
              maxCount={1}
              values={mainImage ? [mainImage] : []}
              onChange={(images) => {
                setMainImage(images[0]);
              }}
              className="profileImg"
            />
            <span style={{ fontSize: 12, color: '#3d3d3d', margin: '6px 0px', display: 'block' }}>
              이미지사이즈는 자유 사이즈로 800*800 기준으로 많이 업로드합니다.
            </span>
          </div>
        </LabelRow>
        <LabelRow label="타켓팅" heightOver>
          <div>
            {Object.keys(pushTargettingType)?.map((key) => {
              return (
                <Button
                  type={targeting === key ? 'primary' : undefined}
                  className={styles.targetButton}
                  onClick={() => handleClick(key)}
                >
                  {pushTargettingTypes[key]}
                </Button>
              );
            })}
            <span style={{ fontSize: 10, marginTop: 10, display: 'block' }}>
              · 첫이용고객을 위한 첫이용 쿠폰 혜택을 등록하세요.
            </span>
          </div>
        </LabelRow>
        {targeting === pushTargettingType.TARGETING && (
          <Card>
            <div style={{ marginBottom: 20 }}>
              <SearchForm fields={accountSearchFields} />
              <div className="tableLayout" style={{ marginTop: 20, backgroundColor: '#fff', border: '1px #ddd solid' }}>
                <Table
                  columns={columns}
                  // rowSelection={rowSelection}
                  pagination={pagination}
                  // rowKey={({ id }) => id}
                  scroll={{ x: 'max-content' }}
                  bordered
                  dataSource={accounts.data?.items}
                  loading={accounts.loading}
                />
              </div>
              <div>
                <h2 style={{ marginTop: 20 }}>타겟팅 유저</h2>
                <Table
                  columns={SelectedColumns}
                  pagination={false}
                  scroll={{ x: 'max-content' }}
                  bordered
                  dataSource={selectedUser}
                  loading={accounts.loading}
                />
              </div>
            </div>
          </Card>
        )}
        <LabelRow label="전송 예정">
          <Space>
            <DatePicker
              showTime={{ format: 'HH:mm' }}
              value={sendingAt}
              format="YYYY-MM-DD HH:mm"
              onChange={(v) => setSendingAt(v)}
            />
            {/*<TimePicker defaultValue={moment('12:08', format)} format={format} />*/}
          </Space>
        </LabelRow>
      </div>
      <div className={style.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={() => navigate('/settings/push', { replace: true })}
          size={'large'}
          className={style.basicButton}
          style={{ height: 40, width: 100, marginRight: 5 }}
        >
          목록
        </Button>
        <Button
          onClick={handleSubmit}
          size={'large'}
          className={style.basicButton}
          style={{ marginRight: 5, height: 40, width: 100, backgroundColor: '#FF7200', color: 'white' }}
        >
          등록
        </Button>
        {/*<Button size="large" className="basicButton dark" style={{ height: 40, width: 100 }}>*/}
        {/*  <Link to={`/settings/coupon/update/${couponId}`}>수정</Link>*/}
        {/*</Button>*/}
      </div>
    </div>
  );
};

export default PushCreate;
