import React, { useState } from 'react';
import { Button, DatePicker, Descriptions, Input, PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import useInput from '../../../../hooks/useInput';
import styles from '../../../work/review/details/reviewDetails.module.less';
import { getPost, updatePost as updatePostApi } from '../../../../apis/posts';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import moment from 'moment';
import TinyMCE from 'react-tinymce';

/**
 * FAQ 수정 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const FAQUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const post = useAxios();
  const title = useInput('');
  const updatePost = useAxios();
  const [contents, setContents] = React.useState('');
  const [createdAt, setCreatedAt] = useState();

  // 뒤로 가기
  const handleCancel = () => {
    navigate('/contents/faq', { replace: true });
  };

  // 수정 컨펌 함수
  const handleUpdateost = async () => {
    if (!title.value.length > 0) {
      alert('제목을 입력해주세요.');
      return;
    }
    if (contents === '<p></p>\n') {
      alert('내용을 입력해주세요.');
      return;
    }
    try {
      await updatePost.loadData(
        updatePostApi({
          id,
          type: 'FAQ',
          title: title.value,
          contents: contents,
          created_at: createdAt.format('YYYY-MM-DD HH:mm:ss'),
        }),
      );
      alert('수정 완료되었습니다.');
      // router.push(`/contents/faq/details/${id}`);
      navigate('/contents/faq', { replace: true });
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
    }
  };

  const [loading, setLoading] = useState(true);

  // 초기 상세 데이터 패칭
  React.useEffect(() => {
    post
      .loadData(getPost(id))
      .then(({ data: responseData }) => {
        title.onChange(responseData.title);
        setContents(responseData?.contents);
        setCreatedAt(moment(responseData?.created_at));
        setLoading(false);
      })
      .catch((error) => {
        console.warn(error);
        alert(error?.response?.data?.message);
        navigate(-1);
      });
  }, []);

  if (loading) return null;

  return (
    <div>
      <PageHeader className="site-page-header" title="FAQ 수정" />
      <Descriptions bordered>
        <Descriptions.Item label={'제목'} span={4} className="nopadding">
          <Input {...title} bordered={false} placeholder="제목을 입력해주세요" style={{ padding: '10px 20px' }} />
        </Descriptions.Item>
        <Descriptions.Item label={'등록일'} span={4} className="nopadding" style={{ padding: '10px 20px' }}>
          <DatePicker value={createdAt} onChange={(v) => setCreatedAt(v)} showTime />
          {/*{userType === 'M' ? '장인' : userType === 'T' ? '기사' : '고객'}*/}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions bordered>
        <Descriptions.Item label={'답변 내용'} span={4} className="nopadding" style={{ verticalAlign: 'top' }}>
          <TinyMCE
            content={contents ?? ''}
            config={{
              content_css: '/editor.css',
              plugins: 'code table',
              toolbar:
                'undo redo styleselect bold italic alignleft aligncenter alignright bullist numlist outdent indent code',
            }}
            onChange={(e) => {
              const text = e.target.getContent();
              setContents(text);
            }}
          />
        </Descriptions.Item>
      </Descriptions>
      <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={handleCancel}
          size={'large'}
          className={styles.basicButton}
          style={{ marginRight: 5, height: 40, width: 100 }}
        >
          취소
        </Button>
        <Button
          onClick={handleUpdateost}
          size={'large'}
          className={styles.basicButton}
          style={{ height: 40, width: 100, backgroundColor: '#FF7200', color: 'white' }}
        >
          수정
        </Button>
      </div>
    </div>
  );
};

export default FAQUpdate;
