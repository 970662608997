import { useState } from 'react';

/**
 * 텍스트 인풋 관련 커스텀 훅스
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const useInput = (defaultValue) => {
    const [value, setValue] = useState(defaultValue);

    const onChange = (e) => {
        console.log({ e })
        const { target } = e ?? {};
        const { value } = target ?? {};
        setValue(value ?? e);
    };

    return { value, onChange };
};

export default useInput;
