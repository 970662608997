import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {restApi} from "../../apis";

const initialState = {
  items: {},
};

/**
 * 작업 관련 Redux
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
export const getWorksItems = createAsyncThunk('items', async () => {
  const response = await restApi.get(`/works/items`);
  return response.data;
});

const AuthSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {},
  extraReducers: {
    [getWorksItems.fulfilled]: (state, action) => {
      state.items = action.payload;
    },
  },
});

export const {} = AuthSlice.actions;

export default AuthSlice.reducer;
