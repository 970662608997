import React, { useEffect, useRef, useState } from 'react';
import { Button, notification, Popover, Timeline } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import { restApi } from '../apis';
import moment from 'moment';
import { uniq } from 'lodash';

const PopoverContents = ({ items }) => {
  const handleRead = async (id) => {
    await restApi.put(`/notifies/${id}/active`, { active: true });
  };
  return (
    <Timeline style={{ maxWidth: 300 }}>
      {items?.map((item) => (
        <Timeline.Item key={`${item.id}`} color={!item.readed ? 'red' : 'gray'}>
          {item.message}
          <a href={item.link} style={{ marginLeft: 5 }} onClick={() => handleRead(item.id)}>
            더보기
          </a>
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

/**
 * 상단 헤더 알림 관련 컴포넌트
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const NotifyTool = (props) => {
  const [visible, setVisible] = useState();
  const [data, setData] = useState();
  const ldt = useRef(0);
  const audio1 = useRef();
  const audio2 = useRef();
  const audio3 = useRef();
  const [already, setAlready] = useState([]);

  const loadData = async () => {
    const { data } = await restApi.get(`/notifies`, { params: { limit: 20 } });

    const last_at = data?.items?.reduce((a, b) => {
      const ts = moment(b.created_at).unix();
      return a < ts ? ts : a;
    }, 0);

    setData({
      items: data?.items,
      last_at,
    });
  };

  useEffect(() => {
    if (ldt.current === data?.last_at) return;

    data?.items
      ?.filter((v) => !v.readed)
      ?.forEach((o) => {
        const key = `key_${o.id}`;
        const btn = (
          <Button
            type="primary"
            size="small"
            onClick={async () => {
              await restApi.put(`/notifies/${o.id}/active`, { active: true });
              notification.close(key);
              if (o.link) {
                window.location.href = o.link;
              }
            }}
          >
            확인하기
          </Button>
        );

        let style = {};
        let color = '#333';
        if (o.view === 'success') {
          color = '#fff';
          style = {
            background: '#4880ff',
          };
        } else if (o.view === 'error') {
          color = '#fff';
          style = {
            background: '#f00',
          };
        } else if (o.view === 'info') {
          color = '#fff';
          style = {
            background: '#964b00',
          };
        }

        if (o.message) {
          notification.open({
            placement: 'bottomLeft',
            message: <span style={{ color }}>{o.message}</span>,
            duration: 0,
            btn,
            key,
            style,
          });
        }
      });

    const len1 = data?.items?.filter(
      (v) => !v.readed && v.quiet === false && v.view === 'warning' && !already.includes(v.id),
    )?.length;
    if (len1 > 0) {
      audio1.current.play();
    }

    const len2 = data?.items?.filter(
      (v) => !v.readed && v.quiet === false && v.view === 'success' && !already.includes(v.id),
    )?.length;
    if (len2 > 0) {
      audio2.current.play();
    }

    const len3 = data?.items?.filter(
      (v) => !v.readed && v.quiet === false && v.view === 'error' && !already.includes(v.id),
    )?.length;
    if (len3 > 0) {
      audio3.current.play();
    }

    setAlready((draft) => {
      const o = [...draft].concat(data?.items?.map((v) => v.id));
      return uniq(o);
    });

    ldt.current = data?.last_at;
  }, [data?.last_at, already]);

  useEffect(() => {
    loadData().catch(console.warn);
    const tk = setInterval(() => loadData().catch(console.warn), 30 * 1000);
    return () => clearInterval(tk);
  }, []);

  return (
    <>
      <Popover
        content={<PopoverContents items={data?.items} />}
        title="알림"
        trigger="click"
        visible={visible}
        onVisibleChange={setVisible}
        placement="bottomRight"
      >
        <Button
          shape="circle"
          type={data?.items?.filter((v) => !v.readed)?.length ? 'primary' : undefined}
          icon={<BellOutlined />}
        />
      </Popover>
      <audio ref={audio1} src="/sound.mp3"></audio>
      <audio ref={audio2} src="/sound2.mp3"></audio>
      <audio ref={audio3} src="/sound3.mp3"></audio>
    </>
  );
};

export default NotifyTool;
