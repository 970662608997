import React from 'react';
import { Button, DatePicker, Descriptions, Input, PageHeader, Select } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import useInput from '../../../../hooks/useInput';
import moment from 'moment';
import { getBanner, updateBanner as updateBannerApi } from '../../../../apis/banners';
import FileUpload from '../../../../components/FileUpload';
import styles from '../../../work/review/details/reviewDetails.module.less';

/**
 * 하단배너 수정 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const BannerBottomUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const banner = useAxios();

  const title = useInput('');
  const uri = useInput('');
  const order = useInput('');

  const [mode, setMode] = React.useState('MAIN_BOTTOM');
  const [images, setImages] = React.useState(null);
  const [startDate, setStartDate] = React.useState(moment());
  const [endDate, setEndDate] = React.useState(moment());

  // 취소 버튼 눌러서 뒤로가기
  const handleCancel = () => {
    navigate('/contents/banner_top', { replace: true });
  };

  // 배너 수정 컨펌 함수
  const handleUpdateBanner = async () => {
    if (!title.value) {
      alert('제목을 입력해주세요');
      return;
    }
    if (!startDate) {
      alert('시작일을 입력해주세요');
      return;
    }
    if (!endDate) {
      alert('종료일을 입력해주세요');
      return;
    }
    if (!order.value) {
      alert('순번을 입력해주세요');
      return;
    }
    if (moment(startDate).isAfter(endDate)) {
      alert('종료일이 시작일보다 이전입니다');
      return;
    }
    if (!images) {
      alert('이미지를 업로드해주세요');
      return;
    }
    try {
      await banner.loadData(
        updateBannerApi({
          id,
          mode,
          title: title.value,
          image: images,
          uri: uri.value,
          start_at: startDate,
          end_at: endDate,
          order_index: order.value,
        }),
      );
      alert('수정 완료되었습니다.');
      navigate('/contents/banner_top', { replace: true });
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message || '최대 10개까지 등록 가능합니다.');
    }
  };

  // 배너 타입 변경
  const handleBannerType = (data) => {
    setMode(data);
  };

  // 배너 상세 데이터 초기 패칭
  React.useEffect(() => {
    (async () =>
      banner
        .loadData(getBanner(id))
        .then(({ data: responseData }) => {
          title.onChange(responseData.title);
          uri.onChange(responseData.uri);
          order.onChange(responseData.order_index);
          setImages(responseData.image);
          setMode(responseData?.mode);
          responseData?.start_at && setStartDate(moment(responseData?.start_at));
          responseData?.end_at && setEndDate(moment(responseData?.end_at));
        })
        .catch((error) => {
          console.warn(error);
          alert(error?.response?.data?.message);
          navigate(-1);
        }))();
  }, []);

  return (
    <div>
      <PageHeader className="site-page-header" title="배너 수정" />
      <Descriptions bordered>
        <Descriptions.Item label={'배너대상'} span={4} className="nopadding" style={{ padding: '10px 20px' }}>
          <Select
            defaultValue="MAIN_BOTTOM"
            style={{ width: 140, fontSize: 14, marginRight: 10 }}
            size={'large'}
            onChange={handleBannerType}
          >
            <Select.Option value="MAIN_TOP">메인상단</Select.Option>
            <Select.Option value="MAIN_BOTTOM">메인하단</Select.Option>
          </Select>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <>
              <span>제목</span>
              <span style={{ color: 'red' }}> *</span>
            </>
          }
          span={4}
          className="nopadding"
        >
          <Input {...title} bordered={false} placeholder="제목을 입력해주세요" style={{ padding: '10px 20px' }} />
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <>
              <span>배너업로드</span>
              <span style={{ color: 'red' }}> *</span>
            </>
          }
          span={4}
          className="nopadding"
          style={{ padding: 25 }}
        >
          <FileUpload
            values={images ? [images] : []}
            maxCount={1}
            onChange={(images) => {
              setImages(images[0]);
            }}
            className="profileImg"
          />
          <a href={'/banner_foot.psd'} target={'_blank'}>
            배너가이드 받기
          </a>
        </Descriptions.Item>
        <Descriptions.Item label={'광고 URL'} span={4} className="nopadding">
          <Input {...uri} bordered={false} placeholder="URL 입력해주세요" style={{ padding: '10px 20px' }} />
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <>
              <span>광고시작일</span>
              <span style={{ color: 'red' }}> *</span>
            </>
          }
          span={4}
          className="nopadding"
        >
          <DatePicker
            value={startDate}
            onChange={setStartDate}
            style={{ width: 200, fontSize: 12, marginRight: 5 }}
            placeholder="날짜선택"
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <>
              <span>광고종료일</span>
              <span style={{ color: 'red' }}> *</span>
            </>
          }
          span={4}
          className="nopadding"
        >
          <DatePicker
            value={endDate}
            onChange={setEndDate}
            style={{ width: 200, fontSize: 12, marginRight: 5 }}
            placeholder="날짜선택"
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <>
              <span>순번</span>
              <span style={{ color: 'red' }}> *</span>
            </>
          }
          span={4}
          className="nopadding"
        >
          <Input
            {...order}
            bordered={false}
            placeholder="광고 순서를 입력해주세요"
            style={{ padding: '10px 20px' }}
            type={'number'}
          />
        </Descriptions.Item>
      </Descriptions>
      <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={handleCancel}
          size={'large'}
          className={styles.basicButton}
          style={{ marginRight: 5, height: 40, width: 100 }}
        >
          취소
        </Button>
        <Button
          onClick={handleUpdateBanner}
          size={'large'}
          className={styles.basicButton}
          style={{ height: 40, width: 100, backgroundColor: '#FF7200', color: 'white' }}
        >
          수정
        </Button>
      </div>
    </div>
  );
};

export default BannerBottomUpdate;
