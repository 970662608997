import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Upload, Button, Tooltip } from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import useAxios from '../hooks/useAxios';
import { restApi, getFileHost } from '../apis/index';
import LoadingIndicator from './LoadingIndicator';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import getBase64 from '../utils/getBase64';
import update from 'immutability-helper';
import produce from 'immer';
import { parseImageUrl } from '../common/utils';

const type = 'DragableUploadList';

const DragableUploadListItem = React.memo(({ originNode, moveRow, file, fileList, maxCount }) => {
  const ref = React.useRef();
  const index = fileList.indexOf(file);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  const errorNode = <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>;
  return (
    <div
      ref={ref}
      className={`ant-upload-draggable-list-item ${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move' }}
    >
      {file.status === 'error' ? errorNode : originNode}
    </div>
  );
});
/**
 * 파일 업로드 컴포넌트
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const FileUpload = ({ values = [], onChange, maxCount, disabled, ...props }) => {
  const [showModal, setShowModal] = useState();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (values?.length) {
      setFiles(values?.map((v) => ({ uid: v, fid: v, name: `${v}.jpg`, url: parseImageUrl(v, 100, 100) })));
    }
  }, [values]);

  const handlePreview = async (e) => {
    setShowModal(e.url.substring(0, e.url.indexOf('?')));
  };

  const handleChange = (e) => {
    setFiles(e.fileList);
    if (e.fileList.filter((v) => v.status === 'uploading')?.length) return;
    if (e.file.status === 'removed') {
      const list = e.fileList?.filter((v) => !!v.fid)?.map((v) => v.fid) ?? [];
      onChange(list);
    } else if (e.file.status === 'done') {
      console.log({ list: e.fileList });

      const v = e.file?.response?.id;
      const list = e.fileList
        ?.map((v) => {
          if (v.fid) return v;

          if (v.response?.id) {
            return {
              fid: v.response?.id,
              name: v.response?.id + '.jpg',
              uid: v.response?.id,
              url: parseImageUrl(v.response?.id, 100, 100),
            };
          }
          return undefined;
        })
        .filter((v) => !!v);
      // list.push(v);
      onChange(list?.map((v) => v.fid));
    }
  };

  const handleMoveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = files[dragIndex];
      const _files = update(files, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });
      setFiles(_files);

      const list = _files?.filter((v) => !!v.fid)?.map((v) => v.fid) ?? [];
      onChange(list);
    },
    [files],
  );

  return (
    <div className={'file-dnd'}>
      <DndProvider backend={HTML5Backend}>
        <Upload
          accept={'image/png, image/gif, image/jpeg'}
          disabled={disabled}
          action={getFileHost()}
          maxCount={maxCount}
          listType="picture-card"
          name="filedata"
          method="put"
          multiple={maxCount > 1}
          onPreview={handlePreview}
          fileList={files}
          onChange={handleChange}
          itemRender={(originNode, file, currFileList) => (
            <DragableUploadListItem
              originNode={originNode}
              file={file}
              fileList={currFileList}
              moveRow={handleMoveRow}
            />
          )}
          {...props}
        >
          {(files?.length ?? 0) < maxCount && (
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          )}
        </Upload>
      </DndProvider>
      <Modal
        centered
        visible={!!showModal}
        title={'이미지 크게보기'}
        footer={null}
        onCancel={() => setShowModal(false)}
      >
        <img alt="priview" style={{ width: '100%' }} src={showModal} />
      </Modal>
    </div>
  );
};

export default React.memo(FileUpload);
