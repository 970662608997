import React, { useMemo, useState } from 'react';
import { Avatar, Button, Descriptions, Divider, Input, Modal, PageHeader, Space, Table, Tabs } from 'antd';
import { NEW_RUN_STATE, PaymentType } from '../../../../common/defines';
import moment from 'moment';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import qs from 'query-string';
import { accountDetailSearchFields } from '../../../../common/searchFields';
import SearchForm from '../../../../components/datalist/SearchForm';
import { getAPIHost, restApi } from '../../../../apis';
import fileDownload from 'js-file-download';
import { parseImageUrl, phoneFormat, toCommaNumber } from '../../../../common/utils';
import { shallowEqual, useSelector } from 'react-redux';
import { flatten } from 'lodash';
import CalcView from '../../../../components/CalcView';

/**
 * 장인 상세 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const AccountExpertDetails = () => {
  const [preview, setPreview] = useState();
  // 상품 리덕스에서 조회
  const product = useSelector((s) => s.product, shallowEqual);

  // 상품 flat 처리
  const productItems = useMemo(() => {
    return flatten(Object.values(product.items));
  }, [product]);

  // 테이블 컬럼 정의
  const columns = [
    {
      title: '주문번호',
      dataIndex: 'purchase',
      align: 'center',
      width: 120,
      render: (data) => data?.id,
    },
    {
      key: 'type',
      title: '서비스',
      dataIndex: 'purchase',
      align: 'center',
      width: 120,
      // render: (data) => data?.reduce((a, c) => [_, c.subject], []),
      render: (data) => PaymentType[data?.type],
    },
    {
      key: 'shoes_count',
      title: '켤레수',
      dataIndex: 'purchase',
      align: 'center',
      width: 100,
      render: (data) => data?.shoes_count,
    },
    // {
    //   title: '배송상태',
    //   dataIndex: 'state',
    //   align: 'center',
    //   render: (data) => RUN_STATES[data] ?? data,
    // },
    {
      title: '작업내역',
      dataIndex: 'purchase',
      align: 'center',
      width: 200,
      render: (data) => {
        const items = productItems?.filter((v) => data?.products?.includes(v.id));

        return items?.map((v) => (
          <div key={String(v.id)}>
            {v.subject} - {v.name}
          </div>
        ));
      },
    },
    {
      title: '작업일',
      dataIndex: 'created_at',
      align: 'center',
      width: 160,
      render: (data) => {
        return moment(data).format('YYYY-MM-DD HH:mm');
      },
    },
    {
      title: '진행현황',
      dataIndex: 'state',
      align: 'center',
      width: 150,
      render: (data) => NEW_RUN_STATE[data] ?? data,
    },
    // {
    //   title: '주문금액',
    //   dataIndex: 'purchase',
    //   align: 'center',
    //   width: 150,
    //   render: (data) => toCommaNumber(data?.sale_price),
    // },
    {
      title: '정산금액',
      dataIndex: 'return_money',
      align: 'center',
      width: 150,
      render: (data) => toCommaNumber(data),
    },
    {
      title: '작업이미지',
      dataIndex: 'images',
      align: 'center',
      render: (data) => {
        if (!data?.length) return null;
        return (
          <div onClick={() => setPreview(data)}>
            <Space>
              {data?.map((v) => (
                <Avatar shape={'square'} src={parseImageUrl(v)} size={80} key={String(v)} />
              ))}
            </Space>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'purchase',
      align: 'center',
      width: 100,
      render: (data) => (
        <Link to={`/work/order/details/${data?.id}`}>
          <a style={{ color: 'black' }}>자세히 보기</a>
        </Link>
      ),
    },
  ];

  // url params
  const { id: accountId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // url query
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);

  // 관리번호 수정
  const handleSubmitAlias = async () => {
    const { data } = await restApi.put(`/accounts/${accountId}`, { alias });
    alert('저장되었습니다.');
  };

  const [data, setData] = React.useState();
  const [accountData, setAccountData] = React.useState();
  const [alias, setAlias] = useState();
  // 유저정보 테이블 렌더
  const userData = [
    {
      label: '관리번호',
      content: (
        <div>
          <Input.Group compact>
            <Input
              value={alias}
              placeholder={accountData?.corp_department + '-' + accountData?.corp_serial}
              onChange={(e) => {
                const v = e.target.value;
                setAlias(v);
              }}
              style={{ width: 200 }}
            />
            <Button onClick={handleSubmitAlias}>저장</Button>
          </Input.Group>
        </div>
      ),
      span: 2,
    },
    { label: '성명', content: accountData?.nickname, span: 2 },
    { label: '주소', content: accountData?.address, span: 2 },
    { label: '은행', content: accountData?.bank, span: 2 },
    { label: '연락처', content: phoneFormat(accountData?.phone), span: 2 },
    { label: '계좌번호', content: accountData?.bankno, span: 2 },
    { label: '가입일자', content: moment(accountData?.created_at).format('YYYY-MM-DD HH:mm') },
  ];

  // 탈퇴처리
  const handleLeave = async () => {
    if (!window.confirm('탈퇴 처리하시겠습니까?\n복구가 불가능합니다.')) return;
    try {
      await restApi.delete(`/accounts/admin/${accountData.id}`);
      alert('삭제되었습니다.');
      window.history.back();
    } catch (e) {
      alert(e?.response?.data?.message || '서버와 연결이 올바르지 않습니다.');
    }
  };
  // 회원상세정보 조회
  const getAccountInfo = async (id) => {
    const { data } = await restApi.get(`/accounts/${id}`);
    setAccountData(data);
    setAlias(data?.alias);
  };

  React.useEffect(() => {
    if (!accountId) return;
    getAccountInfo(accountId);
  }, [accountId]);

  // 테이블 페이지네이션
  const pagination = {
    position: ['bottomCenter'],
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      navigate('?' + qs.stringify({ ...query, page: p - 1 }), { replace: true });
    },
  };

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      ...query,
      account_id: accountId,
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    }

    const { data } = await restApi.get('/purchases/histories', {
      params,
    });
    setData(data);
  }, [query]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);



  return (
    <div>
      <Modal
        visible={!!preview}
        title={'이미지 크게보기'}
        onCancel={() => setPreview(undefined)}
        footer={() => null}
        bodyStyle={{ padding: 0 }}
      >
        {preview?.map((v) => (
          <img src={parseImageUrl(v)} style={{ maxWidth: '100%' }} key={`${v}`} />
        ))}
      </Modal>
      <PageHeader
        className="site-page-header"
        title="장인 회원정보"
        extra={[<Button onClick={() => navigate(-1)}>목록</Button>]}
      />
      <Descriptions bordered>
        {userData.map((data, index) => {
          return (
            <Descriptions.Item key={`${data.label}-${index}`} label={data.label} span={data.span ?? 3}>
              {data.content}
            </Descriptions.Item>
          );
        })}
      </Descriptions>

      <div style={{ marginTop: 20 }}>
        <Space>
          <Button onClick={handleLeave} danger>
            회원탈퇴
          </Button>
        </Space>
      </div>
      <Divider />
      <Tabs>
        <Tabs.TabPane tab={`작업내역(총 ${data?.total_elements || 0}개)`} key={'works'}>
          <div style={{ position: 'relative' }}>
            <Table
              scroll={{ x: 'max-content' }}
              title={() => (
                <>
                  <SearchForm fields={accountDetailSearchFields} />
                </>
              )}
              dataSource={data?.items}
              columns={columns}
              pagination={
                pagination
                  ? {
                      ...pagination,
                      position: ['bottomCenter'],
                      showSizeChanger: false,
                    }
                  : false
              }
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={'정산내역'} key={'calcs'}>
          <CalcView id={accountId} detColumns={columns} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default AccountExpertDetails;
