import React from 'react';
import { useNavigate } from 'react-router-dom';

const Index = (props) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate('/accounts/user', { replace: true });
  }, []);

  return <div />;
};

export default Index;
