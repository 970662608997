import React from 'react';
import { PageHeader, Table } from 'antd';
import { pushSearchField } from '../../../common/searchFields';
import SearchForm from '../../../components/datalist/SearchForm';
import TableButtonWrap from '../../../components/TableButtonWrap';
import moment from 'moment';
import { getAPIHost, restApi } from '../../../apis';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import fileDownload from 'js-file-download';
import { pushTargettingStates, pushTargettingTypes } from '../../../apis/pushes';

/**
 * 알림 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const PushIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);

  const [data, setData] = React.useState();

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      ...query,
      title: query?.keyword,
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.title = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    }

    const { data } = await restApi.get('/pushes', {
      params,
    });
    setData(data);
  }, [query]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  const columns = [
    {
      title: '번호',
      dataIndex: 'id',
      width: 90,
      key: 'id',
      align: 'center',
      render: (_, _2, i) => {
        return data?.total_elements - i - data?.page * 10;
      },
    },
    {
      title: '종류',
      dataIndex: 'type',
      width: 100,
      align: 'center',
      render: (data) => (data === 'M' ? '장인' : data === 'T' ? '기사' : '사용자'),
    },
    {
      title: '타겟팅',
      dataIndex: 'method',
      width: 140,
      align: 'center',
      render: (data) => pushTargettingTypes[data],
    },
    {
      title: '상태',
      dataIndex: 'status',
      width: 140,
      align: 'center',
      render: (data) => pushTargettingStates[data],
    },
    {
      title: '제목',
      dataIndex: 'title',
      align: 'left',
    },
    {
      title: '등록일',
      width: 200,
      dataIndex: 'created_at',
      align: 'center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm'),
    },
    {
      title: '자세히보기',
      width: 120,
      dataIndex: 'id',
      align: 'center',
      render: (data) => (
        <Link to={`/settings/push/details/${data}`}>
          <a style={{ color: 'black' }}>자세히보기</a>
        </Link>
      ),
    },
  ];

  // 테이블 페이지네이션
  const pagination = {
    position: ['bottomCenter'],
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      navigate('?' + qs.stringify({ ...query, page: p - 1 }), { replace: true });
    },
  };

  const xlsxSave = () => {
    const args = {
      ...query,
    };
    restApi
      .get(`${getAPIHost()}/exports/push?${qs.stringify(args)}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `푸시알림_${moment().format('YYYYMMDDHHmmss')}.xlsx`);
      });
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="푸시알림 조회" />
      <SearchForm fields={pushSearchField} />
      <div style={{ position: 'relative' }}>
        <Table
          scroll={{ x: 'max-content' }}
          rowKey={({ id }) => id}
          dataSource={data?.items}
          columns={columns}
          pagination={
            pagination
              ? {
                  ...pagination,
                  position: ['bottomCenter'],
                  showSizeChanger: false,
                }
              : false
          }
        />
        <div className={'tableWrapCover'}>
          <TableButtonWrap
            position="left"
            buttons={[{ icon: require('../../../assets/icon_save.png'), label: '엑셀 저장', onClick: xlsxSave }]}
          />
          <TableButtonWrap
            position="right"
            buttons={[
              { icon: require('../../../assets/icon_write.png'), label: '작성', link: '/settings/push/create' },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default PushIndex;
