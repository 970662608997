import React, { useState } from 'react';
import { Modal, PageHeader, Switch, Table, Typography } from 'antd';
import { accountSearchFields } from '../../../common/searchFields';
import SearchForm from '../../../components/datalist/SearchForm';
import TableButtonWrap from '../../../components/TableButtonWrap';
import { phoneFormat } from '../../../common/utils';
import moment from 'moment';
import { getAPIHost, restApi } from '../../../apis';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import fileDownload from 'js-file-download';
import AccountRemoveView from '../../../components/AccountRemoveView';

/**
 * 긴급기사 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const AccountUrgencyIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);

  const [data, setData] = React.useState();
  const [fileLoading, setFileLoad] = React.useState(false);

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      ...query,
      type: 'S',
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    }

    const { data } = await restApi.get('/accounts', {
      params,
    });
    setData(data);
  }, [query]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  // 활성화 상태 변경
  const handleActiveChange = async (id, active) => {
    try {
      if (!window.confirm('변경하시겠습니까?')) {
        return;
      }
      const { data } = await restApi.put(`/accounts/${id}/active`, {
        active,
      });
      await loadData();
    } catch (e) {
      console.warn(e);
    }
  };

  // 테이블 컬럼
  const columns = [
    {
      title: '번호',
      dataIndex: 'id',
      align: 'center',
      width: 90,
      render: (_, _2, i) => {
        return _;
        // return data?.total_elements - i - data?.page * 10;
      },
    },
    {
      title: '승인여부',
      dataIndex: 'active',
      key: 'active',
      width: 100,
      align: 'center',
      render: (x, row) => {
        return <Switch checked={x} onChange={(e) => handleActiveChange(row?.id, e)} />;
      },
    },
    {
      title: '이름',
      dataIndex: 'nickname',
      width: 100,
      align: 'center',
      render: (r) => (
        <Typography.Text ellipsis={true} style={{ width: 100 }}>
          {r}
        </Typography.Text>
      ),
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      align: 'center',
      render: (r) => phoneFormat(r),
    },
    {
      title: '상태',
      dataIndex: 'online_state',
      align: 'center',
      render: (r) => {
        return r === 'ON' ? (
          <span style={{ fontWeight: 'bold', color: '#00dd1f' }}>출근</span>
        ) : r === 'WAIT' ? (
          <span style={{ fontWeight: 'bold', color: '#555' }}>휴식</span>
        ) : (
          <span style={{ fontWeight: 'bold', color: '#db2929' }}>퇴근</span>
        );
      },
    },
    {
      title: '마지막 사용일자',
      dataIndex: 'online_at',
      key: 'online_at',
      align: 'center',
      render: (data) => data && moment(data).format('YYYY-MM-DD HH:mm'),
    },

    {
      title: '가입일자',
      dataIndex: 'created_at',
      align: 'center',
      width: 200,
      render: (data) => {
        return moment(data).format('YYYY-MM-DD HH:mm');
      },
    },
    {
      title: '자세히보기',
      dataIndex: 'id',
      align: 'center',
      width: 100,
      render: (data) => (
        <Link to={`/accounts/urgency/details/${data}`}>
          <a style={{ color: 'black' }}>
            {/*<Icon source={require('../../../assets/icon_gear.png')} size={18} />*/}
            자세히보기
          </a>
        </Link>
      ),
    },
  ];

  // 테이블 페이지네이션
  const pagination = {
    position: ['bottomCenter'],
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      navigate('?' + qs.stringify({ ...query, page: p - 1 }), { replace: true });
    },
  };

  //엑셀 다운로드 로직
  const xlsxSave = () => {
    const params = {
      ...query,
      type: 'S',
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    }
    restApi
      .get(`${getAPIHost()}/exports/accounts/user?${qs.stringify(params)}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `긴급기사_${moment().format('YYYYMMDDHHmmss')}.xlsx`);
      });
  };

  const [rowKeys, setRowKeys] = useState([]);
  const [showModal, setShowModal] = useState();
  // 삭제 로직
  const handleDelete = async () => {
    if (rowKeys?.length === 0) {
      window.alert('삭제할 내역을 선택하세요');
      return;
    }
    if (!window.confirm('삭제하시겠습니끼?')) return;
    await restApi.put(`/cms/account/hides`, {
      ids: rowKeys?.map((v) => parseInt(v, 10)),
      hide: true,
    });
    await loadData();
  };

  // 삭제 모달 켜기
  const handleDeleteModal = () => {
    setShowModal(true);
  };

  // 테이블 체크박스 콜백
  const handleChange = (selectedRowKeys, selectedRows) => {
    setRowKeys(selectedRowKeys);
  };



  return (
    <div>
      <Modal visible={showModal} width={1200} title={'삭제된 항목'} onCancel={() => setShowModal(false)} footer={null}>
        {showModal && (
          <AccountRemoveView
            extra={{ type: 'S' }}
            column={columns}
            loadParent={() => {
              loadData();
              setShowModal(false);
            }}
          />
        )}
      </Modal>
      <PageHeader className="site-page-header" title="긴급기사" />
      <SearchForm fields={accountSearchFields} />
      <div style={{ position: 'relative' }}>
        <Table
          rowSelection={{
            type: 'checkbox',
            onChange: handleChange,
            selectedRowKeys: rowKeys,
          }}
          scroll={{ x: 'max-content' }}
          dataSource={data?.items}
          columns={columns}
          rowKey={'id'}
          pagination={
            pagination
              ? {
                  ...pagination,
                  position: ['bottomCenter'],
                  showSizeChanger: false,
                }
              : false
          }
        />
        <div className={"tableWrapCover"}>
          <TableButtonWrap
            position="left"
            buttons={[
              { icon: require('../../../assets/icon_save.png'), label: '엑셀 저장', onClick: xlsxSave },
              // { icon: require('../../../assets/icon_upload.png'), label: '엑셀 업로드', onChange: handleExcelUpload },
            ]}
          />
          <TableButtonWrap
            position="right"
            buttons={[
              { label: '삭제항목보기', onClick: handleDeleteModal },
              { label: '삭제', onClick: handleDelete },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountUrgencyIndex;
