import React from 'react';
import styles from '../../../nav/BasicLayout.module.less';
import { Button, Input, Modal, Space } from 'antd';
import { parseImageUrl } from '../../../common/utils';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Icon from '../../../components/Icon';

/**
 * 관리자 생성 모달
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const AdminModal = ({
  isModalVisible,
  handleChangeName,
  handleChangeProfileImage,
  handleChangeDepartment,
  handleChangeLevel,
  handleChangePassword,
  handleSubmit,
  handleDelete,
  handleOk,
  handleCancel,
  modalMode,
  beforePassword,
  newPassword1,
  newPassword2,
  newName,
  newDepartment,
  newLevel,
  index,
  profile,
  name,
  password,
  level,
  department,
}) => {
  return (
    <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={false} width={500}>
      {modalMode == 1 ? (
        <>
          <h4 className={styles.modalTitle}>비밀번호변경</h4>
          <div style={{ paddingTop: 30, paddingBottom: 20 }}>
            <label className={styles.inputLabel}>새 비밀번호</label>
            <div className={styles.inputWrapper}>
              <Input {...newPassword1} bordered={false} type={'password'} />
            </div>
            <label className={styles.inputLabel}>새 비밀번호 확인</label>
            <div className={styles.inputWrapper}>
              <Input {...newPassword2} bordered={false} type={'password'} />
            </div>
          </div>
          <Button
            style={{
              borderColor: '#3d3d3d',
              backgroundColor: '#3d3d3d',
              borderRadius: 30,
              height: 60,
              color: '#fff',
              width: '100%',
              fontWeight: '700',
            }}
            size="large"
            onClick={handleChangePassword}
          >
            비밀번호변경
          </Button>
        </>
      ) : modalMode == 2 ? (
        <>
          <h4 className={styles.modalTitle}>이름 변경</h4>
          <div style={{ paddingTop: 30, paddingBottom: 20 }}>
            <label className={styles.inputLabel}>현재 이름</label>
            <div className={styles.inputWrapper}>
              <Input bordered={false} disabled value={isModalVisible?.nickname} />
            </div>
            <label className={styles.inputLabel}>새 이름</label>
            <div className={styles.inputWrapper}>
              <Input {...newName} bordered={false} placeholder="새 이름을 입력해주세요." />
            </div>
          </div>
          <Button
            onClick={handleChangeName}
            style={{
              borderColor: '#3d3d3d',
              backgroundColor: '#3d3d3d',
              borderRadius: 30,
              height: 60,
              color: '#fff',
              width: '100%',
              fontWeight: '700',
            }}
            size="large"
          >
            이름변경
          </Button>
        </>
      ) : modalMode == 3 ? (
        <>
          <h4 className={styles.modalTitle}>소속 변경</h4>
          <div style={{ paddingTop: 30, paddingBottom: 20 }}>
            <label className={styles.inputLabel}>현재 소속</label>
            <div className={styles.inputWrapper}>
              <Input bordered={false} disabled value={isModalVisible?.corp_department} />
            </div>
            <label className={styles.inputLabel}>새 소속</label>
            <div className={styles.inputWrapper}>
              <Input {...newDepartment} bordered={false} placeholder="새 소속을 입력해주세요." />
            </div>
          </div>
          <Button
            onClick={handleChangeDepartment}
            style={{
              borderColor: '#3d3d3d',
              backgroundColor: '#3d3d3d',
              borderRadius: 30,
              height: 60,
              color: '#fff',
              width: '100%',
              fontWeight: '700',
            }}
            size="large"
          >
            소속변경
          </Button>
        </>
      ) : modalMode == 4 ? (
        <>
          <h4 className={styles.modalTitle}>직급 변경</h4>
          <div style={{ paddingTop: 30, paddingBottom: 20 }}>
            <label className={styles.inputLabel}>현재 직급</label>
            <div className={styles.inputWrapper}>
              <Input bordered={false} disabled value={isModalVisible?.corp_level} />
            </div>
            <label className={styles.inputLabel}>새 직급</label>
            <div className={styles.inputWrapper}>
              <Input {...newLevel} bordered={false} placeholder="새 직급을 입력해주세요." />
            </div>
          </div>
          <Button
            onClick={handleChangeLevel}
            style={{
              borderColor: '#3d3d3d',
              backgroundColor: '#3d3d3d',
              borderRadius: 30,
              height: 60,
              color: '#fff',
              width: '100%',
              fontWeight: '700',
            }}
            size="large"
          >
            직급변경
          </Button>
        </>
      ) : (
        <>
          <h4 className={styles.modalTitle}>관리자정보수정</h4>
          <div className={styles.modalThumbWrapper}>
            <div className={styles.modalThumb}>
              {profile.loading ? (
                <LoadingIndicator />
              ) : (
                <img
                  src={
                    profile.data?.id || isModalVisible?.profile
                      ? parseImageUrl(profile.data?.id ?? isModalVisible?.profile)
                      : require('../../../assets/profile_default.png')
                  }
                  style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 100 }}
                  alt={require('../../../assets/profile_default.png')}
                />
              )}
              <input
                id="profile_image"
                style={{ display: 'none' }}
                type="file"
                accept="image/*"
                onChange={handleChangeProfileImage}
              />
              <Button
                style={{
                  width: 26,
                  height: 26,
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                  padding: 0,
                  background: 'transparent',
                  borderColor: 'transparent',
                }}
              >
                <label htmlFor="profile_image" style={{ cursor: 'pointer' }}>
                  <Icon source={require('../../../assets/icon_modify.png')} size={26} />
                </label>
              </Button>
            </div>
          </div>
          <div style={{ paddingTop: 30, paddingBottom: 20 }}>
            <div className={styles.inputWrapper}>
              <label>아이디</label>
              <span>{isModalVisible?.signname}</span>
            </div>
            <div className={styles.inputWrapper}>
              <label>이름</label>
              <a
                onClick={name}
                style={{
                  justifyContent: 'space-between',
                  flex: '1',
                }}
              >
                {isModalVisible?.nickname} <img src={require('../../../assets/icon_pen_light.png')} />
              </a>
            </div>
            <div className={styles.inputWrapper}>
              <label>연락처</label>
              <span>{isModalVisible?.phone}</span>
            </div>
            <div className={styles.inputWrapper}>
              <label>소속</label>
              <a
                onClick={department}
                style={{
                  justifyContent: 'space-between',
                  flex: '1',
                }}
              >
                {isModalVisible?.corp_department} <img src={require('../../../assets/icon_pen_light.png')} />
              </a>
            </div>
            <div className={styles.inputWrapper}>
              <label>직급</label>
              <a
                onClick={level}
                style={{
                  justifyContent: 'space-between',
                  flex: '1',
                }}
              >
                {isModalVisible?.corp_level} <img src={require('../../../assets/icon_pen_light.png')} />
              </a>
            </div>
            <div className={styles.inputWrapper}>
              <label>비밀번호</label>
              <a
                onClick={password}
                style={{
                  justifyContent: 'space-between',
                  flex: '1',
                }}
              >
                변경하기 <img src={require('../../../assets/arrow-right-light.png')} />
              </a>
            </div>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Space align={'center'}>
              <Button
                style={{
                  borderColor: '#3d3d3d',
                  backgroundColor: '#3d3d3d',
                  color: '#fff',
                }}
                size="large"
                onClick={handleSubmit}
              >
                정보수정
              </Button>
              <Button danger size="large" onClick={handleDelete}>
                삭제
              </Button>
            </Space>
          </div>
        </>
      )}
    </Modal>
  );
};

export default AdminModal;
