import moment from 'moment';
import React from 'react';
import ChartWrapper from '../ChartWrapper';
import { getStats } from '../../apis/stats';
import Chart from 'react-chartjs-2';

/**
 * 통계페이지 상단 요청횟수 차트 컴포넌트
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const RequestChart = ({ title, type, dateRange = [moment().startOf('month'), moment().endOf('month')], ...props }) => {
  const stats = props?.stats;
  const dateDiff = dateRange[1].diff(dateRange[0], 'day') + 1;

  const worksDateMap = React.useMemo(() => {
    const a = [...Array(dateDiff)].reduce((p1, c1, i1) => {
      if (!p1[dateRange[0].clone().add(i1, 'day').format('YYYYMMDD')]) {
        p1[dateRange[0].clone().add(i1, 'day').format('YYYYMMDD')] = [];
      }
      return p1;
    }, {});
    stats.data?.for_date?.forEach((v) => {
      if (!a[moment(v.date).format('YYYYMMDD')]) {
        a[moment(v.date).format('YYYYMMDD')] = [v];
      } else {
        a[moment(v.date).format('YYYYMMDD')].push(v);
      }
    });

    return a;
  }, [stats.data, dateRange, dateDiff]);

  const loadData = async () => {
    if (stats?.data?.for_date) return;
    try {
      const params = {
        type,
      };
      stats.loadData(
        getStats({
          ...params,
          date: dateRange[0] && moment(dateRange[0]).format('YYYY-MM'),
        }),
      );
    } catch (error) {
      console.warn(error);
      // alert(error?.response?.data?.message);
    }
  };

  React.useEffect(() => {
    loadData();
  }, [dateRange]);

  return (
    <ChartWrapper
      title={title}
      flex={1}
      chart={
        <Chart
          type="line"
          width={1600}
          height={300}
          options={{
            scales: {
              yAxes: [
                {
                  ticks: {
                    min: 0,
                  },
                },
              ],
            },
          }}
          data={{
            labels: [...new Array(dateDiff)].map((_, i) => moment(dateRange[0]).add(i, 'd').format('MM.DD')),
            datasets: [
              {
                label: '전체',
                fill: false,
                lineTension: 0.1,
                backgroundColor: '#47bebc',
                borderColor: '#47bebc',
                borderCapStyle: 'butt',
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#47bebc',
                pointBackgroundColor: '#47bebc',
                pointBorderWidth: 5,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#47bebc',
                pointHoverBorderColor: '#47bebc',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: Object.values(worksDateMap)?.map((v) => v.reduce((a1, c1) => a1 + c1?.total || 0, 0)),
              },
              {
                label: '광택',
                fill: false,
                lineTension: 0.1,
                backgroundColor: '#f3cb00',
                borderColor: '#f3cb00',
                borderCapStyle: 'butt',
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#f3cb00',
                pointBackgroundColor: '#f3cb00',
                pointBorderWidth: 5,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#f3cb00',
                pointHoverBorderColor: '#f3cb00',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: Object.values(worksDateMap)?.map((v) => v.reduce((a1, c1) => a1 + c1?.clean || 0, 0)),
              },
              {
                label: '수선',
                fill: false,
                lineTension: 0.1,
                backgroundColor: '#f59300',
                borderColor: '#f59300',
                borderCapStyle: 'butt',
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#f59300',
                pointBackgroundColor: '#f59300',
                pointBorderWidth: 5,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#f59300',
                pointHoverBorderColor: '#f59300',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: Object.values(worksDateMap)?.map((v) => v.reduce((a1, c1) => a1 + c1?.fix || 0, 0)),
              },
              {
                label: '남자',
                fill: false,
                lineTension: 0.1,
                backgroundColor: '#0000ff',
                borderColor: '#0000ff',
                borderCapStyle: 'butt',
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#0000ff',
                pointBackgroundColor: '#0000ff',
                pointBorderWidth: 5,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#0000ff',
                pointHoverBorderColor: '#0000ff',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: Object.values(worksDateMap)?.map((v) => v.reduce((a1, c1) => a1 + c1?.male || 0, 0)),
              },
              {
                label: '여자',
                fill: false,
                lineTension: 0.1,
                backgroundColor: '#db2929',
                borderColor: '#db2929',
                borderCapStyle: 'butt',
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#db2929',
                pointBackgroundColor: '#db2929',
                pointBorderWidth: 5,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#db2929',
                pointHoverBorderColor: '#db2929',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: Object.values(worksDateMap)?.map((v) => v.reduce((a1, c1) => a1 + c1?.female || 0, 0)),
              },
              type === 'SC' && {
                label: '2회차',
                fill: false,
                lineTension: 0.1,
                backgroundColor: '#7030a0',
                borderColor: '#7030a0',
                borderCapStyle: 'butt',
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#7030a0',
                pointBackgroundColor: '#7030a0',
                pointBorderWidth: 5,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#7030a0',
                pointHoverBorderColor: '#7030a0',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: Object.values(worksDateMap)?.map((v) => v.reduce((a1, c1) => a1 + c1?.female || 0, 0)),
              },
              type === 'SC' && {
                label: '3회차',
                fill: false,
                lineTension: 0.1,
                backgroundColor: '#92d050',
                borderColor: '#92d050',
                borderCapStyle: 'butt',
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#92d050',
                pointBackgroundColor: '#92d050',
                pointBorderWidth: 5,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#92d050',
                pointHoverBorderColor: '#92d050',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: Object.values(worksDateMap)?.map((v) => v.reduce((a1, c1) => a1 + c1?.female || 0, 0)),
              },
              type === 'SC' && {
                label: '4회차',
                fill: false,
                lineTension: 0.1,
                backgroundColor: '#ed7d31',
                borderColor: '#ed7d31',
                borderCapStyle: 'butt',
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#ed7d31',
                pointBackgroundColor: '#ed7d31',
                pointBorderWidth: 5,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#ed7d31',
                pointHoverBorderColor: '#ed7d31',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: Object.values(worksDateMap)?.map((v) => v.reduce((a1, c1) => a1 + c1?.female || 0, 0)),
              },
            ].filter((v) => !!v),
          }}
        />
      }
      {...props}
    />
  );
};

export default RequestChart;
