import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import { Button, Descriptions, PageHeader } from 'antd';
import styles from '../../../work/review/details/reviewDetails.module.less';
import { deletePost, readPost } from '../../../../apis/posts';
import { getFileHost } from '../../../../apis';

/**
 * 슈닥이야기 상세 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
// notice와 구조 동일
const ShoedocDetails = () => {
  const navigate = useNavigate();
  const post = useAxios();
  const { id } = useParams();

  React.useEffect(() => {
    if (!id) {
      alert('데이터를 불러올 수 없습니다.');
      navigate(-1);
      return;
    }
    post.loadData(readPost({ id })).catch((error) => {
      alert(error?.response?.data?.message);
      // router.back();
      navigate('/contents/shoedoc', { replace: true });
      return;
    });
  }, []);

  const handleDeletePost = async () => {
    try {
      if (window.confirm('해당 슈닥이야기를 삭제하시겠습니까?')) {
        const { data } = await deletePost(id);
        console.log({ data });
        navigate(-1);
        return;
      }
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="슈닥이야기 상세" />
      <Descriptions bordered>
        <Descriptions.Item label="제목" span={4}>
          {post.data?.title}
        </Descriptions.Item>
        <Descriptions.Item label="대표이미지" span={4}>
          {post.data?.main_image && (
            <img
              src={`${getFileHost()}/${post.data?.main_image}`}
              style={{ width: 170, height: 170, marginRight: 10 }}
            />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="내용이미지" span={4}>
          {post.data?.images?.map((v) => (
            <img
              src={`${getFileHost()}/${v}`}
              style={{ width: 170, height: 170, marginRight: 10, objectFit: 'contain', border: '1px solid #ddd' }}
            />
          ))}
        </Descriptions.Item>
      </Descriptions>
      <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={handleDeletePost}
          size={'large'}
          className={styles.basicButton}
          style={{ marginRight: 5, height: 40, width: 100 }}
        >
          삭제
        </Button>
        <Button
          size="large"
          className="basicButton"
          style={{ height: 40, width: 100, backgroundColor: '#FF7200', color: 'white', marginRight: 5 }}
        >
          <Link to={`/contents/shoedoc`}>목록</Link>
        </Button>
        <Button size="large" className="basicButton" style={{ height: 40, width: 100, marginRight: 5 }}>
          <Link to={`/contents/shoedoc/update/${id}`}>수정</Link>
        </Button>
      </div>
    </div>
  );
};

export default ShoedocDetails;
