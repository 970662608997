import React, { useEffect, useState } from 'react';
import { Button, Input, PageHeader, Radio, Select, Switch, Table, Typography } from 'antd';
import { expertSearchField } from '../../../common/searchFields';
import SearchForm from '../../../components/datalist/SearchForm';
import TableButtonWrap from '../../../components/TableButtonWrap';
import { phoneFormat } from '../../../common/utils';
import moment from 'moment';
import { getAPIHost, restApi } from '../../../apis';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import fileDownload from 'js-file-download';
import produce from 'immer';

/**
 * 장인 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const AccountExpertIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // url query
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);

  const [data, setData] = React.useState();
  const [fileLoading, setFileLoad] = React.useState(false);

  const [limits, setLimits] = useState([]);

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      sort: 'created_at',
      ...query,
      type: 'M',
      limit: 10,
    };

    params.dir = query.sort === 'alias' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    }

    const { data } = await restApi.get('/accounts', {
      params,
    });
    setData(data);
  }, [query]);

  useEffect(() => {
    setLimits([]);
  }, [query]);

  React.useEffect(() => {
    loadData().catch(console.warn);
    const ik = setInterval(() => loadData(), 2000);
    return () => clearInterval(ik);
  }, [loadData]);

  // 일 제한 변경
  const handleSubmitLimit = async (id, day_limit) => {
    const { data } = await restApi.put(`/accounts/${id}`, { day_limit });
    alert('저장되었습니다.');
  };

  // 활성화 상태 변경
  const handleActiveChange = async (id, active) => {
    try {
      const { data } = await restApi.put(`/accounts/${id}/active`, {
        active,
      });
      console.log({ data });
    } catch (e) {
      console.warn(e);
    }
  };

  // 테이블 컬럼 정의
  const columns = [
    {
      title: '번호',
      dataIndex: 'id',
      align: 'center',
      width: 90,
      render: (_, _2, i) => {
        return _;
        // return data?.total_elements - i - data?.page * 10;
      },
    },
    {
      title: '관리번호',
      align: 'left',
      // dataIndex: 'corp_department',
      // dataIndex: 'id',
      width: 160,
      // render: (_, __, index) => {
      //   return (accounts.data?.total_elements || 0) - (limit * page + index);
      // },
      render: (_, __, index) => {
        // console.log('--->', _);
        return _?.alias || _?.corp_department + '-' + _?.corp_serial;
      },
    },
    {
      title: '승인',
      dataIndex: 'active',
      key: 'active',
      width: 100,
      align: 'center',
      render: (x, row) => {
        return <Switch defaultChecked={x} onChange={(e) => handleActiveChange(row?.id, e)} />;
      },
    },
    {
      title: '이름',
      dataIndex: 'nickname',
      width: 100,
      align: 'center',
      render: (r) => (
        <Typography.Text ellipsis={true} style={{ width: 100 }}>
          {r}
        </Typography.Text>
      ),
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      width: 150,
      align: 'center',
      render: (r) => phoneFormat(r),
    },
    {
      title: '상태',
      dataIndex: 'online_state',
      width: 150,
      align: 'center',
      render: (r, row) => {
        return (
          <Radio.Group
            size={'small'}
            value={r ?? 'OFF'}
            onChange={(e) => {
              const value = e.target.value;
              restApi.put(`/accounts/${row.id}/online`, { state: value }).then((r) => {
                loadData();
              });
            }}
          >
            <Radio.Button value={'ON'}>출근</Radio.Button>
            <Radio.Button value={'WAIT'}>휴식</Radio.Button>
            <Radio.Button value={'OFF'}>퇴근</Radio.Button>
          </Radio.Group>
          // <>
          //   {r === 'ON' ? (
          //     <>
          //       <span style={{ fontWeight: 'bold', color: '#00dd1f' }}>출근</span>
          //       <Button size={'small'}>퇴근변경</Button>
          //     </>
          //   ) : r === 'WAIT' ? (
          //     <>
          //       <span style={{ fontWeight: 'bold', color: '#555' }}>휴식</span>
          //       <Button size={'small'}>퇴근변경</Button>
          //     </>
          //   ) : (
          //     <>
          //       <span style={{ fontWeight: 'bold', color: '#db2929' }}>퇴근</span>
          //       <Button size={'small'}>출근변경</Button>
          //     </>
          //   )}
          // </>
        );
      },
    },
    {
      title: '현재할당',
      dataIndex: 'now_work_size',
      width: 80,
      align: 'center',
      render: (r) => r,
    },
    {
      title: '일일최대',
      dataIndex: 'day_limit',
      width: 150,
      align: 'center',
      render: (r, row, i) => {
        const v = limits?.[i];
        return (
          <Input.Group compact>
            <Select
              size={'small'}
              value={v ?? r}
              style={{ width: 80 }}
              onChange={(value) => {
                setLimits(
                  produce((draft) => {
                    draft[i] = value;
                  }),
                );
              }}
            >
              {Array(50)
                .fill('')
                .map((_, i) => (
                  <Select.Option value={(i + 1) * 10}>{(i + 1) * 10} 개</Select.Option>
                ))}
            </Select>
            <Button onClick={() => handleSubmitLimit(row.id, v ?? r)} size={'small'}>
              저장
            </Button>
          </Input.Group>
        );
      },
    },
    {
      title: '주소',
      dataIndex: 'address',
      align: 'left',
    },
    {
      title: '가입일자',
      dataIndex: 'created_at',
      align: 'center',
      width: 200,
      render: (data) => {
        return moment(data).format('YYYY-MM-DD HH:mm');
      },
    },
    // {
    //   title: '아이디',
    //   dataIndex: 'signname',
    //   width: 150,
    //   align: 'center',
    // },
    // {
    //   title: '이메일',
    //   dataIndex: 'email',
    //   width: 150,
    //   align: 'center',
    // },
    {
      title: '자세히보기',
      dataIndex: 'id',
      align: 'center',
      width: 120,
      render: (data) => (
        <Link to={`/accounts/expert/details/${data}`}>
          <a style={{ color: 'black' }}>
            {/*<Icon source={require('../../../assets/icon_gear.png')} size={18} />*/}
            자세히보기
          </a>
        </Link>
      ),
    },
  ];

  // 테이블 페이지네이션
  const pagination = {
    position: ['bottomCenter'],
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      navigate('?' + qs.stringify({ ...query, page: p - 1 }), { replace: true });
    },
  };

  // 엑셀저장
  const xlsxSave = () => {
    const params = {
      ...query,
      type: 'M',
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    }
    restApi
      .get(`${getAPIHost()}/exports/accounts/expert?${qs.stringify(params)}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `장인_${moment().format('YYYYMMDDHHmmss')}.xlsx`);
      });
  };

  // 엑셀 업로드 로직
  const handleExcelUpload = async (e) => {
    if (fileLoading) {
      return;
    }
    try {
      setFileLoad(true);
      const fileData = new FormData();
      const config = {
        header: {
          accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      };

      fileData.append('filedata', e?.target?.files[0]);
      await restApi.post('/accounts/experts/excel', fileData, config);
      // return responseData;
      alert('엑셀이 업로드 되었습니다.');
      setFileLoad(false);
      await loadData();
    } catch (error) {
      console.error('excel upload ERROR', error);
      setFileLoad(false);
      throw error;
    }
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="장인" />
      <SearchForm fields={expertSearchField} />
      <div style={{ position: 'relative' }}>
        <Table
          scroll={{ x: 'max-content' }}
          dataSource={data?.items}
          columns={columns}
          pagination={
            pagination
              ? {
                  ...pagination,
                  position: ['bottomCenter'],
                  showSizeChanger: false,
                }
              : false
          }
        />
        <div>
          <TableButtonWrap
            position="left"
            buttons={[
              { icon: require('../../../assets/icon_save.png'), label: '엑셀 저장', onClick: xlsxSave },
              {
                icon: require('../../../assets/icon_save.png'),
                label: '업로드 양식 저장',
                download: require('../../../assets/슈닥_장인_업로드_양식.xlsx'),
              },
              { icon: require('../../../assets/icon_upload.png'), label: '장인 업로드', onChange: handleExcelUpload },
              // { icon: require('../../../assets/icon_upload.png'), label: '엑셀 업로드', onChange: handleExcelUpload },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountExpertIndex;
