import React, { useEffect, useState } from 'react';
import { Button, Descriptions, PageHeader, Table } from 'antd';
import moment from 'moment';
import { addComma } from '../../../../common/utils';
import { useParams } from 'react-router-dom';
import { getAPIHost, restApi } from '../../../../apis';
import TableButtonWrap from '../../../../components/TableButtonWrap';
import qs from 'query-string';
import fileDownload from 'js-file-download';

/**
 * 작업 상세
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */

const TYPES = {
  M: '장인',
  S: '긴급',
  T: '기사',
  V: '기업',
};

const CalcDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState();

  const loadData = async () => {
    const { data } = await restApi.get(`/calcs/${id}`);
    setData(data);
  };

  useEffect(() => {
    loadData().catch(console.warn);
  }, [id]);

  const handleSend = async (item) => {
    await restApi.post(`/calcs/${id}`, {
      account_id: item.id,
      date: data.date,
    });
    await loadData().catch(console.warn);
  };

  const columns = [
    {
      title: '종류',
      dataIndex: 'type',
      align: 'center',
      width: 90,
      render: (d) => {
        return TYPES[d] ?? d;
      },
    },
    {
      title: '이름',
      dataIndex: 'nickname',
      align: 'left',
      width: 100,
    },
    {
      title: '계좌번호',
      dataIndex: 'price',
      align: 'center',
      render: (d, r) => {
        return r.bank + ' ' + r.bankno;
      },
    },
    {
      title: '금액',
      dataIndex: 'money',
      align: 'right',
      width: 150,
      render: (d) => {
        return addComma(d) + ' 원';
      },
    },
    {
      title: '상태',
      dataIndex: 'item',
      align: 'center',
      width: 120,
      render: (d) => {
        return !d ? '정산대기' : '입금완료';
      },
    },
    {
      title: '입금액',
      dataIndex: 'item',
      align: 'center',
      className: 'color-cell',
      width: 150,
      render: (d) => {
        if (!d) return '미정산';
        return addComma(String(d?.money || 0)) + '원';
      },
    },
    {
      title: '',
      dataIndex: 'count',
      align: 'center',
      className: 'color-cell',
      width: 130,
      render: (d, r) => {
        return (
          <Button disabled={!!r.item} onClick={() => handleSend(r)}>
            입금처리
          </Button>
        );
      },
    },
  ];

  const xlsxSave = () => {
    restApi
      .get(`${getAPIHost()}/exports/calcs/${id}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(
          res.data,
          `정산내역상세_${moment(data?.date).format('YYYY-MM-DD')}_${moment().format('YYYYMMDDHHmmss')}.xlsx`,
        );
      });
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="상세 내역" />

      <Descriptions bordered>
        <Descriptions.Item label="정산일" span={4}>
          <span>{moment(data?.date).format('YYYY-MM-DD')}</span>
        </Descriptions.Item>
        <Descriptions.Item label="기간" span={4}>
          <span>
            {moment(data?.sdate).format('YYYY-MM-DD')} 00:00 ~ {moment(data?.date).add(-1, 'days').format('YYYY-MM-DD')}{' '}
            23:59
          </span>
        </Descriptions.Item>
      </Descriptions>
      <div style={{ marginTop: 20 }}>
        <Table scroll={{ x: 'max-content' }} columns={columns} dataSource={data?.tables} pagination={false} />
      </div>
      <div>
        <TableButtonWrap
          position="left"
          buttons={[{ icon: require('../../../../assets/icon_save.png'), label: '엑셀 저장', onClick: xlsxSave }]}
        />
      </div>
    </div>
  );
};

export default CalcDetails;
