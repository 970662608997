import React from 'react';
import { Button, DatePicker, Descriptions, Input, PageHeader } from 'antd';
import useAxios from '../../../hooks/useAxios';
import { createHoliday as createHolidayApi } from '../../../apis/restdays';
import useInput from '../../../hooks/useInput';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import styles from '../../work/review/details/reviewDetails.module.less';

/**
 * 휴일관리 생성 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const RestdayCreate = () => {
  const createHoliday = useAxios();
  const memo = useInput('');
  const [holiday, setHoliday] = React.useState(null);
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate('/settings/restday', { replace: true });
  };

  const handleCreatePost = async () => {
    try {
      await createHoliday.loadData(
        createHolidayApi({
          date: holiday && moment(holiday).format('YYYY-MM-DD HH:mm'),
          text: memo?.value,
        }),
      );
      alert('휴일이 등록되었습니다.');
      navigate('/settings/restday', { replace: true });
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
    }
  };

  return (
    <>
      <PageHeader className="site-page-header" title="휴일등록" />
      <Descriptions bordered>
        <Descriptions.Item label={'휴일'} span={4} className="nopadding" style={{ padding: '10px 20px' }}>
          <DatePicker
            value={holiday}
            onChange={setHoliday}
            style={{ width: 200, fontSize: 12 }}
            placeholder="날짜선택"
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={'메모'}
          span={4}
          className="nopadding"
          style={{ verticalAlign: 'top', padding: '10px 20px' }}
        >
          <Input {...memo} placeholder="메모를 입력해주세요." />
        </Descriptions.Item>
      </Descriptions>
      <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={() => navigate('/settings/restday', { replace: true })}
          size={'large'}
          className={styles.basicButton}
          style={{ marginRight: 5, height: 40, width: 100 }}
        >
          취소
        </Button>
        <Button
          onClick={handleCreatePost}
          size={'large'}
          className={styles.basicButton}
          style={{ height: 40, width: 100, backgroundColor: '#FF7200', color: 'white' }}
        >
          등록
        </Button>
      </div>
      {/*<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>*/}
      {/*  <Button*/}
      {/*      onClick={handleCreatePost}*/}
      {/*      style={{ background: '#000', color: '#fff', width: 152, height: 40, borderRadius: 4 }}*/}
      {/*      loading={createHoliday.loading}*/}
      {/*  >*/}
      {/*    등록*/}
      {/*  </Button>*/}
      {/*</div>*/}
    </>
  );
};

export default RestdayCreate;
