import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import VendorsCreate from '../vendors/create';

/**
 * 벤더사 로그인 정보
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const VendorMe = (props) => {
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  return <VendorsCreate vid={principal?.id} />;
};

export default VendorMe;
