import React, { useState } from 'react';
import { Button, DatePicker, Descriptions, Input, PageHeader } from 'antd';
import useInput from '../../../hooks/useInput';
import { useNavigate } from 'react-router-dom';
import useAxios from '../../../hooks/useAxios';
import { createPost as createPostApi } from '../../../apis/posts';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from '../../work/review/details/reviewDetails.module.less';
import moment from 'moment';
import TinyMCE from 'react-tinymce';

/**
 * FAQ 생성 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const FAQCreate = () => {
  const navigate = useNavigate();
  const title = useInput('');
  const [contents, setContents] = React.useState(null);
  const [createdAt, setCreatedAt] = useState(moment());
  const createPost = useAxios();

  // 뒤로가기
  const handleCancel = () => {
    navigate('/contents/faq', { replace: true });
  };

  // 생성 함수
  const handleCreatePost = async () => {
    if (!title.value.length > 0) {
      alert('제목을 입력해주세요.');
      return;
    }
    if (contents === '<p></p>\n') {
      alert('내용을 입력해주세요.');
      return;
    }
    const requestBody = {
      type: 'FAQ',
      title: title.value,
      contents: contents,
      active: true,
      created_at: createdAt.format('YYYY-MM-DD HH:mm:ss'),
    };
    try {
      await createPost.loadData(createPostApi(requestBody));
      navigate('/contents/faq', { replace: true });
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="FAQ 등록" />
      <Descriptions bordered>
        <Descriptions.Item label="질문제목" span={4} className="nopadding">
          <Input placeholder="질문 제목" {...title} bordered={false} style={{ padding: '10px 20px' }} />
        </Descriptions.Item>
        <Descriptions.Item label={'등록일'} span={4} className="nopadding" style={{ padding: '10px 20px' }}>
          <DatePicker value={createdAt} onChange={(v) => setCreatedAt(v)} showTime />
          {/*{userType === 'M' ? '장인' : userType === 'T' ? '기사' : '고객'}*/}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions bordered>
        <Descriptions.Item label="답변 내용" span={4} className="nopadding">
          <TinyMCE
            content={contents ?? ''}
            config={{
              content_css: '/editor.css',
              plugins: 'code table',
              toolbar:
                'undo redo styleselect bold italic alignleft aligncenter alignright bullist numlist outdent indent code',
            }}
            onChange={(e) => {
              const text = e.target.getContent();
              setContents(text);
            }}
          />
        </Descriptions.Item>
      </Descriptions>
      <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={handleCancel}
          size={'large'}
          className={styles.basicButton}
          style={{ marginRight: 5, height: 40, width: 100 }}
        >
          취소
        </Button>
        <Button
          onClick={handleCreatePost}
          size={'large'}
          className={styles.basicButton}
          style={{ height: 40, width: 100, backgroundColor: '#FF7200', color: 'white' }}
        >
          확인
        </Button>
      </div>
    </div>
  );
};

export default FAQCreate;
