import React, { useEffect, useMemo, useState } from 'react';
import { Input, Modal, PageHeader, Select, Table, Typography } from 'antd';
import SearchForm from '../../../components/datalist/SearchForm';
import { toCommaNumber } from '../../../common/utils';
import moment from 'moment';
import qs from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { restApi } from '../../../apis';
import TableButtonWrap from '../../../components/TableButtonWrap';
import { codeSearchFields } from '../../../common/searchFields';
import { shallowEqual, useSelector } from 'react-redux';
import styles from '../../../nav/BasicLayout.module.less';

/**
 * 기업회원 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */

const CodeRemoveView = ({ column, loadParent, vendorId, principal }) => {
  const [data, setData] = useState();

  const loadData = async (page = 0) => {
    const { data } = await restApi.get(`/vendors/${vendorId ?? principal?.id}/codes`, {
      params: {
        removed: true,
        page,
        limit: 10,
      },
    });
    setData(data);
  };

  useEffect(() => {
    loadData().catch(console.warn);
  }, []);

  const pagination = {
    position: ['bottomCenter'],
    showSizeChanger: false,
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      await loadData(p - 1);
    },
  };

  const tableColumn = useMemo(() => {
    const o = [...column];
    o[0].render = (v, r, i) => {
      return data?.total_elements - i - data?.page * data?.page_size;
    };
    return o;
  }, [column, data]);

  const [rowKeys, setRowKeys] = useState([]);
  const handleChange = (selectedRowKeys, selectedRows) => {
    setRowKeys(selectedRowKeys);
  };
  const handleRecover = async () => {
    if (rowKeys?.length === 0) {
      window.alert('복구할 내역을 선택하세요');
      return;
    }
    await restApi.put(`/cms/vendors/codes/actives`, {
      ids: rowKeys,
      active: true,
    });
    await loadParent?.();
  };

  return (
    <div style={{ position: 'relative' }}>
      <Table
        rowSelection={{
          type: 'checkbox',
          onChange: handleChange,
          selectedRowKeys: rowKeys,
        }}
        dataSource={data?.items}
        columns={tableColumn}
        pagination={pagination}
        scroll={{ x: 'max-content' }}
        rowKey={'code'}
      />
      <TableButtonWrap position="left" buttons={[{ label: '복원', onClick: handleRecover }]} />
    </div>
  );
};

const WEEKS = ['일', '월', '화', '수', '목', '금', '토'];
const VendersCodesIndex = ({ vendorId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);

  const { principal } = useSelector((s) => s.auth, shallowEqual);

  const [data, setData] = React.useState();
  const [showModal, setShowModal] = useState();
  const [rowKeys, setRowKeys] = useState([]);

  const [editId, setEditId] = React.useState();

  const handleChange = (selectedRowKeys, selectedRows) => {
    setRowKeys(selectedRowKeys);
  };

  const handleDeleteModal = () => {
    setShowModal(true);
  };

  const handleDelete = async () => {
    if (rowKeys?.length === 0) {
      window.alert('삭제할 내역을 선택하세요');
      return;
    }
    if (!window.confirm('삭제하시겠습니끼?')) {
      return;
    }
    await restApi.put(`/cms/vendors/codes/actives`, {
      ids: rowKeys,
      active: false,
    });
    await loadData();
  };

  const handleSubmit = async () => {
    await restApi.put(`/vendors/0/codes/${editId?.code}`, { name: editId?.name, week: editId?.week });
    alert('수정되었습니다.');
    setEditId(undefined);
    loadData().catch(console.warn);
  };

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      ...query,
      type: 'B',
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    }

    const { data } = await restApi.get(`/vendors/${vendorId ?? principal?.id}/codes`, {
      params,
    });
    setData(data);
  }, [query]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  //테이블 컬럼명 정의
  const columns = React.useMemo(
    () => [
      {
        title: '번호',
        align: 'center',
        key: 'id',
        dataIndex: 'id',
        width: 90,
        render: (_, _2, i) => {
          return data?.total_elements - i - data?.page * 10;
        },
      },
      {
        title: '코드',
        key: 'code',
        dataIndex: 'code',
        width: 160,
        align: 'center',
        render: (d, row) => {
          return <a onClick={() => setEditId(row)}>{d}</a>;
        },
      },
      {
        title: '수거요일',
        key: 'week',
        dataIndex: 'week',
        width: 100,
        align: 'center',
        render: (d) => {
          return WEEKS[d] + '요일';
        },
      },
      {
        title: '이름',
        key: 'name',
        dataIndex: 'name',
        width: 180,
        align: 'center',
        render: (r) => (
          <Typography.Text ellipsis={true} style={{ width: 180 }}>
            {r}
          </Typography.Text>
        ),
      },
      {
        title: '주소',
        dataIndex: 'address',
        key: 'address',
        align: 'left',
      },

      {
        title: '주문건수',
        dataIndex: 'purchase_count',
        key: 'purchase_count',
        width: 100,
        align: 'center',
        render: (data) => toCommaNumber(data),
      },
      {
        title: '등록일자',
        dataIndex: 'created_at',
        key: 'created_at',
        align: 'center',
        width: 160,
        render: (data) => {
          return moment(data).format('YYYY-MM-DD HH:mm');
        },
      },
    ],
    [data],
  );

  // 테이블 페이지네이션
  const pagination = {
    position: ['bottomCenter'],
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      navigate('?' + qs.stringify({ ...query, page: p - 1 }), { replace: true });
    },
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="아파트 관리" />
      <SearchForm fields={codeSearchFields} />
      <div style={{ position: 'relative' }}>
        <Table
          rowSelection={{
            type: 'checkbox',
            onChange: handleChange,
            selectedRowKeys: rowKeys,
          }}
          rowKey={'code'}
          scroll={{ x: 'max-content' }}
          dataSource={data?.items}
          columns={columns}
          pagination={
            pagination
              ? {
                  ...pagination,
                  position: ['bottomCenter'],
                  showSizeChanger: false,
                }
              : false
          }
        />
        <TableButtonWrap
          position="right"
          buttons={[
            { label: '삭제항목보기', onClick: handleDeleteModal },
            { label: '삭제', onClick: handleDelete },
          ]}
        />
      </div>
      {showModal && (
        <Modal
          visible={showModal}
          footer={null}
          width={1200}
          title={'삭제된 항목'}
          onCancel={() => setShowModal(false)}
        >
          <CodeRemoveView
            vendorId={vendorId}
            principal={principal}
            column={columns}
            loadParent={() => {
              loadData();
              setShowModal(false);
            }}
          />
        </Modal>
      )}
      <Modal visible={!!editId} onCancel={() => setEditId(undefined)} title={'수정'} onOk={handleSubmit}>
        <div style={{}}>
          <label className={styles.inputLabel}>이름</label>
          <div>
            <Input
              value={editId?.name}
              onChange={(e) => {
                const v = e.target.value;
                setEditId((x) => ({ ...x, name: v }));
              }}
            />
          </div>
          <label className={styles.inputLabel}>요일</label>
          <div>
            <Select
              value={editId?.week}
              style={{ width: '100%' }}
              onChange={(v) => {
                setEditId((x) => ({ ...x, week: v }));
              }}
            >
              <Select.Option value={0}>일요일</Select.Option>
              <Select.Option value={1}>월요일</Select.Option>
              <Select.Option value={2}>화요일</Select.Option>
              <Select.Option value={3}>수요일</Select.Option>
              <Select.Option value={4}>목요일</Select.Option>
              <Select.Option value={5}>금요일</Select.Option>
              <Select.Option value={6}>토요일</Select.Option>
            </Select>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default VendersCodesIndex;
