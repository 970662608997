import React from 'react';
import styles from './LabelRow.module.css';

/**
 * 쿠폰 라벨 컴포넌트
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const LabelRow = ({ children, label, heightOver }) => {
  return (
    <div className={heightOver ? styles.containerStart : styles.container}>
      <div className={styles.labelWrapper}>
        <span className={styles.label}>{label}</span>
      </div>
      <div className={styles.rowContent}>{children}</div>
    </div>
  );
};

export default LabelRow;
