import React from 'react';
import { PageHeader, Table } from 'antd';
import { keywordSearchField } from '../../../common/searchFields';
import SearchForm from '../../../components/datalist/SearchForm';
import TableButtonWrap from '../../../components/TableButtonWrap';
import moment from 'moment';
import { getAPIHost, restApi } from '../../../apis';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import fileDownload from 'js-file-download';

/**
 * 휴일관리 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const RestdayIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);

  const [data, setData] = React.useState();

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      ...query,
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    }

    const { data } = await restApi.get('/holidays/setting', {
      params,
    });
    setData(data);
  }, [query]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  const columns = [
    {
      title: '번호',
      dataIndex: 'id',
      align: 'center',
      width: 90,
      render: (_, _2, i) => {
        return data?.total_elements - i - data?.page * 10;
      },
    },
    {
      title: '휴일',
      dataIndex: 'date',
      align: 'center',
      width: 200,
      render: (data, record) => (
        <Link to={`/settings/restday/details/${record.id}`}>
          <a style={{ color: 'black' }}>{moment(data).format('YYYY-MM-DD dddd')}</a>
        </Link>
      ),
    },
    {
      title: '메모',
      dataIndex: 'text',
      align: 'left',
      render: (data, record) => (
        <Link to={`/settings/restday/details/${record.id}`}>
          <a style={{ color: 'black' }}>{data}</a>
        </Link>
      ),
    },
    {
      title: '작성일자',
      dataIndex: 'created_at',
      align: 'center',
      width: 200,
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm'),
    },
    {
      title: '자세히보기',
      dataIndex: 'id',
      align: 'center',
      key: 'detail',
      width: 120,
      render: (data, record) => (
        <Link to={`/settings/restday/details/${record.id}`}>
          <a style={{ color: 'black' }}>자세히보기</a>
        </Link>
      ),
    },
  ];
  // 테이블 페이지네이션
  const pagination = {
    position: ['bottomCenter'],
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      navigate('?' + qs.stringify({ ...query, page: p - 1 }), { replace: true });
    },
  };

  const xlsxSave = () => {
    const params = {
      ...query,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    }
    restApi
      .get(`${getAPIHost()}/exports/holiday?${qs.stringify(params)}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `휴일관리_${moment().format('YYYYMMDDHHmmss')}.xlsx`);
      });
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="휴일관리" />
      <SearchForm fields={keywordSearchField} />
      <div style={{ position: 'relative' }}>
        <Table
          scroll={{ x: 'max-content' }}
          rowKey={({ id }) => id}
          dataSource={data?.items}
          columns={columns}
          pagination={
            pagination
              ? {
                  ...pagination,
                  position: ['bottomCenter'],
                  showSizeChanger: false,
                }
              : false
          }
        />
        <div className={'tableWrapCover'}>
          <TableButtonWrap
            position="left"
            buttons={[{ icon: require('../../../assets/icon_save.png'), label: '엑셀 저장', onClick: xlsxSave }]}
          />
          <TableButtonWrap
            position="right"
            buttons={[
              { icon: require('../../../assets/icon_write.png'), label: '작성', link: '/settings/restday/create' },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default RestdayIndex;
