import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth';
import worksReducer from './works';
import teamsReducer from './teams';
import productReducer from './products';
import vendorReducer from './vendors';

export default configureStore({
  reducer: {
    auth: authReducer,
    work: worksReducer,
    team: teamsReducer,
    product: productReducer,
    vendor: vendorReducer,
  },
});
