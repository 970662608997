import { Spin } from 'antd';
import React from 'react';

/**
 * 로딩시 컴포넌트
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const Loading = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
      <Spin />
    </div>
  );
};

export default Loading;
