import React, { useEffect, useState } from 'react';
import { Button, Descriptions, Input, PageHeader, Select } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import styles from '../../work/review/details/reviewDetails.module.less';
import FileUpload from '../../../components/FileUpload';
import { restApi } from '../../../apis';

/**
 * 메뉴얼 생성 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const ManualCreate = () => {
  const { id: manualId } = useParams();

  const [body, setBody] = useState({
    prefix: 'U',
    type_key: undefined,
    name: '',
    description: '',
    service: '',
    images: [],
  });

  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      const { data } = await restApi.get(`/manuals`, {
        params: {
          id: manualId,
        },
      });

      const manual = data.find((v) => String(v.id) === String(manualId));
      setBody({
        prefix: manual.prefix,
        type_key: manual.type_key,
        name: manual.name,
        description: manual.description,
        service: manual.service,
        images: manual.images,
      });
    };
    loadData().catch(console.warn);
  }, [manualId]);

  const handleCreatePost = async () => {
    try {
      if (manualId) {
        await restApi.put(`/manuals/${manualId}`, body);
        alert('매뉴얼이 수정되었습니다.');
      } else {
        await restApi.post(`/manuals`, body);
        alert('매뉴얼이 등록되었습니다.');
      }
      navigate('/settings/manuals', { replace: true });
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
    }
  };

  const handleRemove = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;

    await restApi.delete(`/manuals/${manualId}`);
    alert('매뉴얼이 삭제되었습니다.');
    navigate('/settings/manuals', { replace: true });
  };

  return (
    <>
      <PageHeader className="site-page-header" title={manualId ? '매뉴얼 수정' : '매뉴얼 등록'} />
      <Descriptions bordered>
        <Descriptions.Item
          label={'타겟팅'}
          span={4}
          className="nopadding"
          style={{ verticalAlign: 'top', padding: '10px 20px' }}
        >
          <Select
            value={body.prefix}
            style={{ width: '100%' }}
            onChange={(value) => {
              setBody((x) => ({ ...x, name: value }));
            }}
          >
            <Select.Option value={'U'}>일반</Select.Option>
            <Select.Option value={'V'}>기업회원</Select.Option>
          </Select>
        </Descriptions.Item>
        <Descriptions.Item
          label={'상품 연계'}
          span={4}
          className="nopadding"
          style={{ verticalAlign: 'top', padding: '10px 20px' }}
        >
          <Input
            value={body.type_key}
            onChange={(e) => {
              const value = e.target.value;
              setBody((x) => ({ ...x, type_key: value }));
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={'제목'}
          span={4}
          className="nopadding"
          style={{ verticalAlign: 'top', padding: '10px 20px' }}
        >
          <Input
            value={body.name}
            onChange={(e) => {
              const value = e.target.value;
              setBody((x) => ({ ...x, name: value }));
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={'설명'}
          span={4}
          className="nopadding"
          style={{ verticalAlign: 'top', padding: '10px 20px' }}
        >
          <Input.TextArea
            value={body.description}
            onChange={(e) => {
              const value = e.target.value;
              setBody((x) => ({ ...x, description: value }));
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={'서비스 이용방법'}
          span={4}
          className="nopadding"
          style={{ verticalAlign: 'top', padding: '10px 20px' }}
        >
          <Input.TextArea
            value={body.service}
            onChange={(e) => {
              const value = e.target.value;
              setBody((x) => ({ ...x, service: value }));
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={'도움말 파일'}
          span={4}
          className="nopadding"
          style={{ verticalAlign: 'top', padding: '10px 20px' }}
        >
          <FileUpload
            maxCount={15}
            values={body.images}
            onChange={(images) => {
              setBody((x) => ({ ...x, images }));
            }}
          />
          * 가로 800이상 높이제한없음
        </Descriptions.Item>
      </Descriptions>
      <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        {!!manualId && (
          <Button
            onClick={handleRemove}
            size={'large'}
            className={styles.basicButton}
            style={{ marginRight: 5, height: 40, width: 100 }}
          >
            삭제
          </Button>
        )}
        <Button
          onClick={() => navigate('/settings/manuals', { replace: true })}
          size={'large'}
          className={styles.basicButton}
          style={{ marginRight: 5, height: 40, width: 100 }}
        >
          취소
        </Button>
        <Button
          onClick={handleCreatePost}
          size={'large'}
          className={styles.basicButton}
          style={{ height: 40, width: 100, backgroundColor: '#FF7200', color: 'white' }}
        >
          저장
        </Button>
      </div>
      {/*<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>*/}
      {/*  <Button*/}
      {/*      onClick={handleCreatePost}*/}
      {/*      style={{ background: '#000', color: '#fff', width: 152, height: 40, borderRadius: 4 }}*/}
      {/*      loading={createHoliday.loading}*/}
      {/*  >*/}
      {/*    등록*/}
      {/*  </Button>*/}
      {/*</div>*/}
    </>
  );
};

export default ManualCreate;
