import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: ${({ flex }) => flex ?? 0};
  border-radius: 10px;
  border: 1px solid #ddd;
  margin: 0 15px;
  background: #ffffff;
  padding: 15px;
  box-sizing: border-box;
  overflow: hidden;
`;

const Title = styled.h3`
  text-align: ${({ titleAlign }) => titleAlign ?? 'left'};
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 25px;
  color: #222222;
`;

/**
 * 통계페이지 차트 부모 박스 컴포넌트
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const ChartWarpper = ({ title, className, flex, titleAlign, chart, style, cleaningCount, repairCount }) => {
  return (
    <Container flex={flex} className={className} style={style}>
      <Title titleAlign={titleAlign}>{title}</Title>
      {chart}
      <div>
        <span style={{ margin: 25 }}>{cleaningCount}</span>
        <span style={{ margin: 25 }}>{repairCount}</span>
      </div>
    </Container>
  );
};

export default ChartWarpper;
