import React from 'react';
import { Button, Input, InputNumber, PageHeader, Table } from 'antd';
import { restApi } from '../../../apis';
import { toCommaNumber } from '../../../common/utils';

/**
 * 기타설정 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const EtcIndex = () => {
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    const loadData = async () => {
      const { data } = await restApi.get(`/products/types`);
      setData(data);
    };
    loadData().catch(console.warn);
  }, []);

  const column = [
    {
      title: '코드',
      dataIndex: 'type',
      align: 'center',
    },
    {
      title: '이름',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: '배송기본비',
      dataIndex: 'delivery_price',
      align: 'center',
      render: (v) => toCommaNumber(v),
    },
    {
      title: '사용자->센터',
      dataIndex: 'calc_send',
      align: 'center',
      render: (v) => toCommaNumber(v),
    },
    {
      title: '센터->사용자',
      dataIndex: 'calc_return',
      align: 'center',
      render: (v) => toCommaNumber(v),
    },
    {
      title: '센터->장인',
      dataIndex: 'calc_send2',
      align: 'center',
      render: (v) => toCommaNumber(v),
    },
    {
      title: '장인->센터',
      dataIndex: 'calc_return2',
      align: 'center',
      render: (v) => toCommaNumber(v),
    },
    {
      title: '일일제한',
      dataIndex: 'day_limit',
      align: 'center',
      render: (data, record, index) => (
        <Input.Group compact>
          <InputNumber
            value={data}
            onChange={(v) =>
              setData((x) => {
                const out = [...x];
                out[index].day_limit = v;
                return out;
              })
            }
          />
          <Button
            onClick={async () => {
              await restApi.put(`/products/types/${record.type}`, {
                day_limit: data,
              });
              alert('저장되었습니다.');
            }}
            type="primary"
          >
            저장
          </Button>
        </Input.Group>
      ),
    },
  ];

  return (
    <div>
      <PageHeader className="site-page-header" title="기타설정" />
      <Table columns={column} dataSource={data} scroll={{ x: 'max-content' }} />
    </div>
  );
};

export default EtcIndex;
