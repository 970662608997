import { restApi } from '../index';

export const getStats = ({ start_at, target, state, end_at, type, vendor_id, vendor_code }) => {
  if (!start_at || !end_at) return;
  const endpoint = '/stats';
  const params = { start_at, end_at, target, state, type, vendor_id, vendor_code };
  return restApi.get(endpoint, { params });
};

export const getReviewStats = ({ start_at, end_at, target, state, type }) => {
  if (!start_at || !end_at) return;
  const endpoint = '/reviews/stats';
  const params = { start_at, end_at, target, state, type };
  return restApi.get(endpoint, { params });
};
