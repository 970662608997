import React from 'react';
import { PageHeader, Table } from 'antd';
import TableButtonWrap from '../../../components/TableButtonWrap';
import moment from 'moment';
import { getAPIHost, restApi } from '../../../apis';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import fileDownload from 'js-file-download';
import { useDispatch } from 'react-redux';
import CreatedQrModal from './details/create';
import FileSaver from 'file-saver';

/**
 * QR코드관리 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const QrIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);
  const dispatch = useDispatch();

  const [data, setData] = React.useState();
  const [isVisible, setIsVisible] = React.useState(false);

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      sort: 'created_at',
      ...query,
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    const { data } = await restApi.get('/qritems', {
      params,
    });
    setData(data);
  }, [query]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  const columns = [
    {
      title: '코드',
      dataIndex: 'code',
    },
    {
      title: '사용 주문',
      dataIndex: 'purchase',
      key: 'purchase',
      align: 'center',
      width: 100,
      render: (data) => {
        if (!data?.id) return '미사용';
        return <Link to={`/work/order/details/${data?.id}`}>주문번호 : {data?.id}</Link>;
      },
    },
    {
      title: '등록일자',
      dataIndex: 'created_at',
      align: 'center',
      width: 200,
      render: (data) => {
        return moment(data).format('YYYY-MM-DD HH:mm');
      },
    },
  ];

  // 테이블 페이지네이션
  const pagination = {
    position: ['bottomCenter'],
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      navigate('?' + qs.stringify({ ...query, page: p - 1 }), { replace: true });
    },
  };

  const xlsxSave = () => {
    const args = {
      ...query,
      type: 'C',
    };
    restApi
      .get(`${getAPIHost()}/exports/accounts/center?${qs.stringify(args)}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `센터직원_${moment().format('YYYYMMDDHHmmss')}.xlsx`);
      });
  };

  const handleAddTeam = async (body) => {
    try {
      await restApi.post(`/qritems`, body);

      const params = {
        start_at: moment().add(-2, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
        end_at: moment().format('YYYY-MM-DD HH:mm:ss'),
      };

      const filename = `QR_${moment().format('YYYY-MM-DD HH:mm')}`;
      const { data } = await restApi.get(`/files/qrs`, { params, responseType: 'blob' });
      await FileSaver.saveAs(data, `${filename}.zip`);

      loadData();
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="QR 관리" />
      <p>총 갯수 : {data?.total_elements}</p>
      {/*<SearchForm fields={teamsSearchFields} />*/}
      <div style={{ position: 'relative' }}>
        <Table
          scroll={{ x: 'max-content' }}
          dataSource={data?.items}
          columns={columns}
          pagination={
            pagination
              ? {
                  ...pagination,
                  position: ['bottomCenter'],
                  showSizeChanger: false,
                }
              : false
          }
        />
        <div>
          <TableButtonWrap
            position="left"
            buttons={[
              {
                icon: require('../../../assets/user_avatar.png'),
                label: 'QR 등록',
                onClick: () => setIsVisible(true),
              },
            ]}
          />
        </div>
      </div>
      <CreatedQrModal visible={isVisible} onOk={handleAddTeam} onCancel={() => setIsVisible(false)} />
    </div>
  );
};

export default QrIndex;
