import React, { useEffect, useState } from 'react';
import moment from 'moment';
import useAxios from '../../../hooks/useAxios';
import { getReviewStats, getStats } from '../../../apis/stats';
import Loading from '../../../components/Loading';
import { Button, DatePicker, Radio } from 'antd';
import ReactToPrint from 'react-to-print';
import Icon from '../../../components/Icon';
import DoughnutChart from '../../../components/Chart/DoughnutChart';
import styles from '../stats.module.less';
import { pointCut } from '../../../common/utils';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import RequestChartRV from '../../../components/Chart/RequestChartRV';
import DoughnutChart2 from '../../../components/Chart/DoughnutChart2';
import { reviewTypeBad, reviewTypeGood } from '../../../common/defines';

/**
 * 리뷰 통계 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const ReviewStatsIndex = () => {
  // const stats = useAxios();
  const printComponentRef = React.useRef();
  // const beforeStats = useAxios();
  const type = 'RV';

  const [method, setMethod] = useState('month');
  const [date, setDate] = useState(moment());

  const [asIsData, setAsIsData] = useState();
  const [toBeData, setToBeData] = useState();

  const stats = {
    loading: false,
    data: toBeData,
  };

  const beforeStats = {
    loading: false,
    data: asIsData,
  };

  //method
  const start_at = date
    .clone()
    .startOf(method ?? 'day')
    .format('YYYY-MM-DD');
  const end_at = date
    .clone()
    .endOf(method ?? 'day')
    .format('YYYY-MM-DD');

  const dateRange = [moment(start_at), moment(end_at)];

  const [count, setCount] = useState(0);
  useEffect(() => {
    const tk = setInterval(() => {
      setCount((x) => x + 1);
    }, 5000);
    return () => clearInterval(tk);
  }, []);

  React.useEffect(() => {
    if (!type) return;
    if (count > 100) {
      window.location.reload();
    }

    const loadData = async () => {
      const params = {
        type,
      };

      const before_end_at = date
        .clone()
        .startOf(method ?? 'day')
        .add(-1, 'days');

      const before_start_at = before_end_at.clone().startOf(method ?? 'day');

      {
        const { data } = await getReviewStats({
          ...params,
          start_at,
          end_at,
        });
        setToBeData(data);
      }

      {
        const { data } = await getReviewStats({
          ...params,
          start_at: before_start_at.format('YYYY-MM-DD'),
          end_at: before_end_at.format('YYYY-MM-DD'),
        });
        setAsIsData(data);
      }
    };
    loadData().catch(console.warn);
  }, [method, date, count]);

  const handlePdfDownload = async () => {
    const canvas = await html2canvas(printComponentRef.current);
    let imgData = canvas.toDataURL('image/png');
    let margin = 10; // 출력 페이지 여백설정
    let imgWidth = 210 - 10 * 2; // 이미지 가로 길이(mm) A4 기준
    let pageHeight = imgWidth * 1.414; // 출력 페이지 세로 길이 계산 A4 기준
    let imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let doc = new jsPDF('p', 'mm');
    let position = margin; // 첫 페이지 출력
    doc.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
    heightLeft -= pageHeight; // 한 페이지 이상일 경우 루프 돌면서 출력
    while (heightLeft >= 20) {
      position = heightLeft - imgHeight;
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      doc.addPage();
      heightLeft -= pageHeight;
    }

    // 파일 저장
    doc.save(`긴급배송_데이터_관리_${moment().format('YYYYMMDD_HHmmss')}.pdf`);
  };

  if (stats.loading) {
    return <Loading />;
  }

  return (
    <>
      <div className={styles.pageHeader}>
        <h2>후기 데이터 관리</h2>
        <div className={styles.addition}>
          <ReactToPrint
            trigger={() => (
              <Button>
                <Icon size={14} source={require('../../../assets/icon_save.png')} />
                인쇄
              </Button>
            )}
            content={() => printComponentRef.current}
          />
          <Button onClick={handlePdfDownload}>
            <Icon size={14} source={require('../../../assets/icon_save.png')} />
            PDF 저장
          </Button>
          {/*<Button>*/}
          {/*  <Icon size={14} source="save" />*/}
          {/*  엑셀저장*/}
          {/*</Button>*/}
        </div>
      </div>
      <div ref={printComponentRef}>
        <div className={styles.toolbar}>
          <Radio.Group value={method} onChange={(e) => setMethod(e.target.value)}>
            <Radio.Button value={'year'}>연간</Radio.Button>
            <Radio.Button value={'month'}>월간</Radio.Button>
            <Radio.Button value={'week'}>주간</Radio.Button>
            <Radio.Button value={undefined}>일간</Radio.Button>
          </Radio.Group>

          <DatePicker
            style={{ width: 200, fontSize: 12 }}
            placeholder="날짜선택"
            value={date}
            onChange={(a, b) => setDate(a)}
            picker={method}
            allowClear={false}
          />
        </div>
        <div style={{ marginBottom: 25 }}>
          <div className={styles.flexrow}>
            <div className={styles.canvas}>
              <h4 className={styles.title}>총 후기수</h4>
              <p className={styles.count}>{stats?.data?.total}</p>
              <p className={styles.prevCount}>{beforeStats?.data?.total}</p>
              <div className={styles.compare}>
                {stats?.data?.total - beforeStats?.data?.total > 0 ? (
                  <img src={require('../../../assets/arrow_rank_up.png')} style={{ margin: 8, marginBottom: 12 }} />
                ) : (
                  <img src={require('../../../assets/arrow_rank_down.png')} style={{ margin: 8, marginBottom: 12 }} />
                )}
                {Math.abs(Number(stats?.data?.total || 0) - Number(beforeStats?.data?.total))}(
                {pointCut(
                  ((Number(stats?.data?.total || 0) - Number(beforeStats?.data?.total || 0)) /
                    Number(beforeStats?.data?.total || 1)) *
                    100 || '-',
                  2,
                )}
                %)
              </div>
            </div>
            <div className={styles.canvas}>
              <h4 className={styles.title}>평균 점수</h4>
              <p className={styles.count}>{stats?.data?.avg?.toFixed(3) ?? 0}</p>
              <p className={styles.prevCount}>{beforeStats?.data?.avg}</p>
              <div className={styles.compare}>
                {stats?.data?.avg - beforeStats?.data?.avg > 0 ? (
                  <img src={require('../../../assets/arrow_rank_up.png')} style={{ margin: 8, marginBottom: 12 }} />
                ) : (
                  <img src={require('../../../assets/arrow_rank_down.png')} style={{ margin: 8, marginBottom: 12 }} />
                )}
                {Math.abs(Number(stats?.data?.avg || 0) - Number(beforeStats?.data?.avg))?.toFixed(3) ?? 0}(
                {pointCut(
                  ((Number(stats?.data?.avg || 0) - Number(beforeStats?.data?.avg || 0)) /
                    Number(beforeStats?.data?.avg || 1)) *
                    100 || '-',
                  2,
                )}
                %)
              </div>
            </div>
            <div className={styles.canvas}>
              <h4 className={styles.title}>후기 작성비율</h4>
              <p className={styles.count}>{stats?.data?.rate}%</p>
              <p className={styles.prevCount}>{beforeStats?.data?.rate}%</p>
              <div className={styles.compare}>
                {stats?.data?.rate - beforeStats?.data?.rate > 0 ? (
                  <img src={require('../../../assets/arrow_rank_up.png')} style={{ margin: 8, marginBottom: 12 }} />
                ) : (
                  <img src={require('../../../assets/arrow_rank_down.png')} style={{ margin: 8, marginBottom: 12 }} />
                )}
                {Math.abs(Number(stats?.data?.rate || 0) - Number(beforeStats?.data?.rate))}(
                {pointCut(
                  ((Number(stats?.data?.rate || 0) - Number(beforeStats?.data?.rate || 0)) /
                    Number(beforeStats?.data?.rate || 1)) *
                    100 || '-',
                  2,
                )}
                %)
              </div>
            </div>
          </div>
          <p style={{ marginTop: 10 }}>
            ※ 등락 비교일 {dateRange[0].format('YYYY.MM.DD')} - {dateRange[1].format('YYYY.MM.DD')}
          </p>
        </div>
        <div className={styles.flexrow} style={{ marginBottom: 60, overflowX: 'auto' }}>
          <div style={{ minWidth: 1023 }}>
            <RequestChartRV title={`평균점수 그래프`} dateRange={dateRange} type={type} stats={stats} />
          </div>
        </div>
        <div className={styles.pageHeader}>
          <h2>남자/여자 후기 작성</h2>
        </div>
        <div className={styles.flexrow} style={{ marginBottom: 60 }}>
          <DoughnutChart2 type={type} gender="M" title="남자 구두 후기" dateRange={dateRange} stats={stats} />
          <DoughnutChart2 type={type} gender="F" title="여자 구두 후기" dateRange={dateRange} stats={stats} />
        </div>

        <div className={styles.pageHeader}>
          <h2>만족데이터 관리</h2>
        </div>
        <div style={{ marginBottom: 40 }}>
          <div className={styles.flexrow}>
            {reviewTypeGood?.map((tp) => {
              const v1 = Number(stats?.data?.for_ment_good?.[tp] || 0);
              const v2 = Number(beforeStats?.data?.for_ment_good?.[tp] || 0);

              return (
                <div className={styles.canvas}>
                  <h4 className={styles.title}>{tp}</h4>
                  <p className={styles.count}>{v1}</p>
                  <p className={styles.prevCount}>{v2}</p>
                  <div className={styles.compare}>
                    {v1 - v2 > 0 ? (
                      <img src={require('../../../assets/arrow_rank_up.png')} style={{ margin: 8, marginBottom: 12 }} />
                    ) : (
                      <img
                        src={require('../../../assets/arrow_rank_down.png')}
                        style={{ margin: 8, marginBottom: 12 }}
                      />
                    )}
                    {Math.abs(v1 - v2)}({pointCut(((v1 - v2) / (v2 || 1)) * 100 || '-', 2)}
                    %)
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.pageHeader}>
          <h2>불만족데이터 관리</h2>
        </div>
        <div>
          <div className={styles.flexrow}>
            {reviewTypeBad?.map((tp) => {
              const v1 = Number(stats?.data?.for_ment_bad?.[tp] || 0);
              const v2 = Number(beforeStats?.data?.for_ment_bad?.[tp] || 0);

              return (
                <div className={styles.canvas}>
                  <h4 className={styles.title}>{tp}</h4>
                  <p className={styles.count}>{v1}</p>
                  <p className={styles.prevCount}>{v2}</p>
                  <div className={styles.compare}>
                    {v1 - v2 > 0 ? (
                      <img src={require('../../../assets/arrow_rank_up.png')} style={{ margin: 8, marginBottom: 12 }} />
                    ) : (
                      <img
                        src={require('../../../assets/arrow_rank_down.png')}
                        style={{ margin: 8, marginBottom: 12 }}
                      />
                    )}
                    {Math.abs(v1 - v2)}({pointCut(((v1 - v2) / (v2 || 1)) * 100 || '-', 2)}
                    %)
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewStatsIndex;
