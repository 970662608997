import { restApi } from '../index';

export const pushTargettingType = {
  ALL: 'ALL',
  TARGETING: 'TARGETING',
  REUSE: 'REUSE',
};

export const pushTargettingTypes = {
  [pushTargettingType.ALL]: '전체',
  [pushTargettingType.REUSE]: '재이용고객',
  [pushTargettingType.TARGETING]: '타겟팅',
};

export const pushTargettingState = {
  READY: 'READY',
  SENDING: 'SENDING',
  FINISH: 'FINISH',
};

export const pushTargettingStates = {
  [pushTargettingState.READY]: '전송준비중',
  [pushTargettingState.SENDING]: '전송중',
  [pushTargettingState.FINISH]: '완료',
};

export const getPushes = (body) => {
  const endpoint = '/pushes';
  return restApi.get(endpoint, body);
};

export const getPush = (id) => {
  const endpoint = `/pushes/${id}`;
  return restApi.get(endpoint);
};

export const createPush = (body) => {
  const endpoint = '/pushes';
  return restApi.post(endpoint, body);
};
export const updatePush = (id, body) => {
  const endpoint = '/pushes/' + id;
  return restApi.put(endpoint, body);
};

export const deletePush = (id) => {
  const endpoint = `/pushes/${id}`;
  return restApi.delete(endpoint);
};
