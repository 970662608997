import React, { useMemo } from 'react';
import { InboxOutlined, ReconciliationOutlined, SettingOutlined, TeamOutlined, ToolOutlined } from '@ant-design/icons';
import AccountUserIndex from '../pages/accounts/user';
import AccountUserDetail from '../pages/accounts/user/details/[id]';
import OrderDetails from '../pages/work/order/details/[id]';
import Index from '../pages';
import AccountCorpIndex from '../pages/accounts/corp';
import AccountCorpDetails from '../pages/accounts/corp/details/[id]';
import AccountDeliveryIndex from '../pages/accounts/delivery';
import AccountDeliveryDetails from '../pages/accounts/delivery/details/[id]';
import AccountUrgencyDetails from '../pages/accounts/urgency/details/[id]';
import AccountUrgencyIndex from '../pages/accounts/urgency';
import AccountExpertIndex from '../pages/accounts/expert';
import AccountExpertDetails from '../pages/accounts/expert/details/[id]';
import AccountCenterIndex from '../pages/accounts/center';
import AccountCenterDetails from '../pages/accounts/center/details/[id]';
import AccountAdminIndex from '../pages/accounts/admin';
import OrderIndex from '../pages/work/order';
import PaymentIndex from '../pages/work/payment';
import PaymentDetails from '../pages/work/payment/details/[id]';
import CalcIndex from '../pages/work/calc';
import CalcDetails from '../pages/work/calc/details/[id]';
import ReviewIndex from '../pages/work/review';
import ReviewDetails from '../pages/work/review/details/[id]';
import QnAIndex from '../pages/work/qna';
import QnADetails from '../pages/work/qna/details/[id]';
import BannerIndex from '../pages/contents/banner_top';
import BannerCreate from '../pages/contents/banner_top/create';
import BannerDetails from '../pages/contents/banner_top/details/[id]';
import BannerUpdate from '../pages/contents/banner_top/update/[id]';
import NoticeIndex from '../pages/contents/notice';
import NoticeCreate from '../pages/contents/notice/create';
import NoticeDetails from '../pages/contents/notice/details/[id]';
import NoticeUpdate from '../pages/contents/notice/update/[id]';
import StoryIndex from '../pages/contents/story';
import StoryCreate from '../pages/contents/story/create';
import StoryDetails from '../pages/contents/story/details/[id]';
import StoryUpdate from '../pages/contents/story/update/[id]';
import ShoedocIndex from '../pages/contents/shoedoc';
import ShoedocCreate from '../pages/contents/shoedoc/create';
import ShoedocDetails from '../pages/contents/shoedoc/details/[id]';
import ShoedocUpdate from '../pages/contents/shoedoc/update/[id]';
import FAQIndex from '../pages/contents/faq';
import FAQCreate from '../pages/contents/faq/create';
import FAQUpdate from '../pages/contents/faq/update/[id]';
import FAQDetails from '../pages/contents/faq/details/[id]';
import CenterMgrIndex from '../pages/setting/centermgr';
import CenterMgrCreate from '../pages/setting/centermgr/create';
import CenterMgrDetails from '../pages/setting/centermgr/details/[id]';
import CenterMgrUpdate from '../pages/setting/centermgr/update/[id]';
import RestdayIndex from '../pages/setting/restday';
import RestdayCreate from '../pages/setting/restday/create';
import RestdayDetails from '../pages/setting/restday/details/[id]';
import RestdayUpdate from '../pages/setting/restday/update/[id]';
import CouponIndex from '../pages/setting/coupon';
import CouponCreate from '../pages/setting/coupon/create';
import CouponDetails from '../pages/setting/coupon/details/[id]';
import CouponUpdate from '../pages/setting/coupon/update/[id]';
import EtcIndex from '../pages/setting/etc';
import SubscribeIndex from '../pages/stats/subscribe';
import DailyIndex from '../pages/stats/daily';
import EmergencyIndex from '../pages/stats/emergency';
import BannerBottomIndex from '../pages/contents/banner_bottom';
import BannerBottomCreate from '../pages/contents/banner_bottom/create';
import BannerBottomUpdate from '../pages/contents/banner_bottom/update/[id]';
import QrIndex from '../pages/setting/qrcode';
import BannerBottomDetails from '../pages/contents/banner_bottom/details/[id]';
import DeliveryIndex from '../pages/work/delivery';
import PushIndex from '../pages/setting/push';
import PushCreate from '../pages/setting/push/create';
import PushDetails from '../pages/setting/push/details/[id]';
import PushUpdate from '../pages/setting/push/update/[id]';
import ProductsIndex from '../pages/setting/products';
import AgreementIndex from '../pages/setting/agreement';
import AgreementCreate from '../pages/setting/agreement/create';
import ReviewStatsIndex from '../pages/stats/review';
import AccountCorpUpload from '../pages/accounts/corp/upload';
import ManualIndex from '../pages/setting/manual';
import ManualCreate from '../pages/setting/manual/create';
import MapsIndex from '../pages/setting/maps';
import ProductsCategory2 from '../pages/setting/products/category2';
import ProductsCategory3 from '../pages/setting/products/category3';
import TransferIndex from '../pages/stats/transfer';
import CalendarIndex from '../pages/work/calendar';
import VendorsIndex from '../pages/vendors';
import VendorsCreate from '../pages/vendors/create';
import { shallowEqual, useSelector } from 'react-redux';
import VendersCodesIndex from '../pages/for_vendor/codes';
import VendersCodesCreate from '../pages/for_vendor/codes/upload';
import VAccountUserIndex from '../pages/for_vendor/accounts/user';
import VStatsUser from '../pages/for_vendor/stats/user';
import VCalcIndex from '../pages/for_vendor/calcs';
import VendorMe from '../pages/for_vendor/me';
import VendorMenu from './VendorMenu';
import VendorsDetailsInfo from '../pages/vendors/details/info';
import VendorsDetailsCalc from '../pages/vendors/details/calc';
import VendorsDetailsAccounts from '../pages/vendors/details/accounts';
import VendorsDetailsPurchases from '../pages/vendors/details/purchases';
import VendorsDetailsPayments from '../pages/vendors/details/payments';
import VendorsDetailsCodes from '../pages/vendors/details/codes';
import VendorsDetailsStats from '../pages/vendors/details/stats';
import VendorsDetailsCodesCreate from '../pages/vendors/details/codes_create';

export const pageForAdmins = [
  {
    path: '/',
    title: '메인',
    Screen: Index,
  },
  {
    path: '/accounts',
    title: '회원관리',
    icon: TeamOutlined,
    isMenu: true,
  },
  {
    path: '/accounts/user',
    title: '고객 회원',
    screen: AccountUserIndex,
    isMenu: true,
    group: '회원 관리',
  },
  {
    path: '/accounts/user/details/:id',
    title: '고객 상세',
    screen: AccountUserDetail,
  },
  {
    path: '/accounts/corp',
    title: '기업 회원',
    screen: AccountCorpIndex,
    isMenu: true,
    group: '회원 관리',
  },
  {
    path: '/accounts/corp/create',
    title: '기업 회원(주문) 등록',
    screen: AccountCorpUpload,
    isMenu: true,
    group: '회원 관리',
  },
  {
    path: '/accounts/corp/details/:id',
    title: '기업 고객 상세',
    screen: AccountCorpDetails,
  },
  {
    path: '/accounts/delivery',
    title: '정기/하루',
    screen: AccountDeliveryIndex,
    isMenu: true,
    group: '기사 관리',
  },
  {
    path: '/accounts/delivery/details/:id',
    title: '정기/하루 상세',
    screen: AccountDeliveryDetails,
  },
  {
    path: '/accounts/urgency',
    title: '긴급기사',
    screen: AccountUrgencyIndex,
    isMenu: true,
    // 2뎁스 메뉴 그룹핑
    group: '기사 관리',
  },
  {
    path: '/accounts/urgency/details/:id',
    title: '정기/하루 상세',
    screen: AccountUrgencyDetails,
  },
  {
    path: '/accounts/expert',
    title: '장인',
    screen: AccountExpertIndex,
    isMenu: true,
  },
  {
    path: '/accounts/expert/details/:id',
    title: '장인 상세',
    screen: AccountExpertDetails,
  },
  {
    path: '/accounts/center',
    title: '센터',
    screen: AccountCenterIndex,
    isMenu: true,
  },
  {
    path: '/accounts/center/details/:id',
    title: '센터 상세',
    screen: AccountCenterDetails,
  },
  {
    path: '/accounts/admin',
    title: '관리자',
    screen: AccountAdminIndex,
    isMenu: true,
  },
  {
    path: '/work',
    title: '업무처리',
    icon: ToolOutlined,
    isMenu: true,
  },
  {
    path: '/work/order',
    title: '주문내역',
    screen: OrderIndex,
    isMenu: true,
  },
  {
    path: '/work/order/details/:id',
    title: '주문내역 상세',
    screen: OrderDetails,
  },
  {
    path: '/work/payment',
    title: '결제내역',
    screen: PaymentIndex,
    isMenu: true,
  },
  {
    path: '/work/payment/details/:id',
    title: '결제내역 상세',
    screen: PaymentDetails,
  },
  {
    path: '/work/calendar',
    title: '업무일정',
    screen: CalendarIndex,
    isMenu: true,
  },
  {
    path: '/work/delivery',
    title: '배송처리',
    screen: DeliveryIndex,
    isMenu: true,
  },
  {
    path: '/work/calc',
    title: '정산내역',
    screen: CalcIndex,
    isMenu: true,
  },
  {
    path: '/work/calc/details/:id',
    title: '정산내역 상세',
    screen: CalcDetails,
  },
  {
    path: '/work/review',
    title: '후기관리',
    screen: ReviewIndex,
    isMenu: true,
  },
  {
    path: '/work/review/details/:id',
    title: '후기 상세',
    screen: ReviewDetails,
  },
  {
    path: '/work/qna',
    title: '고객문의',
    screen: QnAIndex,
    isMenu: true,
  },
  {
    path: '/work/qna/details/:id',
    title: '고객문의 상세',
    screen: QnADetails,
  },
  {
    path: '/contents',
    title: '콘텐츠관리',
    icon: InboxOutlined,
    isMenu: true,
  },
  {
    path: '/contents/banner_top',
    title: '상단 배너관리',
    screen: BannerIndex,
    isMenu: true,
    group: '배너 관리',
  },
  {
    path: '/contents/banner_top/create',
    title: '배너 작성',
    screen: BannerCreate,
  },
  {
    path: '/contents/banner_top/update/:id',
    title: '배너 수정',
    screen: BannerUpdate,
  },
  {
    path: '/contents/banner_top/details/:id',
    title: '배너 상세',
    screen: BannerDetails,
  },
  {
    path: '/contents/banner_bottom',
    title: '하단 배너관리',
    screen: BannerBottomIndex,
    isMenu: true,
    group: '배너 관리',
  },
  {
    path: '/contents/banner_bottom/create',
    title: '배너 작성',
    screen: BannerBottomCreate,
  },
  {
    path: '/contents/banner_bottom/update/:id',
    title: '배너 수정',
    screen: BannerBottomUpdate,
  },
  {
    path: '/contents/banner_bottom/details/:id',
    title: '배너 상세',
    screen: BannerBottomDetails,
  },
  {
    path: '/contents/notice',
    title: '공지사항',
    screen: NoticeIndex,
    isMenu: true,
  },
  {
    path: '/contents/notice/create',
    title: '공지사항 작성',
    screen: NoticeCreate,
  },
  {
    path: '/contents/notice/details/:id',
    title: '공지사항 상세',
    screen: NoticeDetails,
  },
  {
    path: '/contents/notice/update/:id',
    title: '공지사항 수정',
    screen: NoticeUpdate,
  },
  {
    path: '/contents/story',
    title: '구두이야기',
    screen: StoryIndex,
    isMenu: true,
  },
  {
    path: '/contents/story/create',
    title: '구두이야기 작성',
    screen: StoryCreate,
  },
  {
    path: '/contents/story/details/:id',
    title: '구두이야기 상세',
    screen: StoryDetails,
  },
  {
    path: '/contents/story/update/:id',
    title: '구두이야기 수정',
    screen: StoryUpdate,
  },
  {
    path: '/contents/shoedoc',
    title: '슈닥이야기',
    screen: ShoedocIndex,
    isMenu: true,
  },
  {
    path: '/contents/shoedoc/create',
    title: '슈닥이야기 작성',
    screen: ShoedocCreate,
  },
  {
    path: '/contents/shoedoc/details/:id',
    title: '슈닥이야기 상세',
    screen: ShoedocDetails,
  },
  {
    path: '/contents/shoedoc/update/:id',
    title: '슈닥이야기 수정',
    screen: ShoedocUpdate,
  },
  {
    path: '/contents/faq',
    title: 'FAQ',
    screen: FAQIndex,
    isMenu: true,
  },
  {
    path: '/contents/faq/create',
    title: 'FAQ 작성',
    screen: FAQCreate,
  },
  {
    path: '/contents/faq/details/:id',
    title: 'FAQ 상세',
    screen: FAQDetails,
  },
  {
    path: '/contents/faq/update/:id',
    title: 'FAQ 수정',
    screen: FAQUpdate,
  },
  {
    path: '/settings',
    title: '설정',
    icon: SettingOutlined,
    isMenu: true,
  },
  {
    path: '/settings/centermgr',
    title: '센터관리',
    screen: CenterMgrIndex,
    isMenu: true,
  },
  {
    path: '/settings/centermgr/create',
    title: '지점 등록',
    screen: CenterMgrCreate,
  },
  {
    path: '/settings/centermgr/details/:id',
    title: '지점 상세',
    screen: CenterMgrDetails,
  },
  {
    path: '/settings/centermgr/update/:id',
    title: '지점 수정',
    screen: CenterMgrUpdate,
  },
  {
    path: '/settings/qrcode',
    title: 'QR코드관리',
    screen: QrIndex,
    isMenu: true,
  },
  // {
  //   path: '/settings/center',
  //   title: '팀관리',
  //   screen: TeamsIndex,
  //   isMenu: true,
  // },
  // {
  //   path: '/settings/center/details/:id',
  //   title: '팀관리 상세',
  //   screen: TeamsDetails,
  // },
  {
    path: '/settings/restday',
    title: '휴일관리',
    screen: RestdayIndex,
    isMenu: true,
  },
  {
    path: '/settings/restday/create',
    title: '휴일 등록',
    screen: RestdayCreate,
  },
  {
    path: '/settings/restday/details/:id',
    title: '휴일 상세',
    screen: RestdayDetails,
  },
  {
    path: '/settings/restday/update/:id',
    title: '휴일 수정',
    screen: RestdayUpdate,
  },
  {
    path: '/settings/coupon',
    title: '쿠폰관리',
    screen: CouponIndex,
    isMenu: true,
  },
  {
    path: '/settings/coupon/create',
    title: '쿠폰 등록',
    screen: CouponCreate,
  },
  {
    path: '/settings/coupon/details/:id',
    title: '쿠폰 상세',
    screen: CouponDetails,
  },
  {
    path: '/settings/coupon/update/:id',
    title: '쿠폰 수정',
    screen: CouponUpdate,
  },
  {
    path: '/settings/push',
    title: '푸시알림',
    screen: PushIndex,
    isMenu: true,
  },
  {
    path: '/settings/push/create',
    title: '푸시등록',
    screen: PushCreate,
  },
  {
    path: '/settings/push/details/:id',
    title: '푸시상세',
    screen: PushDetails,
  },
  {
    path: '/settings/push/update/:id',
    title: '푸시수정',
    screen: PushUpdate,
  },

  //
  {
    path: '/settings/manuals',
    title: '매뉴얼 설정',
    screen: ManualIndex,
    isMenu: true,
  },
  {
    path: '/settings/manuals/create',
    title: '매뉴얼 등록',
    screen: ManualCreate,
  },
  {
    path: '/settings/manuals/details/:id',
    title: '매뉴얼 수정',
    screen: ManualCreate,
  },

  //

  {
    path: '/settings/products',
    title: '상품설정',
    screen: ProductsIndex,
    isMenu: true,
  },

  {
    path: '/settings/products/:type/:code',
    title: '상품설정',
    screen: ProductsCategory2,
  },

  {
    path: '/settings/products/:type/:code/:id',
    title: '상품설정',
    screen: ProductsCategory3,
  },

  //

  //
  {
    path: '/settings/etc',
    title: '기타설정',
    screen: EtcIndex,
    isMenu: true,
  },
  {
    path: '/settings/agreement',
    title: '약관설정',
    screen: AgreementIndex,
    isMenu: true,
  },
  {
    path: '/settings/agreement/create',
    title: '약관등록',
    screen: AgreementCreate,
  },
  {
    path: '/settings/maps',
    title: '지도관리',
    screen: MapsIndex,
    isMenu: true,
  },

  {
    path: '/stats',
    title: '통계',
    icon: ReconciliationOutlined,
    isMenu: true,
  },
  {
    path: '/stats/subscribe',
    title: '정기구독',
    screen: SubscribeIndex,
    isMenu: true,
  },
  {
    path: '/stats/daily',
    title: '하루배송',
    screen: DailyIndex,
    isMenu: true,
  },
  {
    path: '/stats/transfer',
    title: '택배배송',
    screen: TransferIndex,
    isMenu: true,
  },
  {
    path: '/stats/emergency',
    title: '긴급배송',
    screen: EmergencyIndex,
    isMenu: true,
  },
  {
    path: '/stats/review',
    title: '후기',
    screen: ReviewStatsIndex,
    isMenu: true,
  },
  {
    path: '/vendors',
    title: '기업',
    icon: ReconciliationOutlined,
    isMenu: true,
    renderComponent: <VendorMenu />,
  },
  {
    path: '/vendors/list',
    title: '기업회원 목록',
    isMenu: true,
    screen: VendorsIndex,
  },
  {
    path: '/vendors/create',
    title: '기업회원 등록',
    screen: VendorsCreate,
  },
  {
    path: '/vendors/details/:id',
    title: '기업회원 수정',
    screen: VendorsCreate,
  },
  {
    path: '/vendors/:id/info',
    title: '기업회원 정보',
    screen: VendorsDetailsInfo,
  },
  {
    path: '/vendors/:id/calc',
    title: '기업회원 정보',
    screen: VendorsDetailsCalc,
  },
  {
    path: '/vendors/:id/accounts',
    title: '기업회원 정보',
    screen: VendorsDetailsAccounts,
  },
  {
    path: '/vendors/:id/purchases',
    title: '기업회원 정보',
    screen: VendorsDetailsPurchases,
  },
  {
    path: '/vendors/:id/payments',
    title: '기업회원 정보',
    screen: VendorsDetailsPayments,
  },
  {
    path: '/vendors/:id/codes',
    title: '기업회원 정보',
    screen: VendorsDetailsCodes,
  },
  {
    path: '/vendors/:id/codes_create',
    title: '기업회원 정보',
    screen: VendorsDetailsCodesCreate,
  },
  {
    path: '/vendors/:id/stats',
    title: '기업회원 정보',
    screen: VendorsDetailsStats,
  },
];

export const pageForVendors = [
  {
    path: '/me',
    title: '기업정보',
    screen: VendorMe,
    icon: ReconciliationOutlined,
    isMenu: true,
  },

  {
    path: '/users',
    title: '회원 관리',
    screen: VAccountUserIndex,
    icon: ReconciliationOutlined,
    isMenu: true,
  },

  {
    path: '/codes',
    title: '아파트 관리',
    screen: VendersCodesIndex,
    icon: ReconciliationOutlined,
    isMenu: true,
  },

  {
    path: '/codes_create',
    title: '아파트 업로드',
    screen: VendersCodesCreate,
    icon: ReconciliationOutlined,
    isMenu: true,
  },

  {
    path: '/stats_apt',
    title: '주문 통계',
    screen: VStatsUser,
    icon: ReconciliationOutlined,
    isMenu: true,
  },

  {
    path: '/calc',
    title: '정산 내역',
    screen: VCalcIndex,
    icon: ReconciliationOutlined,
    isMenu: true,
  },
  //
  // {
  //   path: '/masters',
  //   title: '장인관리',
  //   screen: OrderIndex,
  //   icon: ReconciliationOutlined,
  //   isMenu: true,
  // },
  //
  // {
  //   path: '/stats_master',
  //   title: '장인별 통계',
  //   screen: OrderIndex,
  //   icon: ReconciliationOutlined,
  //   isMenu: true,
  // },
];
/**
 * 좌측 메뉴 정의 컴포넌트
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
export const useRoutes = () => {
  const { principal } = useSelector((s) => s.auth, shallowEqual);

  const pages = principal?.type === 'V' ? pageForVendors : pageForAdmins;

  const routes = useMemo(
    () =>
      pages
        ?.filter((v) => !!v.isMenu || !!v.isSubMenu)
        ?.reduce((output, page) => {
          const path = page.path?.substring(1);
          const parentPath = '/' + path?.substring(0, path.indexOf('/'));
          const parentIndex = path?.indexOf('/') >= 0 ? output.findIndex((v) => v.path === parentPath) : undefined;
          if (parentIndex === undefined) {
            output.push({
              path: page.path,
              title: page.title,
              icon: page?.icon,
              renderComponent: page?.renderComponent,
            });
          } else {
            if (!output[parentIndex].children) output[parentIndex].children = [];
            output[parentIndex].children.push({
              path: page.path,
              title: page.title,
              group: page?.group,
              renderComponent: page?.renderComponent,
            });
          }
          return output;
        }, []),
    [pages],
  );

  return { routes, pages };
};

export default useRoutes;
