import React, { useEffect } from 'react';
import { Button, Descriptions, Input, PageHeader, Radio } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from '../../work/review/details/reviewDetails.module.less';
import { restApi } from '../../../apis';
import qs from 'query-string';
import TinyMCE from 'react-tinymce';

/**
 * 공지사항 생성 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const AgreementCreate = () => {
  const { id } = qs.parse(useLocation().search);
  const [data, setData] = React.useState(
    !id && {
      text: '',
      type: '',
      target: '',
    },
  );

  // 초기 데이터 패칭
  useEffect(() => {
    const loadData = async () => {
      const { data } = await restApi.get(`/agreements/${id}`);
      setData(data);
      // window.tinymce.get(0).setContent(data.text);
    };
    loadData().catch(console.warn);
  }, [id]);

  const navigate = useNavigate();

  // 뒤로가기
  const handleCancel = () => {
    navigate('/settings/agreement', { replace: true });
  };

  // 삭제하기
  const handleDelete = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;

    await restApi.delete(`/agreements/${id}`);
    navigate('/settings/agreement', { replace: true });
  };

  // 작성하기.
  const handleCreatePost = async () => {
    // if (contents === '<p></p>\n') {
    //   alert('내용을 입력해주세요.');
    //   return;
    // }
    try {
      if (!id) {
        const { data: resp } = await restApi.get(`/agreements`);
        const t = resp.find((v) => v.type === data.type && v.target === data.target);
        if (t) {
          alert('이미 등록된 종류입니다. 수정을 통해 작업해주세요.');
          return;
        }
      }

      const text = window.tinymce.get(0).getContent?.();

      await restApi.post(`/agreements`, { ...data, text });
      alert('작성 완료되었습니다.');
      navigate(`/settings/agreement`, { replace: true });
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
    }
  };

  if (!data) return null;

  return (
    <div>
      <PageHeader className="site-page-header" title="약관 작성" />
      <Descriptions bordered>
        <Descriptions.Item label={'대상'} span={4} className="nopadding">
          <Radio.Group
            value={data?.target}
            onChange={(e) => {
              const v = e.target.value;
              setData((x) => ({ ...x, target: v }));
            }}
            disabled={!!id}
          >
            <Radio.Button value={'user'}>사용자</Radio.Button>
            <Radio.Button value={'postman'}>기사</Radio.Button>
            <Radio.Button value={'master'}>장인</Radio.Button>
          </Radio.Group>
        </Descriptions.Item>
        <Descriptions.Item label={'종류'} span={4} className="nopadding">
          <Input
            value={data.type}
            onChange={(e) => {
              const v = e.target.value;
              setData((x) => ({ ...x, type: v }));
            }}
            disabled={!!id}
          />
        </Descriptions.Item>
        <Descriptions.Item label={'내용'} span={4} className="nopadding" style={{ verticalAlign: 'top' }}>
          <TinyMCE
            content={data?.text || ''}
            config={{
              content_css: '/editor.css',
              plugins: 'code table',
              toolbar:
                'undo redo styleselect bold italic alignleft aligncenter alignright bullist numlist outdent indent code',
            }}
            onChange={(e) => {
              const text = e.target.getContent();
              setData((x) => ({ ...x, text }));
            }}
          />
        </Descriptions.Item>
      </Descriptions>
      <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        {id && (
          <Button
            onClick={handleDelete}
            size={'large'}
            className={styles.basicButton}
            style={{ marginRight: 5, height: 40, width: 100 }}
          >
            삭제
          </Button>
        )}
        <Button
          onClick={handleCancel}
          size={'large'}
          className={styles.basicButton}
          style={{ marginRight: 5, height: 40, width: 100 }}
        >
          취소
        </Button>
        <Button
          onClick={handleCreatePost}
          size={'large'}
          className={styles.basicButton}
          style={{ height: 40, width: 100, backgroundColor: '#FF7200', color: 'white' }}
        >
          등록
        </Button>
      </div>
    </div>
  );
};

export default AgreementCreate;
