import React, { useEffect, useState } from 'react';
import './App.less';
import BasicLayout from './nav/BasicLayout';
import { Provider, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { me } from './data/auth';
import store from './data';
import ko_KR from 'antd/lib/locale/ko_KR';
import { ConfigProvider } from 'antd';
import { getProductCategories, getProducts, getProductTypes } from './data/products';
import { getVendors } from './data/vendors';

const Container = React.memo(() => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadData = async () => {
      await dispatch(me());
      await dispatch(getProducts());
      await dispatch(getVendors());
      const types = await dispatch(getProductTypes());
      for (const type of types.payload) {
        await dispatch(getProductCategories(type.type));
      }
    };

    loadData()
      .catch(console.warn)
      .then(() => setLoading(false));
  }, []);

  return <BasicLayout loading={loading} />;
});

const App = () => {
  return (
    <Provider store={store}>
      <ConfigProvider locale={ko_KR}>
        <BrowserRouter>
          <Container />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  );
};

export default App;
