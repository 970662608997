import React, { useCallback, useEffect, useState } from 'react';
import { Button, Input, InputNumber, PageHeader, Radio } from 'antd';
import useAxios from '../../../../hooks/useAxios';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import LabelRow from '../../../../components/LabelRow';
import useInput from '../../../../hooks/useInput';
import { couponStatusType, couponStatusTypes, getCoupon, updateCoupons } from '../../../../apis/coupons';
import { useNavigate, useParams } from 'react-router-dom';
import style from '../../../work/review/details/reviewDetails.module.less';
import FileUpload from '../../../../components/FileUpload';

/**
 * 쿠폰관리 생성 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const CouponUpdate = () => {
  const createCoupons = useAxios();
  const title = useInput('');
  const size = useInput('');
  const salePer = useInput('');
  const saleMaxPrice = useInput('');
  const [limitDate, setLimitDate] = React.useState(90);
  const [status, setStatus] = React.useState(couponStatusType.READY);
  const navigate = useNavigate();

  const [mainImage, setMainImage] = useState();

  const { id: couponId } = useParams();

  const loadData = useCallback(async () => {
    try {
      const { data: responseData } = await getCoupon(couponId);
      title.onChange(responseData.title);
      setMainImage(responseData.image);
      salePer.onChange(responseData.sale_per);
      saleMaxPrice.onChange(responseData.sale_max_price);
      setLimitDate(responseData?.limit_date);
      setStatus(responseData.status);
    } catch (error) {
      console.error(error);
      // handleError(error);
    }
  }, [couponId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleCreateCoupon = async () => {
    if (!title.value.length > 0) {
      alert('쿠폰이름을 입력해주세요.');
      return;
    }

    if (!salePer.value) {
      alert('할인율을 입력해주세요.');
      return;
    }
    if (!saleMaxPrice.value) {
      alert('최대할인가를 입력해주세요.');
      return;
    }
    if (!limitDate) {
      alert('유효기간 입력해주세요.');
      return;
    }
    if (!status.length > 0) {
      alert('상태를 입력해주세요.');
      return;
    }

    try {
      const requestBody = {
        title: title.value,
        size,
        sale_per: salePer.value,
        sale_max_price: saleMaxPrice.value,
        limit_date: limitDate,
        status,
        image: mainImage,
      };
      const { data: responseData } = await createCoupons.loadData(updateCoupons(couponId, requestBody));
      if (!responseData) return;

      alert('쿠폰이 수정 되었습니다.');
      navigate('/settings/coupon', { replace: true });
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
    }
  };

  const onSubmit = () => {
    handleCreateCoupon();
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="쿠폰 등록" />
      <div className="labelrowWrapper">
        <LabelRow label="쿠폰이름">
          <Input {...title} placeholder="최대 30자 이내로 입력하세요." style={{ width: 500 }} />
        </LabelRow>

        <LabelRow label="대표이미지" heightOver>
          <div>
            <FileUpload
              values={mainImage ? [mainImage] : []}
              maxCount={9}
              onChange={(images) => {
                setMainImage(images[0]);
              }}
              className="profileImg"
            />
            {/*<span style={{ fontSize: 12, color: '#3d3d3d', margin: '6px 0px', display: 'block' }}>*/}
            {/*  추가이미지는 최대 9개까지 설정가능.*/}
            {/*</span>*/}
          </div>
        </LabelRow>

        <LabelRow label="할인설정">
          <div style={{ border: '1px #ddd solid', padding: '5px 10px 5px 0', backgroundColor: '#fff' }}>
            <Input {...salePer} style={{ width: 200 }} bordered={false} />
            <span>%</span>
          </div>
          <span style={{ margin: '0 20px' }}>최대</span>
          <div style={{ border: '1px #ddd solid', padding: '5px 10px 5px 0', backgroundColor: '#fff' }}>
            <Input {...saleMaxPrice} style={{ width: 200 }} bordered={false} />
            <span>원</span>
          </div>
          <span style={{ margin: '0 20px' }}>할인</span>
        </LabelRow>
        <LabelRow label="유효기간 (일)">
          <InputNumber
            value={limitDate}
            onChange={setLimitDate}
            style={{ width: 200, fontSize: 12 }}
            placeholder="일단위 입력"
          />
        </LabelRow>
        <LabelRow label="쿠폰적용">
          <Radio.Group
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
            optionType="default"
            options={Object.entries(couponStatusTypes).map((v) => ({
              key: v[0],
              value: v[0],
              label: v[1],
            }))}
          />
        </LabelRow>
      </div>
      <div className={style.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={() => navigate('/settings/coupon', { replace: true })}
          size={'large'}
          className={style.basicButton}
          style={{ height: 40, width: 100, marginRight: 5 }}
        >
          목록
        </Button>
        <Button
          onClick={onSubmit}
          size={'large'}
          className={style.basicButton}
          style={{ marginRight: 5, height: 40, width: 100, backgroundColor: '#FF7200', color: 'white' }}
        >
          저장
        </Button>
        {/*<Button size="large" className="basicButton dark" style={{ height: 40, width: 100 }}>*/}
        {/*  <Link to={`/settings/coupon/update/${couponId}`}>수정</Link>*/}
        {/*</Button>*/}
      </div>
    </div>
  );
};

export default CouponUpdate;
