import React, { useMemo } from 'react';
import { PageHeader, Switch, Table } from 'antd';
import { bannersSearchField } from '../../../common/searchFields';
import SearchForm from '../../../components/datalist/SearchForm';
import TableButtonWrap from '../../../components/TableButtonWrap';
import moment from 'moment';
import { getAPIHost, restApi } from '../../../apis';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import fileDownload from 'js-file-download';
import { toCommaNumber } from '../../../common/utils';
import DraggableEditableTableLastIndex from '../../../components/DraggableEditableTableLastIndex';
import useWindowDimension from '../../../hooks/useWindowDimension';

/**
 * 하단배너 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const BannerBottomIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);
  const [activeBanners, setActiveBanners] = React.useState([]);
  const [inActiveBanners, setInActiveBanners] = React.useState([]);

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      type: 'MAIN_BOTTOM',
      ...query,
      limit: 1000,
    };

    // params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    }

    const { data } = await restApi.get('/banners', {
      params,
    });
    setActiveBanners(data?.items?.filter((x) => !!x.active));
    setInActiveBanners(data?.items?.filter((x) => !x.active));
  }, [query]);

  // 데이터 초기 패칭
  React.useEffect(() => {
    loadData();
  }, [loadData]);


  // 배너 활성화 변경
  const handleActiveChange = async (id, active) => {
    if (!window.confirm('변경하시겠습니까?')) {
      return;
    }
    try {
      await restApi.put(`/banners/${id}/active`, {
        active,
      });
      await loadData();
    } catch (e) {
      console.warn(e);
      alert('배너는 최대 10개 까지만 게시 가능합니다.');
    }
  };

  // 활성화 테이블 컬럼 정의
  const columns = [
    {
      title: '번호',
      dataIndex: 'id',
      align: 'center',
      width: 90,
      render: (v, __, index) => {
        return activeBanners.length - index;
      },
    },
    {
      title: '배너위치',
      dataIndex: 'mode',
      align: 'center',
      width: 80,
      render: (role_type) => {
        return role_type === 'MAIN_BOTTOM' ? '메인하단' : role_type === 'MAIN_TOP' ? '메인상단' : '';
      },
    },
    {
      title: '제목',
      dataIndex: 'title',
      align: 'left',
      width: 200,
      render: (data, record) => (
        <Link to={`/contents/banner_bottom/details/${record.id}`}>
          <a style={{ color: 'black' }}>{data || '제목이 없습니다.'}</a>
        </Link>
      ),
    },
    {
      title: '연결링크',
      dataIndex: 'uri',
      align: 'left',
      width: 400,
      render: (data, record) => (
        <Link to={`/contents/banner_bottom/details/${record.id}`}>
          <a style={{ color: 'black', wordBreak: 'break-all' }}>{data}</a>
        </Link>
      ),
    },
    {
      title: '광고시작일',
      dataIndex: 'start_at',
      align: 'center',
      width: 200,
      render: (data) => data && moment(data).format('YYYY-MM-DD'),
    },
    {
      title: '광고종료일',
      dataIndex: 'end_at',
      align: 'center',
      width: 200,
      render: (data) => data && moment(data).format('YYYY-MM-DD'),
    },
    {
      title: '클릭수',
      dataIndex: 'clicked',
      key: 'clicked',
      align: 'center',
      width: 100,
      render: (data) => toCommaNumber(data),
    },
    {
      title: '순번',
      dataIndex: 'order_index',
      align: 'center',
      width: 90,
      render: (v, __, index) => {
        return v;
      },
    },
    {
      title: '승인여부',
      dataIndex: 'active',
      key: 'active',
      width: 100,
      align: 'center',
      render: (x, row) => {
        return <Switch checked={x} onChange={(e) => handleActiveChange(row?.id, e)} />;
      },
    },
    {
      title: '작성일자',
      dataIndex: 'created_at',
      align: 'center',
      width: 200,
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm'),
    },
    {
      title: '자세히보기',
      width: 120,
      dataIndex: 'id',
      key: 'detail',
      align: 'center',
      render: (data, record) => (
        <Link to={`/contents/banner_bottom/details/${record.id}`}>
          <a style={{ color: 'black' }}>자세히보기</a>
        </Link>
      ),
    },
  ];

  // 비활성화 테이블 컬럼 정의
  const columns2 = useMemo(() => {
    const o = [...columns];
    o[0].render = (_, _2, i) => inActiveBanners.length - i;
    return o;
  }, [columns]);

  // 엑셀 저장 함수
  const xlsxSave = () => {
    const args = {
      ...query,
      type: 'MAIN_BOTTOM',
    };
    restApi
      .get(`${getAPIHost()}/exports/banners?${qs.stringify(args)}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `배너관리_${moment().format('YYYYMMDDHHmmss')}.xlsx`);
      });
  };

  // 순서변경 했을 때 함수
  const handleMoveRow = async (as, to) => {
    const _data = [...activeBanners];
    _data.splice(to, 0, ..._data.splice(as, 1));
    const body = _data?.reduce((p, c, i) => [...p, c?.id], []);
    try {
      const { data } = await restApi.put('/banners/seq', {
        ids: body,
      });
      await loadData();
    } catch (e) {
      console.warn(e);
    }
  };

  const width = useWindowDimension();

  return (
    <div>
      <PageHeader className="site-page-header" title="하단 배너관리" />
      <SearchForm fields={bannersSearchField} />
      <div style={{ position: 'relative' }}>
        <h2>승인 배너</h2>
        {width <= 1023 ? (
          <Table
            scroll={{ x: 'max-content' }}
            // onMoveRow={handleMoveRow}
            dataSource={activeBanners}
            columns={columns}
            // editable={true}
          />
        ) : (
          <DraggableEditableTableLastIndex
            onMoveRow={handleMoveRow}
            originData={activeBanners}
            originColumns={columns}
            editable={true}
          />
        )}
        <h2 style={{ marginTop: 20 }}>미승인 배너</h2>
        {width <= 1023 ? (
          <Table
            scroll={{ x: 'max-content' }}
            // onMoveRow={handleMoveRow}
            dataSource={inActiveBanners}
            columns={columns2}
            // editable={true}
          />
        ) : (
          <DraggableEditableTableLastIndex
            scroll={{ x: 'max-content' }}
            // rowKey={({ id }) => id}
            // rowSelection={rowSelection}
            editable={false}
            originData={inActiveBanners}
            originColumns={columns2}
            style={{ marginBottom: 70 }}
          />
        )}
        <div className={"tableWrapCover"}>
          <TableButtonWrap
            position="left"
            buttons={[{ icon: require('../../../assets/icon_save.png'), label: '엑셀 저장', onClick: xlsxSave }]}
          />
          <TableButtonWrap
            position="right"
            buttons={[
              {
                icon: require('../../../assets/icon_write.png'),
                label: '작성',
                link: '/contents/banner_bottom/create',
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default BannerBottomIndex;
