import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { restApi } from '../apis';

const initialState = {
  vendors: [],
};

export const getVendors = createAsyncThunk('vendors', async () => {
  const response = await restApi.get(`/vendors`);
  return response.data;
});

const VenderSlice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    init(state, action) {
      state = action.payload?.vendor || state;
    },
    initProduct(state, action) {
      state = action.payload || state;
    },
  },
  extraReducers: {
    [getVendors.fulfilled]: (state, action) => {
      state.vendors = action.payload;
    },
  },
});

export default VenderSlice.reducer;
