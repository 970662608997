import moment from 'moment';
import React from 'react';
import ChartWrapper from '../ChartWrapper';
import { getStats } from '../../apis/stats';
import Chart from 'react-chartjs-2';

/**
 * 통계페이지 상단 요청횟수 차트 컴포넌트
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const RequestChartRV = ({
  title,
  type,
  dateRange = [moment().startOf('month'), moment().endOf('month')],
  ...props
}) => {
  const stats = props?.stats;
  const dateDiff = dateRange[1].diff(dateRange[0], 'day') + 1;

  const worksDateMap = React.useMemo(() => {
    const a = [...Array(dateDiff)].reduce((p1, c1, i1) => {
      if (!p1[dateRange[0].clone().add(i1, 'day').format('YYYYMMDD')]) {
        p1[dateRange[0].clone().add(i1, 'day').format('YYYYMMDD')] = [];
      }
      return p1;
    }, {});
    stats.data?.for_date?.forEach((v) => {
      if (!a[moment(v.date).format('YYYYMMDD')]) {
        a[moment(v.date).format('YYYYMMDD')] = [v];
      } else {
        a[moment(v.date).format('YYYYMMDD')].push(v);
      }
    });

    return a;
  }, [stats.data, dateRange, dateDiff]);

  const loadData = async () => {
    if (stats?.data?.for_date) return;
    try {
      const params = {
        type,
      };
      stats.loadData(
        getStats({
          ...params,
          date: dateRange[0] && moment(dateRange[0]).format('YYYY-MM'),
        }),
      );
    } catch (error) {
      console.warn(error);
      // alert(error?.response?.data?.message);
    }
  };

  React.useEffect(() => {
    loadData();
  }, [dateRange]);

  return (
    <ChartWrapper
      title={title}
      flex={1}
      chart={
        <Chart
          type="line"
          width={1600}
          height={300}
          options={{
            scales: {
              yAxes: [
                {
                  ticks: {
                    min: 0,
                  },
                },
              ],
            },
          }}
          data={{
            labels: [...new Array(dateDiff)].map((_, i) => moment(dateRange[0]).add(i, 'd').format('MM.DD')),
            datasets: [
              {
                label: '전체',
                fill: false,
                lineTension: 0.1,
                backgroundColor: '#ff0000',
                borderColor: '#ff0000',
                borderCapStyle: 'butt',
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#ff0000',
                pointBackgroundColor: '#ff0000',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#ff0000',
                pointHoverBorderColor: '#ff0000',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: Object.values(worksDateMap)?.map((v) => v.reduce((a1, c1) => a1 + c1?.total || 0, 0)),
              },
              {
                label: '1점',
                fill: false,
                lineTension: 0.1,
                backgroundColor: '#ff7f00',
                borderColor: '#ff7f00',
                borderCapStyle: 'butt',
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#ff7f00',
                pointBackgroundColor: '#ff7f00',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#ff7f00',
                pointHoverBorderColor: '#ff7f00',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: Object.values(worksDateMap)?.map((v) => v.reduce((a1, c1) => a1 + c1?.point1 || 0, 0)),
              },
              {
                label: '2점',
                fill: false,
                lineTension: 0.1,
                backgroundColor: '#FFFF00',
                borderColor: '#FFFF00',
                borderCapStyle: 'butt',
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#FFFF00',
                pointBackgroundColor: '#FFFF00',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#FFFF00',
                pointHoverBorderColor: '#FFFF00',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: Object.values(worksDateMap)?.map((v) => v.reduce((a1, c1) => a1 + c1?.point2 || 0, 0)),
              },
              {
                label: '3점',
                fill: false,
                lineTension: 0.1,
                backgroundColor: '#008000',
                borderColor: '#008000',
                borderCapStyle: 'butt',
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#008000',
                pointBackgroundColor: '#008000',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#008000',
                pointHoverBorderColor: '#008000',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: Object.values(worksDateMap)?.map((v) => v.reduce((a1, c1) => a1 + c1?.point3 || 0, 0)),
              },
              {
                label: '4점',
                fill: false,
                lineTension: 0.1,
                backgroundColor: '#0000FF',
                borderColor: '#0000FF',
                borderCapStyle: 'butt',
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#0000FF',
                pointBackgroundColor: '#0000FF',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#0000FF',
                pointHoverBorderColor: '#0000FF',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: Object.values(worksDateMap)?.map((v) => v.reduce((a1, c1) => a1 + c1?.point4 || 0, 0)),
              },
              {
                label: '5점',
                fill: false,
                lineTension: 0.1,
                backgroundColor: '#8B00FF',
                borderColor: '#8B00FF',
                borderCapStyle: 'butt',
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#8B00FF',
                pointBackgroundColor: '#8B00FF',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#8B00FF',
                pointHoverBorderColor: '#8B00FF',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: Object.values(worksDateMap)?.map((v) => v.reduce((a1, c1) => a1 + c1?.point5 || 0, 0)),
              },
            ].filter((v) => !!v),
          }}
        />
      }
      {...props}
    />
  );
};

export default RequestChartRV;
