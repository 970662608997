import React from 'react';
import { Button, Descriptions, PageHeader } from 'antd';
import moment from 'moment';
import { PaymentType } from '../../../../common/defines';
import { useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../../apis';
import styles from './reviewDetails.module.less';

/**
 * 후기관리 상세 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const ReviewDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [products, setProducts] = React.useState();
  React.useEffect(() => {
    const loadData = async () => {
      const { data } = await restApi.get(`/products`);
      setProducts(data);
    };
    loadData().catch(console.warn);
  }, []);
  const [accountData, setAccountData] = React.useState();
  const userData = [
    { label: '고객명', content: accountData?.account?.nickname, span: 2 },
    { label: '리뷰남긴날짜', content: moment(accountData?.created_at).format('YYYY-MM-DD HH:mm'), span: 2 },
    { label: '별점', content: accountData?.rating, span: 2 },
    {
      label: '주문내용',
      content: accountData?.purchase?.products?.map((pid) => {
        const pitem = products?.find((x) => x.id === pid);
        return pitem?.subject + ' - ' + pitem?.name;
      }),
      span: 2,
    },
    { label: '서비스', content: PaymentType[accountData?.purchase?.type], span: 4 },
    {
      label: '만족',
      content: (
        <div style={{ height: 50 }}>
          {accountData?.satisfying?.map((text) => (
            <span style={{ border: '1px solid #ddd', margin: 2, padding: 5, background: '#fff' }}>{text}</span>
          ))}
        </div>
      ),
    },
    {
      label: '불만족',
      content: (
        <div style={{ height: 50 }}>
          {accountData?.not_satisfying?.map((text) => (
            <span style={{ border: '1px solid #ddd', margin: 2, padding: 5, background: '#fff' }}>{text}</span>
          ))}
        </div>
      ),
    }, //<div style={{ height: 160 }}>{accountData?.not_satisfying}</div> },
    {
      label: '내용',
      content: <div style={{ height: 150 }}>{accountData?.content}</div>,
    },
  ];

  // 회원상세정보 조회
  const getReview = async () => {
    const { data } = await restApi.get(`/reviews/${id}`);
    setAccountData(data);
  };

  React.useEffect(() => {
    if (!id) return;
    getReview(id);
  }, [id]);

  const handleDelete = async () => {
    if (!window.confirm('삭제하시겠습니까?')) {
      return;
    }
    try {
      await restApi.delete(`/reviews/${id}`);
      navigate('/work/review', { replace: true });
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="사용자 후기" />
      <Descriptions bordered>
        {userData.map((data, index) => {
          return (
            <Descriptions.Item key={`${data.label}-${index}`} label={data.label} span={data.span ?? 3}>
              {data.content}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
      <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        <Button size="large" style={{ marginRight: 5, width: 80 }} onClick={handleDelete}>
          삭제
        </Button>
        <Button size="large" style={{ width: 80 }} type={'primary'} onClick={() => navigate(-1)}>
          목록
        </Button>
      </div>
    </div>
  );
};

export default ReviewDetails;
