import React, { useEffect, useState } from 'react';
import styles from './OrderDetail.module.less';
import { addComma, toCommaNumber } from '../../../../common/utils';
import { getPayname, PaymentType } from '../../../../common/defines';
import { Button, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { restApi } from '../../../../apis';
import moment from 'moment';

const AccountCouponView = React.memo(({ id }) => {
  const [data, setData] = useState();
  useEffect(() => {
    const loadData = async () => {
      const { data } = await restApi.get(`/coupons/for_accounts/${id}`);
      setData(data);
    };
    if (id) loadData().catch(console.warn);
  }, [id]);

  if (!data?.coupon) return null;

  return (
    <a href={'/settings/coupon/details/' + data.coupon?.id}>
      {data.coupon?.title} (만료예정 : {moment(data.close_at).format('YYYY-MM-DD')})
    </a>
  );
});
/**
 * 결제 정보
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const PaymentInfo = ({ data: paydat, id, purchases }) => {
  if (!paydat) return null;

  const iamPortResult = !paydat
    ? null
    : paydat?.payed_json?.[0] === '{'
    ? JSON.parse(paydat?.payed_json)
    : { ...paydat?.payed_json };

  return (
    <div>
      <h4 className={styles.partTitle}>결제금액정보</h4>
      <div className={styles.tableStyleBox}>
        <div className={styles.infoCell}>
          <div style={{ display: 'flex', alignItems: 'stretch' }}>
            <div className={styles.item}>
              <h5 className={styles.cellTitle}>결제금액</h5>
              <ul>
                <li>
                  {/* <span>{paydat?.payment?.payed_json?.name}</span> */}
                  <span />
                  <span>{toCommaNumber(paydat?.origin_price)}원</span>
                </li>
              </ul>
              <p>{toCommaNumber(paydat?.payed_price)}원</p>
            </div>
            <div className={styles.item}>
              <h5 className={styles.cellTitle}>할인금액</h5>
              <ul>
                <li>
                  <span>쿠폰할인</span>
                  <span>
                    {toCommaNumber((paydat.origin_price || 0) - (paydat.payed_price || 0) - (paydat.used_point ?? 0))}원
                  </span>
                </li>
                <li>
                  <AccountCouponView id={paydat.account_coupon_id} />
                </li>
                <li>
                  <span>적립금적용</span>
                  <span>{paydat.used_point ?? 0}원</span>
                </li>
              </ul>
              <p>{addComma(String((paydat.origin_price || 0) - (paydat.payed_price || 0)))}원</p>
            </div>
          </div>
          <div className={styles.bottomRow}>
            <label>{getPayname(iamPortResult)}</label>
            <p>
              {iamPortResult?.card_name || iamPortResult?.vbank_name}
              {!iamPortResult?.card_number ? '' : `(${iamPortResult?.card_number})`}
              {!iamPortResult?.vbank_num ? '' : `(${iamPortResult?.vbank_num})`}
              <span>(승인번호 : {iamPortResult?.pg_tid})</span>
            </p>
          </div>
          {purchases && (
            <div className={styles.bottomRow}>
              <div style={{ flex: 1 }}>
                {purchases?.map((purchase) => (
                  <div style={{ width: '100%', marginBottom: 10 }}>
                    <Row>
                      <Col flex={'1'}>
                        <b>{PaymentType[purchase.type]}</b> -
                        {!!purchase.product_name
                          ? purchase.product_name
                          : purchase.gender === 'X'
                          ? '운동화'
                          : purchase.gender === 'M'
                          ? '남성구두'
                          : '여성구두'}
                      </Col>
                      <Col>
                        {purchase.id === id ? (
                          <Button size={'small'} disabled={true}>
                            현재주문
                          </Button>
                        ) : (
                          <Link to={`/work/order/details/${purchase.id}`}>
                            <Button size={'small'}>바로가기</Button>
                          </Link>
                        )}
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className={styles.paymentCell}>
          <dl>
            <dt>결제금액</dt>
            <dd>{toCommaNumber(paydat?.origin_price)}원</dd>
            <dt>할인금액</dt>
            <dd>(-){toCommaNumber((paydat.origin_price || 0) - (paydat.payed_price || 0))}원</dd>
          </dl>
          <div className={styles.totalPrice}>
            <label>최종결제금액</label>
            <p>
              {toCommaNumber(paydat?.payed_price)}
              <span>원</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentInfo;
