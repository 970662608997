import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import { Button, Descriptions, PageHeader } from 'antd';
import styles from '../../../work/review/details/reviewDetails.module.less';
import { deletePost, readPost } from '../../../../apis/posts';
import { getFileHost } from '../../../../apis';

/**
 * 구두이야기 상세 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
// notice와 구조 동일
const StoryDetails = () => {
  const navigate = useNavigate();
  const post = useAxios();
  const { id } = useParams();

  React.useEffect(() => {
    if (!id) {
      alert('데이터를 불러올 수 없습니다.');
      navigate(-1);
      return;
    }
    post.loadData(readPost({ id })).catch((error) => {
      alert(error?.response?.data?.message);
      navigate(-1);
    });
  }, []);

  const handleDeletePost = async () => {
    try {
      if (window.confirm('해당 구두이야기를 삭제하시겠습니까?')) {
        const { data } = await deletePost(id);
        navigate('/contents/story', { replace: true });
        return;
      }
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="구두이야기 상세" />
      <Descriptions bordered>
        <Descriptions.Item label="제목" span={4}>
          {post.data?.title}
        </Descriptions.Item>
        <Descriptions.Item label="내용이미지" span={4}>
          {post.data?.images?.map((v) => (
            <img src={`${getFileHost()}/${v}`} style={{ width: 350, marginRight: 10 }} alt={`image_${v}`} />
          ))}
        </Descriptions.Item>
      </Descriptions>
      <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={handleDeletePost}
          size={'large'}
          className={styles.basicButton}
          style={{ marginRight: 5, height: 40, width: 100 }}
        >
          삭제
        </Button>
        <Button
          size="large"
          className="basicButton"
          type={'primary'}
          style={{ height: 40, width: 100, marginRight: 5 }}
        >
          <Link to={`/contents/story`}>목록</Link>
        </Button>
        <Button size="large" className="basicButton" style={{ height: 40, width: 100 }}>
          <Link to={`/contents/story/update/${id}`}>수정</Link>
        </Button>
      </div>
    </div>
  );
};

export default StoryDetails;
