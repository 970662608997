import React, { useState } from 'react';
import styles from './BasicLayout.module.less';
import useRoutes from './useRoutes';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Col, Menu, Row, Spin } from 'antd';
import Login from '../components/auth/Login';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { CloseOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons';
import NotifyTool from '../components/NotifyTool';
import { clearPrincipal } from '../data/auth';

const childRenderer = (page, parent) => {
  const args = {
    path: [parent, page.path].filter((v) => !!v).join('/'),
  };
  if (page.children?.length) {
    //, args.path
    args.path = args.path + '/*';
    args.children = page.children?.map((child) => childRenderer(child));
  }
  if (page.screen) {
    const Elem = page.screen;
    args.element = <Elem />;
  }
  return <Route key={page.path} {...args} />;
};

/**
 * 프로젝트 Layout 컴포넌트
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const BasicLayout = ({ children, loading }) => {
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const { routes, pages } = useRoutes();
  const [sideOpen, setSideOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    if (!window.confirm('로그아웃 하시겠습니까?')) {
      return;
    }
    localStorage.clear();
    dispatch(clearPrincipal());
  };

  console.log({ principal });

  return (
    <div
      className={`${styles.basic_layout} ${!principal || loading ? styles.auth : ''} ${
        sideOpen ? styles.side_open : ''
      }`}
    >
      <aside>
        <div className={styles.intro_bg} />
        <div className={styles.bi}>
          <img src={require('../assets/logo.png')} />
          {!!principal && (
            <Button
              onClick={() => setSideOpen((x) => !x)}
              type="text"
              icon={<CloseOutlined style={{ color: '#fff' }} />}
            />
          )}
        </div>
        {!principal ? (
          <div className={styles.center_contents}>{/*<AuthAside />*/}</div>
        ) : (
          <div className={styles.aside_body}>
            <nav className={styles.menu}>
              <Menu
                onSelect={console.log}
                onClick={(item) => {
                  navigate(item.key);
                  setSideOpen(false);
                }}
                style={{ width: '100%' }}
                theme={'dark'}
                mode="inline"
              >
                {routes?.map((route) => {
                  const Icon = route.icon;
                  if (!route.children?.length) {
                    return (
                      <Menu.Item key={route.path} icon={<Icon />}>
                        {route.title}
                      </Menu.Item>
                    );
                  }
                  const items = route?.children ?? [];

                  const grouped = items.reduce((a, c) => {
                    const group = c.group?.replace(/\s+/g, '_') || '_';
                    if (!a[group]) a[group] = [];
                    a[group].push(c);
                    return a;
                  }, {});

                  const keys = Object.keys(grouped).filter((v) => v !== '_');

                  return (
                    <Menu.SubMenu key={route.path} icon={<Icon />} title={route.title}>
                      {keys.map((key) => (
                        <Menu.ItemGroup key={key} title={key.replace(/\_/g, ' ')}>
                          {grouped[key].map((menu) => (
                            <Menu.Item key={menu.path}>{menu.title}</Menu.Item>
                          ))}
                        </Menu.ItemGroup>
                      ))}
                      {grouped['_'].map((child) => (
                        <Menu.Item key={child.path}>{child.title}</Menu.Item>
                      ))}
                      {route.renderComponent}
                    </Menu.SubMenu>
                  );

                  return (
                    <Menu.SubMenu key={route.path} icon={<Icon />} title={route.title}>
                      {items.map((child) => {
                        return (
                          <Menu.Item key={child.path}>
                            {child.title} ({child.group})
                          </Menu.Item>
                        );
                      })}
                    </Menu.SubMenu>
                  );
                })}
              </Menu>
            </nav>
          </div>
        )}
      </aside>

      {!principal ? (
        <div className={styles.auth_body}>{!!loading ? <Spin size={'large'} /> : <Login />}</div>
      ) : (
        <div className={styles.body}>
          <header>
            <Row gutter={5}>
              <Col className={styles.menu_button}>
                <Button onClick={() => setSideOpen((x) => !x)} shape="circle" icon={<MenuOutlined />} />
              </Col>
              <Col flex={'1'} />
              {principal?.type === 'A' ? (
                <>
                  <Col>
                    <NotifyTool />
                  </Col>
                  <Col>
                    <Button shape="circle" icon={<UserOutlined />} onClick={handleLogout} />
                  </Col>
                </>
              ) : (
                <Col>
                  <Button shape={'round'} icon={<UserOutlined />} onClick={handleLogout}>
                    {principal?.nickname}
                  </Button>
                </Col>
              )}
            </Row>
          </header>
          <Routes>
            {principal.type === 'A' ? (
              <Route path={'/'} element={<Navigate to={'/accounts/user'} />} />
            ) : (
              <Route path={'/'} element={<Navigate to={'/me'} />} />
            )}
            {pages?.filter((v) => !!v.screen)?.map((page) => childRenderer(page, undefined))}
          </Routes>
        </div>
      )}
    </div>
  );
};

export default BasicLayout;
