import React from 'react';
import VendorDetailsMenu, { useGetVendor } from './menu';
import VendersCodesIndex from '../../for_vendor/codes';

/**
 * 벤더사 코드
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const VendorsDetailsCodes = (props) => {
  const vendor = useGetVendor();
  return (
    <div>
      <VendorDetailsMenu index={2} />
      {!!vendor?.id && <VendersCodesIndex vendorId={vendor?.id} />}
    </div>
  );
};

export default VendorsDetailsCodes;
