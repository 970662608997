import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Modal,
  PageHeader,
  Radio,
  Row,
  Space,
  Table,
  Typography
} from 'antd';
import moment from 'moment';
import {restApi} from '../../../apis';
import {useLocation, useNavigate} from 'react-router-dom';
import qs from 'query-string';
import {
  getDistanceFromLatLonInKm,
  phoneFormat,
  useCSVData
} from '../../../common/utils';
import NaverMap, {Marker, Overlay, Polyline} from 'react-naver-map';
import proj4 from 'proj4';
import {shallowEqual, useSelector} from 'react-redux';
import {flatten, isEqual, uniq} from 'lodash';
import html2canvas from 'html2canvas';
import SearchUserBox from '../order/details/SearchUserBox';
import fileDownload from 'js-file-download';

const c = (d) => {
  const grs80 = proj4.Proj(proj4.defs['EPSG:3857']);
  const wgs84 = proj4.Proj(proj4.defs['EPSG:4326']);

  const p = proj4.toPoint(d);
  const v = proj4.transform(grs80, wgs84, p);
  return { lat: v.y, lng: v.x };
  // return new window.naver.maps.LatLng(v.y, v.x);
};

/**
 * 작업 배달 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const DeliveryIndex = () => {
  const navermaps = window?.naver?.maps;
  const location = useLocation();
  const navigate = useNavigate();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);

  const [data, setData] = React.useState();
  const [accounts, setAccounts] = useState({});

  const [centers, setCenters] = useState();

  useEffect(() => {
    const loadCenters = async () => {
      const { data } = await restApi.get(`/centers`, { params: { limit: 10000 } });
      setCenters(data.items);
    };
    loadCenters().catch(console.warn);
  }, []);

  const { items: products } = useSelector((s) => s.product, shallowEqual);
  const rawProducts = useMemo(() => {
    return flatten(Object.values(products));
  }, [products]);

  const [processed, setProcessed] = useState(undefined);
  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const method = query?.method ?? 'N';
    const date = query?.date ?? moment().format('YYYY-MM-DD');

    const { data } = await restApi.get('/works/delivery', {
      params: {
        method,
        date,
      },
    });
    setData(data);

    const ids = data.reduce((a, b) => {
      if (method === 'N') {
        if (b.ctou_postman_id) {
          a.push(b.ctou_postman_id);
        }
        if (b.utoc_postman_id) {
          a.push(b.utoc_postman_id);
        }
      } else {
        if (b.ctom_postman_id) {
          a.push(b.ctom_postman_id);
        }
        if (b.mtoc_postman_id) {
          a.push(b.mtoc_postman_id);
        }
      }

      if (b.master_id) {
        a.push(b.master_id);
      }
      return uniq(a);
    }, []);

    const { data: accounts } = await restApi.get(`/accounts`, {
      params: {
        ids,
        page: 0,
        limit: ids.length,
      },
    });

    setAccounts(
      accounts?.items?.reduce((a, b) => {
        a[b.id] = b;
        return a;
      }, {}),
    );

    const args = data.reduce(
      (a, c) => {
        const clat = method === 'N' ? c.purchase?.lat : c.master.lat;
        const clng = method === 'N' ? c.purchase?.lng : c.master.lng;
        if (clat && clng) {
          if (a.lat1 === undefined || a.lat1 > clat) {
            a.lat1 = clat;
          }
          if (a.lat2 === undefined || a.lat2 < clat) {
            a.lat2 = clat;
          }
          if (a.lng1 === undefined || a.lng1 > clng) {
            a.lng1 = clng;
          }
          if (a.lng2 === undefined || a.lng2 < clng) {
            a.lng2 = clng;
          }
        }
        return a;
      },
      {
        lat1: undefined,
        lat2: undefined,
        lng1: undefined,
        lng2: undefined,
      },
    );
    const fited = new window.naver.maps.LatLngBounds(
      new window.naver.maps.LatLng(args.lat1, args.lng1),
      new window.naver.maps.LatLng(args.lat2, args.lng2),
    );
    map.current.fitBounds(fited);
    console.log('fitBounds');
  }, [query]);

  useEffect(() => {
    if (query?.method || query?.date) {
      loadData().catch(console.warn);
    }
  }, [query?.method, query?.date]);

  const [showModal, setShowModal] = useState();
  const handleChangeMode = (row) => {
    setShowModal({
      id: row.id,
      utoc_postman_id: row.utoc_postman_id,
      ctom_postman_id: row.ctom_postman_id,
      mtoc_postman_id: row.mtoc_postman_id,
      ctou_postman_id: row.ctou_postman_id,
      master_id: row.master_id,
    });
  };

  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setLoading(true);

      const id = showModal.id;
      const body = {
        utoc_postman_id: showModal.utoc_postman_id,
        ctom_postman_id: showModal.ctom_postman_id,
        mtoc_postman_id: showModal.mtoc_postman_id,
        ctou_postman_id: showModal.ctou_postman_id,
        master_id: showModal.master_id,
      };

      await restApi.put(`/cms/purchases/dates/${id}`, body);
      await loadData();
      setShowModal(undefined);
    } catch (e) {
      console.warn(e);
      window.alert(e?.response?.data?.message || e?.message);
    }
    setLoading(false);
  };

  const columns = [
    {
      key: 'no',
      title: '순서',
      dataIndex: 'id',
      align: 'center',
      width: 120,
      render: (v) => {
        if (!sortIds?.length) return '최적화 필요';
        if (sortIds?.indexOf(v) < 0) return '-';
        return sortIds?.indexOf(v) + 1 + '번째';
      },
    },
    {
      key: 'dist',
      title: '앞과 거리',
      dataIndex: 'dist',
      align: 'center',
      width: 120,
      render: (v, r) => {
        const o = arriveTimes?.find((x) => x.id === String(r.id));
        const dist = parseInt(o?.distance ?? '0', 10);
        if (!dist) return '-';
        return (Math.floor(dist / 1000) ?? 0) + 'km';
      },
    },
    {
      key: 'purchase_id',
      title: '주문번호',
      dataIndex: 'purchase',
      align: 'center',
      width: 120,
      ellipsis: true,
      xlsRender: (d) => {
        return d.id;
      },
      render: (d) => {
        return d.id;
      },
    },
    {
      key: 'username',
      title: '고객명',
      dataIndex: 'purchase',
      align: 'center',
      width: 120,
      ellipsis: true,
      xlsRender: (d) => {
        return d.account.nickname;
      },
      render: (d) => {
        return (
          <Typography.Text ellipsis={true} style={{ width: 100 }}>
            {d.account.nickname}
          </Typography.Text>
        );
      },
    },
    {
      key: 'account_phone',
      title: '연락처',
      dataIndex: 'purchase',
      align: 'center',
      width: 200,
      render: (d) => phoneFormat(d.account.phone),
    },
    {
      key: 'addr1',
      title: '수거지',
      dataIndex: 'addr',
      width: 300,
      render: (_, r) => {
        if (method === 'N') {
          if (r.state === 'NEW') {
            return `${r.purchase.address} ${r.purchase.address_details ?? ''}`;
          }
        } else if (method === 'D2') {
          return `${r.master?.address ?? '(미지정)'}`;
        }

        // if (method === 'NEW') {
        //   return `${r.purchase.address} ${r.purchase.address_details ?? ''}`;
        // } else if (method === 'IN_FIXER') {
        //   return `${r.center?.address ?? '(미지정)'}`;
        // } else if (method === 'OUT_CENTER') {
        //   return `${r.master?.address ?? '(미지정)'}`;
        // } else if (method === 'SENDING') {
        //   return `${r.center?.address ?? '(미지정)'}`;
        // }
      },
    },
    {
      key: 'addr2',
      title: '배송지',
      dataIndex: 'addr',
      width: 300,
      render: (_, r) => {
        if (method === 'N') {
          if (r.state !== 'NEW') {
            return `${r.purchase.address} ${r.purchase.address_details ?? ''}`;
          }
        } else if (method === 'D1') {
          return `${r.master?.address ?? '(미지정)'}`;
        }
        // if (method === 'NEW') {
        //   return `${r.center?.address ?? '(미지정)'}`;
        // } else if (method === 'IN_FIXER') {
        //   return `${r.master?.address ?? '(미지정)'}`;
        // } else if (method === 'OUT_CENTER') {
        //   return `${r.center?.address ?? '(미지정)'}`;
        // } else if (method === 'SENDING') {
        //   return `${r.purchase.address} ${r.purchase.address_details ?? ''}`;
        // }
      },
    },
    {
      key: 'state',
      title: '구분',
      dataIndex: 'state',
      align: 'center',
      width: 100,
      xlsRender: (d, r) => {
        if (method === 'N') {
          return !!r.begin_expected_at.startsWith(date) ? '수거' : '배송';
        }
        return method === 'D2' ? '수거' : '배송';
      },
      render: (_, r) => {
        if (method === 'N') {
          return (
            <span style={{ fontWeight: 'bold', fontSize: 15 }}>
              {/*{JSON.stringify(r.state)}*/}
              {r.state === 'NEW' ? '수거' : '배송'}
            </span>
          );
        }
        return <span style={{ fontWeight: 'bold', fontSize: 14 }}>{method === 'D2' ? '수거' : '배송'}</span>;
      },
    },
    {
      title: '주문날짜',
      dataIndex: 'id',
      align: 'center',
      width: 120,
      render: (d, r) => {
        return moment(r.created_at).format('YYYY-MM-DD');
      },
    },
    {
      title: '수거시작',
      dataIndex: 'id',
      align: 'center',
      width: 120,
      xlsRender: (d, r) => {
        return moment(r.begin_expected_at).format('MM-DD HH:mm');
      },
      render: (d, r) => {
        let fontWeight = '';
        if (r.begin_expected_at.startsWith(date)) {
          fontWeight = 'bold';
        }
        return <span style={{ fontWeight }}>{moment(r.begin_expected_at).format('MM-DD HH:mm')}</span>;
      },
    },
    {
      title: '수거종료',
      dataIndex: 'id',
      align: 'center',
      width: 120,
      xlsRender: (d, r) => {
        return moment(r.begin_expected_at).add(10, 'hours').format('MM-DD HH:mm');
      },
      render: (d, r) => {
        let fontWeight = '';
        if (r.begin_expected_at.startsWith(date)) {
          fontWeight = 'bold';
        }
        return <span style={{ fontWeight }}>{moment(r.begin_expected_at).add(10, 'hours').format('MM-DD HH:mm')}</span>;
      },
    },
    {
      title: '배송시작',
      dataIndex: 'id',
      align: 'center',
      width: 120,
      xlsRender: (d, r) => {
        return moment(r.finish_expected_at).add(-10, 'hours').format('MM-DD HH:mm');
      },
      render: (d, r) => {
        let fontWeight = '';
        if (moment(r.finish_expected_at).add(-10, 'hours').format('YYYY-MM-DD').startsWith(date)) {
          fontWeight = 'bold';
        }
        return (
          <span style={{ fontWeight }}>{moment(r.finish_expected_at).add(-10, 'hours').format('MM-DD HH:mm')}</span>
        );
      },
    },
    {
      title: '배송종료',
      dataIndex: 'id',
      align: 'center',
      width: 120,
      xlsRender: (d, r) => {
        return moment(r.finish_expected_at).format('MM-DD HH:mm');
      },
      render: (d, r) => {
        let fontWeight = '';
        if (moment(r.finish_expected_at).add(-10, 'hours').format('YYYY-MM-DD').startsWith(date)) {
          fontWeight = 'bold';
        }
        return <span style={{ fontWeight }}>{moment(r.finish_expected_at).format('MM-DD HH:mm')}</span>;
      },
    },
    {
      title: '수거자',
      dataIndex: 'id',
      align: 'center',
      width: 150,
      xlsRender: (d, r) => {
        if (method === 'N') {
          const user = accounts[String(r.utoc_postman_id)];
          if (user) {
            return user.nickname + '(' + user.phone.substring(user.phone.length - 4) + ')';
          }
        } else {
          const user = accounts[String(r.ctom_postman_id)];
          if (user) {
            return user.nickname + '(' + user.phone.substring(user.phone.length - 4) + ')';
          }
        }
      },
      render: (d, r) => {
        if (method === 'N') {
          const user = accounts[String(r.utoc_postman_id)];
          return (
            <Button style={{ width: '100%' }} onClick={() => handleChangeMode(r)}>
              {!user ? '(미지정)' : user.nickname + '(' + user.phone.substring(user.phone.length - 4) + ')'}
            </Button>
          );
        } else {
          const user = accounts[String(r.ctom_postman_id)];
          return (
            <Button style={{ width: '100%' }} onClick={() => handleChangeMode(r)}>
              {!user ? '(미지정)' : user.nickname + '(' + user.phone.substring(user.phone.length - 4) + ')'}
            </Button>
          );
        }
      },
    },
    {
      title: '준비',
      dataIndex: 'id',
      align: 'center',
      width: 150,
      xlsRender: (d, r) => {
        const user = accounts[String(r.master_id)];
        if (user) {
          return user.nickname + '(' + user.phone.substring(user.phone.length - 4) + ')';
        }
      },
      render: (d, r) => {
        const user = accounts[String(r.master_id)];
        return (
          <Button style={{ width: '100%' }} onClick={() => handleChangeMode(r)}>
            {!user ? '(미지정)' : user.nickname + '(' + user.phone.substring(user.phone.length - 4) + ')'}
          </Button>
        );
      },
    },
    {
      title: '배송자',
      dataIndex: 'id',
      align: 'center',
      width: 150,
      xlsRender: (d, r) => {
        if (method === 'N') {
          const user = accounts[String(r.ctou_postman_id)];
          if (user) {
            return user.nickname + '(' + user.phone.substring(user.phone.length - 4) + ')';
          }
        } else {
          const user = accounts[String(r.mtoc_postman_id)];
          if (user) {
            return user.nickname + '(' + user.phone.substring(user.phone.length - 4) + ')';
          }
        }
      },
      render: (d, r) => {
        if (method === 'N') {
          const user = accounts[String(r.ctou_postman_id)];
          return (
            <Button style={{ width: '100%' }} onClick={() => handleChangeMode(r)}>
              {!user ? '(미지정)' : user.nickname + '(' + user.phone.substring(user.phone.length - 4) + ')'}
            </Button>
          );
        } else {
          const user = accounts[String(r.mtoc_postman_id)];
          return (
            <Button style={{ width: '100%' }} onClick={() => handleChangeMode(r)}>
              {!user ? '(미지정)' : user.nickname + '(' + user.phone.substring(user.phone.length - 4) + ')'}
            </Button>
          );
        }
      },
    },
    {
      title: '서비스내용',
      dataIndex: 'purchase',
      align: 'center',
      width: 300,
      render: (d, row) => {
        return d.products
          ?.map((id) => {
            const p = rawProducts.find((v) => v.id === id);
            return p?.name;
          })
          .join(', ');
      },
    },

    {
      title: '배송메모',
      dataIndex: 'purchase',
      align: 'center',
      width: 300,
      render: (d, row) => {
        return d.memo;
      },
    },

    {
      title: '주소메모',
      dataIndex: 'purchase',
      align: 'center',
      width: 300,
      render: (d, row) => {
        return d.address_memo;
      },
    },

    {
      title: '현관비밀번호',
      dataIndex: 'purchase',
      align: 'center',
      width: 300,
      render: (d, row) => {
        return d.entPassword;
      },
    },
  ].filter((v) => !!v);

  const [sortIds, setSortIds] = useState();
  const [lines, setLines] = useState();
  const [arriveTimes, setArriveTimes] = useState([]);

  const processDirection = async () => {
    if (processed?.length) {
      const c = window.confirm('이미 경로지정한 항목이 있습니다. 다시 최적화 하시겠습니까?');
      if (!c) {
        setSelected(processed);
        return;
      }
    }

    const args = data
      ?.filter((v) => selected?.includes(v.id))
      ?.reduce(
        (a, c) => {
          const clat = method === 'N' ? c.purchase?.lat : c.master.lat;
          const clng = method === 'N' ? c.purchase?.lng : c.master.lng;
          if (clat && clng) {
            if (a.lat1 === undefined || a.lat1 > clat) {
              a.lat1 = clat;
            }
            if (a.lat2 === undefined || a.lat2 < clat) {
              a.lat2 = clat;
            }
            if (a.lng1 === undefined || a.lng1 > clng) {
              a.lng1 = clng;
            }
            if (a.lng2 === undefined || a.lng2 < clng) {
              a.lng2 = clng;
            }
          }
          return a;
        },
        {
          lat1: undefined,
          lat2: undefined,
          lng1: undefined,
          lng2: undefined,
        },
      );

    const fited = new window.naver.maps.LatLngBounds(
      new window.naver.maps.LatLng(args.lat1, args.lng1),
      new window.naver.maps.LatLng(args.lat2, args.lng2),
    );
    console.log(fited);
    map.current.fitBounds(fited);

    const { data: center } = await restApi.get(`/centers/1`, {});
    const { data: resp } = await restApi.get(`/positions/routes`, {
      params: {
        method: method,
        lat: center.latitude,
        lng: center.longitude,
        center_id: center.id,
        work_ids: data?.filter((v) => selected?.includes(v.id))?.map((v) => v.id),
      },
    });

    if (resp.error) {
      window.alert(resp.error.message);
      throw { status: 400, message: resp.error.message };
    }

    setArriveTimes(resp.arriveTimes);
    setSortIds(resp.work_sorted_ids);
    setLines(resp.lines);
    setData((x) => {
      const o = [...x];
      o.sort((a, b) => {
        const a1 = resp.work_sorted_ids.indexOf(a.id);
        const b1 = resp.work_sorted_ids.indexOf(b.id);

        const a2 = a1 < 0 ? 999 : a1;
        const b2 = b1 < 0 ? 999 : b1;

        return !selected.includes(b.id) ? -1 : a2 > b2 ? 1 : a2 < b2 ? -1 : 0;
      });
      return o?.map((item, index) => {
        let dist = 0;
        const lat = item.purchase?.lat;
        const lng = item.purchase?.lng;
        if (index === 0 && lat && lng) {
          dist = getDistanceFromLatLonInKm(center.lat, center.lng, lat, lng);
        } else if (index > 0 && lat && lng) {
          const before = o[index - 1];
          dist = getDistanceFromLatLonInKm(before?.purchase?.lat, before?.purchase?.lng, lat, lng);
        }
        return { ...item, dist };
      });
    });

    setProcessed(selected);
    alert('표의 순서대로 진행해주세요.');
  };

  const method = query?.method ?? 'N';
  const setMethod = (method) => {
    navigate(`/work/delivery?` + qs.stringify({ ...query, method }));
  };

  const date = query?.date ?? moment().format('YYYY-MM-DD');
  const setDate = (date) => {
    navigate(`/work/delivery?` + qs.stringify({ ...query, date }));
  };

  const map = useRef();
  const csvProps = useCSVData(columns, data, moment().format('YYYYMMDD_HHmmss'));

  const [selected, setSelected] = useState([]);
  const rowSelection = {
    onChange: (selected) => {
      setSelected(selected);
    },
    getCheckboxProps: () => ({
      // disabled: processed,
    }),
    fixed: true,
    type: 'checkbox',
    selectedRowKeys: selected,
  };

  const imageArea = useRef();
  const handleSaveImage = async () => {
    const canvas = await html2canvas(imageArea.current, {
      useCORS: true,
      onclone: function (cloneDoc) {
        var svg = (window.svg = cloneDoc.querySelector('svg'));
        if (svg) {
          svg.outerHTML = svg.outerHTML.replace(/svg:svg/g, 'svg');
        }
      },
    });
    document.body.appendChild(canvas);
    const imgData = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.download = `배송처리_${date}_${moment().format('YYYYMMDD_HHmmss')}.png`;
    link.href = imgData;
    document.body.appendChild(link);
    link.click();
  };

  const handleDownload = async (data) => {
    const res = await restApi.post(
      `/works/delivery/xls`,
      { raw: data.data },
      {
        responseType: 'blob',
      },
    );
    fileDownload(res.data, `shoedoc_delivery_${moment().format('YYYYMMDD_HHmmss')}.xlsx`);
  };

  return (
    <div>
      <Modal
        visible={!!showModal}
        title={'담당설정'}
        confirmLoading={loading}
        destroyOnClose
        onOk={handleSubmit}
        onCancel={() => setShowModal(undefined)}
      >
        {method === 'N' ? (
          <Form>
            <Form.Item label={'수거자'} style={{ marginBottom: 10 }}>
              <SearchUserBox
                type={'T'}
                value={showModal?.utoc_postman_id}
                onChange={(v) => {
                  setShowModal((x) => ({ ...x, utoc_postman_id: v }));
                }}
              />
            </Form.Item>
            <Form.Item label={'준비'} style={{ marginBottom: 10 }}>
              <SearchUserBox
                type={'M'}
                value={showModal?.master_id}
                onChange={(v) => {
                  setShowModal((x) => ({ ...x, master_id: v }));
                }}
              />
            </Form.Item>
            <Form.Item label={'배송자'} style={{ marginBottom: 10 }}>
              <SearchUserBox
                type={'T'}
                value={showModal?.ctou_postman_id}
                onChange={(v) => {
                  setShowModal((x) => ({ ...x, ctou_postman_id: v }));
                }}
              />
            </Form.Item>
          </Form>
        ) : (
          <Form>
            <Form.Item label={'수거자'} style={{ marginBottom: 10 }}>
              <SearchUserBox
                type={'T'}
                value={showModal?.ctom_postman_id}
                onChange={(v) => {
                  setShowModal((x) => ({ ...x, ctom_postman_id: v }));
                }}
              />
            </Form.Item>
            <Form.Item label={'준비'} style={{ marginBottom: 10 }}>
              <SearchUserBox
                type={'M'}
                value={showModal?.master_id}
                onChange={(v) => {
                  setShowModal((x) => ({ ...x, master_id: v }));
                }}
              />
            </Form.Item>
            <Form.Item label={'배송자'} style={{ marginBottom: 10 }}>
              <SearchUserBox
                type={'T'}
                value={showModal?.mtoc_postman_id}
                onChange={(v) => {
                  setShowModal((x) => ({ ...x, mtoc_postman_id: v }));
                }}
              />
            </Form.Item>
          </Form>
        )}
      </Modal>
      <PageHeader className="site-page-header" title="배송처리" />
      <div style={{ margin: '0px 0px 30px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Radio.Group value={method} onChange={(e) => setMethod(e.target.value)}>
          <Radio.Button value={'N'}>야간기사</Radio.Button>
          <Radio.Button value={'D1'}>센터->장인</Radio.Button>
          <Radio.Button value={'D2'}>장인->센터</Radio.Button>
        </Radio.Group>

        <DatePicker
          style={{ width: 200, fontSize: 12 }}
          placeholder="날짜선택"
          value={moment(date)}
          onChange={(a, b) => setDate(moment(a).format('YYYY-MM-DD'))}
          picker={method}
          allowClear={false}
        />
      </div>
      <div ref={imageArea} style={{ height: 600, border: `1px solid #ccc` }}>
        <NaverMap
          ncp
          clientId={'07jxlkf4vb'}
          mapDivId={'maps-getting-started-uncontrolled'} // default: react-naver-map
          style={{
            width: '100%',
            height: '600px',
          }}
          initialPosition={{ lat: 37.4951801, lng: 127.0139108 }}
          defaultZoom={15}
          onInit={(nmap, naver) => {
            map.current = nmap;
            loadData().catch(console.warn);
          }}
        >
          {centers?.map((center) => {
            return (
              <Marker
                key={'C-' + center.id}
                title={center?.name}
                lat={center.lat}
                lng={center.lng}
                icon={`/img/pin_center.png`}
              />
            );
          })}
          {data?.map((item) => {
            let marker = '';
            if (method === 'N') {
              marker = !!item.begin_expected_at.startsWith(date) ? 'pin_get' : 'pin_send';
            } else {
              marker = method === 'D2' ? 'pin_get' : 'pin_send';
            }

            return (
              <Marker
                key={item.id}
                title={item.purchase?.account?.nickname}
                lat={item.purchase.lat}
                lng={item.purchase.lng}
                icon={`/img/${marker}.png`}
              />
            );
          })}

          {arriveTimes
            ?.filter((v) => v.lat && v.lng)
            ?.map((time, index) => {
              const work = data?.find((v) => String(v.id) === String(time.id));
              const { purchase, master } = work ?? {};
              let address = '';
              if (method === 'N') {
                address = purchase?.address + ' ' + purchase?.address_details;
              } else {
                address = master?.address;
              }
              return (
                <Overlay lat={time.lat} lng={time.lng} zIndex={200}>
                  <div
                    style={{
                      backgroundColor: 'white',
                      border: '1px solid #ddd',
                      padding: '5px 10px',
                    }}
                  >
                    {index + 1}번째 ({purchase?.account?.nickname} 님)
                    <br />
                    <small style={{ fontSize: 12, lineHeight: '14px' }}>
                      {address}
                      {method === 'N' && (
                        <>
                          <br />
                          {purchase?.address_memo}
                          {purchase?.entPassword}
                        </>
                      )}
                    </small>
                    <br />
                    {moment(time.arriveTime, 'YYYYMMDDHHmmss').format('HH:mm')} 도착 예정
                  </div>
                </Overlay>
              );
            })}

          {lines?.map((line, ix) => {
            return (
              <Polyline
                key={ix}
                path={line.map((v) => c(v))}
                strokeColor={'#FF7200'}
                strokeStyle={'shortdash'}
                strokeOpacity={0.8}
                strokeWeight={5}
              />
            );
          })}
        </NaverMap>
      </div>
      <div style={{ margin: `20px 0px 20px` }}>
        <Row>
          <Col flex={'1'}>
            <Space>
              {/*<CSVLink {...csvProps}>*/}
              {/*  <Button>엑셀 저장</Button>*/}
              {/*</CSVLink>*/}
              <Button disabled={selected.length == 0 || isEqual(processed, selected)} onClick={processDirection}>
                경로 최적화
              </Button>
            </Space>
          </Col>
          <Col>
            <Space>
              <Button onClick={() => handleSaveImage()}>지도 저장</Button>
              <Button onClick={() => handleDownload(csvProps)}>
                <>엑셀 저장</>
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
      <div style={{ position: 'relative' }}>
        <Table
          scroll={{ x: 'max-content' }}
          dataSource={data ?? []}
          columns={columns}
          pagination={false}
          rowKey={'id'}
          rowSelection={rowSelection}
        />
      </div>
    </div>
  );
};

export default DeliveryIndex;
