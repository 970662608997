import React, { useEffect, useState } from 'react';
import { restApi } from '../apis';
import { Table } from 'antd';
import TableButtonWrap from './TableButtonWrap';

/**
 * 탈퇴 회원 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const AccountRemoveView = ({ column, loadParent, extra }) => {
  const [data, setData] = useState();

  const loadData = async (page = 0) => {
    const { data } = await restApi.get('/accounts', {
      params: {
        ...extra,
        hide_only: true,
        page,
        limit: 10,
      },
    });
    setData(data);
  };

  useEffect(() => {
    loadData().catch(console.warn);
  }, []);

  const pagination = {
    position: ['bottomCenter'],
    showSizeChanger: false,
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      await loadData(p - 1);
    },
  };

  const [rowKeys, setRowKeys] = useState([]);
  const handleChange = (selectedRowKeys, selectedRows) => {
    setRowKeys(selectedRowKeys);
  };
  const handleRecover = async () => {
    if (rowKeys?.length === 0) {
      window.alert('복구할 내역을 선택하세요');
      return;
    }
    await restApi.put(`/cms/account/hides`, {
      ids: rowKeys?.map((v) => parseInt(v, 10)),
      active: true,
    });
    await loadParent?.();
  };

  return (
    <div style={{ position: 'relative' }}>
      <Table
        rowSelection={{
          type: 'checkbox',
          onChange: handleChange,
          selectedRowKeys: rowKeys,
        }}
        dataSource={data?.items}
        columns={column}
        pagination={pagination}
        scroll={{ x: 'max-content' }}
        rowKey={'id'}
      />
      <TableButtonWrap position="left" buttons={[{ label: '복원', onClick: handleRecover }]} />
    </div>
  );
};

export default AccountRemoveView;
