import React, { useState } from 'react';
import { Button, InputNumber, Modal } from 'antd';
import produce from 'immer';
import { addComma } from '../../../common/utils';
import { restApi } from '../../../apis';
import { getProducts } from '../../../data/products';
import { useDispatch } from 'react-redux';

const TYPE_FOR_DEPTH = {
  SC: ['GET_ITEM', 'FIX_READY', 'PREV_SENDING', 'SEND_USER'],
  DT: ['GET_ITEM', 'FIX_READY', 'PREV_SENDING', 'SEND_USER'],
  SP: ['SPEED_FIX', 'SEND_USER'],
  TS: [],
};

const DEPTH_NAMES = {
  GET_ITEM: '수거',
  FIX_READY: '분배',
  PREV_SENDING: '회수',
  SPEED_FIX: '수거',
  SEND_USER: '배송',
};

/**
 * 상품 비중 설정
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */

const RateFormButton = ({ type, data, setData }) => {
  const [visible, setVisible] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    try {
      setLoading(true);
      await Promise.all(
        data
          ?.filter((v) => (v?.price ?? 0) > 0)
          ?.map((item) => {
            return restApi.put(`/products/items/${item.id}`, {
              master_price: Math.floor(item.base_price * (item.master_per / 100)),
              delivery_price: Object.values(item.rate_prices ?? {}).reduce((a, b) => a + b, 0),
              base_price: item.base_price,
              master_per: item.master_per,
              rate_prices: item.rate_prices,
            });
          }),
      );
      setVisible(false);
      dispatch(getProducts());
      window.alert('저장되었습니다.');
    } catch (e) {}
    setLoading(false);
  };

  const gap = type === 'SP' ? 2 : 4;

  return (
    <>
      <Button onClick={() => setVisible(true)}>전체 배분관리</Button>
      <Modal
        destroyOnClose
        confirmLoading={loading}
        visible={visible}
        width={1000}
        title={'배분 상세설정'}
        onCancel={() => setVisible(false)}
        onOk={handleSubmit}
      >
        {data
          ?.filter((v) => (v?.price ?? 0) > 0)
          ?.map((item, ix) => {
            const { base_price, price, type, rate_prices, master_per } = item;
            const t_price = Math.floor(price / item.repeat);

            const left_total = base_price ?? 0;
            const right_total = t_price - (base_price ?? 0);

            const delivery_price = Object.values(rate_prices ?? {}).reduce((a, b) => a + b, 0);

            return (
              <table key={`${item.id}`} className={'calc_table'}>
                <colgroup>
                  <col width={'170'} />
                  <col width={'170'} />
                  <col width={'170'} />
                  <col width={'170'} />
                  <col width={'100'} />
                </colgroup>
                <tr>
                  <th colSpan={4}>{item.name}</th>
                  <th>가격</th>
                </tr>
                <tr>
                  <td>
                    <InputNumber
                      addonBefore={'장인'}
                      addonAfter={'%'}
                      value={master_per}
                      onChange={(e) => {
                        const value = parseInt(String(e).replace(/[^0-9]+/g, ''), 10);
                        setData(
                          produce((draft) => {
                            draft[ix].master_per = Math.min(value, 100);
                          }),
                        );
                      }}
                      step={0}
                      controls={false}
                    />
                  </td>
                  <td>
                    <InputNumber
                      addonBefore={'센터'}
                      addonAfter={'%'}
                      value={100 - master_per}
                      onChange={(e) => {
                        const value = parseInt(String(e).replace(/[^0-9]+/g, ''), 10);
                        setData(
                          produce((draft) => {
                            draft[ix].master_per = 100 - Math.min(value, 100);
                          }),
                        );
                      }}
                      step={0}
                      controls={false}
                    />
                  </td>
                  <td>
                    <InputNumber
                      addonBefore={'기사'}
                      addonAfter={'%'}
                      value={Math.floor(((delivery_price ?? 0) * 100.0) / right_total)}
                      step={0}
                      controls={false}
                      disabled={true}
                    />
                  </td>
                  <td>
                    <InputNumber
                      addonBefore={'센터'}
                      addonAfter={'%'}
                      value={100 - Math.floor(((delivery_price ?? 0) * 100.0) / right_total)}
                      step={0}
                      controls={false}
                      disabled={true}
                    />
                  </td>
                  <td rowSpan={3}>
                    {addComma(String(item.price))}
                    <br />
                    <small>(회당 {addComma(String(t_price))})</small>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} style={{ textAlign: 'center' }}>
                    <InputNumber
                      style={{ width: 200 }}
                      value={left_total}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      onChange={(value) => {
                        setData(
                          produce((draft) => {
                            draft[ix].base_price = value;
                          }),
                        );
                      }}
                      step={0}
                      controls={false}
                    />
                  </td>
                  <td colSpan={2} style={{ textAlign: 'center' }}>
                    <InputNumber
                      style={{ width: 200 }}
                      value={right_total}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      onChange={(value) => {
                        setData(
                          produce((draft) => {
                            draft[ix].base_price = t_price - value;
                          }),
                        );
                      }}
                      step={0}
                      controls={false}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <InputNumber
                      value={Math.floor(base_price * ((master_per ?? 0) / 100))}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      step={0}
                      controls={false}
                      addonAfter={'원'}
                      disabled={true}
                    />
                  </td>
                  <td>
                    <InputNumber
                      value={Math.ceil(base_price * ((100 - (master_per ?? 0)) / 100))}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      step={0}
                      controls={false}
                      addonAfter={'원'}
                      disabled={true}
                    />
                  </td>
                  <td>
                    {TYPE_FOR_DEPTH[type]?.map((key) => (
                      <InputNumber
                        key={key}
                        value={rate_prices[key]}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={(value) => {
                          setData(
                            produce((draft) => {
                              draft[ix].rate_prices[key] = value;
                            }),
                          );
                        }}
                        step={0}
                        controls={false}
                        addonBefore={DEPTH_NAMES[key]}
                        addonAfter={'원'}
                      />
                    ))}
                  </td>
                  <td>
                    <InputNumber
                      value={right_total - (delivery_price ?? 0)}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      step={0}
                      controls={false}
                      addonAfter={'원'}
                      disabled={true}
                    />
                  </td>
                </tr>
              </table>
            );
          })}
      </Modal>
    </>
  );
};

export default RateFormButton;
