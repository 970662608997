import React from 'react';
import { PageHeader, Table } from 'antd';
import { reviewSearchField } from '../../../common/searchFields';
import SearchForm from '../../../components/datalist/SearchForm';
import TableButtonWrap from '../../../components/TableButtonWrap';
import moment from 'moment';
import { getAPIHost, restApi } from '../../../apis';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import fileDownload from 'js-file-download';
import { PaymentType } from '../../../common/defines';

/**
 * 후기관리 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const ReviewIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);

  const [data, setData] = React.useState();

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      ...query,
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    }

    const { data } = await restApi.get('/reviews', {
      params,
    });
    setData(data);
  }, [query]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  const columns = React.useMemo(
    () => [
      {
        title: '번호',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 90,
        render: (_, __, index) => {
          return (data?.total_elements || 0) - (10 * data?.page + index);
        },
      },
      {
        title: '서비스',
        dataIndex: 'purchase',
        align: 'center',
        width: 120,
        render: (data) => PaymentType[data.type],
      },
      {
        title: '고객명',
        dataIndex: 'account',
        align: 'center',
        width: 100,
        render: (data) => data.nickname,
      },
      {
        title: '별점',
        dataIndex: 'rating',
        width: 80,
        align: 'center',
      },
      {
        title: '내용',
        dataIndex: 'satisfying',
        align: 'left',
        render: (data, row) => [...(row?.satisfying ?? []), ...(row?.not_satisfying ?? []), row.content]?.join(', '),
      },
      {
        title: '리뷰등록일',
        dataIndex: 'created_at',
        width: 200,
        align: 'center',
        render: (data) => moment(data).format('YYYY.MM.DD'),
      },
      {
        title: '자세히보기',
        dataIndex: 'id',
        align: 'center',
        width: 120,
        render: (data) => (
          <Link to={`/work/review/details/${data}`}>
            <a style={{ color: 'black' }}>자세히보기</a>
          </Link>
        ),
      },
    ],
    [data],
  );

  // 테이블 페이지네이션
  const pagination = {
    position: ['bottomCenter'],
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      navigate('?' + qs.stringify({ ...query, page: p - 1 }), { replace: true });
    },
  };

  const xlsxSave = () => {
    const args = {
      ...query,
    };
    restApi
      .get(`${getAPIHost()}/exports/reviews?${qs.stringify(args)}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `후기관리_${moment().format('YYYYMMDDHHmmss')}.xlsx`);
      });
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="사용자후기" />
      <SearchForm fields={reviewSearchField} />
      <div style={{ position: 'relative' }}>
        <Table
          scroll={{ x: 'max-content' }}
          dataSource={data?.items}
          columns={columns}
          pagination={
            pagination
              ? {
                  ...pagination,
                  position: ['bottomCenter'],
                  showSizeChanger: false,
                }
              : false
          }
        />
        <div>
          <TableButtonWrap
            position="left"
            buttons={[{ icon: require('../../../assets/icon_save.png'), label: '엑셀 저장', onClick: xlsxSave }]}
          />
        </div>
      </div>
    </div>
  );
};

export default ReviewIndex;
