import React from 'react';
import VendorDetailsMenu, { useGetVendor } from './menu';
import OrderIndex from '../../work/order';
import VStatsUser from '../../for_vendor/stats/user';

/**
 * 기업 통계
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const VendorsDetailsStats = (props) => {
  const vendor = useGetVendor();
  return (
    <div>
      <VendorDetailsMenu index={2} />
      {!!vendor?.id && <VStatsUser vendorId={vendor?.id} />}
    </div>
  );
};

export default VendorsDetailsStats;
