import React, { useCallback, useEffect, useState } from 'react';
import { restApi } from '../../../../apis';
import styles from './OrderDetail.module.less';
import { Button, Col, DatePicker, Divider, Input, Row, Select } from 'antd';
import moment from 'moment';
import { NEW_RUN_STATE_MODE } from '../../../../common/defines';
import SearchUserBox from './SearchUserBox';
import SearchCenterBox from './SearchCenterBox';

/**
 * 결제 정보
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const PurchaseDateItem = ({ type, active, date, dateArgs, setDateArgs, handleDateUpdate, onRefresh }) => {
  const [showModal, setShowModal] = useState();
  const [loading, setLoading] = useState();

  const [form, setForm] = useState();
  useEffect(() => {
    setForm({
      state: date.state,
      begin_expected_at: date?.begin_expected_at,
      finish_expected_at: date?.finish_expected_at,
      utoc_postman_id: date.utoc_postman_id,
      ctom_postman_id: date.ctom_postman_id,
      mtoc_postman_id: date.mtoc_postman_id,
      ctou_postman_id: date.ctou_postman_id,
      master_id: date.master_id,
      center_id: date.center_id,
      center_user_id: date.center_user_id,
    });
  }, [date]);

  const handleSubmit2 = useCallback(async () => {
    setLoading(true);
    try {
      await restApi.put(`/cms/purchases/dates/${date.id}`, form);
      onRefresh?.();
    } catch (e) {
      alert(e?.response?.data?.message || '서버와 연결이 올바르지 않습니다.');
    }
    setLoading(false);
  }, [form]);

  console.log({form})

  return (
    <div className={styles.tableStyleBox}>
      <ul className={styles.tableContentList}>
        <li>
          <label>수거일</label>
          <div>
            <Row gutter={10}>
              <Col style={{ width: 150 }}>
                <DatePicker
                  value={moment(form?.begin_expected_at)}
                  onChange={(e) => {
                    const begin_expected_at = e.format('YYYY-MM-DD') + ' 22:00:00';
                    const work_date = e.clone().add(1, 'days').format('YYYY-MM-DD');
                    const finish_expected_at = e.clone().add(2, 'days').format('YYYY-MM-DD') + ' 08:00:00';
                    setForm((x) => ({
                      ...x,
                      begin_expected_at,
                      work_date,
                      finish_expected_at,
                    }));
                  }}
                />
              </Col>
              <Col flex={'3'}>
                <Input
                  value={`22:00 ~ ${moment(form?.begin_expected_at).add(1, 'days').format('YYYY-MM-DD')} 08:00`}
                  disabled
                />
              </Col>
            </Row>
          </div>
        </li>
        <li>
          <label>배송일</label>
          <div>
            <Row gutter={10}>
              <Col flex={'2'}>
                <Input
                  value={`${moment(form?.finish_expected_at).add(-10, 'hours').format('YYYY-MM-DD')} 22:00 ~`}
                  disabled
                />
              </Col>
              <Col style={{ width: 150 }}>
                <DatePicker
                  value={moment(form?.finish_expected_at)}
                  onChange={(e) => {
                    const finish_expected_at = e.format('YYYY-MM-DD') + ' 08:00:00';
                    setForm((x) => ({
                      ...x,
                      finish_expected_at,
                    }));
                  }}
                />
              </Col>
              <Col flex={'1'}>
                <Input value={`08:00`} disabled />
              </Col>
            </Row>
          </div>
        </li>
        <li>
          <label>상태</label>
          <div>
            <Select
              value={form?.state}
              onChange={(state) => {
                setForm((x) => ({
                  ...x,
                  state,
                }));
              }}
              style={{ width: '100%' }}
            >
              {Object.keys(NEW_RUN_STATE_MODE[type === 'SP' ? 'speed' : 'default']).map((key) => (
                <Select.Option key={key} value={key}>
                  {NEW_RUN_STATE_MODE[type === 'SP' ? 'speed' : 'default'][key]}
                </Select.Option>
              ))}
            </Select>
          </div>
        </li>
        {active && form && (
          <>
            <Divider />
            {date?.type !== 'SP' ? (
              <>
                <li>
                  <label>사용자 -> 센터 야간기사</label>
                  <div>
                    <SearchUserBox
                      type={'T'}
                      workmode={'N'}
                      placeholder={'야간기사'}
                      value={form.utoc_postman_id}
                      onChange={(v) => setForm((x) => ({ ...x, utoc_postman_id: v }))}
                    />
                  </div>
                </li>
                <>
                  <li>
                    <label>센터 -> 장인 주간기사</label>
                    <div>
                      <SearchUserBox
                        type={'T'}
                        workmode={'D'}
                        placeholder={'주간기사'}
                        value={form.ctom_postman_id}
                        onChange={(v) => setForm((x) => ({ ...x, ctom_postman_id: v }))}
                      />
                    </div>
                  </li>
                  <li>
                    <label>장인 -> 센터 주간기사</label>
                    <div>
                      <SearchUserBox
                        type={'T'}
                        workmode={'D'}
                        placeholder={'주간기사'}
                        value={form.mtoc_postman_id}
                        onChange={(v) => setForm((x) => ({ ...x, mtoc_postman_id: v }))}
                      />
                    </div>
                  </li>
                </>
                <li>
                  <label>센터 -> 사용자 야간기사</label>
                  <div>
                    <SearchUserBox
                      type={'T'}
                      workmode={'N'}
                      placeholder={'야간기사'}
                      value={form.ctou_postman_id}
                      onChange={(v) => setForm((x) => ({ ...x, ctou_postman_id: v }))}
                    />
                  </div>
                </li>
              </>
            ) : (
              <>
                <li>
                  <label>사용자 -> 장인 긴급기사</label>
                  <div>
                    <SearchUserBox
                      type={'S'}
                      value={form.utoc_postman_id}
                      onChange={(v) => setForm((x) => ({ ...x, utoc_postman_id: v }))}
                    />
                  </div>
                </li>
                <li>
                  <label>장인 -> 사용자 긴급기사</label>
                  <div>
                    <SearchUserBox
                      type={'S'}
                      placeholder={'기사'}
                      value={form.ctou_postman_id}
                      onChange={(v) => setForm((x) => ({ ...x, ctou_postman_id: v }))}
                    />
                  </div>
                </li>
              </>
            )}

            <li>
              <label>장인</label>
              <div>
                <SearchUserBox
                  type={'M'}
                  placeholder={'장인'}
                  value={form.master_id}
                  onChange={(v) => setForm((x) => ({ ...x, master_id: v }))}
                />
              </div>
            </li>
            <li>
              <label>센터 직원</label>
              <div>
                <SearchUserBox
                  type={'C'}
                  placeholder={'센터 직원'}
                  value={form.center_user_id}
                  onChange={(v, o) => {
                    setForm((x) => ({ ...x, center_user_id: v, center_id: o.center_id }));
                  }}
                />
              </div>
            </li>
            <li>
              <label>센터</label>
              <div>
                <SearchCenterBox disabled={true} placeholder={'센터'} value={form.center_id} />
              </div>
            </li>
          </>
        )}
        <li style={{ textAlign: 'right', paddingTop: 10 }}>
          <div style={{ flex: 1 }}></div>
          <Button onClick={handleSubmit2} loading={loading}>
            저장
          </Button>
        </li>
      </ul>
    </div>
  );
};

export default PurchaseDateItem;
