import React from 'react';
import useAxios from '../../../../hooks/useAxios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deleteHoliday, getholiday } from '../../../../apis/restdays';
import { Button, Descriptions, PageHeader } from 'antd';
import moment from 'moment';
import styles from '../../../work/review/details/reviewDetails.module.less';

/**
 * 휴일관리 상세 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const RestdayDetails = () => {
  const restday = useAxios();
  const { id } = useParams();
  const navigate = useNavigate();

  const handleDeleteHoliday = async () => {
    try {
      await deleteHoliday(id);
      navigate('/settings/restday', { replace: true });
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
    }
  };

  const handleReadList = () => {
    navigate('/settings/restday', { replace: true });
  };

  React.useEffect(() => {
    restday.loadData(getholiday(id)).catch((error) => {
      console.warn(error);
      alert(error?.response?.data?.message);
      navigate('/settings/restday', { replace: true });
    });
  }, []);
  return (
    <div>
      <PageHeader className="site-page-header" title="휴일 정보" />
      <Descriptions bordered>
        <Descriptions.Item label={'휴일'} span={4} className="nopadding" style={{ padding: '10px 20px' }}>
          {moment(restday?.data?.date).format('YYYY-MM-DD dddd')}
        </Descriptions.Item>
        <Descriptions.Item
          label={'메모'}
          span={4}
          className="nopadding"
          style={{ verticalAlign: 'top', padding: '10px 20px' }}
        >
          {restday?.data?.text}
        </Descriptions.Item>
      </Descriptions>
      <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={handleDeleteHoliday}
          size={'large'}
          className={styles.basicButton}
          style={{ marginRight: 5, height: 40, width: 100 }}
        >
          삭제
        </Button>
        <Button
          size="large"
          className="basicButton"
          style={{ height: 40, width: 100, backgroundColor: '#FF7200', color: 'white', marginRight: 5 }}
        >
          <Link to={`/settings/restday`}>목록</Link>
        </Button>
        <Button size="large" className="basicButton" style={{ height: 40, width: 100, marginRight: 5 }}>
          <Link to={`/settings/restday/update/${id}`}>수정</Link>
        </Button>
      </div>
      {/*<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>*/}
      {/*  <Button onClick={handleDeleteHoliday} style={{ width: 152, height: 40, borderRadius: 4 }}>*/}
      {/*    삭제*/}
      {/*  </Button>*/}
      {/*  <Button*/}
      {/*    onClick={handleReadList}*/}
      {/*    style={{ background: '#000', color: '#fff', width: 152, height: 40, borderRadius: 4 }}*/}
      {/*  >*/}
      {/*    목록*/}
      {/*  </Button>*/}
      {/*  <Button*/}
      {/*    onClick={() => navigate(`/settings/restday/update/${id}`, { replace: true })}*/}
      {/*    style={{ width: 152, height: 40, borderRadius: 4 }}*/}
      {/*  >*/}
      {/*    수정*/}
      {/*  </Button>*/}
      {/*</div>*/}
    </div>
  );
};

export default RestdayDetails;
