import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Input, Modal, PageHeader, Row, Select, Space, Table, Upload } from 'antd';
import produce from 'immer';
import fileDownload from 'js-file-download';
import { restApi } from '../../../apis';
import { UploadOutlined } from '@ant-design/icons';
import Postcode from '@actbase/react-daum-postcode';
import { getGeocode } from '../../../apis/thirdparty';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * 기업회원 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const VendersCodesCreate = ({ vendorId }) => {
  const [items, setItems] = useState();
  const [file, setFile] = useState();
  const [postcode, setPostcode] = useState();

  const columns = useMemo(() => {
    // 기업명	주소	의뢰 상품	이름	성별	연락처	켤레수	수거일	금액
    return [
      {
        title: '아파트명',
        dataIndex: 'name',
        key: 'name',
        width: 150,
        render: (d, r, i) => {
          const style = {};
          if (!d) {
            style.border = '1px solid #f00';
          }

          return (
            <Input
              style={style}
              value={d}
              onChange={(e) => {
                const value = e.target.value;
                setItems(
                  produce((draft) => {
                    draft[i].name = value;
                  }),
                );
              }}
            />
          );
        },
      },
      {
        title: '주소',
        dataIndex: 'address',
        key: 'address',
        width: 350,
        render: (d, r, i) => {
          const style = {};
          if (!d || !r.lat || !r.lng) {
            style.border = '1px solid #f00';
          }
          return (
            <Input.Search
              style={style}
              value={d}
              onSearch={() => {
                setPostcode(i);
              }}
              onChange={(e) => {
                const value = e.target.value;
                setItems(
                  produce((draft) => {
                    draft[i].address = value;
                  }),
                );
              }}
            />
          );
        },
      },
      {
        title: '요일',
        dataIndex: 'week',
        key: 'week',
        width: 150,
        render: (d, r, i) => {
          const style = {};
          if (!d) {
            style.border = '1px solid #f00';
          }
          return (
            <Select
              value={d}
              style={{ width: '100%' }}
              onChange={(value) => {
                setItems(
                  produce((draft) => {
                    draft[i].week = value;
                  }),
                );
              }}
            >
              <Select.Option value={0}>일</Select.Option>
              <Select.Option value={1}>월</Select.Option>
              <Select.Option value={2}>화</Select.Option>
              <Select.Option value={3}>수</Select.Option>
              <Select.Option value={4}>목</Select.Option>
              <Select.Option value={5}>금</Select.Option>
              <Select.Option value={6}>토</Select.Option>
            </Select>
          );
        },
      },
      {
        title: '설정',
        dataIndex: 'serial',
        key: 'serial2',
        width: 70,
        align: 'center',
        fixed: 'right',
        render: (d, r, i) => (
          <Button
            size={'small'}
            danger
            onClick={() => {
              setItems(
                produce((draft) => {
                  draft.splice(i, 1);
                }),
              );
            }}
          >
            삭제
          </Button>
        ),
      },
    ];
  }, [file]);

  // 주소 선택
  const handleSelectAddress = async (e) => {
    if (e) {
      const ix = postcode;
      const { data: ad } = await getGeocode(e.roadAddress);
      setItems(
        produce((draft) => {
          draft[ix].address = ad.addr;
          draft[ix].lat = ad.lat;
          draft[ix].lng = ad.lng;
        }),
      );
    }

    setPostcode(undefined);
  };

  const handleDownload = async () => {
    const { data } = await restApi.get(`/vendors/codes/xlsx`, { responseType: 'blob' });
    fileDownload(data, 'shoedoc_vendor_apartments_example.xlsx');
  };

  const [loading, setLoading] = useState(false);
  const processUpload = async (file) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('filedata', file);
      const { data } = await restApi.post(`/vendors/codes/xlsx`, formData);
      setItems(data);
    } catch (e) {
      console.error(e);
      alert(e?.response?.data?.message || e?.message || '서버와 연결이 올바르지 않습니다.');
    }
    setLoading(false);
  };

  const mainTable = useRef();

  const [bodyHeight, setBodyHeight] = useState(10);
  const { principal } = useSelector((s) => s.auth, shallowEqual);

  useEffect(() => {
    const sizeChange = () => {
      const { top } = mainTable.current?.getBoundingClientRect?.();
      const height = document.body.clientHeight - top - 80;
      setBodyHeight(height);
    };
    sizeChange();
  }, [file]);

  const handleSubmit = async () => {
    setLoading(true);
    const inputs = [...items];
    let ix = 0;
    const finished = [];
    const errors = [];
    while (true) {
      const item = inputs?.[ix];
      if (!item) break;
      try {
        const body = { ...item };
        const { data: result } = await restApi.post(`/vendors/${vendorId ?? principal?.id}/codes`, body);
        finished.push({
          ...inputs.splice(0, 1)?.[0],
          result,
        });
      } catch (e) {
        ix++;
        errors.push(e?.response?.data?.message);
      }
      setItems(inputs);
    }

    if (inputs.length === 0) {
      alert('성공적으로 업로드가 완료되었습니다.');
      setFile(undefined);
    } else {
      alert(
        '처리 완료.\n실패한 항목은 데이터 수정 후 다시 시도해주세요.\n\n' +
          errors?.map((v, i) => `${i + 1}. ${v}`)?.join('\n'),
      );
    }
    setLoading(false);
  };

  return (
    <div>
      <Modal
        visible={postcode !== undefined}
        bodyStyle={{ padding: 0 }}
        closable
        title={'주소찾기'}
        onCancel={() => handleSelectAddress()}
        centered
        footer={null}
        width={500}
        destroyOnClose
      >
        <Postcode style={{ width: '100%' }} onSelected={handleSelectAddress} />
      </Modal>
      <PageHeader className="site-page-header" title="아파트 업로드" />
      <div style={{ marginBottom: 20 }}>
        <Row>
          <Col flex={'1'}>
            <Space>
              <Button onClick={handleDownload}>업로드 템플릿 다운로드</Button>
              <Upload
                fileList={[]}
                beforeUpload={(f) => {
                  setFile(f);
                  processUpload(f).catch(console.warn);
                  return false;
                }}
              >
                <Button icon={<UploadOutlined />}>업로드 처리</Button>
              </Upload>
            </Space>
          </Col>
          {!!items?.length && (
            <Col>
              <Button loading={loading} onClick={handleSubmit} type={'primary'}>
                일괄 저장
              </Button>
            </Col>
          )}
        </Row>
      </div>
      <div className={'input-table'} ref={mainTable}>
        {!file ? (
          <p>템플릿 다운로드 후 내용을 추가설정하여 올려주세요.</p>
        ) : (
          <Table
            loading={loading}
            scroll={{ x: columns?.reduce((a, b) => a + b.width, 0), y: bodyHeight }}
            dataSource={items}
            columns={columns}
            pagination={false}
            rowKey={(r) => `${items.indexOf(r)}`}
          />
        )}
      </div>
    </div>
  );
};

export default VendersCodesCreate;
