import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Input, Modal, PageHeader, Radio, Row, Select, Space, Table, TreeSelect, Upload } from 'antd';
import produce from 'immer';
import fileDownload from 'js-file-download';
import { restApi } from '../../../apis';
import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { shallowEqual, useSelector } from 'react-redux';
import moment from 'moment';
import FileUpload from '../../../components/FileUpload';
import Postcode from '@actbase/react-daum-postcode';
import { getGeocode } from '../../../apis/thirdparty';

/**
 * 기업회원 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const TYPE_NAMES = { SC: '정기배송', DT: '하루배송', SP: '긴급배송', TS: '택배배송' };
const GENDER_NAMES = { M: '남성구두', F: '여성구두', X: '운동화' };
const AccountCorpUpload = (props) => {
  const [items, setItems] = useState();
  const [file, setFile] = useState();
  const [postcode, setPostcode] = useState();

  const { items: products } = useSelector((s) => s.product, shallowEqual);

  // 트리 데이터 생성
  const treeData = useMemo(() => {
    const output = [];
    for (const type of ['SC', 'DT', 'SP', 'TS']) {
      for (const gender of ['M', 'F', 'X']) {
        const key = `${type}-${gender}`;
        output.push({
          key,
          type: type,
          title: `${TYPE_NAMES[type]} ${GENDER_NAMES[gender]}`,
          children: products[key]?.filter((v) => v.active)?.map((v) => ({ key: v.id, title: v.name, value: v.id })),
          value: '__' + key,
          selectable: false,
        });
      }
    }

    return output;
  }, [products]);

  // 테이블 컬럼 정의
  const columns = useMemo(() => {
    // 기업명	주소	의뢰 상품	이름	성별	연락처	켤레수	수거일	금액
    return [
      {
        title: '기업명',
        dataIndex: 'corp_department',
        key: 'corp_department',
        width: 150,
        render: (d, r, i) => {
          const style = {};
          if (!d) {
            style.border = '1px solid #f00';
          }

          return (
            <Input
              style={style}
              value={d}
              onChange={(e) => {
                const value = e.target.value;
                setItems(
                  produce((draft) => {
                    draft[i].corp_department = value;
                  }),
                );
              }}
            />
          );
        },
      },
      {
        title: '주소',
        dataIndex: 'address',
        key: 'address',
        width: 350,
        render: (d, r, i) => {
          const style = {};
          if (!d || !r.lat || !r.lng) {
            style.border = '1px solid #f00';
          }
          return (
            <Input.Search
              style={style}
              value={d}
              onSearch={() => {
                setPostcode(i);
              }}
              onChange={(e) => {
                const value = e.target.value;
                setItems(
                  produce((draft) => {
                    draft[i].address = value;
                  }),
                );
              }}
            />
          );
        },
      },
      {
        title: '상세주소',
        dataIndex: 'address_details',
        key: 'address_details',
        width: 200,
        render: (d, r, i) => {
          const style = {};
          if (!d) {
            style.border = '1px solid #f00';
          }
          return (
            <Input
              style={style}
              value={d}
              onChange={(e) => {
                const value = e.target.value;
                setItems(
                  produce((draft) => {
                    draft[i].address = value;
                  }),
                );
              }}
            />
          );
        },
      },
      {
        title: '종류',
        dataIndex: 'product_type',
        key: 'product_type',
        width: 150,
        render: (d, r, i) => {
          return (
            <Select
              style={{ width: '100%' }}
              value={d}
              onChange={(v) => {
                setItems(
                  produce((draft) => {
                    draft[i].product_type = v;
                  }),
                );
              }}
            >
              {Object.keys(TYPE_NAMES).map((key) => (
                <Select.Option value={key} key={key}>
                  {TYPE_NAMES[key]}
                </Select.Option>
              ))}
            </Select>
          );
        },
      },
      {
        title: '의뢰 상품',
        dataIndex: 'product_id',
        key: 'product_id',
        width: 250,
        render: (d, r, i) => {
          const style = {};
          return (
            <>
              <TreeSelect
                treeData={treeData?.filter((v) => v.type === r.product_type)}
                style={{ ...style, width: '100%' }}
                value={d}
                allowClear
                placeholder={'없을시 하루배송판단'}
                onChange={(value, e) => {
                  console.log(value, e);
                  if (String(value)?.startsWith('__')) return false;
                  setItems(
                    produce((draft) => {
                      draft[i].product_id = value;
                    }),
                  );
                }}
              />
              {!r.product_id && (
                <Input
                  style={{ marginTop: 5 }}
                  value={r.product_name}
                  onChange={(e) => {
                    const value = e.target.value;
                    setItems(
                      produce((draft) => {
                        draft[i].product_name = value;
                      }),
                    );
                  }}
                />
              )}
            </>
          );
        },
      },
      {
        title: '이름',
        dataIndex: 'nickname',
        key: 'nickname',
        width: 150,
        render: (d, r, i) => {
          const style = {};
          if (!d) {
            style.border = '1px solid #f00';
          }
          return (
            <Input
              value={d}
              onChange={(e) => {
                const value = e.target.value;
                setItems(
                  produce((draft) => {
                    draft[i].nickname = value;
                  }),
                );
              }}
            />
          );
        },
      },
      {
        title: '성별',
        dataIndex: 'gender',
        key: 'gender',
        align: 'center',
        width: 100,
        render: (d, r, i) => {
          const style = {};
          if (!d) {
            style.border = '1px solid #f00';
          }
          return (
            <Radio.Group
              style={style}
              size={'small'}
              value={d}
              onChange={(e) => {
                const value = e.target.value;
                setItems(
                  produce((draft) => {
                    draft[i].gender = value;
                  }),
                );
              }}
            >
              <Radio.Button value={'M'}>남</Radio.Button>
              <Radio.Button value={'F'}>여</Radio.Button>
            </Radio.Group>
          );
        },
      },
      {
        title: '연락처',
        dataIndex: 'phone',
        key: 'phone',
        width: 200,
        render: (d, r, i) => {
          const style = {};
          if (!d || d?.length < 10 || !String(d)?.startsWith('01')) {
            style.border = '1px solid #f00';
          }
          return (
            <Input
              style={style}
              value={d}
              onChange={(e) => {
                const value = e.target.value;
                setItems(
                  produce((draft) => {
                    draft[i].phone = value;
                  }),
                );
              }}
            />
          );
        },
      },
      {
        title: '이미지',
        dataIndex: 'images',
        key: 'images',
        width: 300,
        render: (d, r, i) => {
          return (
            <FileUpload
              values={d}
              onChange={(value) => {
                setItems(
                  produce((draft) => {
                    draft[i].images = value;
                  }),
                );
              }}
              maxCount={100}
            />
          );
        },
      },
      {
        title: '켤레수',
        dataIndex: 'shoes_count',
        key: 'shoes_count',
        width: 100,
        render: (d, r, i) => {
          const style = {};
          if (!d || parseInt(String(d), 10) < 1) {
            style.border = '1px solid #f00';
          }
          return (
            <Input
              style={style}
              value={d}
              onChange={(e) => {
                const value = e.target.value;
                setItems(
                  produce((draft) => {
                    draft[i].shoes_count = value;
                  }),
                );
              }}
            />
          );
        },
      },
      {
        title: '수거일',
        dataIndex: 'begin_expected_at',
        key: 'begin_expected_at',
        width: 150,
        render: (d, r, i) => {
          const style = {};
          if (!d || d.length !== 10 || !moment(d).isAfter(moment().add(-1, 'years'))) {
            style.border = '1px solid #f00';
          }
          return (
            <Input
              style={style}
              value={d}
              onChange={(e) => {
                const value = e.target.value;
                setItems(
                  produce((draft) => {
                    draft[i].begin_expected_at = value;
                  }),
                );
              }}
            />
          );
        },
      },
      {
        title: '배송일',
        dataIndex: 'finish_expected_at',
        key: 'finish_expected_at',
        width: 150,
        render: (d, r, i) => {
          const style = {};
          if (!d || d.length !== 10 || !moment(d).isAfter(moment().add(-1, 'years'))) {
            style.border = '1px solid #f00';
          }
          return (
            <Input
              style={style}
              value={d}
              onChange={(e) => {
                const value = e.target.value;
                setItems(
                  produce((draft) => {
                    draft[i].finish_expected_at = value;
                  }),
                );
              }}
            />
          );
        },
      },
      {
        title: '금액',
        dataIndex: 'price',
        key: 'price',
        width: 150,
        render: (d, r, i) => (
          <Input
            value={d}
            onChange={(e) => {
              const value = e.target.value;
              setItems(
                produce((draft) => {
                  draft[i].price = value;
                }),
              );
            }}
          />
        ),
      },
      {
        title: '접수처',
        dataIndex: 'ref',
        key: 'ref',
        width: 150,
        render: (d, r, i) => (
          <Input
            value={d}
            onChange={(e) => {
              const value = e.target.value;
              setItems(
                produce((draft) => {
                  draft[i].ref = value;
                }),
              );
            }}
          />
        ),
      },
      {
        title: '설정',
        dataIndex: 'serial',
        key: 'serial2',
        width: 70,
        align: 'center',
        fixed: 'right',
        render: (d, r, i) => (
          <Button
            size={'small'}
            danger
            onClick={() => {
              setItems(
                produce((draft) => {
                  draft.splice(i, 1);
                }),
              );
            }}
          >
            삭제
          </Button>
        ),
      },
    ];
  }, [file]);

  // 주소 변경 처리
  const handleSelectAddress = async (e) => {
    if (e) {
      const ix = postcode;
      const { data: ad } = await getGeocode(e.roadAddress);
      setItems(
        produce((draft) => {
          draft[ix].address = ad.addr;
          draft[ix].lat = ad.lat;
          draft[ix].lng = ad.lng;
        }),
      );
    }

    setPostcode(undefined);
  };

  // 다운로드
  const handleDownload = async () => {
    const { data } = await restApi.get(`/b2b/xlsx`, { responseType: 'blob' });
    fileDownload(data, 'shoedoc_b2b_example.xlsx');
  };

  const [loading, setLoading] = useState(false);
  // 파일 업로드
  const processUpload = async (file) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('filedata', file);
      const { data } = await restApi.post(`/b2b/xlsx`, formData);
      setItems(data);
    } catch (e) {
      console.error(e);
      alert(e?.response?.data?.message || e?.message || '서버와 연결이 올바르지 않습니다.');
    }
    setLoading(false);
  };

  const mainTable = useRef();

  const [bodyHeight, setBodyHeight] = useState(10);

  // 사이즈 변경시 처리 로직
  useEffect(() => {
    const sizeChange = () => {
      const { top } = mainTable.current?.getBoundingClientRect?.();
      const height = document.body.clientHeight - top - 80;
      setBodyHeight(height);
    };
    sizeChange();
  }, [file]);

  // 제출 로직
  const handleSubmit = async () => {
    setLoading(true);
    const inputs = [...items];
    let ix = 0;
    const finished = [];
    while (true) {
      const item = inputs?.[ix];
      if (!item) break;
      try {
        const body = { ...item };
        const grouping = finished.find((v) => v.corp_department === item.corp_department);
        if (grouping) {
          body.payment_id = grouping.result.payment_id;
        }
        const { data: result } = await restApi.post(`/b2b`, body);
        finished.push({
          ...inputs.splice(0, 1)?.[0],
          result,
        });
      } catch (e) {
        ix++;
      }
      setItems(inputs);
    }

    if (inputs.length === 0) {
      alert('성공적으로 업로드가 완료되었습니다.');
      setFile(undefined);
    } else {
      alert('처리 완료.\n실패한 항목은 데이터 수정 후 다시 시도해주세요.');
    }
    setLoading(false);
  };

  return (
    <div>
      <Modal
        visible={postcode !== undefined}
        bodyStyle={{ padding: 0 }}
        closable
        title={'주소찾기'}
        onCancel={() => handleSelectAddress()}
        centered
        footer={null}
        width={500}
        destroyOnClose
      >
        <Postcode style={{ width: '100%' }} onSelected={handleSelectAddress} />
      </Modal>
      <PageHeader className="site-page-header" title="기업회원 등록" />
      <div style={{ marginBottom: 20 }}>
        <Row>
          <Col flex={'1'}>
            <Space>
              <Button onClick={handleDownload}>업로드 템플릿 다운로드</Button>
              <Upload
                fileList={[]}
                beforeUpload={(f) => {
                  setFile(f);
                  processUpload(f).catch(console.warn);
                  return false;
                }}
              >
                <Button icon={<UploadOutlined />}>업로드 처리</Button>
              </Upload>
            </Space>
          </Col>
          {!!items?.length && (
            <Col>
              <Button loading={loading} onClick={handleSubmit} type={'primary'}>
                일괄 저장
              </Button>
            </Col>
          )}
        </Row>
      </div>
      <div className={'input-table'} ref={mainTable}>
        {!file ? (
          <p>
            사람 이름으로 파일명 설정하여 이미지 파일과 함께 압축해서 올려주세요. <br />혹 같은 이름에 여러 주문건이
            있는경우 사람이름을 A, B 로 뒤에 붙이고 파일명도 동일하게 해서 zip으로 올리시면 됩니다.
          </p>
        ) : (
          <Table
            loading={loading}
            scroll={{ x: columns?.reduce((a, b) => a + b.width, 0), y: bodyHeight }}
            dataSource={items}
            columns={columns}
            pagination={false}
            rowKey={(r) => `${items.indexOf(r)}`}
          />
        )}
      </div>
    </div>
  );
};

export default AccountCorpUpload;
