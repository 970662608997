import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import { Button, Descriptions, PageHeader } from 'antd';
import styles from '../../../work/review/details/reviewDetails.module.less';
import { deletePost, getPost } from '../../../../apis/posts';

/**
 * FAQ 상세 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const FAQDetails = () => {
  const navigate = useNavigate();
  const post = useAxios();
  const { id } = useParams();

  // 삭제 함수
  const handleDeletePost = async () => {
    if (!window.confirm('삭제 하시겠습니까?')) {
      return;
    }
    try {
      await deletePost(id);
      navigate('/contents/faq', { replace: true });
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
    }
  };

  // 뒤로 가기
  const handleReadList = () => {
    navigate('/contents/faq', { replace: true });
  };

  // 초기 데이터 패칭
  React.useEffect(() => {
    post.loadData(getPost(id));
  }, []);

  return (
    <div>
      <PageHeader className="site-page-header" title="FAQ 상세" />
      <Descriptions bordered>
        <Descriptions.Item label="질문" span={4}>
          {post.data?.title}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions bordered>
        <Descriptions.Item label="답변" span={4}>
          <div dangerouslySetInnerHTML={{ __html: post.data?.contents }} />
        </Descriptions.Item>
      </Descriptions>
      <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={handleDeletePost}
          size={'large'}
          className={styles.basicButton}
          style={{ marginRight: 5, height: 40, width: 100 }}
        >
          삭제
        </Button>
        <Button
          onClick={handleReadList}
          size={'large'}
          className={styles.basicButton}
          style={{ height: 40, width: 100, backgroundColor: '#FF7200', color: 'white', marginRight: 10 }}
        >
          목록
        </Button>
        <Button size="large" className="basicButton dark" style={{ height: 40, width: 100 }}>
          <Link to={`/contents/faq/update/${id}`}>수정</Link>
        </Button>
      </div>
    </div>
  );
};

export default FAQDetails;
