import React from 'react';
import moment from 'moment';
import ChartWrapper from '../ChartWrapper';
import Chart from 'react-chartjs-2';
import { getStats } from '../../apis/stats';
import { pointCut } from '../../common/utils';

/**
 * 통계페이지 원형 차트 컴포넌트
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const DoughnutChart2 = ({
  title,
  type,
  gender,
  dateRange = [moment().startOf('month'), moment().endOf('month')],
  ...props
}) => {
  const stats = props?.stats;
  const point1 = stats.data?.for_gender?.reduce((a1, c1) => {
    if (c1?.gender === gender) {
      return a1 + c1?.point1;
    }
    return a1;
  }, 0);
  const point2 = stats.data?.for_gender?.reduce((a1, c1) => {
    if (c1?.gender === gender) {
      return a1 + c1?.point2;
    }
    return a1;
  }, 0);

  const point3 = stats.data?.for_gender?.reduce((a1, c1) => {
    if (c1?.gender === gender) {
      return a1 + c1?.point3;
    }
    return a1;
  }, 0);

  const point4 = stats.data?.for_gender?.reduce((a1, c1) => {
    if (c1?.gender === gender) {
      return a1 + c1?.point4;
    }
    return a1;
  }, 0);

  const point5 = stats.data?.for_gender?.reduce((a1, c1) => {
    if (c1?.gender === gender) {
      return a1 + c1?.point5;
    }
    return a1;
  }, 0);

  const total = point1 + point2 + point3 + point4 + point5;

  return total > 0 ? (
    <ChartWrapper
      title={title}
      titleAlign="center"
      flex={1}
      chart={
        <Chart
          type="doughnut"
          width={300}
          height={300}
          options={{
            responsive: false,
            legend: {
              position: 'bottom',
            },
          }}
          data={{
            labels: ['1점', '2점', '3점', '4점', '5점'],
            datasets: [
              {
                backgroundColor: ['#ff7f00', '#FFFF00', '#008000', '#0000FF', '#8B00FF'],
                data: [point1, point2, point3, point4, point5],
              },
            ],
          }}
        />
      }
      {...props}
    />
  ) : (
    <ChartWrapper
      title={title}
      titleAlign="center"
      flex={1}
      chart={
        <Chart
          type="doughnut"
          width={300}
          height={300}
          options={{
            responsive: false,
            legend: {
              position: 'bottom',
            },
          }}
          data={{
            labels: ['1점', '2점', '3점', '4점', '5점'],
            datasets: [
              {
                backgroundColor: ['#ff7f00', '#FFFF00', '#008000', '#0000FF', '#8B00FF'],
                data: [0, 0, 0, 0, 0],
              },
            ],
          }}
        />
      }
      {...props}
    />
  );
};

export default DoughnutChart2;
