import React from 'react';
import { Badge, Calendar } from 'antd';
import moment from 'moment';
import ko_KR from 'moment/locale/ko';
import { dayFormat } from '../../common/utils';

/**
 * 주문상세 페이지 달력 컴포넌트
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const SubscribeCalendar = ({ type, dates, repeat }) => {
  const [currentDate, setCurrentDate] = React.useState(moment().format('YYYYMM'));
  // const [startDate] = workDate ?? [];
  const subscribeDate = React.useMemo(() => {
    const items = {};

    for (const date of dates) {
      items[moment(date.begin_expected_at).format('YYYY-MM-DD')] = {
        text: '수거',
      };
      items[moment(date.work_date).format('YYYY-MM-DD')] = {
        text: '준비',
      };
      items[moment(date.finish_expected_at).format('YYYY-MM-DD')] = {
        text: '배송',
      };
    }
    //
    //   if (type === 'DT') {
    //     const date = moment(startDate);
    //

    //

    //

    //   }
    //
    //   if (type === 'SC') {
    //     const _selectedDate = moment(startDate);
    //     const _currentDate = moment(currentDate);
    //     const _date =
    //       _selectedDate.month() === _currentDate.month() ? _selectedDate : _currentDate.day(_selectedDate.day());
    //
    //     // let i = +_date.format('YYYYMMDD');
    //     for (let i = 0; i < repeat; i++) {
    //       // if (_selectedDate.format('YYYYMM') > _lastDayOfMonth.format('YYYYMM')) {
    //       //   break;
    //       // }
    //       items[_date.format('YYYY-MM-DD HH:mm')] = {
    //         text: '준비',
    //       };
    //       items[_date.add(1, 'd').format('YYYY-MM-DD HH:mm')] = {
    //         text: '수거',
    //       };
    //       items[_date.add(1, 'd').format('YYYY-MM-DD HH:mm')] = {
    //         text: '배송',
    //       };
    //       _date.add(5, 'd');
    //       // i += 7;
    //     }
    //   }
    //
    return items;
  }, [dates, currentDate]);

  const dateCellRender = (date) => {
    const { text } = subscribeDate?.[date.format('YYYY-MM-DD')] ?? {};
    return (
      text && (
        <ul className="events" style={{ padding: 0, margin: 0, listStyleType: 'none' }}>
          <li>
            <Badge status="success" text={text} />
          </li>
        </ul>
      )
    );
  };

  const monthCellRender = (date) => {
    const days = Array.from(Array(date.daysInMonth()), (_, i) => i + 1);
    const events = days
      ?.map((x) => ({
        event: subscribeDate?.[`${date.format('YYYY-MM')}-${dayFormat(x)}`]?.text,
        day: `${date.format('YYYY-MM')}-${dayFormat(x)}`,
      }))
      .filter((x) => x.event);
    return (
      events.length > 0 && (
        <ul className="events" style={{ padding: 0, margin: 0, listStyleType: 'none' }}>
          {events?.map((x) => (
            <li>
              <Badge status="success" text={`${x?.day} / ${x?.event}`} />
            </li>
          ))}
        </ul>
      )
    );
  };

  return (
    <Calendar
      locale={ko_KR}
      onChange={(e) => {
        setCurrentDate(moment(e).format('YYYYMM'));
      }}
      dateCellRender={dateCellRender}
      monthCellRender={monthCellRender}
    />
  );
};

export default SubscribeCalendar;
