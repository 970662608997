import React from 'react';
import { PageHeader, Table } from 'antd';
import { pushSearchField } from '../../../common/searchFields';
import SearchForm from '../../../components/datalist/SearchForm';
import TableButtonWrap from '../../../components/TableButtonWrap';
import moment from 'moment';
import { getAPIHost, restApi } from '../../../apis';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import fileDownload from 'js-file-download';
import { pushTargettingStates, pushTargettingTypes } from '../../../apis/pushes';
import { updatePost } from '../../../apis/posts';
import Checkbox from 'antd/es/checkbox/Checkbox';
import DraggableEditableTableLastIndex from '../../../components/DraggableEditableTableLastIndex';

/**
 * 쿠폰관리 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const ManualIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);

  const [data, setData] = React.useState();

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      ...query,
      title: query?.keyword,
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.title = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    }

    const { data } = await restApi.get('/manuals', {
      params,
    });
    setData(data);
  }, [query]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  const columns = [
    {
      title: '번호',
      dataIndex: 'id',
      width: 90,
      key: 'id',
      align: 'center',
      render: (_, _2, i) => {
        return data?.length - i;
      },
    },
    {
      title: '타겟',
      dataIndex: 'prefix',
      align: 'center',
      render: (v) => (v === 'V' ? '기업회원' : '일반'),
      width: 140,
    },
    {
      title: '연결타입',
      dataIndex: 'type_key',
      align: 'center',
      width: 140,
    },
    {
      title: '제목',
      dataIndex: 'name',
      align: 'left',
    },
    {
      title: '노출허용',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      width: 80,
      render: (value, record) => {
        const { id } = record ?? {};
        const checked = value;
        const onClick = async () => {
          if (!window.confirm('변경하시겠습니까?')) {
            return;
          }
          await restApi.put(`/manuals/${id}/active`, { active: !checked });
          // await updatePost({ id, active: !checked });
          await loadData();
        };

        return <Checkbox checked={checked} onClick={onClick} />;
      },
    },
    {
      title: '등록일',
      width: 180,
      dataIndex: 'created_at',
      align: 'center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm'),
    },
    {
      title: '자세히보기',
      width: 120,
      dataIndex: 'id',
      align: 'center',
      render: (data) => (
        <Link to={`/settings/manuals/details/${data}`}>
          <a style={{ color: 'black' }}>자세히보기</a>
        </Link>
      ),
    },
  ];

  const xlsxSave = () => {
    const args = {
      ...query,
    };
    restApi
      .get(`${getAPIHost()}/exports/manuals?${qs.stringify(args)}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `매뉴얼_${moment().format('YYYYMMDDHHmmss')}.xlsx`);
      });
  };

  const handleMoveRow = async (as, to) => {
    const _data = [...data];
    _data.splice(to, 0, ..._data.splice(as, 1));
    const body = _data?.reduce((p, c, i) => [...p, c?.id], []);
    try {
      const { data } = await restApi.put('/manuals/seq', {
        ids: body,
      });
      await loadData();
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="매뉴얼 조회" />
      <div style={{ position: 'relative' }}>
        <DraggableEditableTableLastIndex
          scroll={{ x: 'max-content' }}
          rowKey={({ id }) => id}
          originData={data}
          originColumns={columns}
          pagination={false}
          onMoveRow={handleMoveRow}
          editable={true}
        />
        <div className={'tableWrapCover'}>
          <TableButtonWrap
            position="left"
            buttons={[{ icon: require('../../../assets/icon_save.png'), label: '엑셀 저장', onClick: xlsxSave }]}
          />
          <TableButtonWrap
            position="right"
            buttons={[
              { icon: require('../../../assets/icon_write.png'), label: '작성', link: '/settings/manuals/create' },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default ManualIndex;
