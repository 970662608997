import React from 'react';
import moment from 'moment';
import ChartWrapper from '../ChartWrapper';
import Chart from 'react-chartjs-2';
import {getStats} from "../../apis/stats";
import {pointCut} from "../../common/utils";

/**
 * 통계페이지 원형 차트 컴포넌트
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const DoughnutChart = ({
  title,
  type,
  gender,
  dateRange = [moment().startOf('month'), moment().endOf('month')],
  ...props
}) => {
  const stats = props?.stats
  const cleaningCount = stats.data?.for_gender?.reduce((a1, c1) => {
    if (c1?.gender === gender) {
      return a1 + c1?.clean;
    }
    return a1;
  }, 0);
  const repairCount = stats.data?.for_gender?.reduce((a1, c1) => {
    if (c1?.gender === gender) {
      return a1 + c1?.fix;
    }
    return a1;
  }, 0);

  const loadData = async () => {
    if (stats?.data?.for_date) return;
    try {
      const params = {
        type,
      };
      stats.loadData(
        getStats({
          ...params,
          date: dateRange[0] && moment(dateRange[0]).format('YYYY-MM'),
        }),
      );
    } catch (error) {
      console.warn(error);
      // alert(error?.response?.data?.message);
    }
  };

  React.useEffect(() => {
    loadData();
  }, [dateRange]);

  const total = cleaningCount + repairCount;
  const percent = (par) => {
    // return Number(12.7);
    return pointCut(Number(par / total) * 100, 2);
  };

  return total > 0 ? (
    <ChartWrapper
      title={title}
      titleAlign="center"
      flex={1}
      chart={
        <Chart
          type="doughnut"
          width={300}
          height={300}
          options={{
            responsive: false,
            legend: {
              position: 'bottom',
            },
          }}
          data={{
            labels: ['광택', '수선'],
            datasets: [
              {
                backgroundColor: ['#47bebc', '#f3cb00'],
                data: [cleaningCount, repairCount],
              },
            ],
          }}
        />
      }
      cleaningCount={`${percent(cleaningCount)}%`}
      repairCount={`${percent(repairCount)}%`}
      {...props}
    />
  ) : (
    <ChartWrapper
      title={title}
      titleAlign="center"
      flex={1}
      chart={
        <Chart
          type="doughnut"
          width={300}
          height={300}
          options={{
            responsive: false,
            legend: {
              position: 'bottom',
            },
          }}
          data={{
            labels: ['광택', '수선'],
            datasets: [
              {
                backgroundColor: ['#47bebc', '#f3cb00'],
                data: [0, 0],
              },
            ],
          }}
        />
      }
      cleaningCount={'0%'}
      repairCount={'0%'}
      {...props}
    />
  );
};

export default DoughnutChart;
