import { restApi } from '../index';

export const getPosts = (body) => {
  const endpoint = `/posts`;
  return restApi.get(endpoint, body);
};

export const getPost = (id) => {
  const endpoint = `/posts/${id}`;
  return restApi.get(endpoint);
};

export const createPost = ({ type, title, contents, mainImage, images, active, role_type }) => {
  const endpoint = '/posts';
  const requestBody = {
    type,
    title,
    contents,
    main_image: mainImage,
    images,
    active,
    role_type: role_type || undefined,
  };
  return restApi.post(endpoint, requestBody);
};

export const readPost = ({ id, type, page, limit = 20, sort, dir, start_at, end_at }) => {
  const endpoint = '/posts';
  if (id) {
    return restApi.get(`${endpoint}/${id}`);
  }
  const params = {
    type,
    page,
    limit,
    sort,
    dir,
    start_at,
    end_at,
  };
  return restApi.get(endpoint, { params });
};

export const updatePost = ({ id, type, title, contents, mainImage, images, active, created_at }) => {
  const endpoint = `/posts/${id}`;
  const requestBody = {
    type,
    title,
    contents,
    main_image: mainImage,
    images,
    active,
    created_at,
  };
  return restApi.put(endpoint, requestBody);
};

export const deletePost = (id) => {
  const endpoint = `/posts/${id}`;
  return restApi.delete(endpoint);
};
