import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Space } from 'antd';
import { restApi } from '../../../apis';
import FileUpload from '../../../components/FileUpload';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * 상품 설정 1
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const PCategory1Form = ({ type, formType, onExit, onSubmitted }) => {
  const visible = formType !== undefined;
  const [data, setData] = useState({
    type,
    code: '',
    name: '',
    depth: 0,
    extra1: '',
    extra2: '',
    extra3: '',
    color: '',
    images: [],
  });

  const { categories } = useSelector((s) => s.product, shallowEqual);

  useEffect(() => {
    if (!formType) {
      setData({
        type,
        code: '',
        name: '',
        depth: 0,
        extra1: '',
        extra2: '',
        extra3: '',
        color: '',
        images: [],
      });
      return;
    }
    const item = categories[type].find((v) => v.id === formType);
    setData(item);
  }, [formType, type]);

  const handleSubmit = async () => {
    // 사원번호 이름 센터 부서 직급 연락처 아이디 비밀번호
    if (!data.name) {
      alert('이름을 입력해주세요.');
      return;
    }

    if (formType === 0) {
      // new..
      await restApi.post(`/products/categories`, data);
    } else {
      await restApi.put(`/products/categories/${formType}`, data);
    }
    onSubmitted();
  };

  const handleDelete = async () => {
    if (!window.confirm('삭제하시겠습니끼?')) return;
    await restApi.delete(`/products/categories/${formType}`);
    onSubmitted();
  };

  return (
    <Modal visible={visible} onOk={handleSubmit} onCancel={onExit} footer={false} width={500} centered destroyOnClose>
      <h4>카테고리</h4>
      <Form>
        <div style={{ paddingTop: 30, paddingBottom: 20 }}>
          <Form.Item label={'코드'} style={{ marginBottom: 15 }}>
            <Input
              value={data.code}
              onChange={(e) => {
                const v = e.target.value;
                setData((x) => ({ ...x, code: v }));
              }}
              placeholder="알파벳한자리"
              type="text"
            />
          </Form.Item>

          <Form.Item label={'이름'} style={{ marginBottom: 15 }}>
            <Input
              value={data.name}
              onChange={(e) => {
                const v = e.target.value;
                setData((x) => ({ ...x, name: v }));
              }}
              placeholder="이름"
              type="text"
            />
          </Form.Item>

          <Form.Item label={'컬러'} style={{ marginBottom: 15 }}>
            <Input
              value={data.color}
              onChange={(e) => {
                const v = e.target.value;
                setData((x) => ({ ...x, color: v }));
              }}
              placeholder="컬러 6비트"
              type="text"
            />
          </Form.Item>
          <Form.Item label={'단어'} style={{ marginBottom: 15 }}>
            <Input
              value={data.extra2}
              onChange={(e) => {
                const v = e.target.value;
                setData((x) => ({ ...x, extra2: v }));
              }}
              placeholder="구두, 운동화"
              type="text"
            />
          </Form.Item>
          <Form.Item label={'상단설명'} style={{ marginBottom: 15 }}>
            <Input.TextArea
              value={data.extra1}
              onChange={(e) => {
                const v = e.target.value;
                setData((x) => ({ ...x, extra1: v }));
              }}
              maxLength={255}
              type="text"
            />
          </Form.Item>
          <Form.Item label={'하단 텍스트설명'} style={{ marginBottom: 15 }}>
            <Input.TextArea
              value={data.extra3}
              onChange={(e) => {
                const v = e.target.value;
                setData((x) => ({ ...x, extra3: v }));
              }}
              maxLength={255}
              type="text"
            />
          </Form.Item>
          <Form.Item label={'미리보기 이미지'} style={{ marginBottom: 15 }}>
            <FileUpload
              maxCount={4}
              values={data.images}
              onChange={(images) => {
                setData((x) => ({ ...x, images }));
              }}
            />
          </Form.Item>
        </div>
        <div style={{ textAlign: 'right' }}>
          <Space>
            <Button primary onClick={handleSubmit}>
              저장
            </Button>
            {!!formType && (
              <Button danger onClick={handleDelete}>
                삭제
              </Button>
            )}
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default PCategory1Form;
