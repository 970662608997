import React from 'react';
import VendorDetailsMenu, { useGetVendor } from './menu';
import VendorsCreate from '../create';

/**
 * 기업 정보
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const VendorsDetailsInfo = (props) => {
  const vendor = useGetVendor();
  return (
    <div>
      <VendorDetailsMenu index={0} />
      <VendorsCreate vid={vendor?.id} title={'기업 정보'} />
    </div>
  );
};

export default VendorsDetailsInfo;
