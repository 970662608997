import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Calendar, Col, Modal, PageHeader, Row, Space, Table } from 'antd';
import moment from 'moment';
import { restApi } from '../../../apis';
import { phoneFormat } from '../../../common/utils';
import SearchUserBox from '../order/details/SearchUserBox';

const columns = [
  {
    key: 'id',
    dataIndex: 'purchase',
    title: '주문번호',
    width: 80,
    align: 'center',
    render: (d) => d.id,
  },
  {
    key: 'name',
    dataIndex: 'purchase',
    title: '이름',
    width: 90,
    align: 'center',
    render: (d) => {
      return d?.account?.nickname;
    },
  },
  {
    key: 'phone',
    dataIndex: 'purchase',
    title: '전화번호',
    width: 140,
    align: 'center',
    render: (d) => {
      return phoneFormat(d?.account?.phone);
    },
  },
  {
    key: 'address',
    dataIndex: 'purchase',
    title: '주소',
    width: 250,
    render: (d) => {
      return d?.address;
    },
  },
  {
    key: 'date',
    title: '수거/배송일자',
    width: 170,
    render: (d) => {
      return moment(d).format('YYYY-MM-DD HH:mm');
    },
  },
  {
    key: 'ctrl',
    dataIndex: 'purchase',
    title: ' ',
    width: 80,
    fixed: 'right',
    render: (d) => {
      return (
        <Button onClick={() => (window.location.href = '/work/order/details/' + d.id)} size={'small'}>
          자세히
        </Button>
      );
    },
  },
];
/**
 * 작업 MWS
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const CalendarIndex = (props) => {
  const [value, setValue] = useState(moment());
  const [focusDate, setFocusDate] = useState();
  const [data, setData] = useState();
  const [details, setDetails] = useState();
  const [postmanIds, setPostmanIds] = useState({});

  const loadData = useCallback(async () => {
    const start = value.clone().startOf('months').startOf('weeks');
    const close = value.clone().startOf('months').add(5, 'week').endOf('weeks');

    const { data } = await restApi.get(`/schedules`, {
      params: { s: start.format('YYYY-MM-DD'), e: close.format('YYYY-MM-DD') },
    });
    setData(data);
  }, [value]);

  const handleSubmit = async () => {
    try {
      for (const idx of Object.keys(postmanIds)) {
        await restApi.put(`/schedules/${focusDate}`, {
          postman: postmanIds[idx],
          idx,
        });
      }
      await loadData();
      alert('저장되었습니다.');
      setFocusDate(undefined);
    } catch (e) {
      alert('서버연결실패');
    }
  };

  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  useEffect(() => {
    if (!focusDate) {
      setDetails(undefined);
      return;
    }

    const loadDetails = async () => {
      const { data } = await restApi.get(`/schedules/${focusDate}`);
      setDetails(data);
      setPostmanIds(data.users);
    };
    loadDetails().catch(console.warn);
  }, [focusDate]);

  const columns1 = useMemo(() => {
    const o = columns?.map((v) => ({ ...v }));
    o[4].dataIndex = 'begin_expected_at';
    return o;
  }, []);

  const columns2 = useMemo(() => {
    const o = columns?.map((v) => ({ ...v }));
    o[4].dataIndex = 'finish_expected_at';
    return o;
  }, []);

  return (
    <div>
      <Modal
        visible={!!focusDate}
        title={`${focusDate} 업무일지`}
        width={1400}
        footer={null}
        onCancel={() => setFocusDate(undefined)}
      >
        <Row gutter={20}>
          <Col span={12} xs={24}>
            <h3>수거</h3>
            <Table
              dataSource={details?.gets || []}
              columns={columns1}
              scroll={{ x: 'max-content' }}
              pagination={false}
            />
          </Col>
          <Col span={12} xs={24}>
            <h3>배송</h3>
            <Table
              dataSource={details?.sends || []}
              columns={columns2}
              scroll={{ x: 'max-content' }}
              pagination={false}
            />
          </Col>
        </Row>
        <div style={{ marginTop: 30 }}>
          <Space className={"popspace"}>
            <div style={{ minWidth: 200 }}>
              <SearchUserBox
                placeholder={'주 당직자'}
                type={'T'}
                value={postmanIds[`0`]}
                onChange={(v) => {
                  setPostmanIds((x) => ({ ...x, 0: v }));
                }}
              />
            </div>
            <div style={{ minWidth: 200 }}>
              <SearchUserBox
                placeholder={'부 당직자1'}
                type={'T'}
                value={postmanIds['1']}
                onChange={(v) => {
                  setPostmanIds((x) => ({ ...x, 1: v }));
                }}
              />
            </div>
            <div style={{ minWidth: 200 }}>
              <SearchUserBox
                placeholder={'부 당직자2'}
                type={'T'}
                value={postmanIds['2']}
                onChange={(v) => {
                  setPostmanIds((x) => ({ ...x, 2: v }));
                }}
              />
            </div>
            <Button onClick={handleSubmit}>저장</Button>
          </Space>
        </div>
      </Modal>
      <PageHeader className="site-page-header" title="일정관리" />
      <div style={{ width: '100%', overflowX: 'auto' }}>
        <div style={{ minWidth: 900 }}>
          <Calendar
            value={value}
            onChange={setValue}
            dateCellRender={(value) => {
              const dt = value?.format('YYYY-MM-DD');
              return (
                <ul key={dt} style={{ listStyleType: 'none', padding: 0, margin: 0 }} onClick={() => setFocusDate(dt)}>
                  <li>수거 : {data?.[dt]?.get ?? 0}건</li>
                  <li>배송 : {data?.[dt]?.send ?? 0}건</li>
                  <li>
                    당직자 :{' '}
                    {Object.values(data?.[dt]?.users ?? {})
                      ?.map((v) => v?.nickname)
                      ?.join(', ') ?? ''}
                  </li>
                </ul>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarIndex;
