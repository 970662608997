import React, { useEffect, useState } from 'react';
import { restApi } from '../../../../apis';
import { Select } from 'antd';

/**
 * 작업 검색
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const SearchCenterBox = ({ type, workmode, value, onChange, placeholder }) => {
  const [data, setData] = useState();
  const [display, setDisplay] = useState();

  useEffect(() => {
    const loadValue = async () => {
      if (display?.id != value) {
        const { data } = await restApi.get(`/centers/${value}`);
        console.log(data);
        setDisplay(data);
      }
    };
    loadValue().catch(console.warn);
  }, [display, value]);

  const handleSearch = async (keyword) => {
    const { data } = await restApi.get(`/centers`, { params: { type, keyword, work_type: workmode } });
    setData(data);
  };

  const handleChange = (v) => {
    setDisplay(data?.items?.find((x) => x.id === v));
    onChange(v);
  };

  const options = (
    !display
      ? []
      : [
          <Select.Option key={String(display?.id)} value={display?.id}>
            {display?.name} ({display?.address})
          </Select.Option>,
        ]
  ).concat(
    data?.items
      ?.filter((v) => v.id != display?.id)
      ?.map((d) => (
        <Select.Option key={String(d.id)} value={d.id}>
          {d.name} ({d.address})
        </Select.Option>
      )) ?? [],
  );

  return (
    <Select
      showSearch
      style={{ width: '100%' }}
      value={value}
      placeholder={placeholder}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
    >
      {options}
    </Select>
  );
};

export default SearchCenterBox;
