import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, Modal, PageHeader, Row, Select, Space, Spin, Table } from 'antd';
import moment from 'moment';
import { getPayname, NEW_RUN_STATE, PaymentType, PURCHASE_STATE_TEXT } from '../../../../common/defines';
import styles from './OrderDetail.module.less';
import { addComma, imagesArchive, parseImageUrl, phoneFormat, toCommaNumber } from '../../../../common/utils';
import Gallery from '../../../../components/ImageGallery';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../../apis';
import SubscribeCalendar from '../../../../components/SubscribeCalendar/SubscribeCalendar';
import Postcode from '@actbase/react-daum-postcode';
import PurchaseDateItem from './PurchaseDateItem';
import TsStatusCtrl from './TSStatusCtrl';
import PaymentInfo from './PaymentInfo';

const DELIVERS = [
  'CJ대한통운',
  '로젠택배',
  '옐로우캡',
  '우체국택배',
  '한진택배',
  '롯데택배',
  '드림택배',
  'KGB택배',
  '대신택배',
  '일양로지스',
  '경동택배',
  '천일택배',
  '합동택배',
  'CVSnet편의점택배',
  '우편발송',
  'GTX 로지스',
  '건영택배',
  '드림택배',
  'EMS',
  'DHL',
  'FedEx',
  'UPS',
  'USPS',
  'TNT Express',
  '한의사랑택배',
  'GSMNtoN(인로스)',
  '에어보이익스프레스',
  'DHL Global Mail',
  'i-Paracel',
  '범한판토스',
  '굿투럭',
];

/**
 * 배달 상세 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const OrderDetails = () => {
  const { id } = useParams();

  const [data, setData] = React.useState();
  const [products, setProducts] = React.useState();
  const [isVisible, setIsVisible] = React.useState(false);
  const navigate = useNavigate();

  const [input, setInput] = useState({
    address: data?.address,
    address_details: data?.address_details,
    ent_password: data?.ent_password,
  });

  const paydat = data?.payment ?? { originPrice: 0, payed_price: 0 };

  const [payment, setPayment] = useState();

  const loadData = async () => {
    await restApi.put(`/purchases/${id}/read`);
    const { data: products } = await restApi.get(`/products`);
    setProducts(products);
    const { data } = await restApi.get(`/purchases/${id}`);
    setData(data);
    setInput({
      address: data?.address,
      address_details: data?.address_details,
      ent_password: data?.ent_password,
    });
    const { data: payment } = await restApi.get(`/payments/${data.payment.id}`);
    setPayment(payment);
  };

  React.useEffect(() => {
    loadData().catch(console.warn);
  }, [id]);

  const handleImageDownload = async () => {
    const fname = `고객주문_${data.id}_${data?.account?.nickname || '탈퇴회원'}님_업로드`;
    await imagesArchive(data?.images, fname);
  };

  const handleWorkImageDownload = async (states, name) => {
    const fname = `고객주문_${data.id}_${data?.account?.nickname || '탈퇴회원'}님_${name}_사진`;
    const histories = data.histories
      ?.filter((v) => states.includes(v.state))
      ?.reduce((a, c) => a.concat(c.images), [])
      ?.filter((v) => !!v);

    if (!histories.length) {
      alert('등록된 이미지가 없습니다.');
      return;
    }

    await imagesArchive(histories, fname);
  };

  const columns = [
    {
      title: '이미지',
      dataIndex: 'images',
      align: 'center',
      width: 150,
      render: (data) => {
        const [thumbnail] = data ?? [];
        return (
          <>
            <Gallery
              onCancel={() => setIsVisible(false)}
              isVisible={isVisible}
              images={data?.map((x) => {
                return {
                  url: parseImageUrl(x),
                };
              })}
            />
            <div className="thumb">
              {thumbnail ? (
                <img
                  src={parseImageUrl(thumbnail)}
                  style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  alt="thumbnail"
                  onClick={() => setIsVisible(true)}
                />
              ) : (
                <span>이미지없음</span>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: '주문정보',
      dataIndex: 'images',
      align: 'left',
      render: (data, record) => {
        return (
          <div className="complex-cell">
            <div className="contents">
              <label>[{PaymentType[record?.type]}]</label>
              {record?.product_name ? (
                <h4>{record?.product_name}</h4>
              ) : (
                <>
                  <h4>{record?.gender === 'X' ? '운동화' : record?.gender === 'M' ? '남자구두' : '여자구두'}</h4>
                  <span>
                    {products
                      ?.filter((x) => record?.products?.includes(x.id))
                      ?.map((v, ix) => (
                        <div key={`${ix}`}>
                          <span>{v.name} - </span>
                          <span>{addComma(String(v.price))}</span>
                        </div>
                      ))}
                  </span>
                </>
              )}
            </div>
            <div>
              <Space>
                <Button
                  size={'small'}
                  style={{ marginTop: 20, marginBottom: 5, width: '100%' }}
                  onClick={handleImageDownload}
                >
                  고객 사진
                </Button>
                <Button
                  size={'small'}
                  style={{ marginTop: 20, marginBottom: 5, width: '100%' }}
                  onClick={() => handleWorkImageDownload(['GET_ITEM'], '야간기사_수거')}
                >
                  수거 사진
                </Button>
                <Button
                  size={'small'}
                  style={{ marginTop: 20, marginBottom: 5, width: '100%' }}
                  onClick={() => handleWorkImageDownload(['FIX_READY'], '주간기사_분배')}
                >
                  분배 사진
                </Button>
                <Button
                  size={'small'}
                  style={{ marginTop: 20, marginBottom: 5, width: '100%' }}
                  onClick={() => handleWorkImageDownload(['FIX_ING', 'OUT_FIXER'], '장인')}
                >
                  장인 작업 사진
                </Button>
                <Button
                  size={'small'}
                  style={{ marginTop: 20, marginBottom: 5, width: '100%' }}
                  onClick={() => handleWorkImageDownload(['IN_CENTER', 'IN_FIXER', 'OUT_CENTER', 'SENDING'], '센터')}
                >
                  센터 사진
                </Button>
                <Button
                  size={'small'}
                  style={{ marginTop: 20, marginBottom: 5, width: '100%' }}
                  onClick={() => handleWorkImageDownload(['PREV_SENDING'], '주간기사_회수')}
                >
                  회수 사진
                </Button>
                <Button
                  size={'small'}
                  style={{ marginTop: 20, marginBottom: 5, width: '100%' }}
                  onClick={() => handleWorkImageDownload(['SEND_USER'], '야간기사_배송')}
                >
                  배송 사진
                </Button>
              </Space>
            </div>
          </div>
        );
      },
    },
    {
      title: '주문 수량',
      dataIndex: 'shoes_count',
      align: 'center',
      render: (data) => toCommaNumber(data),
    },
    {
      title: '결제금액',
      dataIndex: 'sale_price',
      align: 'center',
      width: 150,
      render: (data) => <span>{toCommaNumber(data)}원</span>,
    },

    {
      title: '진행현황',
      dataIndex: 'run_state',
      align: 'center',
      className: 'color-cell',
      width: 120,
      render: (data) => NEW_RUN_STATE[data],
    },
    // {
    //   title: '남은 횟수',
    //   dataIndex: 'count',
    //   align: 'center',
    //   className: 'color-cell',
    //   width: 130,
    //   render: (x, r) => {
    //     if (r?.type === PaymentType.SUBSCRIBE) {
    //       return 3 / 4;
    //     }
    //   },
    // },
  ];

  const histories = React.useMemo(() => {
    const o = [...(data?.histories || [])];
    return o.reverse();
  }, [data?.histories]);

  const handleSubmit = async () => {
    await restApi.put(`/cms/purchases/${id}`, input);
    await loadData();
    alert('수정되었습니다.');
  };

  const [showPostcode, setShowPostcode] = useState();

  const [dateArgs, setDateArgs] = useState({});
  const handleDateUpdate = async (key) => {
    const dt = dateArgs[`${key}`]?.format('YYYY-MM-DD');
    await restApi.put(`/cms/purchases/dates/${key}`, { begin_expected_at: dt });
    await loadData();
    alert('수정되었습니다.');
  };

  const [showVCancel, setShowVCancel] = useState();
  const handleCancel = () => {
    if (data.payment?.payed_json?.pay_method === 'vbank') {
      setShowVCancel({});
      return;
    }
    if (!window.confirm('취소하시겠습니까?')) return;
    restApi.delete(`/purchases/${data.id}`).then((r) => {
      loadData().catch(console.warn);
      alert('취소처리 되었습니다.');
    });
  };
  const handleCancel2 = () => {
    if (!window.confirm('취소하시겠습니까?')) return;
    restApi.delete(`/purchases/${data.id}`, { params: showVCancel }).then((r) => {
      loadData().catch(console.warn);
      setShowVCancel(undefined);
      alert('취소처리 되었습니다.');
    });
  };

  const [showImages, setShowImages] = useState([]);

  console.log({data})
  return !data ? (
    <div style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <Spin />
    </div>
  ) : (
    <div>
      <Modal
        visible={!!showImages?.length}
        title={'이미지보기'}
        width={500}
        footer={null}
        bodyStyle={{ padding: 0 }}
        onCancel={() => setShowImages([])}
      >
        {showImages?.map((v) => (
          <img src={parseImageUrl(v)} style={{ maxWidth: '100%' }} />
        ))}
      </Modal>
      <Modal
        title={'무통장 취소'}
        visible={!!showVCancel}
        onCancel={() => setShowVCancel(undefined)}
        onOk={handleCancel2}
        destroyOnClose
      >
        <Form>
          <Form.Item label={'은행'} style={{ marginBottom: 10 }}>
            <Select
              value={showVCancel?.bank}
              onChange={(value) => {
                setShowVCancel((x) => ({ ...x, bank: value }));
              }}
            >
              <Select.Option value={'BK04'}>KB국민은행</Select.Option>
              <Select.Option value={'BK23'}>SC제일은행</Select.Option>
              <Select.Option value={'BK39'}>경남은행</Select.Option>
              <Select.Option value={'BK34'}>광주은행</Select.Option>
              <Select.Option value={'BK03'}>기업은행</Select.Option>
              <Select.Option value={'BK11'}>농협</Select.Option>
              <Select.Option value={'BK31'}>대구은행</Select.Option>
              <Select.Option value={'BK32'}>부산은행</Select.Option>
              <Select.Option value={'BK02'}>산업은행</Select.Option>
              <Select.Option value={'BK45'}>새마을금고</Select.Option>
              <Select.Option value={'BK07'}>수협</Select.Option>
              <Select.Option value={'BK88'}>신한은행</Select.Option>
              <Select.Option value={'BK48'}>신협</Select.Option>
              <Select.Option value={'BK05'}>외환은행</Select.Option>
              <Select.Option value={'BK20'}>우리은행</Select.Option>
              <Select.Option value={'BK71'}>우체국</Select.Option>
              <Select.Option value={'BK37'}>전북은행</Select.Option>
              <Select.Option value={'BK16'}>축협</Select.Option>
              <Select.Option value={'BK90'}>카카오뱅크</Select.Option>
              <Select.Option value={'BK89'}>케이뱅크</Select.Option>
              <Select.Option value={'BK81'}>하나은행(서울은행)</Select.Option>
              <Select.Option value={'BK53'}>한국씨티은행(한미은행)</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={'계좌번호'} style={{ marginBottom: 10 }}>
            <Input
              value={showVCancel?.account}
              onChange={(e) => {
                const value = e.target.value;
                setShowVCancel((x) => ({ ...x, account: value }));
              }}
            />
          </Form.Item>
          <Form.Item label={'예금주'} style={{ marginBottom: 10 }}>
            <Input
              value={showVCancel?.holder}
              onChange={(e) => {
                const value = e.target.value;
                setShowVCancel((x) => ({ ...x, holder: value }));
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={showPostcode}
        title={'우편번호찾기'}
        width={500}
        footer={null}
        bodyStyle={{ padding: 0 }}
        onCancel={() => setShowPostcode(false)}
      >
        <Postcode
          onSelected={(e) => {
            setInput((x) => ({ ...x, address: e.roadAddress }));
            setShowPostcode(false);
          }}
        />
      </Modal>
      <PageHeader
        className="site-page-header"
        title="주문내역"
        extra={[<Button onClick={() => navigate(-1)}>목록</Button>]}
      />
      <div className={styles.borderBlock}>
        <div className={styles.item}>
          <label>주문일시</label>
          <span>{moment(data?.created_at).format('YYYY.MM.DD')}</span>
        </div>
        <div className={styles.item}>
          <label>구분</label>
          <span>{data?.memo === 'B2B 주문' ? '아파트 배송' : PaymentType[data?.type]}</span>
        </div>
        <div className={styles.item}>
          <label>주문번호</label>
          <span>{data?.id}</span>
        </div>
        <div className={styles.item}>
          <label>주문상태</label>
          <span>
            {PURCHASE_STATE_TEXT[data?.state]}
            {!!data?.payment?.cancelled_at && (
              <small> ({moment(data?.payment?.cancelled_at).format('MM-DD HH:mm')})</small>
            )}
          </span>
        </div>
        <div style={{ flex: 1, textAlign: 'right' }}>
          {data?.state === 'BUYED' && (
            <Button onClick={handleCancel} danger>
              주문취소
            </Button>
          )}
        </div>
      </div>

      <Row gutter={10}>
        <Col span={12} xs={24}>
          <Table
            scroll={{ x: 'max-content' }}
            className="product-table"
            columns={columns}
            dataSource={[data]}
            bordered
            pagination={false}
          />
        </Col>
        <Col span={12} xs={24}>
          <div>
            <div className={styles.tableStyleBox}>
              <ul className={styles.tableContentList}>
                <li>
                  <label>수령인</label>
                  <span>{data?.account?.nickname ?? '탈퇴회원'}</span>
                </li>
                <li>
                  <label>연락처</label>
                  <span>{phoneFormat(data?.account?.phone)}</span>
                </li>
                {data?.address_memo && (
                  <li>
                    <label>배송메모</label>
                    <span>{data?.address_memo}</span>
                  </li>
                )}
                <li>
                  <label>배송지</label>
                  <div>
                    <Input
                      style={data?.address !== input?.address ? { borderColor: '#db2929' } : {}}
                      value={input?.address}
                      onChange={(e) => {
                        setInput((x) => ({ ...x, address: e.target.value }));
                      }}
                      readOnly={true}
                      onClick={() => setShowPostcode(true)}
                    />
                  </div>
                </li>
                <li>
                  <label>배송지 상세주소</label>
                  <div>
                    <Input
                      style={data?.address_details !== input?.address_details ? { borderColor: '#db2929' } : {}}
                      value={input?.address_details}
                      onChange={(e) => {
                        setInput((x) => ({ ...x, address_details: e.target.value }));
                      }}
                    />
                  </div>
                </li>
                <li style={{ height: 'auto', minHeight: 30 }}>
                  <label>요청 사항</label>
                  <div>{data?.memo}</div>
                </li>
                <li>
                  <label>현관비번</label>
                  <div>
                    <Input
                      style={data?.ent_password !== input?.ent_password ? { borderColor: '#db2929' } : {}}
                      value={input?.ent_password}
                      placeholder={'없음'}
                      onChange={(e) => {
                        setInput((x) => ({ ...x, ent_password: e.target.value }));
                      }}
                    />
                  </div>
                </li>
                <li>
                  <div style={{ flex: 1 }} />
                  <Button onClick={handleSubmit}>저장</Button>
                </li>
              </ul>
            </div>
          </div>

          {!!data?.dates?.length && (
            <div className={styles.tableStyleBox} style={{ paddingTop: 25, marginTop: 20, flexDirection: 'column' }}>
              {data?.dates?.map((date, i) => (
                <dl className={styles.short}>
                  {date.type === 'SP' ? (
                    <>
                      <dt className={styles.title}>긴급</dt>
                      <dt>수거일시</dt>
                      <dd>
                        {moment(date?.begin_expected_at).format('MM-DD HH:mm') +
                          '~' +
                          moment(date?.begin_expected_at).add(2, 'hours').format('HH:mm')}
                      </dd>
                      <dt>배송일시</dt>
                      <dd>
                        {moment(date?.finish_expected_at).add(-2, 'hours').format('MM-DD HH:mm') +
                          '~' +
                          moment(date?.finish_expected_at).format('HH:mm')}
                      </dd>
                    </>
                  ) : (
                    <>
                      {data?.dates?.length === 1 ? (
                        <dt className={styles.title}>하루</dt>
                      ) : (
                        <dt className={styles.title}>{i + 1}회차</dt>
                      )}
                      <dt>수거일시</dt>
                      <dd>{moment(date?.begin_expected_at).format('MM-DD HH:mm') + '~08:00'}</dd>
                      <dt>배송일시</dt>
                      <dd>{moment(date?.finish_expected_at).add(-10, 'hours').format('MM-DD HH:mm') + '~08:00'}</dd>
                    </>
                  )}
                </dl>
              ))}
            </div>
          )}
        </Col>
      </Row>

      <PaymentInfo data={paydat} id={data?.id} purchases={payment?.purchases} />

      <div>
        <div className={styles.showOrder}>
          {data?.type !== 'SP' && (
            <div style={{ flex: 1, padding: 10 }}>
              <h4 className={styles.partTitle}>{PaymentType[data?.type]}날짜</h4>
              <SubscribeCalendar type={data?.type} dates={data?.dates} repeat={data?.repeat || 1} />
            </div>
          )}
          <div style={{ flex: 1, padding: 10 }}>
            {!['CANCELLED', 'PAY_READY'].includes(data.state) && (
              <>
                {!!data?.dates?.length && (
                  <div>
                    <h4 className={styles.partTitle}>작업 회차관련</h4>
                    {data?.dates?.map((date, index) => (
                      <PurchaseDateItem
                        key={`${index}`}
                        type={data?.type}
                        active={index === data.run_index}
                        date={date}
                        dateArgs={dateArgs}
                        setDateArgs={setDateArgs}
                        handleDateUpdate={handleDateUpdate}
                        onRefresh={() => loadData()}
                      />
                    ))}
                  </div>
                )}
                {data.type === 'TS' && <TsStatusCtrl data={data} onSubmitted={() => loadData()} />}
              </>
            )}
            <div>
              <h4 className={styles.partTitle}>배송지 정보</h4>
              <div className={styles.tableStyleBox}>
                <ul className={styles.tableContentList}>
                  <li>
                    <label>수령인</label>
                    <span>{data?.account?.nickname}</span>
                  </li>
                  <li>
                    <label>주문일시</label>
                    <span>{moment(data?.created_at).format('YYYY년 MM월 DD일')}</span>
                  </li>
                  <li>
                    <label>연락처</label>
                    <span>{phoneFormat(data?.account?.phone)}</span>
                  </li>
                  <li>
                    <label>배송지</label>
                    <span>
                      {data?.address} {data?.address_details}
                      (현관비번 : {data?.ent_password || '없음'})
                    </span>
                  </li>
                  <li>
                    <label>배송메모</label>
                    <span>{data?.address_memo}</span>
                  </li>
                  {data?.start_date && (
                    <li>
                      <label>수거일시</label>
                      <span>
                        {data?.type === 'SC'
                          ? moment(data?.created_at).add(1, 'd').format('YYYY년 MM월 DD일')
                          : moment(data?.start_date).format('YYYY년 MM월 DD일')}
                      </span>
                    </li>
                  )}
                  {data?.return_date && (
                    <li>
                      <label>배송일시</label>
                      <span>{moment(data?.return_date).format('YYYY년 MM월 DD일')}</span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div>
              <h4 className={styles.partTitle}>진행단계</h4>
              <div className={styles.tableStyleBox}>
                <ul className={styles.tableContentList}>
                  {histories?.map((item, index) => {
                    let link = '';
                    if (item.account.type === 'T') {
                      link = '/accounts/delivery/details/' + item.account.id;
                    } else if (item.account.type === 'S') {
                      link = '/accounts/urgency/details/' + item.account.id;
                    }
                    return (
                      <li style={{ marginBottom: 0 }}>
                        <div className={styles.tableContentListItem}>
                          <label>{NEW_RUN_STATE[item?.state]}</label>
                          <div className={styles.img}>
                            {item.images?.length > 0 && (
                              <img
                                src={parseImageUrl(item.images?.[0], 100, 100)}
                                onClick={() => setShowImages(item.images)}
                              />
                            )}
                          </div>
                          <div className={styles.date}>{moment(item?.created_at).format('YYYY-MM-DD HH:mm:ss')}</div>
                          {!!item.account && (
                            <span>
                              작업자 :{' '}
                              <a href={link} target={'_blank'}>
                                {item.account.nickname}
                              </a>
                            </span>
                          )}{' '}
                          ({item.id}){/*{item?.master?.nickname ? (*/}
                          {/*  <span>&nbsp; ({item?.master?.nickname})</span>*/}
                          {/*) : item?.postman?.nickname ? (*/}
                          {/*  <span>&nbsp; ({item?.postman?.nickname})</span>*/}
                          {/*) : (*/}
                          {/*  ''*/}
                          {/*)}*/}
                        </div>
                      </li>
                    );
                  })}
                  <li>
                    <div className={styles.tableContentListItem}>
                      <label>주문시작</label>
                      <img className={styles.img} src={parseImageUrl(data.images?.[0], 100, 100)} />
                      <div className={styles.date}>{moment(data?.created_at).format('YYYY-MM-DD HH:mm:ss')}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
