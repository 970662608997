import React, { useEffect, useState } from 'react';
import styles from './OrderDetail.module.less';
import { Button, Checkbox, DatePicker, Input, Select } from 'antd';
import moment from 'moment';
import { restApi } from '../../../../apis';

const DELIVERS = [
  'CJ대한통운',
  '로젠택배',
  '옐로우캡',
  '우체국택배',
  '한진택배',
  '롯데택배',
  '드림택배',
  'KGB택배',
  '대신택배',
  '일양로지스',
  '경동택배',
  '천일택배',
  '합동택배',
  'CVSnet편의점택배',
  '우편발송',
  'GTX 로지스',
  '건영택배',
  '드림택배',
  'EMS',
  'DHL',
  'FedEx',
  'UPS',
  'USPS',
  'TNT Express',
  '한의사랑택배',
  'GSMNtoN(인로스)',
  '에어보이익스프레스',
  'DHL Global Mail',
  'i-Paracel',
  '범한판토스',
  '굿투럭',
];
/**
 * 배송 관리
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const TsStatusCtrl = React.memo(({ data, onSubmitted }) => {
  const [form, setForm] = useState({
    noti: false,
    state: 'NEW',
    begin_expected_at: undefined,
    finish_expected_at: undefined,
    ts_corp: '',
    ts_no: '',
  });
  const [loading, setLoading] = useState();
  const handleSubmit = async () => {
    setLoading(true);
    try {
      await restApi.put(`/cms/purchases/${data?.id}/ts`, form);
      onSubmitted?.();
    } catch (e) {
      alert(e?.response?.data?.message || e?.message || '서버연결실패');
    }
    setLoading(false);
  };

  const dataState = data.run_state || 'NEW';
  useEffect(() => {
    setForm((x) => ({
      noti: false,
      state: dataState,
      begin_expected_at: !data.begin_expected_at ? undefined : moment(data.begin_expected_at).format('YYYY-MM-DD'),
      finish_expected_at: !data.finish_expected_at ? undefined : moment(data.finish_expected_at).format('YYYY-MM-DD'),
      ts_corp: data.ts_corp,
      ts_no: data.ts_no,
    }));
  }, [data]);

  return (
    <div>
      <h4 className={styles.partTitle}>상태변경</h4>
      <div className={styles.tableStyleBox}>
        <ul className={styles.tableContentList}>
          <li>
            <label>상태</label>
            <div>
              <Select
                value={form.state}
                onChange={(d) => setForm((x) => ({ ...x, state: d }))}
                style={{ width: '100%' }}
              >
                <Select.Option value={'NEW'}>신규주문</Select.Option>
                <Select.Option value={'TRNS_BEGIN'}>접수완료</Select.Option>
                <Select.Option value={'TRNS_SEND'}>배송시작</Select.Option>
                <Select.Option value={'SEND_USER'}>배송완료</Select.Option>
              </Select>
            </div>
          </li>
          {['TRNS_BEGIN', 'TRNS_SEND', 'SEND_USER'].includes(form.state) && (
            <li>
              <label>택배수신일</label>
              <div>
                <DatePicker
                  value={!form.begin_expected_at ? undefined : moment(form.begin_expected_at)}
                  onChange={(d) => {
                    setForm((x) => ({ ...x, begin_expected_at: d?.format('YYYY-MM-DD') }));
                  }}
                />
              </div>
            </li>
          )}
          {['TRNS_SEND', 'SEND_USER'].includes(form.state) && (
            <>
              <li>
                <label>배송일</label>
                <div>
                  <DatePicker
                    value={!form.finish_expected_at ? undefined : moment(form.finish_expected_at)}
                    onChange={(d) => {
                      setForm((x) => ({ ...x, finish_expected_at: d?.format('YYYY-MM-DD') }));
                    }}
                  />
                </div>
              </li>
              <li>
                <label>택배사</label>
                <div>
                  <Select
                    style={{ width: '100%' }}
                    allowClear
                    value={form.ts_corp}
                    onChange={(d) => setForm((x) => ({ ...x, ts_corp: d }))}
                  >
                    {DELIVERS?.map((d) => (
                      <Select.Option value={d} key={d}>
                        {d}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </li>
              <li>
                <label>운송장번호</label>
                <div>
                  <Input
                    value={form.ts_no}
                    onChange={(e) => {
                      const v = e.target.value;
                      setForm((x) => ({ ...x, ts_no: v }));
                    }}
                  />
                </div>
              </li>
            </>
          )}
          <li style={{ paddingTop: 10 }}>
            <div style={{ flex: 1 }}>
              {dataState !== form.state && (
                <Checkbox value={form.noti} onChange={() => setForm((x) => ({ ...x, noti: !x.noti }))}>
                  알림톡전송
                </Checkbox>
              )}
            </div>
            <Button onClick={handleSubmit} loading={loading}>
              저장
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
});

export default TsStatusCtrl;
