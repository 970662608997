import {restApi} from "../index";

export const getBanners = (body) => {
  const endpoint = `/banners`;
  return restApi.get(endpoint, body);
};

export const getBanner = (id) => {
  const endpoint = `/banners/${id}`;
  return restApi.get(endpoint);
};

/*
    mode: { type: TypeIs.STRING, comment: '종류' },
    // MAIN_TOP, MAIN_BOTTOM
    title: { type: TypeIs.STRING, comment: '제목' },
    uri: { type: TypeIs.STRING, comment: '연결주소' },
    image: { type: TypeIs.INT, comment: '메인이미지' },
    start_at: { type: TypeIs.DATEONLY, comment: '시작일자' },
    end_at: { type: TypeIs.DATEONLY, comment: '종료일자' },
    active: { type: TypeIs.BOOLEAN, comment: '출력여부' },
    account_id: { type: TypeIs.INT, connectTo: Account },
    order_index: { type: TypeIs.INT, comment: '정렬' },
*/

export const createBanner = ({ mode, title, uri, image, start_at, end_at, order_index }) => {
  //배너종류| MAIN_TOP, MAIN_BOTTOM
  const endpoint = '/banners';
  const requestBody = {
    mode,
    title,
    uri,
    image,
    start_at,
    end_at,
    order_index,
    // active,
  };
  return restApi.post(endpoint, requestBody);
};

export const readBanners = ({ id, mode, page, limit = 20, sort, dir, start_at, end_at }) => {
  const endpoint = '/banners';
  if (id) {
    return restApi.get(`${endpoint}/${id}`);
  }
  const params = {
    mode,
    page,
    limit,
    sort,
    dir,
    start_at,
    end_at,
  };
  return restApi.get(endpoint, { params });
};

export const updateBanner = ({ id, mode, title, uri, image, start_at, end_at, order_index }) => {
  const endpoint = `/banners/${id}`;
  const requestBody = {
    mode,
    title,
    uri,
    image,
    start_at,
    end_at,
    order_index,
    // active,
  };
  return restApi.put(endpoint, requestBody);
};

export const deleteBanner = (id) => {
  const endpoint = `/banners/${id}`;
  return restApi.delete(endpoint);
};

export const putBannerActive = ({ id, active }) => {
  const endpoint = `/banners/${id}/active`;
  const requestBody = {
    active,
  };
  return restApi.put(endpoint, requestBody);
};
