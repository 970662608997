import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import { Button, Descriptions, Divider, PageHeader, Table } from 'antd';
import moment from 'moment';
import styles from '../../../work/review/details/reviewDetails.module.less';
import { deleteCenter as deleteCenterApi, getCenterMembers } from '../../../../apis/centers';
import { restApi } from '../../../../apis';

const getCenter = (id) => {
  const endpoint = `/centers/${id}`;
  return restApi.get(endpoint);
};

/**
 * 센터관리 상세 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const CenterMgrDetails = () => {
  const center = useAxios();
  const members = useAxios();
  const [page, setPage] = React.useState(0);
  const [limit] = React.useState(10);
  const navigate = useNavigate();
  const deleteCenter = useAxios();

  const { id: centerId } = useParams();

  const userData = [
    { label: '지점명', content: center.data?.name },
    { label: '지역', content: String(center.data?.address || '미지정').split(' ')[0] },
    { label: '운영여부', content: center.data?.active == true ? '운영' : '미운영' },
    { label: '주소', content: center.data?.address.replaceAll('|', ', ') },
    { label: '등록일자', content: moment(center.data?.created_at).format('YYYY-MM-DD HH:mm') },
  ];

  const columns = [
    {
      title: '사원번호',
      dataIndex: 'corp_serial',
      align: 'center',
    },
    {
      title: '성명',
      dataIndex: 'nickname',
      align: 'center',
    },
    {
      title: '직급',
      dataIndex: 'corp_level',
      align: 'center',
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      align: 'center',
    },
    {
      title: '아이디',
      dataIndex: 'signname',
      align: 'center',
    },
    {
      title: '가입일자',
      dataIndex: 'created_at',
      align: 'center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm'),
    },
  ];

  const pagination = {
    position: ['bottomCenter'],
    total: members.data?.total_elements,
    pageSize: limit,
    current: page + 1,
    onChange: (page) => {
      setPage(page - 1);
    },
  };

  const handleEditCenter = async () => {
    navigate(`/settings/centermgr/update/${centerId}`, { replace: true });
  };

  const handleDeleteCenter = async () => {
    return deleteCenter.loadData(deleteCenterApi(centerId));
  };

  const onDeleteCenter = async () => {
    if (!centerId) return;
    const result = window.confirm('삭제하시겠습니까?');
    if (result) {
      try {
        await handleDeleteCenter();
        navigate('/settings/centermgr', { replace: true });
      } catch (error) {
        console.warn(error);
        alert(error?.response?.data?.message);
      }
    }
  };

  const loadData = async () => {
    try {
      await center.loadData(getCenter(centerId));
      await members.loadData(getCenterMembers(centerId));
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
      navigate('/settings/centermgr', { replace: true });
    }
  };

  React.useEffect(() => {
    if (!centerId) return;
    loadData();
  }, []);

  return (
    <div>
      <PageHeader className="site-page-header" title="센터관리" />
      <Descriptions bordered>
        {userData.map((data) => {
          return (
            <Descriptions.Item key={data.label} label={data.label} span={data.span ? data.span : 4}>
              {data.content}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
      <Divider />

      <div className="tableLayout">
        <Table
          scroll={{ x: 'max-content' }}
          style={{ marginTop: 10 }}
          columns={columns}
          dataSource={members.data?.items}
          bordered
          loading={members.loading}
          pagination={pagination}
          rowKey={({ id }) => id}
        />
      </div>

      <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={onDeleteCenter}
          size={'large'}
          className={styles.basicButton}
          style={{ marginRight: 5, height: 40, width: 100 }}
        >
          삭제
        </Button>
        <Button
          onClick={handleEditCenter}
          size={'large'}
          className={styles.basicButton}
          style={{ height: 40, width: 100, backgroundColor: '#FF7200', color: 'white', marginRight: 5 }}
        >
          수정
        </Button>
        <Button size="large" className="basicButton dark" style={{ height: 40, width: 100 }}>
          <Link to={`/settings/centermgr/`}>목록</Link>
        </Button>
      </div>
    </div>
  );
};

export default CenterMgrDetails;
