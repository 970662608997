import React from 'react';
import { Button, PageHeader, Switch, Table, Typography } from 'antd';
import { accountSearchFields } from '../../../common/searchFields';
import SearchForm from '../../../components/datalist/SearchForm';
import TableButtonWrap from '../../../components/TableButtonWrap';
import moment from 'moment';
import { getAPIHost, restApi } from '../../../apis';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import fileDownload from 'js-file-download';
import useInput from '../../../hooks/useInput';
import useFiles from '../../../hooks/useFiles';
import AdminModal from './join';
import { phoneFormat } from '../../../common/utils';

/**
 * 관리자 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const AccountAdminIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);

  const [data, setData] = React.useState();
  const [fileLoading, setFileLoad] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState(undefined);
  const [modalMode, setmodalMode] = React.useState(0);
  const profile = useFiles();
  const beforePassword = useInput();
  const newPassword1 = useInput();
  const newPassword2 = useInput();
  const newName = useInput();
  const newDepartment = useInput();
  const newLevel = useInput();

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      sort: 'created_at',
      ...query,
      type: 'A',
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    }

    const { data } = await restApi.get('/accounts', {
      params,
    });
    setData(data);
  }, [query]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  // 모달 관련 on off
  const showModal = (data) => {
    setIsModalVisible(data);
  };

  const handleOk = () => {
    setIsModalVisible(undefined);
  };

  const handleCancel = () => {
    setIsModalVisible(undefined);
    setmodalMode(0);
  };

  const password = () => {
    setmodalMode(1);
  };

  const name = () => {
    setmodalMode(2);
  };

  const department = () => {
    setmodalMode(3);
  };

  const level = () => {
    setmodalMode(4);
  };

  // 상태변경
  const handleActiveChange = async (id, active) => {
    if (!window.confirm('변경하시겠습니까?')) {
      return;
    }
    try {
      const { data } = await restApi.put(`/accounts/${id}/active`, {
        active,
      });
      await loadData();
    } catch (e) {
      console.warn(e);
    }
  };

  // 테이블 컬럼 정의
  const columns = [
    {
      title: '번호',
      dataIndex: 'id',
      align: 'center',
      width: 90,
      render: (_, _2, i) => {
        return _;
        // return data?.total_elements - i - data?.page * 10;
      },
    },
    {
      title: '이름',
      dataIndex: 'nickname',
      width: 100,
      align: 'center',
      render: (r) => (
        <Typography.Text ellipsis={true} style={{ width: 100 }}>
          {r}
        </Typography.Text>
      ),
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      width: 150,
      align: 'center',
      render: (data) => phoneFormat(data),
    },
    {
      title: '아이디',
      // dataIndex: 'email',
      dataIndex: 'signname',
      width: 150,
      align: 'center',
    },
    {
      title: '소속',
      dataIndex: 'corp_department',
      width: 200,
      align: 'center',
    },
    {
      title: '직급',
      dataIndex: 'corp_level',
      width: 200,
      align: 'center',
    },
    {
      title: '승인여부',
      dataIndex: 'active',
      key: 'active',
      width: 100,
      align: 'center',
      render: (x, row) => {
        return <Switch checked={x} onChange={(e) => handleActiveChange(row?.id, e)} />;
      },
    },
    {
      title: '가입일자',
      dataIndex: 'created_at',
      align: 'center',
      width: 200,
      render: (data) => {
        return moment(data).format('YYYY-MM-DD HH:mm');
      },
    },
    {
      title: '자세히보기',
      dataIndex: 'id',
      align: 'center',
      render: (data, row) => (
        <a
          style={{ color: 'black' }}
          // href={`/account/admin/details/${data}`}
          onClick={(e) => {
            showModal(row);
            e.preventDefault();
          }}
        >
          자세히보기
          {/*<Icon source={require('../../../assets/icon_gear.png')} size={18} />*/}
        </a>
      ),
    },
  ];

  // 테이블 페이지네이션
  const pagination = {
    position: ['bottomCenter'],
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      navigate('?' + qs.stringify({ ...query, page: p - 1 }), { replace: true });
    },
  };

  // 엑셀 저장
  const xlsxSave = () => {
    const params = {
      ...query,
      type: 'A',
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    }
    restApi
      .get(`${getAPIHost()}/exports/accounts/admin?${qs.stringify(params)}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `관리자_${moment().format('YYYYMMDDHHmmss')}.xlsx`);
      });
  };

  const formSave = () => {};

  // 관리자 업로드
  const handleExcelUpload = async (e) => {
    if (fileLoading) {
      return;
    }
    try {
      setFileLoad(true);
      const fileData = new FormData();
      const config = {
        header: {
          accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      };

      fileData.append('filedata', e?.target?.files[0]);
      await restApi.post('/accounts/excel', fileData, config);
      // return responseData;
      alert('엑셀이 업로드 되었습니다.');
      setFileLoad(false);
      await loadData();
    } catch (error) {
      console.error('excel upload ERROR', error);
      setFileLoad(false);
      throw error;
    }
  };

  // 프로필 사진 변경
  const handleChangeProfileImage = async ({ isTrusted, target: { files } }) => {
    const [file] = files;
    if (isTrusted) {
      try {
        profile.loadData(file);
      } catch (error) {
        console.warn(error);
      }
    }
    setmodalMode(0);
  };

  // 정보 수정
  const handleSubmit = async () => {
    try {
      await restApi.put(`/accounts/${isModalVisible?.id}`, {
        profile: profile.data?.id,
      });
      await loadData();
      alert('정보 수정 완료되었습니다.');
      handleCancel();
      await loadData();
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
    }
    setmodalMode(0);
  };

  // 회원 탈퇴
  const handleDelete = async () => {
    if (!window.confirm('탈퇴 처리하시겠습니까?\n복구가 불가능합니다.')) return;
    try {
      await restApi.delete(`/accounts/admin/${isModalVisible?.id}`);
      alert('삭제되었습니다.');
      handleCancel();
      await loadData();
    } catch (e) {
      alert(e?.response?.data?.message || '서버와 연결이 올바르지 않습니다.');
    }
  };

  // 이름 변경
  const handleChangeName = async () => {
    try {
      await restApi.put(`/accounts/${isModalVisible?.id}`, {
        nickname: newName.value,
      });
      await loadData();
      alert('변경 완료되었습니다.');
      handleCancel();
      await loadData();
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
    }
    setmodalMode(0);
  };

  // 비밀번호 변경
  const handleChangePassword = async () => {
    try {
      const requestBody = {
        password: newPassword1?.value,
      };
      const { data } = await restApi.put(`/accounts/${isModalVisible?.id}/password`, requestBody);
      alert('비밀번호가 변경되었습니다.');
      await loadData();
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
    }
    setmodalMode(0);
  };

  // 부서 변경
  const handleChangeDepartment = async () => {
    try {
      await restApi.put(`/accounts/${isModalVisible?.id}`, {
        corp_department: newDepartment.value,
      });
      await loadData();
      alert('변경 완료되었습니다.');
      handleCancel();
      await loadData();
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
    }
    setmodalMode(0);
  };

  // 직급 변경
  const handleChangeLevel = async () => {
    try {
      await restApi.put(`/accounts/${isModalVisible?.id}`, {
        corp_level: newLevel.value,
      });
      await loadData();
      alert('변경 완료되었습니다.');
      handleCancel();
      await loadData();
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
    }
    setmodalMode(0);
  };

  // 모달 프롭스
  const adminModalProps = {
    isModalVisible,
    handleChangeName,
    handleChangeProfileImage,
    handleChangePassword,
    handleChangeDepartment,
    handleChangeLevel,
    handleSubmit,
    handleOk,
    handleCancel,
    handleDelete,
    modalMode,
    beforePassword,
    newPassword1,
    newPassword2,
    newName,
    profile,
    newDepartment,
    newLevel,
    name,
    password,
    department,
    level,
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="관리자 회원정보"
        extra={[<Button onClick={() => navigate(-1)}>목록</Button>]}
      />
      <SearchForm fields={accountSearchFields} />
      <div style={{ position: 'relative' }}>
        <Table
          scroll={{ x: 'max-content' }}
          dataSource={data?.items}
          columns={columns}
          pagination={
            pagination
              ? {
                  ...pagination,
                  position: ['bottomCenter'],
                  showSizeChanger: false,
                }
              : false
          }
        />
        <div>
          <TableButtonWrap
            position="left"
            buttons={[
              { icon: require('../../../assets/icon_save.png'), label: '엑셀 저장', onClick: xlsxSave },
              {
                icon: require('../../../assets/icon_save.png'),
                label: '업로드 양식 저장',
                download: require('../../../assets/슈닥_관리자_업로드_양식.xlsx'),
              },
              { icon: require('../../../assets/icon_upload.png'), label: '관리자 업로드', onChange: handleExcelUpload },
            ]}
          />
        </div>
      </div>
      <AdminModal {...adminModalProps} />
    </div>
  );
};

export default AccountAdminIndex;
