import { Modal } from 'antd';
import React from 'react';

/**
 * 이미지 클릭시 모달 컴포넌트
 * @function ImageGallery
 * @param {Array} images [{url: ''}]
 * @returns {React.Component}
 */
const Gallery = ({ images, isVisible, onCancel }) => {
  return (
    <Modal
      visible={isVisible}
      onCancel={() => onCancel()}
      footer={null}
      width={900}
      centered
      bodyStyle={{ paddingTop: 50 }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        {images?.map((v) => (
          <div style={{ marginBottom: 10 }}>
            <img style={{ maxWidth: '100%' }} src={v.url} />
          </div>
        ))}
        {/*<SimpleImageSlider images={images} showBullets showNavs width={800} height={800} />*/}
      </div>
    </Modal>
  );
};

export default Gallery;
