import React from 'react';
import { Button, Input, Modal, PageHeader, Table } from 'antd';
import useAxios from '../../../../hooks/useAxios';
import LabelRow from '../../../../components/LabelRow';
import CreateAccountModal from '../../../../components/CreateAccountModal';
import Postcode from '@actbase/react-daum-postcode';
import Loading from '../../../../components/Loading';
import {
  createCenterMember,
  deleteCenterMember as deleteCenterMemberApi,
  getCenterMembers,
  updateCenter as updateCenterApi,
} from '../../../../apis/centers';
import { useNavigate, useParams } from 'react-router-dom';
import { getGeocode } from '../../../../apis/thirdparty';
import { restApi } from '../../../../apis';
import CenterAccountForm from '../../../accounts/center/details/form';
import styles from '../../../work/review/details/reviewDetails.module.less';

export const getCenter = (id) => {
  const endpoint = `/centers/${id}`;
  return restApi.get(endpoint);
};

/**
 * 센터관리 수정 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const CenterMgrUpdate = () => {
  const navigate = useNavigate();
  const updateCenter = useAxios();
  const center = useAxios();
  const members = useAxios();
  const [loading, setLoading] = React.useState(true);
  const [name, setName] = React.useState();
  const [address, setAddress] = React.useState();
  const [visible, setVisible] = React.useState(false);
  const [addressVisible, setAddressVisible] = React.useState(false);
  const [accountData, setAccountData] = React.useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);

  const { id: centerId } = useParams();

  const columns = [
    { title: '사원번호', dataIndex: 'corp_serial', width: 120, align: 'center' },
    { title: '성명', dataIndex: 'nickname', width: 150, align: 'center' },
    { title: '직급', dataIndex: 'corp_level', width: 150, align: 'center' },
    { title: '연락처', dataIndex: 'phone', width: 150, align: 'center' },
    { title: '아이디', dataIndex: 'signname', width: 150, align: 'center' },
  ];

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys,
    onChange: (e) => {
      setSelectedRowKeys(e);
    },
  };

  const handleUpdateCenter = async () => {
    if (updateCenter.loading) return;

    const updateLoadData = async () => {
      const { data: getcodeData } = await getGeocode(address.address);
      if (!getcodeData) return null;
      const requestBody = {
        type: 'R',
        name,
        address: getcodeData.addr,
        lat: getcodeData.lat,
        lng: getcodeData.lng,
        sido: address.sido,
        gugun: address.sigungu,
      };
      const { data: responseData } = await updateCenterApi(centerId, requestBody);
      if (responseData) {
        await Promise.all([
          // Create Member
          ...accountData
            .filter((v) => v.id === undefined)
            .map((v) => {
              return createCenterMember(centerId, v);
            }),
          // Delete Member
          ...members.data?.items
            .filter((v) => accountData.findIndex((v2) => v.id === v2.id) < 0)
            .map((v) => {
              return deleteCenterMemberApi(centerId, v.id);
            }),
        ]);
      }
      return responseData;
    };

    await updateCenter.loadData(updateLoadData());
    alert('수정 완료되었습니다.');
    navigate(`/settings/centermgr/details/${centerId}`, { replace: true });
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleOpenAddressModal = () => {
    setAddressVisible(true);
  };

  const handleCloseAddressModal = () => {
    setAddressVisible(false);
  };

  const handleSelectAddress = (e) => {
    setAddress(e);
    handleCloseAddressModal();
  };

  const handleSelectedDelete = () => {
    setAccountData((prevState) => prevState.filter((v) => !selectedRowKeys.includes(v.id)));
    setSelectedRowKeys([]);
  };

  const loadData = async () => {
    try {
      const { data: centerData } = await center.loadData(getCenter(centerId));
      const { data: membersData } = await members.loadData(getCenterMembers(centerId));
      setName(centerData.name);
      setAddress({ address: centerData.address });
      setAccountData(membersData.items);
      setLoading(false);
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
      navigate('/settings/centermgr', { replace: true });
    }
  };

  React.useEffect(() => {
    if (!centerId) return;
    loadData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Modal
        visible={addressVisible}
        bodyStyle={{ padding: 0 }}
        closable
        onCancel={handleCloseAddressModal}
        closeIcon={() => null}
        centered
        footer={null}
      >
        <Postcode style={{ width: '100%' }} onSelected={handleSelectAddress} />
      </Modal>
      <CenterAccountForm
        formType={visible ? 0 : undefined}
        centerId={centerId}
        onExit={() => setVisible(false)}
        onSubmitted={() => {
          loadData().catch(console.warn);
          setVisible(false);
        }}
      />
      <PageHeader className="site-page-header" title="센터관리" />
      <div className="labelrowWrapper">
        <LabelRow placeholder="최대 15자 이내로 입력하세요." label="지점이름">
          <Input style={{ width: '100%' }} value={name} onChange={(e) => setName(e.target.value)} />
        </LabelRow>
        <LabelRow label="주소">
          <Input
            placeholder="주소를 입력해주세요."
            value={address?.address}
            contentEditable="false"
            onClick={handleOpenAddressModal}
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          />
        </LabelRow>
      </div>
      <div className="labelrowButtonSet" style={{ justifyContent: 'flex-start' }}>
        <Button size="large" onClick={handleOpenModal} className="basicButton" style={{ marginRight: 10 }}>
          사원등록
        </Button>
        <Button size="large" onClick={handleSelectedDelete} className="basicButton">
          선택삭제
        </Button>
      </div>
      <div className="tableLayout" style={{ marginTop: 20, backgroundColor: '#fff', border: '1px #ddd solid' }}>
        <Table
          dataSource={accountData}
          columns={columns}
          rowSelection={rowSelection}
          rowKey={({ id }) => id}
          scroll={{ x: 'max-content' }}
          bordered
          pagination={false}
        />
      </div>
      <div>{`사원 정보관리는 "회원관리 > 센터직원"에서 수정가능합니다.`}</div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
        <Button
          onClick={handleUpdateCenter}
          size={'large'}
          className={styles.basicButton}
          style={{ height: 40, width: 100, backgroundColor: '#FF7200', color: 'white', marginRight: 5 }}
          loading={updateCenter.loading}
        >
          수정
        </Button>
        <Button
          onClick={() => {
            navigate('/settings/centermgr', { replace: true });
          }}
          style={{
            background: '#fff',
            color: '#000',
            width: 100,
            border: '1px solid #ccc',
            height: 40,
            borderRadius: 4,
          }}
          // size={'large'}
          // className="basicButton"
        >
          목록
        </Button>
      </div>
    </div>
  );
};

export default CenterMgrUpdate;
