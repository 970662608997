import React, { useState } from 'react';
import { Button, Card, Input, InputNumber, PageHeader, Radio, Table, Typography } from 'antd';
import useAxios from '../../../hooks/useAxios';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import LabelRow from '../../../components/LabelRow';
import useInput from '../../../hooks/useInput';
import moment from 'moment';
import {
  couponFirstMethodTypes,
  couponStatusType,
  couponStatusTypes,
  couponTargettingType,
  couponTargettingTypes,
  createCoupons as createCouponsApi,
} from '../../../apis/coupons';
import { getAccounts } from '../../../apis/accounts';
import { AccountType } from '../../../common/defines';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './coupon.module.less';
import SearchForm from '../../../components/datalist/SearchForm';
import { accountSearchFields } from '../../../common/searchFields';
import { getUserFrom, phoneFormat, toCommaNumber } from '../../../common/utils';
import qs from 'query-string';
import style from '../../work/review/details/reviewDetails.module.less';
import FileUpload from '../../../components/FileUpload';

/**
 * 쿠폰관리 생성 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const CouponCreate = () => {
  const accounts = useAxios();
  const createCoupons = useAxios();
  const title = useInput('');
  const size = useInput('');
  const salePer = useInput('');
  const saleMaxPrice = useInput('');
  const [method, setMethod] = React.useState();
  const [limitDate, setLimitDate] = React.useState(90);
  const [status, setStatus] = React.useState(couponStatusType.READY);
  const [targeting, setTargeting] = React.useState(couponTargettingType.ALL);
  const [page, setPage] = React.useState(0);
  const [limit] = React.useState(10);
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = React.useState([]);
  const location = useLocation();
  const [mainImage, setMainImage] = useState();

  const handleClick = (t) => {
    salePer.onChange();
    size.onChange();
    saleMaxPrice.onChange();
    setLimitDate(90);
    setTargeting(t);
  };

  const handleCreateCoupon = async () => {
    if (targeting === couponTargettingType.FIRST) {
      if (!method) {
        alert('쿠폰 종류를 선택해주세요.');
        return;
      }
    }

    if (targeting === couponTargettingType.TARGETING) {
      if (selectedUser.length === 0) {
        alert('타켓팅 유저를 선택해주세요.');
        return;
      }
    }

    if (!title.value.length > 0) {
      alert('쿠폰이름을 입력해주세요.');
      return;
    }
    // if (!method) {
    //   alert('지급종류를 입력해주세요.');
    //   return;
    // }
    if (!salePer.value) {
      alert('할인율을 입력해주세요.');
      return;
    }
    if (!saleMaxPrice.value) {
      alert('최대할인가를 입력해주세요.');
      return;
    }
    if (!limitDate) {
      alert('유효기간 입력해주세요.');
      return;
    }
    if (!status.length > 0) {
      alert('상태를 입력해주세요.');
      return;
    }

    try {
      const requestBody = {
        title: title.value,
        targeting,
        method,
        size: size.value,
        sale_per: salePer.value,
        sale_max_price: saleMaxPrice.value,
        limit_date: limitDate,
        status,
        image: mainImage,
        user_ids: selectedUser?.map((v) => v.id),
      };
      const { data: responseData } = await createCoupons.loadData(createCouponsApi(requestBody));
      if (!responseData) return;

      alert('쿠폰이 등록 되었습니다.');
      navigate('/settings/coupon', { replace: true });
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
    }
  };

  const loadData = async () => {
    try {
      await accounts.loadData(getAccounts({ params: { type: AccountType.user, page, limit } }));
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
    }
  };

  const onSubmit = () => {
    handleCreateCoupon();
  };

  React.useEffect(() => {
    loadData();
  }, [page]);

  const pagination = {
    position: ['bottomCenter'],
    total: accounts.data?.total_elements,
    pageSize: limit,
    current: page + 1,
    onChange: (page) => {
      setPage(page - 1);
    },
  };

  React.useEffect(() => {
    const loadAccount = async () => {
      const query = qs.parse(location?.search);
      const params = {
        ...query,
        type: 'U',
        limit: 10,
      };
      params.dir = query.sort === 'id' ? 'asc' : 'desc';
      if (query.field === 'name') {
        params.name = query.keyword;
      } else if (query.field === 'phone') {
        params.phone = query.keyword;
      }
      try {
        await accounts.loadData(getAccounts({ params: params }));
      } catch (e) {
        console.warn(e);
      }
    };
    loadAccount().catch(console.warn);
  }, [location?.search]);

  const columns = React.useMemo(
    () => [
      {
        title: '번호',
        align: 'center',
        key: 'id',
        dataIndex: 'id',
        width: 90,
      },
      {
        title: '이름',
        key: 'nickname',
        dataIndex: 'nickname',
        width: 100,
        align: 'center',
        render: (r) => (
          <Typography.Text ellipsis={true} style={{ width: 100 }}>
            {r}
          </Typography.Text>
        ),
      },
      {
        title: '연락처',
        dataIndex: 'phone',
        key: 'phone',
        width: 150,
        align: 'center',
        render: (r) => phoneFormat(r),
      },
      {
        title: '가입수단',
        dataIndex: 'platform',
        key: 'platform',
        width: 90,
        align: 'center',
        render: (data, row) => {
          return getUserFrom(row.signname, data);
        },
      },
      {
        title: '멤버십등급',
        dataIndex: 'membership',
        key: 'membership',
        width: 100,
        align: 'center',
        render: (_, __, index) => {
          return _?.name || '-';
        },
      },
      {
        title: '포인트',
        dataIndex: 'point',
        key: 'point',
        width: 100,
        align: 'center',
        render: (data) => toCommaNumber(data),
      },
      {
        title: '주문건수',
        dataIndex: 'purchase_count',
        key: 'purchase_count',
        width: 100,
        align: 'center',
        render: (data) => toCommaNumber(data),
      },
      {
        title: '주소',
        dataIndex: 'address',
        key: 'address',
        align: 'left',
      },

      {
        title: '가입일자',
        dataIndex: 'created_at',
        key: 'created_at',
        align: 'center',
        width: 200,
        render: (data) => {
          return moment(data).format('YYYY-MM-DD HH:mm');
        },
      },
      {
        title: '추가',
        dataIndex: 'id',
        key: 'add',
        align: 'center',
        width: 100,
        render: (_, record) => (
          <Button
            onClick={() =>
              setSelectedUser((prev) =>
                [...prev, record].filter((e, i, a) => a.findIndex((e2) => e2.id === e.id) === i),
              )
            }
          >
            추가
          </Button>
        ),
      },
    ],
    [],
  );

  const SelectedColumns = React.useMemo(
    () => [
      {
        title: '번호',
        align: 'center',
        key: 'id',
        dataIndex: 'id',
        width: 90,
      },
      {
        title: '이름',
        key: 'nickname',
        dataIndex: 'nickname',
        width: 100,
        align: 'center',
        render: (r) => (
          <Typography.Text ellipsis={true} style={{ width: 100 }}>
            {r}
          </Typography.Text>
        ),
      },
      {
        title: '연락처',
        dataIndex: 'phone',
        key: 'phone',
        width: 150,
        align: 'center',
        render: (r) => phoneFormat(r),
      },
      {
        title: '가입수단',
        dataIndex: 'platform',
        key: 'platform',
        width: 90,
        align: 'center',
        render: (data, row) => {
          return getUserFrom(row.signname, data);
        },
      },
      {
        title: '멤버십등급',
        dataIndex: 'membership',
        key: 'membership',
        width: 100,
        align: 'center',
        render: (_, __, index) => {
          return _?.name || '-';
        },
      },
      {
        title: '포인트',
        dataIndex: 'point',
        key: 'point',
        width: 100,
        align: 'center',
        render: (data) => toCommaNumber(data),
      },
      {
        title: '주문건수',
        dataIndex: 'purchase_count',
        key: 'purchase_count',
        width: 100,
        align: 'center',
        render: (data) => toCommaNumber(data),
      },
      {
        title: '주소',
        dataIndex: 'address',
        key: 'address',
        align: 'left',
      },

      {
        title: '가입일자',
        dataIndex: 'created_at',
        key: 'created_at',
        align: 'center',
        width: 200,
        render: (data) => {
          return moment(data).format('YYYY-MM-DD HH:mm');
        },
      },
      {
        title: '삭제',
        dataIndex: 'id',
        key: 'delete',
        align: 'center',
        width: 100,
        render: (data, record) => (
          <Button
            onClick={() => {
              if (!window.confirm('삭제하시겠습니끼?')) return;
              setSelectedUser((prev) => prev.filter((x) => x !== record));
            }}
          >
            삭제
          </Button>
        ),
      },
    ],
    [],
  );

  return (
    <div>
      <PageHeader className="site-page-header" title="쿠폰 등록" />
      <div className="labelrowWrapper">
        <LabelRow label="쿠폰이름">
          <Input {...title} placeholder="최대 30자 이내로 입력하세요." style={{ width: 500 }} />
        </LabelRow>

        <LabelRow label="대표이미지" heightOver>
          <div>
            <FileUpload
              values={mainImage ? [mainImage] : []}
              maxCount={9}
              onChange={(images) => {
                setMainImage(images[0]);
              }}
              className="profileImg"
            />
            {/*<span style={{ fontSize: 12, color: '#3d3d3d', margin: '6px 0px', display: 'block' }}>*/}
            {/*  추가이미지는 최대 9개까지 설정가능.*/}
            {/*</span>*/}
          </div>
        </LabelRow>
        <LabelRow label="타켓팅" heightOver>
          <div>
            {Object.keys(couponTargettingType)?.map((key) => {
              return (
                <Button
                  type={targeting === key ? 'primary' : undefined}
                  className={styles.targetButton}
                  onClick={() => handleClick(key)}
                >
                  {couponTargettingTypes[key]}
                </Button>
              );
            })}
            <span style={{ fontSize: 10, marginTop: 10, display: 'block' }}>
              · 첫이용고객을 위한 첫이용 쿠폰 혜택을 등록하세요.
            </span>
          </div>
        </LabelRow>
        {targeting === couponTargettingType.CODE && (
          <LabelRow label="코드생성개수">
            <div style={{ border: '1px #ddd solid', padding: '5px 10px 5px 0', backgroundColor: '#fff' }}>
              <Input {...size} style={{ width: 200 }} bordered={false} />
              <span>개</span>
            </div>
          </LabelRow>
        )}
        {targeting === couponTargettingType.FIRST && (
          <LabelRow label="쿠폰종류선택">
            <Radio.Group
              optionType="default"
              value={method}
              onChange={(e) => {
                setMethod(e.target.value);
              }}
              options={Object.entries(couponFirstMethodTypes).map((v) => ({
                key: v[0],
                value: v[0],
                label: v[1],
              }))}
            />
          </LabelRow>
        )}
        {targeting === couponTargettingType.TARGETING && (
          <Card>
            <div style={{ marginBottom: 20 }}>
              {/*<div className="labelrowButtonSet" style={{ justifyContent: 'flex-start' }}>*/}
              {/*  <Button size="large" className="basicButton dark">*/}
              {/*    고객등록*/}
              {/*  </Button>*/}
              {/*  <Button size="large" className="basicButton">*/}
              {/*    선택삭제*/}
              {/*  </Button>*/}
              {/*</div>*/}
              <SearchForm fields={accountSearchFields} />
              <div className="tableLayout" style={{ marginTop: 20, backgroundColor: '#fff', border: '1px #ddd solid' }}>
                <Table
                  columns={columns}
                  // rowSelection={rowSelection}
                  pagination={pagination}
                  // rowKey={({ id }) => id}
                  scroll={{ x: 'max-content' }}
                  bordered
                  dataSource={accounts.data?.items}
                  loading={accounts.loading}
                />
              </div>
              <div>
                <h2 style={{ marginTop: 20 }}>타겟팅 유저</h2>
                <Table
                  columns={SelectedColumns}
                  pagination={false}
                  scroll={{ x: 'max-content' }}
                  bordered
                  dataSource={selectedUser}
                  loading={accounts.loading}
                />
              </div>
            </div>
          </Card>
        )}
        <LabelRow label="할인설정">
          <div style={{ border: '1px #ddd solid', padding: '5px 10px 5px 0', backgroundColor: '#fff' }}>
            <Input {...salePer} style={{ width: 200 }} bordered={false} />
            <span>%</span>
          </div>
          <span style={{ margin: '0 20px' }}>최대</span>
          <div style={{ border: '1px #ddd solid', padding: '5px 10px 5px 0', backgroundColor: '#fff' }}>
            <Input {...saleMaxPrice} style={{ width: 200 }} bordered={false} />
            <span>원</span>
          </div>
          <span style={{ margin: '0 20px' }}>할인</span>
        </LabelRow>
        <LabelRow label="유효기간 (일)">
          <InputNumber
            value={limitDate}
            onChange={setLimitDate}
            style={{ width: 200, fontSize: 12 }}
            placeholder="일단위 입력"
          />
        </LabelRow>
        <LabelRow label="쿠폰적용">
          <Radio.Group
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
            optionType="default"
            options={Object.entries(couponStatusTypes).map((v) => ({
              key: v[0],
              value: v[0],
              label: v[1],
            }))}
          />
        </LabelRow>
      </div>
      <div className={style.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={() => navigate('/settings/coupon', { replace: true })}
          size={'large'}
          className={style.basicButton}
          style={{ height: 40, width: 100, marginRight: 5 }}
        >
          목록
        </Button>
        <Button
          onClick={onSubmit}
          size={'large'}
          className={style.basicButton}
          style={{ marginRight: 5, height: 40, width: 100, backgroundColor: '#FF7200', color: 'white' }}
        >
          등록
        </Button>
        {/*<Button size="large" className="basicButton dark" style={{ height: 40, width: 100 }}>*/}
        {/*  <Link to={`/settings/coupon/update/${couponId}`}>수정</Link>*/}
        {/*</Button>*/}
      </div>
    </div>
  );
};

export default CouponCreate;
