import React from 'react';
import { Col, PageHeader, Row, Spin, Table } from 'antd';
import moment from 'moment';
import { getPayname, PaymentType } from '../../../../common/defines';
import { parseImageUrl, toCommaNumber } from '../../../../common/utils';
import Gallery from '../../../../components/ImageGallery';
import { Link, useParams } from 'react-router-dom';
import { restApi } from '../../../../apis';
import styles from './PaymentDetail.module.less';
import PaymentInfo from "../../order/details/PaymentInfo";

/**
 * 결제내역 상세 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const PaymentDetails = () => {
  const { id } = useParams();

  const [data, setData] = React.useState();
  const [isVisible, setIsVisible] = React.useState(false);

  const iamPortResult = !data
    ? null
    : data?.payed_json?.[0] === '{'
    ? JSON.parse(data?.payed_json)
    : { ...data?.payed_json };

  React.useEffect(() => {
    const loadData = async () => {
      const { data } = await restApi.get(`/payments/${id}`);
      setData(data);
    };
    loadData().catch(console.warn);
  }, [id]);

  const columns = [
    {
      title: '주문번호',
      dataIndex: 'id',
      align: 'center',
      width: 100,
    },
    {
      title: '주문정보',
      dataIndex: 'images',
      align: 'left',
      // width: 150,
      render: (data, record) => {
        const [thumb] = data ?? [];
        return (
          <div style={{ display: 'flex' }}>
            <Gallery
              onCancel={() => setIsVisible(false)}
              isVisible={isVisible}
              images={data?.map((x) => {
                return {
                  url: parseImageUrl(x),
                };
              })}
            />
            <div style={{ marginRight: 20, marginLeft: 10, marginTop: 10, marginBottom: 10 }}>
              {!thumb ? (
                <span>이미지 없음</span>
              ) : (
                <img
                  src={parseImageUrl(thumb)}
                  style={{ width: 112, height: 112, objectFit: 'contain' }}
                  alt="thumbnail"
                  onClick={() => setIsVisible(true)}
                />
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <label>[{PaymentType[record.type]}]</label>
              <h4>
                {!!record?.product_name
                  ? record.product_name
                  : record?.gender === 'X'
                  ? '운동화'
                  : record?.gender === 'M'
                  ? '남자구두'
                  : '여자구두'}
              </h4>
              {/* <span>({record?.items?.map((v) => v.name).join(', ')})</span> */}
            </div>
          </div>
        );
      },
    },
    {
      title: '결제금액',
      dataIndex: 'sale_price',
      align: 'center',
      width: 150,
      render: (data) => <span>{toCommaNumber(data || 0)}원</span>,
    },
    {
      title: '배송비',
      dataIndex: 'delivery_price',
      align: 'center',
      className: 'color-cell',
      width: 150,
      render: (data) => toCommaNumber(data),
    },
    {
      title: '진행상태',
      dataIndex: 'state',
      align: 'center',
      className: 'color-cell',
      width: 120,
      // render: (data) => WorkStateType[data],
      render: (data) =>
        data == 'BUYED'
          ? '구매완료'
          : data == 'CANCELLED'
          ? '결제취소'
          : data == 'FINISH'
          ? '완료'
          : data == 'PAY_READY'
          ? '결제대기'
          : data,
    },
    {
      title: '',
      dataIndex: 'id',
      width: 100,
      align: 'center',
      render: (id) => {
        return (
          <Link to={`/work/order/details/${id}`}>
            <a style={{ color: 'black' }}>자세히보기</a>
          </Link>
        );
      },
    },
  ];

  console.log({ data });
  return !data ? (
    <div style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <Spin />
    </div>
  ) : (
    <div>
      <PageHeader className="site-page-header" title="결제내역" />
      <div className={styles.borderBlock}>
        <div className={styles.item}>
          <label>결제일자</label>
          <span>{moment(data?.created_at).format('YYYY.MM.DD')}</span>
        </div>
      </div>

      <Row gutter={10}>
        <Col span={24}>
          <Table
            className="product-table"
            columns={columns}
            dataSource={data?.purchases}
            bordered
            pagination={false}
            scroll={{ x: 'max-content' }}
          />
        </Col>
        <Col span={24}>
          <PaymentInfo data={data} id={-1} />
        </Col>
      </Row>
    </div>
  );
};

export default PaymentDetails;
