import React from 'react';
import { Button, DatePicker, Descriptions, Input, PageHeader, Select } from 'antd';
import useInput from '../../../hooks/useInput';
import { useNavigate } from 'react-router-dom';
import useAxios from '../../../hooks/useAxios';
import { createBanner as createBannerApi } from '../../../apis/banners';
import FileUpload from '../../../components/FileUpload';
import styles from '../../work/review/details/reviewDetails.module.less';
import moment from 'moment';

/**
 * 하단배너 생성 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const BannerBottomCreate = () => {
  const navigate = useNavigate();
  const createBanner = useAxios();

  const title = useInput('');
  const uri = useInput('');
  const order = useInput('');
  const [mode, setMode] = React.useState(null);
  const [images, setImages] = React.useState(null);
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();

  // 취소하면 뒤로가기
  const handleCancel = () => {
    navigate(-1);
  };

  // 배너 타입 변경
  const handleBannerType = (data) => {
    setMode(data);
  };

  // 배너 생성 컨펌 함수
  const handleCreateBanner = async () => {
    if (!title.value) {
      alert('제목을 입력해주세요');
      return;
    }
    if (!startDate) {
      alert('시작일을 입력해주세요');
      return;
    }
    if (!endDate) {
      alert('종료일을 입력해주세요');
      return;
    }
    if (!order.value) {
      alert('순번을 입력해주세요');
      return;
    }
    if (moment(startDate).isAfter(endDate)) {
      alert('종료일이 시작일보다 이전입니다');
      return;
    }
    if (!images) {
      alert('이미지를 업로드해주세요');
      return;
    }
    try {
      await createBanner.loadData(
        createBannerApi({
          mode: 'MAIN_BOTTOM',
          title: title.value,
          image: images,
          uri: uri.value,
          start_at: startDate,
          end_at: endDate,
          order_index: order.value,
        }),
      );
      alert('작성 완료되었습니다.');
      navigate(-1);
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message || '최대 10개까지 등록 가능합니다.');
    }
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="배너 작성" />
      <Descriptions bordered>
        <Descriptions.Item label={'배너대상'} span={4} className="nopadding" style={{ padding: '10px 20px' }}>
          <Select
            defaultValue="MAIN_BOTTOM"
            style={{ width: 140, fontSize: 14, marginRight: 10 }}
            size={'large'}
            onChange={handleBannerType}
            disabled
          >
            <Select.Option value="MAIN_TOP">메인상단</Select.Option>
            <Select.Option value="MAIN_BOTTOM">메인하단</Select.Option>
          </Select>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <>
              <span>제목</span>
              <span style={{ color: 'red' }}> *</span>
            </>
          }
          span={4}
          className="nopadding"
        >
          <Input {...title} bordered={false} placeholder="제목을 입력해주세요" style={{ padding: '10px 20px' }} />
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <>
              <span>배너업로드</span>
              <span style={{ color: 'red' }}> *</span>
            </>
          }
          span={4}
          className="nopadding"
          style={{ padding: 25 }}
        >
          <FileUpload
            values={images ? [images] : []}
            maxCount={1}
            onChange={(images) => {
              setImages(images[0]);
            }}
            className="profileImg"
          />
          <a href={'/banner_foot.psd'} target={'_blank'}>
            배너가이드 받기
          </a>
        </Descriptions.Item>
        <Descriptions.Item label={'광고 URL'} span={4} className="nopadding">
          <Input {...uri} bordered={false} placeholder="URL 입력해주세요" style={{ padding: '10px 20px' }} />
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <>
              <span>광고시작일</span>
              <span style={{ color: 'red' }}> *</span>
            </>
          }
          span={4}
          className="nopadding"
        >
          <DatePicker
            value={startDate}
            onChange={setStartDate}
            style={{ width: 200, fontSize: 12, marginRight: 5 }}
            placeholder="날짜선택"
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <>
              <span>광고종료일</span>
              <span style={{ color: 'red' }}> *</span>
            </>
          }
          span={4}
          className="nopadding"
        >
          <DatePicker
            value={endDate}
            onChange={setEndDate}
            style={{ width: 200, fontSize: 12, marginRight: 5 }}
            placeholder="날짜선택"
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <>
              <span>순번</span>
              <span style={{ color: 'red' }}> *</span>
            </>
          }
          span={4}
          className="nopadding"
        >
          <Input
            {...order}
            bordered={false}
            placeholder="광고 순서를 입력해주세요"
            style={{ padding: '10px 20px' }}
            type={'number'}
          />
        </Descriptions.Item>
      </Descriptions>
      <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={handleCancel}
          size={'large'}
          className={styles.basicButton}
          style={{ marginRight: 5, height: 40, width: 100 }}
        >
          취소
        </Button>
        <Button
          onClick={handleCreateBanner}
          size={'large'}
          className={styles.basicButton}
          style={{ height: 40, width: 100, backgroundColor: '#FF7200', color: 'white' }}
        >
          등록
        </Button>
      </div>
    </div>
  );
};

export default BannerBottomCreate;
