import React, { useState } from 'react';
import { Button, DatePicker, Descriptions, Input, PageHeader, Select } from 'antd';
import useInput from '../../../hooks/useInput';
import { useNavigate } from 'react-router-dom';
import useAxios from '../../../hooks/useAxios';
import styles from '../../work/review/details/reviewDetails.module.less';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { restApi } from '../../../apis';
import moment from 'moment';
import TinyMCE from 'react-tinymce';

/**
 * 공지사항 생성 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const createPostApi = ({ type, title, contents, mainImage, images, active, role_type, created_at }) => {
  const endpoint = '/posts';
  const requestBody = {
    type,
    title,
    contents,
    main_image: mainImage,
    images,
    active,
    role_type: role_type || undefined,
    created_at,
  };
  return restApi.post(endpoint, requestBody);
};

const NoticeCreate = () => {
  const title = useInput('');

  const createPost = useAxios();
  const [images, setImages] = React.useState(null);
  const [contents, setContents] = React.useState(null);
  const [userType, setUserType] = React.useState('U');
  const [createdAt, setCreatedAt] = useState(moment());
  const navigate = useNavigate();

  // 뒤로가기
  const handleCancel = () => {
    navigate('/contents/notice', { replace: true });
  };

  // 생성 함수
  const handleCreatePost = async () => {
    if (!title.value) {
      alert('제목을 입력해주세요');
      return;
    }
    if (contents === '<p></p>\n') {
      alert('내용을 입력해주세요.');
      return;
    }
    try {
      await createPost.loadData(
        createPostApi({
          type: 'NOTICE',
          title: title.value,
          contents: contents, //contents.value,
          images: images,
          role_type: userType,
          created_at: createdAt.format('YYYY-MM-DD HH:mm:ss'),
        }),
      );
      alert('작성 완료되었습니다.');
      navigate('/contents/notice', { replace: true });
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
    }
  };

  // 타입 변경
  const handleChangeType = (data) => {
    setUserType(data);
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="공지사항 작성" />
      <Descriptions bordered>
        <Descriptions.Item label={'공지대상'} span={2} className="nopadding" style={{ padding: '10px 20px' }}>
          <Select
            defaultValue="U"
            style={{ width: 140, fontSize: 14, marginRight: 10 }}
            size={'large'}
            onChange={handleChangeType}
          >
            <Select.Option value="U">고객</Select.Option>
            <Select.Option value="T">기사</Select.Option>
            <Select.Option value="M">장인</Select.Option>
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label={'등록일'} span={2} className="nopadding" style={{ padding: '10px 20px' }}>
          <DatePicker value={createdAt} onChange={(v) => setCreatedAt(v)} showTime />
          {/*{userType === 'M' ? '장인' : userType === 'T' ? '기사' : '고객'}*/}
        </Descriptions.Item>
        <Descriptions.Item label={'제목'} span={4} className="nopadding">
          <Input {...title} bordered={false} placeholder="제목을 입력해주세요" style={{ padding: '10px 20px' }} />
        </Descriptions.Item>

        <Descriptions.Item label={'공지내용'} span={4} className="nopadding" style={{ verticalAlign: 'top' }}>
          <TinyMCE
            content={contents ?? ''}
            config={{
              // content_css: '/editor.css',
              plugins: 'code table',
              toolbar:
                'undo redo styleselect bold italic alignleft aligncenter alignright bullist numlist outdent indent code',
            }}
            onChange={(e) => {
              const text = e.target.getContent();
              setContents(text);
            }}
          />
        </Descriptions.Item>
      </Descriptions>
      <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={handleCancel}
          size={'large'}
          className={styles.basicButton}
          style={{ marginRight: 5, height: 40, width: 100 }}
        >
          취소
        </Button>
        <Button
          onClick={handleCreatePost}
          size={'large'}
          className={styles.basicButton}
          style={{ height: 40, width: 100, backgroundColor: '#FF7200', color: 'white' }}
        >
          등록
        </Button>
      </div>
    </div>
  );
};

export default NoticeCreate;
