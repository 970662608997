import React, { useState } from 'react';
import { Button, DatePicker, Input, PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import useInput from '../../../../hooks/useInput';
import styles from '../../../work/review/details/reviewDetails.module.less';
import { readPost, updatePost as updatePostApi } from '../../../../apis/posts';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import LabelRow from '../../../../components/LabelRow';
import FileUpload from '../../../../components/FileUpload';
import { postType } from '../../../../common/defines';
import moment from 'moment';

/**
 * 구두이야기 수정 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const StoryUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const post = useAxios();

  const updatePost = useAxios();
  const title = useInput('');
  const [contents, setContents] = React.useState('');
  const [mainImage, setMainImage] = React.useState();
  const [images, setImages] = React.useState([]);
  const [createdAt, setCreatedAt] = useState(moment());

  const handleSubmit = async () => {
    if (!title.value.length > 0) {
      alert('제목을 입력해주세요.');
      return;
    }
    try {
      const params = {
        id,
        type: postType.SHOE_STORY,
        title: title.value,
        contents: contents,
        mainImage,
        images,
        created_at: createdAt.format('YYYY-MM-DD HH:mm:ss'),
      };
      await updatePost.loadData(updatePostApi(params));
      alert('수정 완료되었습니다.');
      navigate(`/contents/story/details/${id}`, { replace: true });
    } catch (error) {
      alert(error?.response?.data?.message ?? error);
    }
  };

  React.useEffect(() => {
    post.loadData(readPost({ id })).then(({ data: responseData }) => {
      title.onChange(responseData.title);
      setMainImage(responseData.main_image);
      setImages(responseData.images);
      setCreatedAt(moment(responseData.created_at));
    });
  }, []);

  return (
    post.data && (
      <div>
        <PageHeader className="site-page-header" title="구두이야기 수정" />
        <div className="labelrowWrapper">
          <LabelRow label="제목">
            <Input placeholder="최대 30자 이내로 입력하세요." {...title} style={{ width: 500 }} />
          </LabelRow>
          <LabelRow label="등록일">
            <DatePicker value={createdAt} onChange={(v) => setCreatedAt(v)} showTime />
          </LabelRow>
          <LabelRow
            label={`내용이미지(${images.length}/9)`}
            maxCount={9}
            hideUpload={(images) => images.length >= 9}
            heightOver
          >
            <div>
              <FileUpload
                values={images}
                maxCount={9}
                onChange={(images) => {
                  setImages(images);
                }}
              />
              <span style={{ fontSize: 12, color: '#3d3d3d', margin: '6px 0px', display: 'block' }}>
                권장 크기 : 1000 x 1000 (윈도대상 750 x 1000)
              </span>
              <span style={{ fontSize: 12, color: '#3d3d3d', margin: '6px 0px', display: 'block' }}>
                추가이미지는 최대 9개까지 설정가능.
              </span>
            </div>
          </LabelRow>
        </div>
        <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
          <Button
            onClick={() => navigate('/contents/story', { replace: true })}
            size={'large'}
            className={styles.basicButton}
            style={{ marginRight: 5, height: 40, width: 100 }}
          >
            취소
          </Button>
          <Button
            onClick={handleSubmit}
            size={'large'}
            className={styles.basicButton}
            style={{ height: 40, width: 100, backgroundColor: '#FF7200', color: 'white' }}
          >
            수정
          </Button>
        </div>
      </div>
    )
  );
};

export default StoryUpdate;
