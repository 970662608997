import React from 'react';
import VendorDetailsMenu, { useGetVendor } from './menu';
import VCalcIndex from '../../for_vendor/calcs';

/**
 * 벤더사 상세 계산
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const VendorsDetailsCalc = (props) => {
  const vendor = useGetVendor();
  return (
    <div>
      <VendorDetailsMenu index={1} />
      <VCalcIndex vendorId={vendor?.id} />
    </div>
  );
};

export default VendorsDetailsCalc;
