import React, { useEffect, useState } from 'react';
import { Modal, Table } from 'antd';
import { restApi } from '../apis';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { addComma } from '../common/utils';

/**
 * 정산 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const CalcView = ({ id, detColumns }) => {
  const [showCalcOnly, setShowCalcOnly] = useState();

  const columns = [
    {
      key: 'start',
      title: '정산시작일',
      dataIndex: 'calc',
      align: 'center',
      width: 100,
      render: (d) => moment(d.sdate).format('YYYY-MM-DD') + ' 00:00:00',
    },
    {
      key: 'finish',
      title: '정산종료일',
      dataIndex: 'calc',
      align: 'center',
      width: 100,
      render: (d) => moment(d.date).add(-1, 'seconds').format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '금액',
      dataIndex: 'money',
      align: 'center',
      width: 100,
      render: (d) => addComma(String(d)) + ' 원',
    },
    {
      title: '',
      dataIndex: 'id',
      align: 'center',
      width: 100,
      render: (data) => (
        <a
          onClick={() => {
            setShowCalcOnly(data);
          }}
          style={{ color: 'black' }}
        >
          자세히 보기
        </a>
      ),
    },
  ];
  const [data, setData] = useState();

  const loadData = async (page = 0) => {
    const { data } = await restApi.get(`/calcs/foruser/${id}`, {
      params: {
        page,
        limit: 10,
      },
    });
    console.log(data);
    setData(data);
  };

  useEffect(() => {
    loadData().catch(console.warn);
  }, [id]);

  const [calcData, setCalcData] = useState();

  const loadData2 = async (page = 0) => {
    if (!showCalcOnly) {
      setCalcData(undefined);
      return false;
    }
    const { data } = await restApi.get('/purchases/histories', {
      params: {
        calc_item_id: showCalcOnly,
        page,
        limit: 10,
      },
    });
    console.log(data);
    setCalcData(data);
  };

  useEffect(() => {
    loadData2();
  }, [showCalcOnly]);

  const pagination = {
    position: ['bottomCenter'],
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      loadData(p - 1).catch(console.warn);
    },
  };

  const pagination2 = {
    position: ['bottomCenter'],
    total: calcData?.total_elements,
    pageSize: 10,
    current: calcData?.page + 1,
    onChange: async (p) => {
      loadData2(p - 1).catch(console.warn);
    },
  };

  return (
    <div>
      <Modal
        visible={showCalcOnly}
        width={1400}
        footer={false}
        title={'정산 상세내역'}
        onCancel={() => setShowCalcOnly(undefined)}
      >
        <Table
          scroll={{ x: 'max-content' }}
          dataSource={calcData?.items}
          columns={detColumns}
          pagination={
            pagination2
              ? {
                  ...pagination2,
                  position: ['bottomCenter'],
                  showSizeChanger: false,
                }
              : false
          }
        />
      </Modal>
      <Table
        scroll={{ x: 'max-content' }}
        dataSource={data?.items}
        columns={columns}
        pagination={
          pagination
            ? {
                ...pagination,
                position: ['bottomCenter'],
                showSizeChanger: false,
              }
            : false
        }
      />
    </div>
  );
};

export default CalcView;
