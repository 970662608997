import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, message, PageHeader, Radio, Row, Space, Spin, Table, Upload } from 'antd';
import { parseImageUrl } from '../../../common/utils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { getAPIHost, restApi, TOKEN_SAVE_NAME } from '../../../apis';
import produce from 'immer';
import { getProductCategories } from '../../../data/products';
import DraggableEditableTableLastIndex from '../../../components/DraggableEditableTableLastIndex';
import { Link } from 'react-router-dom';
import PCategory1Form from './form1';
import useWindowDimension from '../../../hooks/useWindowDimension';

const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);

const FileUploader = ({ guides, index, data, setData, setPreview }) => {
  const token = JSON.parse(localStorage.getItem(TOKEN_SAVE_NAME));

  const [files, setFiles] = useState([]);
  useEffect(() => {
    setFiles(guides?.map((v) => ({ uid: v, fid: v, name: `${v}.jpg`, url: parseImageUrl(v, 100, 100) })));
  }, [guides]);

  return (
    <Upload
      action={getAPIHost() + '/files'}
      method={'PUT'}
      name={'filedata'}
      headers={{
        Authorization: `Bearer ${token.access_token}`,
      }}
      multiple={'true'}
      listType="picture-card"
      fileList={files}
      onPreview={(e) => setPreview(e.url.substring(0, e.url.indexOf('?')))}
      onChange={(e) => {
        setFiles(e.fileList);
        if (e.file.status === 'removed') {
          const list = e.fileList?.filter((v) => !!v.fid)?.map((v) => v.fid) ?? [];
          setData(
            produce(data, (draft) => {
              draft[index].guides = list;
            }),
          );
        } else if (e.file.status === 'done') {
          const v = e.file?.response?.id;
          const list = e.fileList?.filter((v) => !!v.fid)?.map((v) => v.fid) ?? [];
          list.push(v);
          setData(
            produce(data, (draft) => {
              draft[index].guides = list;
            }),
          );
        }
      }}
    >
      {files.length >= 8 ? null : uploadButton}
    </Upload>
  );
};
/**
 * 상품 등록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const ProductsIndex = () => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);

  const { types, categories } = useSelector((s) => s.product, shallowEqual);
  const [type, setType] = useState(types?.[0]?.type ?? 'SC');

  const getOrigin = useMemo(() => {
    return categories?.[type] ?? [];
  }, [categories, type]);

  useEffect(() => {
    setData([...getOrigin]);
  }, [getOrigin]);

  const column = [
    {
      title: '코드',
      dataIndex: 'code',
      align: 'center',
      width: 80,
    },
    {
      title: '이름',
      dataIndex: 'name',
      align: 'left',
      width: 150,
    },
    {
      title: '색상',
      dataIndex: 'color',
      align: 'left',
      width: 150,
    },
    {
      title: '단어',
      dataIndex: 'extra2',
      align: 'left',
      width: 100,
    },
    {
      title: '상단설명',
      dataIndex: 'extra1',
      align: 'left',
    },
    {
      title: '텍스트박스 설명',
      dataIndex: 'extra3',
      align: 'left',
    },
    {
      title: '',
      dataIndex: 'id',
      align: 'center',
      width: 80,
      render: (data) => (
        <a onClick={() => setAddForm(data)} style={{ color: 'black' }}>
          수정
        </a>
      ),
    },
    {
      title: '',
      dataIndex: 'code',
      align: 'center',
      width: 100,
      render: (data) => (
        <Link to={`/settings/products/${type}/${data}`}>
          <a style={{ color: 'black' }}>자세히보기</a>
        </Link>
      ),
    },
  ];

  const [addForm, setAddForm] = useState();

  const [loading, setLoading] = useState(false);
  const handleSort = async (dragIndex, hoverIndex, data) => {
    setLoading(true);
    try {
      const ids = data?.map((v) => v.id);
      await restApi.put(`/products/categories/sort`, { ids });
      message.success('저장되었습니다.');
      dispatch(getProductCategories(type));
    } catch (e) {
      alert(e?.message);
    }
    setLoading(false);
  };

  const width = useWindowDimension();
  return (
    <div>
      <PCategory1Form
        type={type}
        formType={addForm}
        onExit={() => setAddForm(undefined)}
        onSubmitted={() => {
          dispatch(getProductCategories(type));
          setAddForm(undefined);
        }}
      />
      {!!loading && (
        <div
          style={{
            zIndex: 100,
            position: 'fixed',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'center',
            textAlign: 'center',
            background: 'rgba(255,255,255,0.5)',
          }}
        >
          <Spin style={{ margin: '0px auto' }} />
        </div>
      )}
      <PageHeader className="site-page-header" title="상품 설정" />
      <div style={{ marginBottom: 20 }}>
        <Row>
          <Col sm={18} xs={24}>
            <Space>
              <Radio.Group
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                }}
              >
                {types?.map((type) => (
                  <Radio.Button value={type.type} key={type.type}>
                    {type.name}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Space>
          </Col>
          <Col sm={6} xs={24} style={{ textAlign: 'right' }}>
            <Button onClick={() => setAddForm(0)}>추가</Button>
          </Col>
        </Row>
      </div>
      {width < 1023 ? (
        <Table scroll={{ x: 'max-content' }} dataSource={data} columns={column} />
      ) : (
        <DraggableEditableTableLastIndex
          onMoveRow={handleSort}
          style={{ width: '100%' }}
          originData={data}
          originColumns={column}
          editable={true}
        />
      )}
    </div>
  );
};

export default ProductsIndex;
