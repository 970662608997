import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, message, PageHeader, Radio, Row, Space, Spin, Table } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { restApi } from '../../../apis';
import { getProductCategories } from '../../../data/products';
import DraggableEditableTableLastIndex from '../../../components/DraggableEditableTableLastIndex';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PCategory2Form from './form2';
import useWindowDimension from '../../../hooks/useWindowDimension';

/**
 * 상품 설정 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const ProductsCategory2 = () => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);

  const params = useParams();
  const type = params.type;

  const { categories, types } = useSelector((s) => s.product, shallowEqual);
  const item = useMemo(() => {
    const items = categories?.[params.type];
    return items?.find?.((v) => String(v.code) === params.code);
  }, [params, categories]);

  const getOrigin = useMemo(() => {
    return item?.items ?? [];
  }, [item]);

  useEffect(() => {
    setData([...getOrigin]);
  }, [getOrigin]);

  const column = [
    {
      title: '이름',
      dataIndex: 'name',
      align: 'left',
      width: 150,
    },
    {
      title: '색상',
      dataIndex: 'color',
      align: 'left',
      width: 150,
    },
    {
      title: '상단설명1',
      dataIndex: 'extra2',
      align: 'left',
    },
    {
      title: '상단설명2',
      dataIndex: 'extra1',
      align: 'left',
    },
    {
      title: '',
      dataIndex: 'id',
      align: 'center',
      width: 80,
      render: (data) => (
        <a onClick={() => setAddForm(data)} style={{ color: 'black' }}>
          수정
        </a>
      ),
    },
    {
      title: '',
      dataIndex: 'id',
      align: 'center',
      width: 100,
      render: (data) => (
        <Link to={`/settings/products/${type}/${params.code}/${data}`}>
          <a style={{ color: 'black' }}>자세히보기</a>
        </Link>
      ),
    },
  ];

  const [addForm, setAddForm] = useState();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const handleSort = async (dragIndex, hoverIndex, data) => {
    setLoading(true);
    try {
      const ids = data?.map((v) => v.id);
      await restApi.put(`/products/categories/sort`, { ids });
      message.success('저장되었습니다.');
      dispatch(getProductCategories(type));
    } catch (e) {
      alert(e?.message);
    }
    setLoading(false);
  };
  const width = useWindowDimension();

  return (
    <div>
      {!!loading && (
        <div
          style={{
            zIndex: 100,
            position: 'fixed',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'center',
            textAlign: 'center',
            background: 'rgba(255,255,255,0.5)',
          }}
        >
          <Spin style={{ margin: '0px auto' }} />
        </div>
      )}
      <PCategory2Form
        type={type}
        code={params.code}
        parent={item}
        formType={addForm}
        onExit={() => setAddForm(undefined)}
        onSubmitted={() => {
          dispatch(getProductCategories(type));
          setAddForm(undefined);
        }}
      />
      <PageHeader className="site-page-header" title="상품 상세 설정" />
      <div style={{ marginBottom: 20 }}>
        {width < 1023 ? (
          <div>
            <div style={{ marginBottom: 10 }}>
              <Radio.Group
                value={type}
                onChange={(e) => {
                  navigate(`/settings/products/${e.target.value}/${params?.code}`, { replace: true });
                }}
              >
                {types?.map((type) => (
                  <Radio.Button value={type.type} key={type.type}>
                    {type.name}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </div>
            <div style={{ marginBottom: 10 }}>
              <Radio.Group
                value={params?.code}
                onChange={(e) => {
                  navigate(`/settings/products/${type}/${e.target.value}`, { replace: true });
                }}
              >
                {categories[type]?.map((item) => (
                  <Radio.Button key={item.code} value={String(item.code)}>
                    {item.name} ({item.code})
                  </Radio.Button>
                ))}
              </Radio.Group>
            </div>
            <div style={{ marginBottom: 10 }}>
              <Button onClick={() => setAddForm(0)}>추가</Button>
            </div>
          </div>
        ) : (
          <Row>
            <Col flex={'1'}>
              <Space>
                <Radio.Group
                  value={type}
                  onChange={(e) => {
                    navigate(`/settings/products/${e.target.value}/${params?.code}`, { replace: true });
                  }}
                >
                  {types?.map((type) => (
                      <Radio.Button value={type.type} key={type.type}>
                        {type.name}
                      </Radio.Button>
                  ))}
                </Radio.Group>
                <Radio.Group
                  value={params?.code}
                  onChange={(e) => {
                    navigate(`/settings/products/${type}/${e.target.value}`, { replace: true });
                  }}
                >
                  {categories[type]?.map((item) => (
                    <Radio.Button key={item.code} value={String(item.code)}>
                      {item.name} ({item.code})
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Space>
            </Col>
            <Col>
              <Button onClick={() => setAddForm(0)}>추가</Button>
            </Col>
          </Row>
        )}
      </div>

      {width < 1023 ? (
        <Table scroll={{ x: 'max-content' }} dataSource={data} columns={column} />
      ) : (
        <DraggableEditableTableLastIndex
          onMoveRow={handleSort}
          style={{ width: '100%' }}
          originData={data}
          originColumns={column}
          editable={true}
        />
      )}
    </div>
  );
};

export default ProductsCategory2;
