export const accountSearchFields = [
  {
    width: 0.5,
    label: '정렬',
    type: 'radiobutton',
    key: 'sort',
    defaultValue: 'id',
    items: [
      { label: '오름차순', value: 'id' },
      { label: '내림차순', value: 'created_at' },
    ],
  },
  {
    width: 0.5,
    label: '기간 설정',
    type: 'daterange',
    key: 'dateRange',
    keys: ['start_at', 'end_at'],
  },
  {
    width: 0.5,
    label: '검색조건',
    type: 'radiobutton',
    key: 'field',
    defaultValue: 'name',
    items: [
      { label: '이름', value: 'name' },
      { label: '연락처', value: 'phone' },
      { label: '주소', value: 'address' },
    ],
  },
  {
    width: 0.5,
    label: '키워드',
    type: 'input',
    key: 'keyword',
  },
];

export const vendorSearchFields = [
  {
    width: 0.5,
    label: '정렬',
    type: 'radiobutton',
    key: 'sort',
    defaultValue: 'created_at',
    items: [
      { label: '등록일자순', value: 'created_at' },
      { label: '이름순', value: 'name' },
    ],
  },
  {
    width: 0.5,
    label: '기간 설정',
    type: 'daterange',
    key: 'dateRange',
    keys: ['start_at', 'end_at'],
  },
  {
    width: 0.5,
    label: '검색조건',
    type: 'radiobutton',
    key: 'field',
    defaultValue: 'name',
    items: [
      { label: '회사명', value: 'name' },
      { label: '연락처', value: 'phone' },
      { label: '주소', value: 'address' },
    ],
  },
  {
    width: 0.5,
    label: '키워드',
    type: 'input',
    key: 'keyword',
  },
];

export const codeSearchFields = [
  {
    width: 0.5,
    label: '정렬',
    type: 'radiobutton',
    key: 'sort',
    defaultValue: 'created_at',
    items: [
      { label: '등록일자순', value: 'created_at' },
      { label: '번호순', value: 'id' },
      { label: '이름순', value: 'name' },
    ],
  },
  {
    width: 0.5,
    label: '기간 설정',
    type: 'daterange',
    key: 'dateRange',
    keys: ['start_at', 'end_at'],
  },
  {
    width: 0.5,
    label: '검색조건',
    type: 'radiobutton',
    key: 'field',
    defaultValue: 'name',
    items: [
      { label: '이름', value: 'name' },
      { label: '주소', value: 'address' },
    ],
  },
  {
    width: 0.5,
    label: '키워드',
    type: 'input',
    key: 'keyword',
  },
];

export const expertSearchField = [
  {
    width: 0.5,
    label: '정렬',
    type: 'radiobutton',
    key: 'sort',
    defaultValue: 'created_at',
    items: [
      { label: '가입일자순', value: 'created_at' },
      { label: '관리번호순', value: 'alias' },
    ],
  },
  {
    width: 0.5,
    label: '기간 설정',
    type: 'daterange',
    key: 'dateRange',
    keys: ['start_at', 'end_at'],
  },
  {
    width: 0.5,
    label: '검색조건',
    type: 'select',
    key: 'field',
    defaultValue: 'name',
    items: [
      { label: '이름', value: 'name' },
      { label: '연락처', value: 'phone' },
    ],
  },
  {
    width: 0.5,
    label: '키워드',
    type: 'input',
    key: 'keyword',
  },
];

export const teamsSearchFields = [
  {
    width: 0.5,
    label: '정렬',
    type: 'radiobutton',
    key: 'sort',
    defaultValue: 'created_at',
    items: [
      { label: '등록일자순', value: 'created_at' },
      { label: '번호순', value: 'id' },
    ],
  },
  {
    width: 0.5,
    label: '기간 설정',
    type: 'daterange',
    key: 'dateRange',
    keys: ['start_at', 'end_at'],
  },
  {
    width: 0.5,
    label: '검색조건',
    type: 'select',
    key: 'field',
    defaultValue: 'name',
    items: [
      { label: '이름', value: 'name' },
      { label: '연락처', value: 'phone' },
    ],
  },
  {
    width: 0.5,
    label: '키워드',
    type: 'input',
    key: 'keyword',
  },
];

export const workSearchFields = [
  {
    width: 1,
    label: '정렬',
    type: 'radiobutton',
    key: 'sort',
    defaultValue: 'created_at',
    items: [
      // { label: '기본(일시)', value: 'sort_at' },
      { label: '주문일시', value: 'created_at' },
      { label: '주소순', value: 'address' },
    ],
  },
  {
    width: 0.5,
    label: '서비스',
    type: 'select',
    key: 'type',
    items: [
      { label: '정기구독', value: 'SC' },
      { label: '하루배송', value: 'DT' },
      { label: '긴급배송', value: 'SP' },
      { label: '택배배송', value: 'TS' },
    ],
  },
  {
    width: 0.5,
    label: '상태',
    type: 'radiobutton',
    key: 'state',
    defaultValue: '-',
    items: [
      { label: '전체', value: '-' },
      { label: '결제완료', value: 'BUYED' },
      { label: '결제대기', value: 'PAY_READY' },
      { label: '취소', value: 'CANCELLED' },
    ],
  },
  {
    width: 0.5,
    label: '일자 종류',
    type: 'radiobutton',
    key: 'date_type',
    defaultValue: 'created_at',
    items: [
      { label: '주문일시', value: 'created_at' },
      { label: '수거일시', value: 'begin_expected_at' },
      { label: '배송일시', value: 'finish_expected_at' },
    ],
  },
  {
    width: 0.5,
    label: '일자',
    type: 'daterange',
    key: 'dateRange',
    keys: ['start_at', 'end_at'],
  },
  {
    width: 0.5,
    label: '검색조건',
    type: 'radiobutton',
    key: 'field',
    defaultValue: 'name',
    items: [
      { label: '이름', value: 'name' },
      { label: '연락처', value: 'phone' },
      { label: '주문번호', value: 'order_num' },
      { label: '주소', value: 'address' },
    ],
  },
  {
    width: 0.5,
    label: '키워드',
    type: 'input',
    key: 'keyword',
  },
];

export const paymentSearchField = [
  {
    width: 0.5,
    label: '검색조건',
    type: 'select',
    key: 'field',
    defaultValue: 'name',
    items: [
      { label: '이름', value: 'name' },
      { label: '연락처', value: 'phone' },
    ],
  },
  {
    width: 0.5,
    label: '키워드',
    type: 'input',
    key: 'keyword',
  },
  {
    width: 0.5,
    label: '상태',
    type: 'radiobutton',
    key: 'state',
    defaultValue: '-',
    items: [
      { label: '전체', value: '-' },
      { label: '결제대기', value: 'WAIT' },
      { label: '결제완료', value: 'BUYED' },
      { label: '결제취소', value: 'CANCELLED' },
    ],
  },
];

export const reviewSearchField = [
  {
    width: 0.5,
    label: '정렬',
    type: 'radiobutton',
    key: 'sort',
    defaultValue: 'created_at',
    items: [
      { label: '작성일자순', value: 'created_at' },
      { label: '번호순', value: 'id' },
    ],
  },
  {
    width: 0.5,
    label: '기간',
    type: 'daterange',
    key: 'dateRange',
    keys: ['start_at', 'end_at'],
  },
  {
    width: 0.5,
    label: '검색조건',
    type: 'select',
    key: 'field',
    defaultValue: 'name',
    items: [
      { label: '이름', value: 'name' },
      { label: '연락처', value: 'phone' },
    ],
  },
  {
    width: 0.5,
    label: '키워드',
    type: 'input',
    key: 'keyword',
  },
];

export const QnASearchField = [
  {
    width: 0.5,
    label: '정렬',
    type: 'radiobutton',
    key: 'sort',
    defaultValue: 'created_at',
    items: [
      { label: '작성일자순', value: 'created_at' },
      { label: '번호순', value: 'id' },
    ],
  },
  {
    width: 0.5,
    label: '기간',
    type: 'daterange',
    key: 'dateRange',
    keys: ['start_at', 'end_at'],
  },
  {
    width: 0.5,
    label: '검색조건',
    type: 'select',
    key: 'field',
    defaultValue: 'name',
    items: [
      { label: '이름', value: 'name' },
      { label: '제목', value: 'title' },
    ],
  },
  {
    width: 0.5,
    label: '키워드',
    type: 'input',
    key: 'keyword',
  },
];

export const accountDetailSearchFields = [
  {
    width: 0.5,
    label: '주문일시',
    type: 'daterange',
    key: 'dateRange',
    keys: ['start_at', 'end_at'],
  },
  {
    width: 0.5,
    label: '서비스',
    type: 'select',
    key: 'type',
    items: [
      { label: '정기구독', value: 'SC' },
      { label: '하루배송', value: 'DT' },
      { label: '긴급배송', value: 'SP' },
      { label: '택배배송', value: 'TS' },
    ],
  },
  // {
  //     width: 0.5,
  //     label: '검색조건',
  //     type: 'select',
  //     key: 'field',
  //     defaultValue: 'name',
  //     items: [
  //         { label: '이름', value: 'name' },
  //         { label: '연락처', value: 'phone' },
  //     ],
  // },
  // {
  //     width: 0.5,
  //     label: '키워드',
  //     type: 'input',
  //     key: 'keyword',
  // },
];

export const bannerSearchField = [
  {
    width: 0.5,
    label: '정렬',
    type: 'radiobutton',
    key: 'sort',
    defaultValue: 'created_at',
    items: [
      { label: '작성일자순', value: 'created_at' },
      { label: '번호순', value: 'id' },
    ],
  },
  {
    width: 0.5,
    label: '기간',
    type: 'daterange',
    key: 'dateRange',
    keys: ['start_at', 'end_at'],
  },
  {
    width: 0.5,
    label: '제목검색',
    type: 'input',
    key: 'keyword',
  },
];

export const bannersSearchField = [
  {
    width: 0.5,
    label: '정렬',
    type: 'radiobutton',
    key: 'sort',
    defaultValue: 'order_index',
    items: [
      // { label: '작성일자순', value: 'created_at' },
      // { label: '번호순', value: 'id' },
      { label: '노출순서순', value: 'order_index' },
    ],
  },
  {
    width: 0.5,
    label: '기간',
    type: 'daterange',
    key: 'dateRange',
    keys: ['start_at', 'end_at'],
  },
  {
    width: 0.5,
    label: '제목검색',
    type: 'input',
    key: 'keyword',
  },
];

export const faqSearchField = [
  {
    width: 0.5,
    label: '정렬',
    type: 'radiobutton',
    key: 'sort',
    defaultValue: 'created_at',
    items: [
      { label: '작성일자순', value: 'created_at' },
      { label: '번호순', value: 'id' },
    ],
  },
  {
    width: 0.5,
    label: '기간',
    type: 'daterange',
    key: 'dateRange',
    keys: ['start_at', 'end_at'],
  },
  {
    width: 0.5,
    label: '키워드',
    type: 'input',
    key: 'keyword',
  },
];

export const settingSearchField = [
  {
    width: 0.5,
    label: '검색조건',
    type: 'select',
    key: 'field',
    defaultValue: 'name',
    items: [
      { label: '명칭', value: 'name' },
      { label: '주소', value: 'address' },
    ],
  },
  {
    width: 0.5,
    label: '키워드',
    type: 'input',
    key: 'keyword',
  },
];

export const keywordSearchField = [
  {
    width: 0.5,
    label: '키워드',
    type: 'input',
    key: 'keyword',
  },
];

export const pushSearchField = [
  {
    width: 0.5,
    label: '타겟팅 대상',
    type: 'radio',
    key: 'targeting',
    isSort: true,
    items: [
      { label: '전체', value: 'ALL' },
      { label: '재이용고객', value: 'REUSE' },
      { label: '타겟팅', value: 'TARGETING' },
    ],
  },
  {
    width: 0.5,
    label: '상태',
    type: 'select',
    key: 'status',
    items: [
      { label: '전송완료', value: 'SENT' },
      { label: '대기', value: 'WAIT' },
    ],
  },
  {
    width: 0.5,
    label: '키워드',
    type: 'input',
    key: 'keyword',
  },
];

export const couponSearchField = [
  {
    width: 0.5,
    label: '타겟팅 대상',
    type: 'radio',
    key: 'targeting',
    isSort: true,
    items: [
      { label: '첫이용고객', value: 'FIRST' },
      { label: '재이용고객', value: 'REUSE' },
      { label: '타겟팅', value: 'TARGETING' },
    ],
  },
  {
    width: 0.5,
    label: '쿠폰이용상태',
    type: 'select',
    key: 'status',
    items: [
      { label: '적용', value: 'READY' },
      { label: '대기', value: 'WAIT' },
    ],
  },
  {
    width: 0.5,
    label: '키워드',
    type: 'input',
    key: 'keyword',
  },
];
