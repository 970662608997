import React from 'react';

/**
 * axios 통신을 위한 커스텀 훅스
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const useAxios = () => {
  const [state, setState] = React.useState({
    data: null,
    error: null,
    loading: false,
  });

  const loadData = async (axios) => {
    setState({ data: null, error: null, loading: true });
    try {
      const responseData = await axios;
      if (responseData) {
        setState({ data: responseData.data, error: null, loading: false });
        return responseData;
      }
      return null;
    } catch (error) {
      setState((prevState) => ({ ...prevState, error, loading: false }));
      throw error;
    }
  };

  return { ...state, loadData };
};

export default useAxios;
