import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Menu } from 'antd';

/**
 * 벤더사 전용 메뉴
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const VendorMenu = (props) => {
  const { vendors } = useSelector((s) => s.vendor, shallowEqual);
  return (
    <Menu.ItemGroup key={'vendor-list'} title={'기업'}>
      {vendors?.map((v) => (
        <Menu.Item key={`/vendors/${v.id}/info`}>{v?.name}</Menu.Item>
      ))}
    </Menu.ItemGroup>
  );
  return;
};

export default VendorMenu;
