import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal, PageHeader, Radio, Table, Typography } from 'antd';
import { workSearchFields } from '../../../common/searchFields';
import SearchForm from '../../../components/datalist/SearchForm';
import TableButtonWrap from '../../../components/TableButtonWrap';
import { phoneFormat, toCommaNumber } from '../../../common/utils';
import moment from 'moment';
import { getAPIHost, restApi } from '../../../apis';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import fileDownload from 'js-file-download';
import { NEW_RUN_STATE } from '../../../common/defines';
import FileSaver from 'file-saver';

/**
 * 주문내역 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */

const ProductRemoveView = ({ column, loadParent }) => {
  const [data, setData] = useState();

  const loadData = async (page = 0) => {
    const { data } = await restApi.get('/cms/purchases', {
      params: {
        removed: true,
        page,
        limit: 10,
      },
    });
    setData(data);
  };

  useEffect(() => {
    loadData().catch(console.warn);
  }, []);

  const pagination = {
    position: ['bottomCenter'],
    showSizeChanger: false,
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      await loadData(p - 1);
    },
  };

  const tableColumn = useMemo(() => {
    const o = [...column];
    o[0].render = (v, r, i) => {
      return data?.total_elements - i - data?.page * data?.page_size;
    };
    return o;
  }, [column, data]);

  const [rowKeys, setRowKeys] = useState([]);
  const handleChange = (selectedRowKeys, selectedRows) => {
    setRowKeys(selectedRowKeys);
  };
  const handleRecover = async () => {
    if (rowKeys?.length === 0) {
      window.alert('복구할 내역을 선택하세요');
      return;
    }
    await restApi.put(`/cms/purchases/actives`, {
      ids: rowKeys?.map((v) => parseInt(v, 10)),
      active: true,
    });
    await loadParent?.();
  };

  return (
    <div style={{ position: 'relative' }}>
      <Table
        rowSelection={{
          type: 'checkbox',
          onChange: handleChange,
          selectedRowKeys: rowKeys,
        }}
        dataSource={data?.items}
        columns={tableColumn}
        pagination={pagination}
        scroll={{ x: 'max-content' }}
        rowKey={'id'}
      />
      <TableButtonWrap position="left" buttons={[{ label: '복원', onClick: handleRecover }]} />
    </div>
  );
};

const OrderIndex = ({ vendorId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = React.useMemo(() => ({ ...qs.parse(location?.search), vendor_id: vendorId }), [location?.search]);

  const [data, setData] = React.useState();

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      date_type: 'created_at',
      sort: 'created_at',
      field: 'name',
      state: '-',
      ...query,
      limit: 10,
    };

    if (query.state === '-' || !query.state) {
      params.states = ['PAY_READY', 'BUYED', 'CANCELLED', 'FINISH'];
    } else {
      params.state = query.state;
    }

    params.dir = query.sort === 'address' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    } else if (query.field === 'ids') {
      params.order_num = query.keyword;
    }

    const { data } = await restApi.get('/cms/purchases', {
      params,
    });
    setData(data);
    setRowKeys([]);
  }, [query]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  const columns = React.useMemo(
    () => [
      {
        title: '번호',
        dataIndex: 'number',
        align: 'center',
        width: 90,
        render: (_, __, index) => {
          return (data?.total_elements || 0) - (10 * data?.page + index);
        },
      },
      {
        title: '주문방식',
        dataIndex: 'ref',
        key: 'ref',
        align: 'center',
        width: 100,
        render: (r) => r ?? '앱',
      },
      {
        title: '주문번호',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 90,
      },
      {
        title: '서비스',
        dataIndex: 'type',
        align: 'center',
        width: 100,
        render: (data) => {
          if (data === 'SC') return '정기배송';
          if (data === 'DT') return '하루배송';
          if (data === 'SP') return '긴급배송';
          if (data === 'TS') return '택배배송';
          return data;
        },
      },
      {
        title: '고객이름/번호',
        dataIndex: 'account',
        align: 'center',
        width: 200,
        render: (data, row, index) => {
          if (data?.nickname && data?.phone) {
            return (
              <>
                <Typography.Text ellipsis={true} style={{ width: 60 }}>
                  {data?.nickname}
                </Typography.Text>
                / {phoneFormat(data?.phone)}
              </>
            );
          } else {
            return '탈퇴회원';
          }
        },
      },
      {
        title: '주문일시',
        dataIndex: 'created_at',
        align: 'center',
        width: 160,
        render: (data) => moment(data)?.format('YYYY-MM-DD HH:mm'),
      },
      {
        title: '지역',
        dataIndex: 'address',
        width: 140,
        render: (r) => {
          if (!r) return null;
          const o = r.split(' ').filter((v) => !!v);
          return [o[0], o[1]].join(' ');
        },
      },
      {
        title: '결제현황',
        dataIndex: 'state',
        align: 'center',
        width: 100,
        render: (data) =>
          data === 'BUYED'
            ? '구매완료'
            : data === 'CANCELLED'
            ? '취소'
            : data === 'FINISH'
            ? '완료'
            : data === 'PAY_READY'
            ? '결제대기'
            : data,
      },
      {
        title: '진행현황',
        dataIndex: 'run_state',
        align: 'center',
        width: 100,
        render: (data) => NEW_RUN_STATE[data] ?? data,
      },
      {
        title: '결제금액',
        dataIndex: 'sale_price',
        align: 'center',
        width: 100,
        render: (data, row) => toCommaNumber(data + row.delivery_price),
      },
      {
        title: '수거일시',
        dataIndex: 'begin_expected_at',
        align: 'center',
        width: 200,
        render: (data) => {
          if (!data) return '';
          return moment(data)?.format('YYYY-MM-DD HH:mm') + ' ~ 08:00';
        },
      },
      {
        title: '배송일시',
        dataIndex: 'finish_expected_at',
        align: 'center',
        width: 200,
        render: (data) => {
          if (!data) return '';
          return moment(data).add(-10, 'hours').format('YYYY-MM-DD HH:mm') + ' ~ 08:00';
        },
      },
      {
        title: '자세히보기',
        dataIndex: 'id',
        align: 'center',
        width: 120,
        render: (data) => (
          <Link to={`/work/order/details/${data}`}>
            <a style={{ color: 'black' }}>자세히보기</a>
          </Link>
        ),
      },
    ],
    [data],
  );

  // 테이블 페이지네이션
  const pagination = {
    position: ['bottomCenter'],
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      navigate('?' + qs.stringify({ ...query, page: p - 1 }), { replace: true });
    },
  };

  const [rowKeys, setRowKeys] = useState([]);
  const [showModal, setShowModal] = useState();
  const handleDelete = async () => {
    if (rowKeys?.length === 0) {
      window.alert('삭제할 내역을 선택하세요');
      return;
    }
    if (!window.confirm('삭제하시겠습니끼?')) return;
    await restApi.put(`/cms/purchases/actives`, {
      ids: rowKeys?.map((v) => parseInt(v, 10)),
      active: false,
    });
    await loadData();
  };

  const handleDeleteModal = () => {
    setShowModal(true);
  };

  const handleChange = (selectedRowKeys, selectedRows) => {
    setRowKeys(selectedRowKeys);
  };

  const xlsxSave = () => {
    const params = {
      ...query,
      states: ['PAY_READY', 'BUYED', 'CANCELLED', 'FINISH'],
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    } else if (query.field === 'order_num') {
      params.order_num = 'order_num';
    }
    restApi
      .get(`${getAPIHost()}/exports/purchase/users?${qs.stringify(params)}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `주문내역_${moment().format('YYYYMMDDHHmmss')}.xlsx`);
      });
  };

  const [delMode, setDelMode] = useState(true);
  const [showDownModal, setShowDownModal] = useState();

  const handleDownload = async (modes, suffix) => {
    const fname = `${suffix}_일괄_다운로드_${moment().format('YYYY_MM_DD')}.zip`;

    const { data } = await restApi.get(`/purchases/images`, { params: { ids: rowKeys, modes }, responseType: 'blob' });
    FileSaver.saveAs(data, fname);
  };

  return (
    <div>
      <Modal
        visible={showDownModal}
        title={'다운로드할 파일 선택'}
        onCancel={() => setShowDownModal(false)}
        footer={false}
        width={300}
      >
        <Button style={{ marginBottom: 10 }} block onClick={() => handleDownload([], '고객사진')}>
          고객사진
        </Button>
        <Button style={{ marginBottom: 10 }} block onClick={() => handleDownload(['GET_ITEM'], '수거사진')}>
          수거사진
        </Button>
        <Button style={{ marginBottom: 10 }} block onClick={() => handleDownload(['FIX_READY'], '분배사진')}>
          분배사진
        </Button>
        <Button
          style={{ marginBottom: 10 }}
          block
          onClick={() => handleDownload(['FIX_ING', 'OUT_FIXER'], '장인_작업사진')}
        >
          장인 작업사진
        </Button>
        <Button
          style={{ marginBottom: 10 }}
          block
          onClick={() => handleDownload(['IN_CENTER', 'IN_FIXER', 'OUT_CENTER', 'SENDING'], '센터사진')}
        >
          센터 사진
        </Button>
        <Button style={{ marginBottom: 10 }} block onClick={() => handleDownload(['PREV_SENDING'], '회수사진')}>
          회수 사진
        </Button>
        <Button style={{ marginBottom: 10 }} block onClick={() => handleDownload(['SEND_USER'], '배송사진')}>
          배송 사진
        </Button>
      </Modal>
      <Modal visible={showModal} width={1200} title={'삭제된 항목'} onCancel={() => setShowModal(false)}>
        {showModal && (
          <ProductRemoveView
            column={columns}
            loadParent={() => {
              loadData();
              setShowModal(false);
            }}
          />
        )}
      </Modal>
      <PageHeader className="site-page-header" title="주문내역" />
      <SearchForm fields={workSearchFields} />
      <div style={{ marginBottom: 10 }}>
        <Radio.Group
          value={delMode}
          onChange={(e) => {
            setDelMode(e.target.value);
          }}
        >
          <Radio.Button value={true}>삭제모드</Radio.Button>
          <Radio.Button value={false}>다운모드</Radio.Button>
        </Radio.Group>
      </div>
      <div style={{ position: 'relative' }}>
        <Table
          rowSelection={{
            type: 'checkbox',
            onChange: handleChange,
            selectedRowKeys: rowKeys,
            // getCheckboxProps: (r) => {
            //   if (delMode) {
            //     if (r.state === 'FINISH' || r.state === 'BUYED') {
            //       return { disabled: true };
            //     }
            //   }
            // },
          }}
          rowKey={'id'}
          scroll={{ x: 'max-content' }}
          dataSource={data?.items}
          columns={columns}
          rowClassName={(props) => {
            return !props.read ? 'tr-bg-red' : '';
          }}
          pagination={
            pagination
              ? {
                  ...pagination,
                  position: ['bottomCenter'],
                  showSizeChanger: false,
                }
              : false
          }
        />
        <div className={'tableWrapCover'}>
          <TableButtonWrap
            position="left"
            buttons={[{ icon: require('../../../assets/icon_save.png'), label: '엑셀 저장', onClick: xlsxSave }]}
          />
          {delMode ? (
            <TableButtonWrap
              position="right"
              buttons={[
                { label: '삭제항목보기', onClick: handleDeleteModal },
                { label: '삭제', onClick: handleDelete },
              ]}
            />
          ) : (
            <TableButtonWrap
              position="right"
              buttons={[
                {
                  icon: require('../../../assets/icon_save.png'),
                  label: '묶음다운로드',
                  onClick: () => {
                    if (rowKeys?.length === 0) {
                      window.alert('다운받을 내역을 선택하세요');
                      return;
                    }

                    setShowDownModal(true);
                  },
                },
              ]}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderIndex;
