import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { restApi } from '../apis';

const initialState = {
  items: {},
  categories: {},
  types: [],
};

export const getProducts = createAsyncThunk('product/items', async () => {
  const response = await restApi.get(`/products`);
  return response.data;
});

export const getProductTypes = createAsyncThunk('product/types', async () => {
  const response = await restApi.get(`/products/types`);
  return response.data;
});

export const getProductCategories = createAsyncThunk('product/categories', async (type) => {
  const response = await restApi.get(`/products/categories`, { params: { type } });
  return { items: response.data, type };
});

const ProductSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    init(state, action) {
      state = action.payload?.product || state;
    },
    initProduct(state, action) {
      state = action.payload || state;
    },
  },
  extraReducers: {
    [getProducts.fulfilled]: (state, action) => {
      const items = action.payload.reduce((a, v) => {
        const key = `${v.type}-${v.gender}`;
        if (!Object.keys(a).includes(key)) a[key] = [];
        a[key].push(v);
        a[key].sort((a, b) => (a.order_index > b.order_index ? 1 : a.order_index < b.order_index ? -1 : 0));
        return a;
      }, {});
      state.items = items;
    },
    [getProductTypes.fulfilled]: (state, action) => {
      state.types = action.payload;
    },
    [getProductCategories.fulfilled]: (state, action) => {
      const { type, items } = action.payload;
      state.categories[type] = items;
    },
  },
});

export default ProductSlice.reducer;
