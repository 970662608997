import React from 'react';
import { PageHeader, Table } from 'antd';
import { bannerSearchField } from '../../../common/searchFields';
import SearchForm from '../../../components/datalist/SearchForm';
import TableButtonWrap from '../../../components/TableButtonWrap';
import moment from 'moment';
import { getAPIHost, restApi } from '../../../apis';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import fileDownload from 'js-file-download';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { updatePost } from '../../../apis/posts';

/**
 * 구두이야기 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const StoryIndex = () => {
  // const edit = useAxios();
  const location = useLocation();
  const navigate = useNavigate();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);

  const [data, setData] = React.useState();
  const [selectedRowKeys, setSelectedRowKeys] = React.useState();

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      ...query,
      type: 'SHOE_STORY',
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    }

    const { data } = await restApi.get('/posts', {
      params,
    });
    setData(data);
  }, [query]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  const columns = [
    {
      title: '번호',
      dataIndex: 'id',
      align: 'center',
      width: 90,
      render: (_, _2, i) => {
        return data?.total_elements - i - data?.page * 10;
      },
    },
    {
      title: '제목',
      dataIndex: 'title',
      align: 'left',
      render: (value, record) => (
        <Link to={`/contents/story/details/${record.id}`}>
          <a style={{ color: 'black' }}>{value || '제목이 없습니다.'}</a>
        </Link>
      ),
    },
    {
      title: '작성일자',
      dataIndex: 'created_at',
      align: 'center',
      width: 200,
      render: (value) => moment(value).format('YYYY-MM-DD HH:mm'),
    },
    {
      title: '노출허용',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      width: 80,
      render: (value, record) => {
        const { id } = record ?? {};
        const checked = value;
        const onClick = async () => {
          if (!window.confirm('변경하시겠습니까?')) {
            return;
          }
          await updatePost({ id, active: !checked });
          await loadData();
        };

        return <Checkbox checked={checked} onClick={onClick} />;
      },
    },
    {
      title: '자세히보기',
      dataIndex: 'id',
      key: 'detail',
      align: 'center',
      width: 120,
      render: (value, record) => (
        <Link to={`/contents/story/details/${record.id}`}>
          <a style={{ color: 'black' }}>자세히보기</a>
        </Link>
      ),
    },
  ];
  // 테이블 페이지네이션
  const pagination = {
    position: ['bottomCenter'],
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      navigate('?' + qs.stringify({ ...query, page: p - 1 }), { replace: true });
    },
  };

  const xlsxSave = () => {
    const args = {
      ...query,
      type: 'SHOE_STORY',
    };
    restApi
      .get(`${getAPIHost()}/exports/shoedoc?${qs.stringify(args)}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `구두이야기_${moment().format('YYYYMMDDHHmmss')}.xlsx`);
      });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="구두이야기" />
      <SearchForm fields={bannerSearchField} />
      <div style={{ position: 'relative' }}>
        <Table
          scroll={{ x: 'max-content' }}
          // rowKey={({ id }) => id}
          // rowSelection={{
          //   type: 'checkbox',
          //   ...rowSelection,
          // }}
          dataSource={data?.items}
          columns={columns}
          pagination={
            pagination
              ? {
                  ...pagination,
                  position: ['bottomCenter'],
                  showSizeChanger: false,
                }
              : false
          }
        />
        <div className={"tableWrapCover"}>
          <TableButtonWrap
            position="left"
            buttons={[{ icon: require('../../../assets/icon_save.png'), label: '엑셀 저장', onClick: xlsxSave }]}
          />
          <TableButtonWrap
            position="right"
            buttons={[
              { icon: require('../../../assets/icon_write.png'), label: '작성', link: '/contents/story/create' },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default StoryIndex;
