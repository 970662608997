import React, { useState } from 'react';
import { Button, Descriptions, Divider, Input, Modal, PageHeader, Space, Table } from 'antd';
import { addComma, getUserFrom, phoneFormat, toCommaNumber } from '../../../../common/utils';
import { NEW_RUN_STATE, PaymentType } from '../../../../common/defines';
import moment from 'moment';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import qs from 'query-string';
import { accountDetailSearchFields } from '../../../../common/searchFields';
import SearchForm from '../../../../components/datalist/SearchForm';
import { getAPIHost, restApi } from '../../../../apis';
import fileDownload from 'js-file-download';
import TableButtonWrap from '../../../../components/TableButtonWrap';

// 테이블 컬럼 정의
const columns = [
  {
    title: '서비스',
    dataIndex: 'type',
    align: 'center',
    width: 150,
    // render: (data) => data?.reduce((a, c) => [_, c.subject], []),
    render: (data) => PaymentType[data],
  },

  {
    title: '주문일시',
    dataIndex: 'created_at',
    align: 'center',
    width: 200,
    render: (data) => moment(data).format('YYYY-MM-DD HH:mm'),
  },
  {
    title: '주문상태',
    dataIndex: 'state',
    align: 'center',
    width: 80,
    render: (data) =>
      data == 'BUYED' ? '구매완료' : data == 'CANCELLED' ? '취소' : data == 'FINISH' ? '완료' : '결제대기',
  },
  {
    title: '배송상태',
    dataIndex: 'run_state',
    align: 'center',
    width: 120,
    render: (data) => NEW_RUN_STATE[data] ?? data,
    // render: (data) => (data && WorkStateType[WorkState[data]]) || '준비중',
  },
  // {
  //   title: '주문정보',
  //   // dataIndex: 'type',
  //   align: 'center',
  //   width: 417,
  //   // render: (data) => PaymentType[data],
  //   render: (_, __, index) => {
  //     // console.log();
  //     return `${_?.gender === 'M' ? '남성구두' : '여성구두'} ${_?.shoes_count} 컬레${
  //       _?.repeat > 0 ? ` (반복${_?.repeat}회)` : ''
  //     }`;
  //   },
  // },
  {
    title: '결제금액',
    dataIndex: 'sale_price',
    align: 'center',
    width: 200,
    render: (data, row) => toCommaNumber(data + row.delivery_price),
  },
  {
    title: '수거일시',
    dataIndex: 'begin_expected_at_real',
    align: 'center',
    width: 200,
    render: (data) => data && moment(data).format('YYYY-MM-DD HH:mm'),
  },
  {
    title: '배송일시',
    dataIndex: 'finish_expected_at_real',
    align: 'center',
    width: 200,
    render: (data) => data && moment(data).format('YYYY-MM-DD HH:mm'),
  },
  {
    title: '자세히보기',
    dataIndex: 'id',
    align: 'center',
    // width: 120,
    render: (data) => (
      <Link to={`/work/order/details/${data}`}>
        <a style={{ color: 'black' }}>자세히보기</a>
      </Link>
    ),
  },
];

/**
 * 고객회원 상세 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const AccountUserDetail = () => {
  const { id: accountId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // url query
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);

  // 포인트 수정 모달
  const [pointModal, setPointModal] = useState();
  // 포인트 수정
  const handlePointSubmit = async () => {
    try {
      const point = parseInt(pointModal, 10);
      await restApi.post(`/accounts/${accountId}/points`, {
        point,
      });

      await getAccountInfo(accountId);
      setPointModal(undefined);
      alert('포인트 적립완료');
    } catch (e) {
      alert('서버 연결이 실패되었습니다.');
    }
  };
  const [data, setData] = React.useState();
  const [accountData, setAccountData] = React.useState();
  // 유저 정보 테이블 렌더
  const userData = [
    { label: '성명', content: accountData?.nickname },
    // { label: '아이디', content: account.data?.signname },
    { label: '주소', content: accountData?.address || '' },
    { label: '연락처', content: phoneFormat(accountData?.phone) },
    { label: '가입수단', content: getUserFrom(accountData?.signname) },
    { label: '멤버십등급', content: accountData?.membership?.name },
    {
      label: '보유포인트',
      content: (
        <>
          {addComma(String(accountData?.point ?? 0))} <Button onClick={() => setPointModal('')}>포인트 가감</Button>
        </>
      ),
    },
    { label: '가입일자', content: moment(accountData?.created_at).format('YYYY-MM-DD HH:mm') },
  ];

  // 탈퇴처리
  const handleLeave = async () => {
    if (!window.confirm('탈퇴 처리하시겠습니까?\n복구가 불가능합니다.')) return;
    try {
      await restApi.delete(`/accounts/admin/${accountData.id}`);
      alert('삭제되었습니다.');
      window.history.back();
    } catch (e) {
      alert(e?.response?.data?.message || '서버와 연결이 올바르지 않습니다.');
    }
  };

  // 회원상세정보 조회
  const getAccountInfo = async (id) => {
    const { data } = await restApi.get(`/accounts/${id}`);
    setAccountData(data);
  };

  React.useEffect(() => {
    if (!accountId) return;
    getAccountInfo(accountId);
  }, [accountId]);

  // 테이블 페이지네이션
  const pagination = {
    position: ['bottomCenter'],
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      navigate('?' + qs.stringify({ ...query, page: p - 1 }), { replace: true });
    },
  };

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      ...query,
      account_id: accountId,
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    }

    const { data } = await restApi.get('/cms/purchases', {
      params,
    });
    setData(data);
  }, [query]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  // 엑셀 저장
  const xlsxSave = () => {
    const args = {
      // ...query,
      // type: 'U',
      account_id: accountId,
    };
    restApi
      .get(`${getAPIHost()}/exports/purchase?${qs.stringify(args)}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `고객회원_상세_${moment().format('YYYYMMDDHHmmss')}.xlsx`);
      });
  };

  return (
    <div>
      <Modal
        visible={pointModal !== undefined}
        title={'포인트 지급'}
        onCancel={() => setPointModal(undefined)}
        onOk={() => {
          handlePointSubmit();
        }}
      >
        <div>
          <label>변동 포인트</label>
          <Input
            placeholder={'지급시 숫자, 감소 시 -로 입력'}
            value={pointModal}
            onChange={(e) => {
              const v = e.target.value;
              setPointModal(v);
            }}
          />
        </div>
      </Modal>
      <PageHeader
        className="site-page-header"
        title="고객 회원정보"
        extra={[<Button onClick={() => navigate(-1)}>목록</Button>]}
      />
      <Descriptions bordered>
        {userData.map((data, index) => {
          return (
            <Descriptions.Item key={`${data.label}-${index}`} label={data.label} span={3}>
              {data.content}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
      <div style={{ marginTop: 20 }}>
        <Space>
          <Button onClick={handleLeave} danger>
            회원탈퇴
          </Button>
        </Space>
      </div>

      <Divider />

      <div style={{ position: 'relative' }}>
        <Table
          scroll={{ x: 'max-content' }}
          title={() => (
            <>
              <h2 style={{ marginBottom: 20 }}>{`주문내역(총 ${data?.total_elements || 0}개)`}</h2>
              <SearchForm fields={accountDetailSearchFields} />
            </>
          )}
          dataSource={data?.items}
          columns={columns}
          pagination={
            pagination
              ? {
                  ...pagination,
                  position: ['bottomCenter'],
                  showSizeChanger: false,
                }
              : false
          }
        />
        <div>
          <TableButtonWrap
            position="left"
            buttons={[{ icon: require('../../../../assets/icon_save.png'), label: '엑셀 저장', onClick: xlsxSave }]}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountUserDetail;
