import React from 'react';
import { PageHeader, Table } from 'antd';
import { settingSearchField } from '../../../common/searchFields';
import SearchForm from '../../../components/datalist/SearchForm';
import TableButtonWrap from '../../../components/TableButtonWrap';
import moment from 'moment';
import { getAPIHost, restApi } from '../../../apis';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import fileDownload from 'js-file-download';
import { toCommaNumber } from '../../../common/utils';

/**
 * 센터관리 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const CenterMgrIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);

  const [data, setData] = React.useState();

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      ...query,
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    } else if (query.field === 'address') {
      params.address = query.keyword;
    }

    const { data } = await restApi.get('/centers', {
      params,
    });
    setData(data);
  }, [query]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  const columns = [
    {
      title: '번호',
      align: 'center',
      width: 90,
      render: (_, __, index) => {
        return (data?.total_elements || 0) - (10 * data?.page + index);
      },
    },
    {
      title: '지역',
      width: 120,
      dataIndex: 'address',
      align: 'center',
      render: (address) => {
        const sido = String(address).split(' ');
        if (sido?.length > 0) return sido[0];
        return '미지정';
      },
    },
    {
      title: '운영여부',
      dataIndex: 'active',
      width: 120,
      align: 'center',
      render: (active) => {
        return active == true ? '운영' : '미운영';
      },
    },
    {
      title: '명칭',
      dataIndex: 'name',
      width: 230,
      align: 'center',
    },
    {
      title: '주소',
      dataIndex: 'address',
      align: 'left',
    },
    {
      title: '직원수',
      dataIndex: 'member_size',
      width: 146,
      align: 'center',
      render: (data) => {
        return `${toCommaNumber(data)}명`;
      },
    },
    {
      title: '자세히보기',
      dataIndex: 'id',
      align: 'center',
      width: 120,
      render: (data) => (
        <Link to={`/settings/centermgr/details/${data}`}>
          <a style={{ color: 'black' }}>
            자세히보기
            {/*<Icon source={require('../../../assets/icon_gear.png')} size={18} />*/}
          </a>
        </Link>
      ),
    },
  ];
  // 테이블 페이지네이션
  const pagination = {
    position: ['bottomCenter'],
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      navigate('?' + qs.stringify({ ...query, page: p - 1 }), { replace: true });
    },
  };

  const xlsxSave = () => {
    const args = {
      ...query,
    };
    restApi
      .get(`${getAPIHost()}/exports/centermgr?${qs.stringify(args)}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `센터관리_${moment().format('YYYYMMDDHHmmss')}.xlsx`);
      });
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="센터관리" />
      <SearchForm fields={settingSearchField} />
      <div style={{ position: 'relative' }}>
        <Table
          scroll={{ x: 'max-content' }}
          rowKey={({ id }) => id}
          dataSource={data?.items}
          columns={columns}
          pagination={
            pagination
              ? {
                  ...pagination,
                  position: ['bottomCenter'],
                  showSizeChanger: false,
                }
              : false
          }
        />
        <div className={'tableWrapCover'}>
          <TableButtonWrap
            position="left"
            buttons={[{ icon: require('../../../assets/icon_save.png'), label: '엑셀 저장', onClick: xlsxSave }]}
          />
          <TableButtonWrap
            position="right"
            buttons={[
              {
                icon: require('../../../assets/icon_write.png'),
                label: '센터 등록',
                link: '/settings/centermgr/create',
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default CenterMgrIndex;
