import React, { useMemo } from 'react';
import { Button, Space } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

const MENU_ITEMS = [
  {
    key: 'info',
    label: '기업정보',
  },
  {
    key: 'calc',
    label: '정산내역',
  },
  {
    key: 'accounts',
    label: '회원관리',
  },
  {
    key: 'purchases',
    label: '주문내역',
  },
  {
    key: 'payments',
    label: '결제내역',
  },
  {
    key: 'codes',
    label: '아파트관리',
  },
  {
    key: 'codes_create',
    label: '아파트등록',
  },
  {
    key: 'stats',
    label: '주문통계',
  },
];

export const useGetVendor = () => {
  const params = useParams();
  const { vendors } = useSelector((s) => s.vendor, shallowEqual);

  const vendor = useMemo(() => {
    return vendors?.find((v) => v.id === parseInt(params?.id, 10));
  }, [params?.id, vendors]);

  return vendor;
};
/**
 * 기업 메뉴
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const VendorDetailsMenu = (props) => {
  const loc = useLocation();
  const navigate = useNavigate();
  const vendor = useGetVendor();

  return (
    <div style={{ marginBottom: 20 }}>
      <h1>{vendor?.name}</h1>
      <Space>
        {MENU_ITEMS?.map((item) => {
          const active = loc.pathname?.endsWith(`/${item.key}`);
          return (
            <Button
              key={item.key}
              onClick={() => {
                navigate(`/vendors/${vendor?.id}/${item.key}`);
              }}
              type={active ? 'primary' : undefined}
            >
              {item.label}
            </Button>
          );
        })}
      </Space>
    </div>
  );
};

export default VendorDetailsMenu;
