import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {restApi} from "../../apis";

const initialState = {
  principal: null,
  hasNotify: true,
};

export const me = createAsyncThunk('auth/principal', async (options) => {
  const response = await restApi.get(`/auth/login`, options);
  return response.data;
});

/**
 * 회원 기본 정보 관련 Redux
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const AuthSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    init(state, action) {
      state = action.payload?.auth || state;
    },
    initAuth(state, action) {
      state = action.payload || state;
    },
    setPrincipal(state, action) {
      state.principal = {
        ...action.payload,
      };
    },
    clearPrincipal(state) {
      state.principal = null;
    },
    setHasNotify(state, action) {
      state.hasNotify = action.payload;
    },
    changeWorkType(state, action) {
      if (state.principal) {
        state.principal.workType = action.payload;
      }
    },
  },
  extraReducers: {
    [me.fulfilled]: (state, action) => {
      state.principal = action.payload;
    },
  },
});

export const { clearPrincipal, init, initAuth, setHasNotify, setPrincipal, changeWorkType } = AuthSlice.actions;

export default AuthSlice.reducer;
