import React from 'react';
import VendorDetailsMenu, { useGetVendor } from './menu';
import VendersCodesIndex from '../../for_vendor/codes';
import VendersCodesCreate from '../../for_vendor/codes/upload';

/**
 * 벤더사 코드 생성
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const VendorsDetailsCodesCreate = (props) => {
  const vendor = useGetVendor();
  return (
    <div>
      <VendorDetailsMenu index={2} />
      {!!vendor?.id && <VendersCodesCreate vendorId={vendor?.id} />}
    </div>
  );
};

export default VendorsDetailsCodesCreate;
