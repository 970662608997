import React, { useEffect, useState } from 'react';
import { restApi } from '../../../../apis';
import { Select } from 'antd';

/**
 * 작업자 검색
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const SearchUserBox = ({ type, workmode, value, onChange, placeholder }) => {
  const [data, setData] = useState();
  const [display, setDisplay] = useState();

  useEffect(() => {
    const loadValue = async () => {
      if (display?.id != value && value) {
        const { data } = await restApi.get(`/accounts/${value}`);
        setDisplay(data?.id ? data : { id: value });
      }
    };
    loadValue().catch(console.warn);
  }, [display, value]);

  const handleSearch = async (keyword) => {
    const { data } = await restApi.get(`/accounts`, { params: { type, keyword, work_type: workmode } });
    setData(data);
  };

  const handleChange = (v) => {
    const display = data?.items?.find((x) => x.id === v);
    setDisplay(display);
    onChange(v, display);
  };

  const options = (
    !display
      ? []
      : [
          <Select.Option key={String(display?.id)} value={display?.id}>
            {display?.nickname} ({display?.phone})
          </Select.Option>,
        ]
  ).concat(
    data?.items
      ?.filter((v) => v.id != display?.id)
      ?.map((d) => (
        <Select.Option key={String(d.id)} value={d.id}>
          {d.nickname} ({d.phone})
        </Select.Option>
      )) ?? [],
  );

  return (
    <Select
      showSearch
      allowClear
      style={{ width: '100%' }}
      value={value}
      placeholder={placeholder}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
    >
      {options}
    </Select>
  );
};

export default SearchUserBox;
