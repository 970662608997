import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Table, Input, InputNumber, Form, Typography, Space, Button, Modal, Select } from 'antd';
import { ExclamationCircleOutlined, MenuOutlined } from '@ant-design/icons';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

const { Title } = Typography;

const type = 'DraggableBodyRow';

const deleteInfo = () => {
  Modal.success({
    width: 450,
    centered: true,
    content: '삭제가 완료되었습니다.',
    okText: '확인',
  });
};

const deleteConfirm = () => {
  return new Promise((r) => {
    Modal.confirm({
      width: 450,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: '해당 내용을 삭제하시겠습니까?',
      okText: '확인',
      onOk: () => {
        r(true);
      },
      cancelText: '취소',
      onCancel: () => {
        r(false);
      },
    });
  });
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  options,
  validateMessage,
  isRequired,
  isEditable,
  inputProps = {},

  ...restProps
}) => {
  // console.log({ index, title, validateMessage, restProps });
  const renderContent = () => {
    if (editing && isEditable) {
      return (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: isRequired,
              message: validateMessage ?? `${title}를 입력해주세요!`,
            },
          ]}
        >
          {options ? (
            <Select defaultValue={children[1]} style={{ width: 120 }} {...inputProps}>
              {options?.map((option) => {
                if (Array.isArray(option.items)) {
                  return (
                    <Select.OptGroup label={option?.label} key={option.value}>
                      {option.items.map((child) => {
                        return (
                          <Select.Option value={child.value} key={child.value}>
                            {child.label}
                          </Select.Option>
                        );
                      })}
                    </Select.OptGroup>
                  );
                }
                return (
                  <Select.Option value={option.value} key={option.key}>
                    {option.label}
                  </Select.Option>
                );
              })}
            </Select>
          ) : inputType === 'number' ? (
            <InputNumber {...inputProps} />
          ) : (
            <Input {...inputProps} />
          )}
        </Form.Item>
      );
    }
    return children;
  };
  return <td {...restProps}>{renderContent()}</td>;
};

const DraggableBodyRow = React.memo(({ index, moveRow, className, style, ...restProps }) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  useEffect(() => {
    drop(drag(ref));
  }, []);

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
});

/**
 * 드래그 가능한 테이블
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const DraggableEditableTableLastIndex = ({
  originData,
  originColumns,
  editable,
  title,
  noAdd = false,
  onSave,
  onDelete,
  onMoveRow,
  style,
}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState('');

  React.useEffect(() => {
    setData(originData);
  }, [originData]);

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      const newData = update(data, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });
      setData(newData);
      onMoveRow?.(dragIndex, hoverIndex, newData);
    },
    [data],
  );

  const isEditing = (record) => record.id === editingKey;

  const handleAddRow = () => {
    const dataAdded = { id: `added-${data.length}`, isNew: true };
    setData((prev) => [dataAdded, ...prev]);
    form.setFieldsValue(dataAdded);
    setEditingKey(dataAdded.id);
  };

  const handleEdit = (record) => {
    // console.log('handleEdit', record);
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.id);
  };

  const handleSave = async (record) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const targetData = data.find((item) => record.id === item.id);
      const index = newData.findIndex((item) => record.id === item.id);

      await onSave?.({ ...targetData, ...row });
      setEditingKey();
      return;

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey();
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey();
      }
    } catch (errInfo) {
      // console.log('Validate Failed:', errInfo);
    }
  };
  const handleDelete = async (row) => {
    onDelete?.(row);
  };

  const columns = !!editable
    ? [
        {
          title: '',
          key: 'drag',
          align: 'center',
          width: 50,
          render: () => (editingKey ? null : <MenuOutlined />),
        },
        ...originColumns,
      ]
    : [...originColumns];

  const mergedColumns = columns.map((col) => {
    const render = col.render ? (field, row, index) => col.render(field, row, index, !!editingKey) : null;
    if (!!editingKey && !col.isEditable) {
      return { ...col, render };
    }

    return {
      ...col,

      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        ...col,
        render,
      }),
    };
  });
  return (
    <DndProvider backend={HTML5Backend}>
      <Table
        style={style}
        components={
          editable && {
            body: {
              row: DraggableBodyRow,
            },
          }
        }
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowKey={'id'}
        rowClassName="editable-row"
        pagination={false}
        onRow={(record, index) => ({
          index,
          moveRow,
        })}
      />
    </DndProvider>
  );
};

export default DraggableEditableTableLastIndex;
