import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { getAPIHost, restApi } from '../apis';
import FileSaver from 'file-saver';

export const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const phoneFormat = (str) => {
  return String(str)
    .replace(/[^0-9]+/g, '')
    .replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3');
};

export function getDistanceFromLatLonInKm(lat1, lng1, lat2, lng2) {
  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  let r = 6371; //지구의 반지름(km)
  let dLat = deg2rad(lat2 - lat1);
  let dLon = deg2rad(lng2 - lng1);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = r * c; // Distance in km
  return Math.round(d);
}

export const getUserFrom = (signname, platform) => {
  if (signname?.includes('kakao')) {
    return `${platform ?? ''} [kakao]`;
  }
  if (signname?.includes('naver')) {
    return `${platform ?? ''} [naver]`;
  }
  if (signname?.includes('google')) {
    return `${platform ?? ''} [google]`;
  }
  // return platform;
  return '[전화번호]';
};

export const numberFormat = (str) => {
  return String(str).replace(/[^0-9]+/g, '');
};

export function hashCode(str) {
  let hash = 0,
    i,
    chr;

  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash >>= 0;
  }
  return hash;
}

export function stringFormat(str, ...param) {
  let args;

  if (param.length === 1 && typeof param[0] === 'object') {
    args = param[0];
  } else {
    args = [...param];
  }

  if (!args?.hasOwnProperty) {
    args = {};
  }

  return str.replace(/{(\w+)}/g, (match, k, index) => {
    if (str[index - 1] === '{' && str[index + match.length] === '}') {
      return k;
    }

    const result = args.hasOwnProperty(k) ? args[k] : null;
    if (result === null || result === undefined) {
      return '';
    }

    return result;
  });
}

/**
 *
 * @param {number | undefined} num
 * @returns {string}
 */
export function formatNumber(num) {
  if ((num ?? 0) < 999) return num ?? 0;
  return formatNumber((num / 1000) >> 0) + ',' + ('00' + (num % 1000 >> 0)).substr(-3, 3);
}
export function removeDot(str) {
  return str.replace(/(^0)(?=[^.])/, '');
}

export function replComma(str) {
  return removeDot(str.replace(/(^[\d,]+\.(?:\d*[^0])?)(0*)$/, '$1').replace(/\.$/, ''));
}

export function addComma(str) {
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function toCommaNumber(number, input) {
  if (!number) {
    return '0';
  }
  const numbers = number.toString().split('.');
  numbers[0] = addComma(numbers[0]);

  if (input) {
    if (typeof numbers[1] === 'string') return removeDot([numbers[0], numbers[1]].join('.'));
    return removeDot(numbers[0]);
  }
  if (typeof number === 'number') {
    return number.toLocaleString();
    // if (typeof numbers[1] === 'string') return replComma([numbers[0], numbers[1]].join('.'));
    return replComma(numbers[0]);
  }
  if (typeof numbers[1] === 'string') return replComma([numbers[0], numbers[1]].join('.'));
  return replComma(numbers[0]);
}

export default {
  sleep,
  hashCode,
  stringFormat,
  formatNumber,
  toCommaNumber,
};

export const parseImageUrl = (id, w = -1, h = -1) => {
  const querystring = [];
  if (w > 0) querystring.push(`w=${w}`);
  if (h > 0) querystring.push(`h=${h}`);

  return getAPIHost() + `/files/${id}` + (querystring?.length ? `?${querystring.join('&')}` : '');
};

export const parseAppLink = (url) => {
  const route = url.replace(/.*?:\/\//g, '');
  const id = route.match(/\/([^\/]+)\/?$/)[1];
  const routeName = route.split('/')[0];

  return { route, id, routeName };
};

export const addHyphen = (str) => {
  return str?.replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3').replace('—', '-');
};

export const splitPhone = (str) => {
  return str?.replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3').split('-');
};

export var zf = function (value, length, fill) {
  if (length === void 0) {
    length = 2;
  }

  if (fill === void 0) {
    fill = '0';
  }

  var s = '' + value;
  if (s.length > length) return s;
  return '' + fill.repeat(length - s.length) + s;
};

export const avg = (array = []) => {
  return array.reduce((p, c) => p + c, 0) / array.length;
};

export const objectTo1depth = (obj, join = '_', parents = []) => {
  return Object.entries(obj).reduce((acc, [childKey, value]) => {
    return value !== null && typeof value === 'object'
      ? { ...acc, ...objectTo1depth(value, join, [...parents, childKey]) }
      : { ...acc, [[...parents, childKey].join(join)]: value };
  }, {});
};

export const d2r = (d) => (d * Math.PI) / 180;
export const r2d = (r) => (r * 180) / Math.PI;

export const distanceWGS84 = (lat1, long1, lat2, long2) => {
  const R = 6371.00877; // earth radius with km
  const a =
    Math.sin(d2r(lat2 - lat1) / 2) ** 2 +
    Math.sin(d2r(long2 - long1) / 2) ** 2 * Math.cos(d2r(lat1)) * Math.cos(d2r(lat2));
  return 2 * R * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
};

export const createArray = (length, mapper = (i) => i) => {
  return [...new Array(length)].map((_, i) => mapper(i));
};

export const imagesArchive = async (ids, filename) => {
  if (ids.length < 1) return;

  const { data } = await restApi.get(`/files_zip`, { params: { ids }, responseType: 'blob' });
  FileSaver.saveAs(data, `${filename}.zip`);
};

export const pointCut = (num, cuttingPoint) => {
  if (typeof num !== 'number') {
    return num;
  } else {
    const fixed = num?.toFixed(cuttingPoint ?? 2);
    const [integer, point] = fixed?.split('.');
    if (point === '00') {
      return integer;
    } else return `${integer}.${point}`;
  }
};

export const dayFormat = (x) => {
  if (x < 10) {
    return `0${x}`;
  } else return x;
};

export const ofChildren = (data) => {
  if (!data.children) {
    return [
      {
        label: data.title,
        dataIndex: data.dataIndex,
        render: data.render,
        xlsRender: data.xlsRender,
      },
    ];
  } else {
    let items = [];
    data.children
      ?.filter((v) => !v.noExport)
      ?.forEach((child) => {
        items = items.concat(ofChildren(child));
      });
    return items;
  }
};

export const useCSVData = (columns, items, filename, displayname) => {
  const onClick = useCallback(
    (event, done) => {
      const isConfirm = window.confirm(
        `요청하신 내역을 csv파일로 다운로드 합니다.\n` +
          `파일명은 "${displayname || filename}"입니다. 확인 버튼을 클릭하시면 다운로드가 실행됩니다.`,
      );
      if (!isConfirm) {
        event.preventDefault();
      }
      return isConfirm;
    },
    [filename],
  );

  const data = useMemo(() => {
    const result = [];
    const fields = columns
      .filter((v) => !v.noExport)
      .reduce((x, y) => {
        if (!y.children) {
          x.push({
            label: y.title,
            dataIndex: y.dataIndex,
            render: y.render,
            xlsRender: y.xlsRender,
          });
        } else {
          x = x.concat(ofChildren(y));
        }
        return x;
      }, []);

    result.push(fields.map((v) => v.label));
    if (items?.length > 0) {
      items.forEach((v, i) => {
        const row = fields.map((x) => {
          const data = x.xlsRender
            ? x.xlsRender(v[x.dataIndex], v, i)
            : x.render
            ? x.render(v[x.dataIndex], v, i)
            : v[x.dataIndex];
          return typeof data === 'object'
            ? typeof data?.children !== 'object'
              ? data?.children
              : v[x.dataIndex]
            : data;
        });
        result.push(row);
      });
    }
    return result;
  }, [columns, items]);

  // console.log({ data });

  return {
    data,
    onClick,
    filename,
  };
};
