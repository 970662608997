import React from 'react';
import { PageHeader, Table, Typography } from 'antd';
import SearchForm from '../../../components/datalist/SearchForm';
import { getUserFrom, phoneFormat, toCommaNumber } from '../../../common/utils';
import moment from 'moment';
import qs from 'query-string';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getAPIHost, restApi } from '../../../apis';
import TableButtonWrap from '../../../components/TableButtonWrap';
import fileDownload from 'js-file-download';
import { accountSearchFields } from '../../../common/searchFields';

/**
 * 기업회원 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const AccountCorpIndex = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);

  const [data, setData] = React.useState();

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      ...query,
      type: 'B',
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    } else if (query.field === 'address') {
        params.address = query.keyword;
    }

    const { data } = await restApi.get('/accounts', {
      params,
    });
    setData(data);
  }, [query]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  //테이블 컬럼명 정의
  const columns = React.useMemo(
    () => [
      {
        title: '번호',
        align: 'center',
        key: 'id',
        dataIndex: 'id',
        width: 90,
        render: (_, _2, i) => {
          return _;
          // return data?.total_elements - i - data?.page * 10;
        },
      },
      {
        title: '이름',
        key: 'nickname',
        dataIndex: 'nickname',
        width: 100,
        align: 'center',
        render: (r) => (
          <Typography.Text ellipsis={true} style={{ width: 100 }}>
            {r}
          </Typography.Text>
        ),
      },
      {
        title: '연락처',
        dataIndex: 'phone',
        key: 'phone',
        width: 150,
        align: 'center',
        render: (r) => phoneFormat(r),
      },
      {
        title: '회사명',
        dataIndex: 'corp_department',
        key: 'corp_department',
        width: 120,
        align: 'left',
      },
      {
        title: '주소',
        dataIndex: 'address',
        key: 'address',
        align: 'left',
      },

      {
        title: '주문건수',
        dataIndex: 'purchase_count',
        key: 'purchase_count',
        width: 100,
        align: 'center',
        render: (data) => toCommaNumber(data),
      },
      {
        title: '가입일자',
        dataIndex: 'created_at',
        key: 'created_at',
        align: 'center',
        width: 160,
        render: (data) => {
          return moment(data).format('YYYY-MM-DD HH:mm');
        },
      },
      {
        title: '자세히보기',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 120,
        render: (data) => (
          <Link to={`/accounts/corp/details/${data}`}>
            <a style={{ color: 'black' }}>
              {/*<Icon source={require('../../../assets/icon_gear.png')} size={18} />*/}
              자세히보기
            </a>
          </Link>
        ),
      },
    ],
    [data],
  );

  // 테이블 페이지네이션
  const pagination = {
    position: ['bottomCenter'],
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      navigate('?' + qs.stringify({ ...query, page: p - 1 }), { replace: true });
    },
  };

  //엑셀 다운로드 로직
  const xlsxSave = () => {
    const params = {
      ...query,
      type: 'B',
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    } else if (query.field === 'address') {
      params.address = query.keyword;
    }
    restApi
      .get(`${getAPIHost()}/exports/accounts/user?${qs.stringify(params)}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `기업회원_${moment().format('YYYYMMDDHHmmss')}.xlsx`);
      });
  };

  // 엑셀 업로드 로직
  const [fileLoading, setFileLoad] = React.useState(false);
  const handleExcelUpload = async (e) => {
    if (fileLoading) {
      return;
    }
    try {
      setFileLoad(true);
      const fileData = new FormData();
      const config = {
        header: {
          accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      };

      fileData.append('filedata', e?.target?.files[0]);
      await restApi.post('/accounts/user/excel', fileData, config);
      alert('엑셀이 업로드 되었습니다.');
      setFileLoad(false);
    } catch (error) {
      console.error('excel upload ERROR', error);
      setFileLoad(false);
      throw error;
    }
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="기업회원" />
      <SearchForm fields={accountSearchFields} />
      <div style={{ position: 'relative' }}>
        <Table
          scroll={{ x: 'max-content' }}
          dataSource={data?.items}
          columns={columns}
          pagination={
            pagination
              ? {
                  ...pagination,
                  position: ['bottomCenter'],
                  showSizeChanger: false,
                }
              : false
          }
        />
        <div>
          <TableButtonWrap
            position="left"
            buttons={[
              { icon: require('../../../assets/icon_save.png'), label: '엑셀 저장', onClick: xlsxSave },
              // { icon: require('../../../assets/icon_upload.png'), label: '엑셀 업로드', onChange: handleExcelUpload },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountCorpIndex;
