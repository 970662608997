import React, { useEffect, useState } from 'react';

/**
 * 유저 화면 사이즈 훅스
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const useWindowDimension = (props) => {
  const [bodyWidth, setBodyWidth] = useState(window.innerWidth);
  useEffect(() => {
    const callback = () => {
      setBodyWidth(window.innerWidth);
    };
    window.addEventListener('resize', callback);
    return () => window.removeEventListener('resize', callback);
  }, []);
  return bodyWidth;
};

export default useWindowDimension;
