import {restApi} from "../index";

export const getGeocode = (addr) => {
  const endpoint = '/thirdparty/geocode';
  const requestBody = { params: { addr } };
  return restApi.get(endpoint, requestBody);
};

export const searchJuso = (keyword) => {
  const endpoint = '/thirdparty/juso';
  const requestBody = {
    params: {
      keyword,
      page: 0,
      limit: 10000,
    },
  };
  return restApi.get(endpoint, requestBody);
};
