import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import { Button, Descriptions, PageHeader, Table, Typography } from 'antd';
import moment from 'moment';
import Loading from '../../../../components/Loading';
import { getUserFrom, phoneFormat, toCommaNumber } from '../../../../common/utils';
import styles from '../../../work/review/details/reviewDetails.module.less';
import { getAPIHost, restApi } from '../../../../apis';
import fileDownload from 'js-file-download';
import { deletePush, getPush, pushTargettingStates, pushTargettingTypes } from '../../../../apis/pushes';

const CODE_COLUMNS = [
  {
    title: '코드',
    align: 'center',
    key: 'code',
    dataIndex: 'code',
    width: 90,
  },
  {
    title: '사용유저',
    align: 'center',
    key: 'account',
    dataIndex: 'account',
    width: 120,
    render: (r) => (!r?.id ? '미사용' : r.nickname),
  },
  {
    title: '사용일시',
    align: 'center',
    key: 'used_at',
    dataIndex: 'used_at',
    width: 120,
    render: (r) => (!r ? '-' : moment(r).format('YYYY-MM-DD HH:mm')),
  },
];

const SelectedColumns = [
  {
    title: '번호',
    align: 'center',
    key: 'id',
    dataIndex: 'id',
    width: 90,
  },
  {
    title: '이름',
    key: 'nickname',
    dataIndex: 'nickname',
    width: 100,
    align: 'center',
    render: (r) => (
      <Typography.Text ellipsis={true} style={{ width: 100 }}>
        {r}
      </Typography.Text>
    ),
  },
  {
    title: '연락처',
    dataIndex: 'phone',
    key: 'phone',
    width: 150,
    align: 'center',
    render: (r) => phoneFormat(r),
  },
  {
    title: '가입수단',
    dataIndex: 'platform',
    key: 'platform',
    width: 90,
    align: 'center',
    render: (data, row) => {
      return getUserFrom(row.signname, data);
    },
  },
  {
    title: '멤버십등급',
    dataIndex: 'membership',
    key: 'membership',
    width: 100,
    align: 'center',
    render: (_, __, index) => {
      return _?.name || '-';
    },
  },
  {
    title: '포인트',
    dataIndex: 'point',
    key: 'point',
    width: 100,
    align: 'center',
    render: (data) => toCommaNumber(data),
  },
  {
    title: '주문건수',
    dataIndex: 'purchase_count',
    key: 'purchase_count',
    width: 100,
    align: 'center',
    render: (data) => toCommaNumber(data),
  },
  {
    title: '주소',
    dataIndex: 'address',
    key: 'address',
    align: 'left',
  },

  {
    title: '가입일자',
    dataIndex: 'created_at',
    key: 'created_at',
    align: 'center',
    width: 200,
    render: (data) => {
      return moment(data).format('YYYY-MM-DD HH:mm');
    },
  },
];

/**
 * 알림  페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const PushDetails = () => {
  const { id: pushId } = useParams();
  const push = useAxios();
  const deleteCoupon = useAxios();
  const navigate = useNavigate();
  const [targetUsers, setTargetUsers] = React.useState();

  const loadData = async () => {
    try {
      await push.loadData(getPush(pushId));
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
      navigate('/settings/push', { replace: true });
    }
  };

  useEffect(() => {
    if (!push?.data?.user_ids) return;
    const loadData2 = async () => {
      const { data } = await restApi.get(`/accounts`, {
        params: { ids: [0].concat(push?.data?.user_ids), limit: 999 },
      });
      setTargetUsers(data);
    };
    loadData2().catch(console.warn);
  }, [push?.data?.user_ids]);

  const handleDeleteCoupon = async () => {
    try {
      await deleteCoupon.loadData(deletePush(pushId));
      alert('푸시알림이 삭제되었습니다.');
      navigate('/settings/push', { replace: true });
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
    }
  };

  const onDelete = () => {
    const result = window.confirm('푸시알림을 삭제하시겠습니까 ?');
    if (result) {
      handleDeleteCoupon();
    }
  };

  React.useEffect(() => {
    loadData().catch(console.warn);
  }, []);

  if (push.loading) {
    return <Loading />;
  }

  const userData = [
    {
      label: '이미지',
      content: push.data?.image && (
        <img
          style={{ width: 200, height: 100, objectFit: 'contain', border: '1px solid #ddd' }}
          src={`${getAPIHost()}/files/${push.data?.image}`}
        />
      ),
      span: 3,
    },
    { label: '제목', content: push.data?.title, span: 3 },
    { label: '내용', content: push.data?.contents, span: 3 },
    { label: '타겟', content: pushTargettingTypes[push.data?.method], span: 2 },
    { label: '상태', content: pushTargettingStates[push.data?.status], span: 2 },
    { label: '생성날짜', content: moment(push.data?.created_at).format('YYYY-MM-DD HH:mm'), span: 4 },
    // { label: '적용상태', content: pushStatusTypes[push.data?.status || 'WAIT'], span: 1 },
  ];

  const onRetry = async () => {
    if (!window.confirm('정말 진행하시겠습니까?')) return;
    await restApi.get(`/pushes/${pushId}/retry`);
  };

  const xlsxSave = () => {
    restApi
      .get(`/coupons/${pushId}/codes/xlsx`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `푸시알림_${push.data.title}_${moment().format('YYYYMMDDHHmmss')}.xlsx`);
      });
  };

  return (
    <>
      <PageHeader className="site-page-header" title="푸시알림 조회" />
      <Descriptions bordered>
        {userData.map((data, index) => {
          return (
            <Descriptions.Item key={index} label={data.label} span={data.span ? data.span : 2}>
              {data.content}
            </Descriptions.Item>
          );
        })}
      </Descriptions>

      {push.data?.method === 'TARGETING' && (
        <div>
          <h2 style={{ marginTop: 20 }}>알림받는 유저</h2>
          <Table
            columns={SelectedColumns}
            pagination={false}
            scroll={{ x: 'max-content' }}
            bordered
            dataSource={targetUsers?.items}
          />
        </div>
      )}
      <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        {push.data?.status === 'READY' ? (
          <>
            <Link to={'/settings/push/update/' + pushId}>
              <Button size={'large'} className={styles.basicButton} style={{ height: 40, width: 100, marginRight: 5 }}>
                수정
              </Button>
            </Link>
          </>
        ) : (
          <Button
            onClick={onRetry}
            size={'large'}
            className={styles.basicButton}
            style={{ height: 40, width: 100, marginRight: 5 }}
          >
            재발송
          </Button>
        )}
        <Button
          onClick={onDelete}
          size={'large'}
          className={styles.basicButton}
          style={{ height: 40, width: 100, marginRight: 5 }}
        >
          삭제
        </Button>
        <Button
          onClick={() => navigate('/settings/push', { replace: true })}
          size={'large'}
          className={styles.basicButton}
          style={{ marginRight: 5, height: 40, width: 100, backgroundColor: '#FF7200', color: 'white' }}
        >
          목록
        </Button>
      </div>
    </>
  );
};

export default PushDetails;
