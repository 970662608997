import React from 'react';
import { Button, Form, Modal } from 'antd';
import styles from '../../../../nav/BasicLayout.module.less';
import useInput from '../../../../hooks/useInput';

/**
 * QR코드관리 상세 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const CreatedQrModal = ({ corp_department, visible, onOk, onCancel }) => {
  const size = useInput('');
  const type = useInput('');
  const areas = useInput('');

  const handleResetForm = () => {
    size.onChange('');
    type.onChange('');
    areas.onChange('');
  };

  const handleSubmit = () => {
    onOk({
      size: size.value,
    });
    handleResetForm();
    onCancel();
  };

  const handleClose = () => {
    handleResetForm();
    onCancel();
  };

  return (
    <Modal visible={visible} onOk={handleSubmit} onCancel={handleClose} footer={false} width={500} centered>
      <Form>
        <h4 className={styles.modalTitle}>QR 등록</h4>
        <div style={{ paddingTop: 30, paddingBottom: 20 }}>
          <div className={styles.inputWrapper}>
            <label>생성개수</label>
            <input {...size} placeholder="갯수" type="text" required />
          </div>
        </div>
        <Button
          htmlType="submit"
          style={{
            borderColor: '#3d3d3d',
            backgroundColor: '#3d3d3d',
            borderRadius: 30,
            height: 60,
            color: '#fff',
            width: '100%',
            fontWeight: '700',
          }}
          size="large"
          onClick={handleSubmit}
        >
          등록하기
        </Button>
      </Form>
    </Modal>
  );
};

export default CreatedQrModal;
