import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import { Button, Descriptions, PageHeader } from 'antd';
import styles from '../../../work/review/details/reviewDetails.module.less';
import { deletePost, getPost } from '../../../../apis/posts';

/**
 * 공지사항 상세 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const NoticeDetails = () => {
  const navigate = useNavigate();
  const post = useAxios();
  const { id } = useParams();

  // 삭제 함수
  const handleDeletePost = async () => {
    try {
      await deletePost(id);
      navigate('/contents/notice', { replace: true });
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
    }
  };

  // 뒤로가기
  const handleReadList = () => {
    navigate('/contents/notice', { replace: true });
  };

  // 초기 상세 데이터 패칭
  React.useEffect(() => {
    post.loadData(getPost(id)).catch((error) => {
      console.warn(error);
      alert(error?.response?.data?.message);
      navigate('/contents/notice', { replace: true });
    });
  }, []);

  return (
    <div>
      <PageHeader className="site-page-header" title="공지사항 상세" />
      <Descriptions bordered>
        <Descriptions.Item label={'공지대상'} span={4} className="nopadding" style={{ padding: '10px 20px' }}>
          {post.data?.role_type === 'T' ? '기사' : post.data?.role_type === 'M' ? '장인' : '고객'}
        </Descriptions.Item>
        <Descriptions.Item label={'제목'} span={4}>
          {post.data?.title}
        </Descriptions.Item>

        <Descriptions.Item label={'공지내용'} span={4} style={{ height: 400, verticalAlign: 'top' }}>
          <div dangerouslySetInnerHTML={{ __html: post.data?.contents }} />
        </Descriptions.Item>
      </Descriptions>
      <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={handleDeletePost}
          size={'large'}
          className={styles.basicButton}
          style={{ marginRight: 5, height: 40, width: 100 }}
        >
          삭제
        </Button>
        <Button
          onClick={handleReadList}
          size={'large'}
          className={styles.basicButton}
          style={{ height: 40, width: 100, backgroundColor: '#FF7200', color: 'white', marginRight: 10 }}
        >
          목록
        </Button>
        <Button size="large" className="basicButton dark" style={{ height: 40, width: 100 }}>
          <Link to={`/contents/notice/update/${id}`}>수정</Link>
        </Button>
      </div>
    </div>
  );
};

export default NoticeDetails;
