import {restApi} from "./index";


export const getQuestion = (id) => {
    const endpoint = `/mypages/questions/${id}`;
    return restApi.get(endpoint);
};


export const deleteQeustionAnswer = (id) => {
    const endpoint = `/mypages/questions/${id}/answer`;
    return restApi.delete(endpoint);
};



export const updateQuestion = (id, body) => {
    const endpoint = `/mypages/questions/${id}`;
    return restApi.put(endpoint, body);
};

