import React from 'react';
import { Button, Form, Modal } from 'antd';
import uniqid from 'uniqid';
import useInput from '../hooks/useInput';
import useFiles from '../hooks/useFiles';
import styles from '../nav/BasicLayout.module.less';
import { parseImageUrl } from '../common/utils';
import Icon from '../components/Icon';
import Loading from './Loading';

const usePhoneInput = (defaultValue, targetInput) => {
  const [value, setValue] = React.useState(defaultValue);

  const onChange = (e) => {
    console.log({ e });
    const { target } = e ?? {};
    const { value } = target ?? {};
    setValue(value ?? e);
    if (value?.length > 0) {
      targetInput.onChange(`C${value}`);
    }
  };

  return { value, onChange };
};

/**
 * 센터 사원등록 모달 컴포넌트
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const CreateAccountModal = ({ corp_department, visible, onOk, onCancel }) => {
  const temp_department = corp_department === '-' ? '' : corp_department;
  const serial = useInput('');
  const nickname = useInput('');
  const department = useInput(temp_department || '');
  const level = useInput('');
  const signname = useInput('');
  const phone = usePhoneInput('', signname);
  const password = useInput('');
  const password2 = useInput('');
  const profile = useFiles();

  const handleResetForm = () => {
    profile.clear();
    serial.onChange('');
    nickname.onChange('');
    department.onChange('');
    level.onChange('');
    signname.onChange('');
    phone.onChange('', signname);
    password.onChange('');
    password2.onChange('');
  };

  const handleChangeProfile = async ({ isTrusted, target: { files } }) => {
    const [file] = files;
    if (isTrusted) {
      try {
        profile.loadData(file);
      } catch (error) {
        console.warn(error);
      }
    }
  };

  console.log({profile})
  const handleSubmit = () => {
    if (!serial?.value || !nickname?.value || !department?.value || !level?.value || !phone?.value || !signname?.value || !password?.value) {
      alert('입력되지 않은 항목이 있습니다.')
      return;
    }
    if (password.value !== password2.value) {
      alert('비밀번호가 일치하지 않습니다.');
      return;
    }
    if (!serial?.value || !nickname?.value || !department?.value || !level?.value || !phone?.value || !signname?.value || !password?.value) {
      alert('입력되지 않은 항목이 있습니다.')
      return;
    }
    onOk({
      id: uniqid(),
      profile: profile.data?.id ?? undefined,
      corp_serial: serial.value,
      nickname: nickname.value,
      corp_department: department.value,
      corp_level: level.value,
      phone: phone.value,
      signname: signname.value,
      password: password.value,
    });
    handleResetForm();
    onCancel();
  };

  const handleClose = () => {
    handleResetForm();
    onCancel();
  };

  return (
    <Modal visible={visible} onOk={handleSubmit} onCancel={handleClose} footer={false} width={500}>
      <Form>
        <h4 className={styles.modalTitle}>사원등록</h4>
        <div className={styles.modalThumbWrapper}>
          <div className={styles.modalThumb}>
            {profile.loading ? (
              <Loading />
            ) : (
              <img
                src={profile.data ? parseImageUrl(profile.data.id) : require('../assets/profile_default.png')}
                style={{ width: 86, height: 86, borderRadius: 86, overflow: 'hidden', objectFit: 'cover' }}
                alt="profile"
              />
            )}

            <Button
              style={{
                width: 26,
                height: 26,
                position: 'absolute',
                right: 0,
                bottom: 0,
                padding: 0,
                background: 'transparent',
                borderColor: 'transparent',
              }}
            >
              <input
                id="accountProfile"
                type="file"
                onChange={handleChangeProfile}
                style={{ display: 'none' }}
                accept="image/*"
              />
              <label htmlFor="accountProfile">
                <Icon source={require('../assets/icon_modify.png')} size={26} />
              </label>
            </Button>
          </div>
        </div>
        <div style={{ paddingTop: 30, paddingBottom: 20 }}>
          <div className={styles.inputWrapper}>
            <label>사원번호</label>
            <input {...serial} placeholder="사원번호" type="text" required />
          </div>
          <div className={styles.inputWrapper}>
            <label>이름</label>
            <input {...nickname} placeholder="이름" type="text" required />
          </div>
          <div className={styles.inputWrapper}>
            <label>부서</label>
            <input {...department} placeholder="부서" type="text" required />
          </div>
          <div className={styles.inputWrapper}>
            <label>직급</label>
            <input {...level} placeholder="직급" type="text" required />
          </div>
          <div className={styles.inputWrapper}>
            <label>연락처</label>
            <input {...phone} placeholder="연락처" maxLength={11} type="number" required />
          </div>
          <div className={styles.inputWrapper}>
            <label>아이디</label>
            <input {...signname} placeholder="아이디" type="text" required />
          </div>
          <div className={styles.inputWrapper}>
            <label>비밀번호</label>
            <input {...password} placeholder="비밀번호" type="password" required />
          </div>
          <div className={styles.inputWrapper}>
            <label>비밀번호 확인</label>
            <input {...password2} placeholder="비밀번호 확인" type="password" required />
          </div>
        </div>
        <Button
          htmlType="submit"
          style={{
            borderColor: '#3d3d3d',
            backgroundColor: '#3d3d3d',
            borderRadius: 30,
            height: 60,
            color: '#fff',
            width: '100%',
            fontWeight: '700',
          }}
          size="large"
          onClick={handleSubmit}
        >
          등록하기
        </Button>
      </Form>
    </Modal>
  );
};

export default CreateAccountModal;
