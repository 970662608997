import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Modal, Select} from 'antd';
import styles from '../../../../nav/BasicLayout.module.less';
import {restApi} from '../../../../apis';


/**
 * 센터 사원등록 모달
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const CenterAccountForm = ({ centerId, formType, onExit, onSubmitted }) => {
  const [center, setCenter] = React.useState([]);

  const visible = formType !== undefined;
  const [data, setData] = useState({
    center_id: centerId || undefined,
    corp_serial: '',
    nickname: '',
    corp_department: '',
    corp_level: '',
    phone: '',
    password: '',
  });

  // 데이터 초기 조회
  useEffect(() => {
    if (!formType) return;
    const loadData = async () => {
      const { data } = await restApi.get(`/accounts/${formType}`);
      setData({
        center_id: data.center_id,
        corp_serial: data.corp_serial,
        nickname: data.nickname,
        corp_department: data.corp_department,
        corp_level: data.corp_level,
        phone: data.phone,
        password: '',
      });
    };
    loadData().catch(console.warn);
  }, [formType]);

  // 저장
  const handleSubmit = async () => {
    // 사원번호 이름 센터 부서 직급 연락처 아이디 비밀번호
    if (!data.corp_serial) {
      alert('사원번호를 입력해주세요.');
      return;
    }

    if (!data.nickname) {
      alert('이름을 입력해주세요.');
      return;
    }

    if (!data.center_id) {
      alert('센터를 선택해주세요.');
      return;
    }

    if (!data.phone) {
      alert('연락처를 입력해주세요.');
      return;
    }
    if (!data.password && formType === 0) {
      alert('비밀번호를 입력해주세요.');
      return;
    }

    console.log({ data });
    if (formType === 0) {
      // new..
      await restApi.post(`/centers/${data.center_id}/members`, data);
    } else {
      console.log({ data });
      await restApi.put(`/centers/${data.center_id}/members/${formType}`, data);
    }
    onSubmitted();
  };

  // 센터 목록 조회
  const loadCenter = async () => {
    try {
      const { data } = await restApi.get('/centers', {
        params: {
          limit: 999,
        },
      });
      setCenter(data?.items);
    } catch (e) {
      console.warn(e);
    }
  };

  React.useEffect(() => {
    loadCenter().catch();
  }, []);

  return (
    <Modal visible={visible} onOk={handleSubmit} onCancel={onExit} footer={false} width={500} centered destroyOnClose>
      <h4 className={styles.modalTitle}>사원등록</h4>
      <Form>
        <div style={{ paddingTop: 30, paddingBottom: 20 }}>
          {!centerId && (
            <Form.Item label={'센터'} style={{ marginBottom: 15 }}>
              <Select
                style={{ width: '100%' }}
                value={data.center_id}
                onChange={(e) => {
                  setData((x) => ({ ...x, center_id: e }));
                }}
                placeholder={'센터를 선택해주세요.'}
              >
                {center?.map((x) => (
                  <Select.Option key={x?.id} value={x?.id}>
                    {x?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item label={'사원번호'} style={{ marginBottom: 15 }}>
            <Input
              value={data.corp_serial}
              onChange={(e) => {
                const v = e.target.value;
                setData((x) => ({ ...x, corp_serial: v }));
              }}
              placeholder="사원번호"
              type="text"
            />
          </Form.Item>

          <Form.Item label={'이름'} style={{ marginBottom: 15 }}>
            <Input
              value={data.nickname}
              onChange={(e) => {
                const v = e.target.value;
                setData((x) => ({ ...x, nickname: v }));
              }}
              placeholder="이름"
              type="text"
            />
          </Form.Item>

          <Form.Item label={'부서'} style={{ marginBottom: 15 }}>
            <Input
              value={data.corp_department}
              onChange={(e) => {
                const v = e.target.value;
                setData((x) => ({ ...x, corp_department: v }));
              }}
              placeholder="부서"
              type="text"
            />
          </Form.Item>
          <Form.Item label={'직급'} style={{ marginBottom: 15 }}>
            <Input
              value={data.corp_level}
              onChange={(e) => {
                const v = e.target.value;
                setData((x) => ({ ...x, corp_level: v }));
              }}
              placeholder="직급"
              type="text"
            />
          </Form.Item>
          <Form.Item label={'연락처'} style={{ marginBottom: 15 }}>
            <Input
              value={data.phone}
              onChange={(e) => {
                const v = e.target.value;
                setData((x) => ({ ...x, phone: v }));
              }}
              placeholder="연락처"
              maxLength={11}
              type="text"
            />
          </Form.Item>

          <Form.Item label={'비밀번호'}>
            <Input.Password
              value={data.password}
              onChange={(e) => {
                const v = e.target.value;
                setData((x) => ({ ...x, password: v }));
              }}
              placeholder="비밀번호"
              type="password"
            />
            {formType > 0 && <span>비밀번호 입력 시 교체됩니다.</span>}
          </Form.Item>
        </div>
        <Button
          style={{
            borderColor: '#3d3d3d',
            backgroundColor: '#3d3d3d',
            borderRadius: 30,
            height: 60,
            color: '#fff',
            width: '100%',
            fontWeight: '700',
          }}
          size="large"
          onClick={handleSubmit}
        >
          저장
        </Button>
      </Form>
    </Modal>
  );
};

export default CenterAccountForm;
