import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Descriptions, Input, PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import styles from '../work/review/details/reviewDetails.module.less';
import { restApi } from '../../apis';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * 기업 생성
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const VendorsCreate = ({ vid, title }) => {
  const { id } = useParams();

  const vendorId = id ?? vid;
  const { principal } = useSelector((s) => s.auth, shallowEqual);

  const [body, setBody] = useState({
    name: '',
    address: '',
    phone: '',
    signname: '',
    password: '',
    mgrname: '',
    perm_master: false,
    perm_user: true,
    sale_per: 0,
    rate_per: 0,
    master_per: 0,
    allow_service: [],
  });

  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      const { data } = await restApi.get(`/vendors/${vendorId}`, {});
      setBody({
        ...data,
      });
    };
    if (vendorId) {
      loadData().catch(console.warn);
    }
  }, [vendorId]);

  // 기업생성
  const handleCreatePost = async () => {
    try {
      if (vendorId) {
        await restApi.put(`/vendors/${vendorId}`, body);
        alert('기업이 수정되었습니다.');
      } else {
        await restApi.post(`/vendors`, body);
        alert('기업이 등록되었습니다.');
      }
      if (!vid) {
        navigate('/vendors/list', { replace: true });
      }
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
    }
  };

  // 기업 삭제
  const handleRemove = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;

    await restApi.delete(`/vendors/${vendorId}`);
    alert('기업회원이 삭제되었습니다.');
    navigate('/vendors/list', { replace: true });
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={title ? title : vid ? '내 정보' : vendorId ? '기업회원 수정' : '기업회원 등록'}
      />
      <Descriptions bordered>
        <Descriptions.Item
          label={'기업명'}
          span={4}
          className="nopadding"
          style={{ verticalAlign: 'top', padding: '10px 20px' }}
        >
          <Input
            value={body.name}
            onChange={(e) => {
              const value = e.target.value;
              setBody((x) => ({ ...x, name: value }));
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={'주소'}
          span={4}
          className="nopadding"
          style={{ verticalAlign: 'top', padding: '10px 20px' }}
        >
          <Input
            value={body.address}
            onChange={(e) => {
              const value = e.target.value;
              setBody((x) => ({ ...x, address: value }));
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={'아이디'}
          span={2}
          className="nopadding"
          style={{ verticalAlign: 'top', padding: '10px 20px' }}
        >
          <Input
            value={body.signname}
            disabled={!!vendorId}
            onChange={(e) => {
              const value = e.target.value;
              setBody((x) => ({ ...x, signname: value }));
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={'비밀번호'}
          span={2}
          className="nopadding"
          style={{ verticalAlign: 'top', padding: '10px 20px' }}
        >
          <Input.Password
            value={body.password}
            onChange={(e) => {
              const value = e.target.value;
              setBody((x) => ({ ...x, password: value }));
            }}
          />
          {vendorId && <span>변경시 입력해주세요.</span>}
        </Descriptions.Item>
        <Descriptions.Item
          label={'담당자명'}
          span={2}
          className="nopadding"
          style={{ verticalAlign: 'top', padding: '10px 20px' }}
        >
          <Input
            value={body.mgrname}
            onChange={(e) => {
              const value = e.target.value;
              setBody((x) => ({ ...x, mgrname: value }));
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={'연락처'}
          span={2}
          className="nopadding"
          style={{ verticalAlign: 'top', padding: '10px 20px' }}
        >
          <Input
            value={body.phone}
            onChange={(e) => {
              const value = e.target.value;
              setBody((x) => ({ ...x, phone: value }));
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={'분류'}
          span={4}
          className="nopadding"
          style={{ verticalAlign: 'top', padding: '10px 20px' }}
        >
          <Checkbox
            disabled={!!vid && principal?.type !== 'A'}
            checked={!!body.perm_user}
            onClick={() => setBody((x) => ({ ...x, perm_user: !x.perm_user }))}
          >
            고객 영업 기업
          </Checkbox>
          <Checkbox
            disabled={!!vid && principal?.type !== 'A'}
            checked={!!body.perm_master}
            onClick={() => setBody((x) => ({ ...x, perm_master: !x.perm_master }))}
          >
            장인 관리 기업
          </Checkbox>
        </Descriptions.Item>
        {body.perm_user && (
          <>
            <Descriptions.Item
              label={'배송범위'}
              span={4}
              className="nopadding"
              style={{ verticalAlign: 'top', padding: '10px 20px' }}
            >
              <Checkbox.Group value={body.allow_service} onChange={(v) => setBody((x) => ({ ...x, allow_service: v }))}>
                <Checkbox disabled={!!vid && principal?.type !== 'A'} value={'DT'}>
                  직접 배송
                </Checkbox>
                <Checkbox disabled={!!vid && principal?.type !== 'A'} value={'TS'}>
                  택배 배송
                </Checkbox>
              </Checkbox.Group>
            </Descriptions.Item>
            <Descriptions.Item
              label={'할인비율'}
              span={2}
              className="nopadding"
              style={{ verticalAlign: 'top', padding: '10px 20px' }}
            >
              <Input
                disabled={!!vid && principal?.type !== 'A'}
                suffix={'%'}
                value={body.sale_per}
                onChange={(e) => {
                  const value = e.target.value;
                  setBody((x) => ({ ...x, sale_per: value }));
                }}
              />
            </Descriptions.Item>
            <Descriptions.Item
              label={'배당비율'}
              span={2}
              className="nopadding"
              style={{ verticalAlign: 'top', padding: '10px 20px' }}
            >
              <Input
                disabled={!!vid && principal?.type !== 'A'}
                suffix={'%'}
                value={body.rate_per}
                onChange={(e) => {
                  const value = e.target.value;
                  setBody((x) => ({ ...x, rate_per: value }));
                }}
              />
            </Descriptions.Item>
          </>
        )}
        {body.perm_master && (
          <>
            <Descriptions.Item
              label={'장인정산비율'}
              span={2}
              className="nopadding"
              style={{ verticalAlign: 'top', padding: '10px 20px' }}
            >
              <Input
                value={body.master_per}
                onChange={(e) => {
                  const value = e.target.value;
                  setBody((x) => ({ ...x, master_per: value }));
                }}
              />
            </Descriptions.Item>
          </>
        )}
      </Descriptions>
      <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        {!!vendorId && !vid && (
          <Button
            onClick={handleRemove}
            size={'large'}
            className={styles.basicButton}
            style={{ marginRight: 5, height: 40, width: 100 }}
          >
            삭제
          </Button>
        )}
        {!vid && (
          <Button
            onClick={() => navigate('/vendors/list', { replace: true })}
            size={'large'}
            className={styles.basicButton}
            style={{ marginRight: 5, height: 40, width: 100 }}
          >
            취소
          </Button>
        )}
        <Button
          onClick={handleCreatePost}
          size={'large'}
          className={styles.basicButton}
          style={{ height: 40, width: 100, backgroundColor: '#FF7200', color: 'white' }}
        >
          저장
        </Button>
      </div>
    </>
  );
};

export default VendorsCreate;
