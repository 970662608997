import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Space } from 'antd';
import { restApi } from '../../../apis';
import FileUpload from '../../../components/FileUpload';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * 상품설정 2 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const PCategory2Form = ({ code, type, parent, formType, onExit, onSubmitted }) => {
  const visible = formType !== undefined;
  const INIT_STATE = {
    code,
    type,
    name: '',
    depth: 1,
    extra1: '',
    extra2: '',
    extra3: '',
    color: '',
  };

  const [data, setData] = useState(INIT_STATE);

  const { categories } = useSelector((s) => s.product, shallowEqual);

  useEffect(() => {
    if (!formType || !parent || !type) return;
    const parentItem = categories?.[type]?.find?.((v) => String(v.id) === String(parent?.id));
    const item = parentItem?.items?.find((v) => v.id === formType);
    setData(item);
  }, [formType, parent, type]);

  const handleSubmit = async () => {
    // 사원번호 이름 센터 부서 직급 연락처 아이디 비밀번호
    if (!data.name) {
      alert('이름을 입력해주세요.');
      return;
    }

    console.log(data, parent);

    const body = { ...data };

    body.code = parent?.code;
    body.parent = parent?.id;

    if (formType === 0) {
      // new..
      await restApi.post(`/products/categories`, body);
    } else {
      await restApi.put(`/products/categories/${formType}`, body);
    }
    onSubmitted();
    setData(INIT_STATE);
  };

  const handleDelete = async () => {
    if (!window.confirm('삭제하시겠습니끼?')) return;
    await restApi.delete(`/products/categories/${formType}`);
    onSubmitted();
    setData(INIT_STATE);
  };

  return (
    <Modal
      visible={visible}
      onOk={handleSubmit}
      onCancel={() => {
        onExit();
        setData(INIT_STATE);
      }}
      footer={false}
      width={500}
      centered
      destroyOnClose
    >
      <h4>카테고리</h4>
      <Form>
        <div style={{ paddingTop: 30, paddingBottom: 20 }}>
          <Form.Item label={'이름'} style={{ marginBottom: 15 }}>
            <Input
              value={data.name}
              onChange={(e) => {
                const v = e.target.value;
                setData((x) => ({ ...x, name: v }));
              }}
              placeholder="이름"
              type="text"
            />
          </Form.Item>

          <Form.Item label={'컬러'} style={{ marginBottom: 15 }}>
            <Input
              value={data.color}
              onChange={(e) => {
                const v = e.target.value;
                setData((x) => ({ ...x, color: v }));
              }}
              placeholder="컬러 6비트"
              type="text"
            />
          </Form.Item>
          <Form.Item label={'상단설명1'} style={{ marginBottom: 15 }}>
            <Input.TextArea
              value={data.extra2}
              onChange={(e) => {
                const v = e.target.value;
                setData((x) => ({ ...x, extra2: v }));
              }}
              type="text"
            />
          </Form.Item>
          <Form.Item label={'상단설명2'} style={{ marginBottom: 15 }}>
            <Input.TextArea
              value={data.extra1}
              onChange={(e) => {
                const v = e.target.value;
                setData((x) => ({ ...x, extra1: v }));
              }}
              type="text"
            />
          </Form.Item>
        </div>
        <div style={{ textAlign: 'right' }}>
          <Space>
            <Button primary onClick={handleSubmit}>
              저장
            </Button>
            {!!formType && (
              <Button danger onClick={handleDelete}>
                삭제
              </Button>
            )}
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default PCategory2Form;
