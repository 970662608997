import React from 'react';

/**
 * 이미지 asset 컴포넌트
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const Icon = ({ source, size, style }) => (
  <span style={style}>
    <img
      src={source}
      style={{ width: size, height: size, objectFit: 'contain', marginRight: 5 }}
    />
  </span>
);

export default Icon;
