import React from 'react';
import { Button, Descriptions, Divider, Input, PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import useInput from '../../../../hooks/useInput';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Loading from '../../../../components/Loading';
import useAxios from '../../../../hooks/useAxios';
import { deleteQeustionAnswer, getQuestion, updateQuestion as updateQuestionApi } from '../../../../apis/mypage';
import styles from './reviewDetails.module.less';
import { restApi } from '../../../../apis';

/**
 * qna 상세 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const QnADetails = () => {
  const navigate = useNavigate();
  const [editable, setEditable] = React.useState(false);
  const [editorState, setEditorState] = React.useState(null);
  const [replyContents, setContents] = React.useState('');
  const question = useAxios();
  const updateQuestion = useAxios();
  const isFinish = question.data?.state === 'FINISH';
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const replyTitle = useInput('');
  const { id: questionId } = useParams();

  // 기존데이터 조회
  const loadData = () => {
    return question.loadData(getQuestion(questionId)).then((res) => {
      replyTitle?.onChange(res?.data?.reply_title);
      if (res?.data?.reply_contents) {
        setContents(res?.data?.reply_contents);
        setEditorState(
          EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(res?.data?.reply_contents))),
        );
      }
      return res;
    });
  };
  const handleSubmit = async () => {
    if (!replyTitle.value) {
      alert('답변 제목을 입력해주세요.');
      return;
    }
    try {
      const requestBody = {
        reply_title: replyTitle.value,
        reply_contents: replyContents,
      };
      await updateQuestion.loadData(updateQuestionApi(questionId, requestBody));
      alert('답변 완료되었습니다.');
      loadData();
      setEditable(false);
    } catch (error) {
      console.warn(error);
      alert(error?.respones?.data?.message);
    }
  };

  // 답변 삭제
  const handleDeleteQna = async () => {
    if (questionId > 0) {
      await updateQuestion.loadData(deleteQeustionAnswer(questionId));
    }
    alert('답변을 초기화 하였습니다.');
    navigate(-1);
  };

  const handleDeleteQna1 = async () => {
    if (!window.confirm('삭제하시겠습니끼?')) return;
    if (questionId > 0) {
      await restApi.delete(`/mypages/questions/${questionId}`);
      // await updateQuestion.loadData(deleteQeustionAnswer(questionId));
    }
    alert('해당 문의를 삭제하였습니다.');
    navigate(-1);
  };

  //

  // 에러나면 뒤로~
  React.useEffect(() => {
    loadData().catch((error) => {
      console.warn(error);
      alert(error?.response?.data?.message);
      navigate(-1);
    });
  }, []);

  // 에디터 상태 저장
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  if (question.loading) {
    return <Loading />;
  }

  return (
    <div>
      <PageHeader className="site-page-header" title="Q&A 답변하기" />

      <Descriptions bordered>
        <Descriptions.Item label="문의제목" span={4}>
          <span>{question.data?.title}</span>
        </Descriptions.Item>
        <Descriptions.Item label="질문자" span={4}>
          <span>{question.data?.account.nickname}</span>
        </Descriptions.Item>
        <Descriptions.Item label="질문내용">
          <div style={{ height: 200 }}>
            {/* {question.data?.contents} */}
            <div dangerouslySetInnerHTML={{ __html: question?.data?.contents }} />
          </div>
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      <Descriptions bordered>
        <Descriptions.Item label="답변 제목" span={4}>
          {isFinish && !editable && <span>{question.data?.reply_title}</span>}
          {(!isFinish || !!editable) && (
            <Input
              value={replyTitle?.value}
              onChange={(e) => replyTitle?.onChange(e)}
              placeholder="답변 제목"
              bordered={false}
              style={{ padding: 0 }}
            />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="답변자" span={4}>
          {principal?.nickname}
        </Descriptions.Item>
        <Descriptions.Item label="답변 내용">
          {isFinish && !editable && <div dangerouslySetInnerHTML={{ __html: question?.data?.reply_contents }} />}
          {(!isFinish || !!editable) && (
            <Editor
              toolbarClassName="toolbar-class"
              onChange={(value) => setContents(draftToHtml(value))}
              editorStyle={{ height: 350 }}
              toolbar={{
                options: [
                  'inline',
                  'blockType',
                  'fontSize',
                  'fontFamily',
                  'list',
                  'textAlign',
                  'colorPicker',
                  'link',
                  'emoji',
                  'remove',
                  'history',
                ],
              }}
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              // toolbarHidden
              placeholder="내용을 작성해주세요."
              // 한국어 설정
              localization={{
                locale: 'ko',
              }}
            />
          )}
        </Descriptions.Item>
      </Descriptions>
      {!isFinish || !!editable ? (
        <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
          <Button
            size="large"
            onClick={handleSubmit}
            style={{ marginRight: 10 }}
            loading={updateQuestion.loading}
            className={styles.basicButton_dark}
          >
            확인
          </Button>
          <Button
            size="large"
            className={styles.basicButton_dark}
            onClick={() => {
              navigate(-1);
            }}
          >
            목록
          </Button>
        </div>
      ) : (
        <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
          <Button
            size="large"
            onClick={handleDeleteQna1}
            style={{ marginRight: 10 }}
            loading={updateQuestion.loading}
            className={styles.basicButton_dark}
          >
            삭제
          </Button>
          <Button
            size="large"
            loading={updateQuestion.loading}
            className={styles.basicButton_dark}
            onClick={() => setEditable(true)}
            style={{ marginRight: 10 }}
          >
            답변수정
          </Button>
          <Button
            size="large"
            onClick={handleDeleteQna}
            style={{ marginRight: 10 }}
            loading={updateQuestion.loading}
            className={styles.basicButton_dark}
          >
            답변삭제
          </Button>
          <Button
            size="large"
            className={styles.basicButton_dark}
            onClick={() => {
              navigate(-1);
            }}
          >
            목록
          </Button>
        </div>
      )}
    </div>
  );
};

export default QnADetails;
