import { restApi } from '../index';

export const couponTargettingType = {
  ALL: 'ALL',
  CODE: 'CODE',
  TARGETING: 'TARGETING',
  FIRST: 'FIRST',
  REUSE: 'REUSE',
};

export const couponTargettingTypes = {
  [couponTargettingType.ALL]: '전체',
  [couponTargettingType.FIRST]: '첫이용고객',
  [couponTargettingType.REUSE]: '재이용고객',
  [couponTargettingType.TARGETING]: '타겟팅',
  [couponTargettingType.CODE]: '코드방식',
};

export const couponFirstMethodType = {
  FIRST_PAYMENT: 'FIRST_PAYMENT',
  FIRST_PAYMENT_DT: 'FIRST_PAYMENT_DT',
  FIRST_PAYMENT_SP: 'FIRST_PAYMENT_SP',
  FIRST_MEMBERSHIP: 'FIRST_MEMBERSHIP',
  JOIN: 'JOIN',
};

export const couponFirstMethodTypes = {
  [couponFirstMethodType.FIRST_PAYMENT]: '첫 정기구독',
  [couponFirstMethodType.FIRST_PAYMENT_DT]: '첫 하루배송',
  [couponFirstMethodType.FIRST_PAYMENT_SP]: '첫 긴급배송',
  [couponFirstMethodType.FIRST_MEMBERSHIP]: '첫 멤버십 가입',
  [couponFirstMethodType.JOIN]: '회원가입',
};

export const couponReuseMethodType = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
};

export const couponReuseMethodTypes = {
  [couponReuseMethodType[1]]: '1개월 후 재이용',
  [couponReuseMethodType[2]]: '2개월 후 재이용',
  [couponReuseMethodType[3]]: '3개월 후 재이용',
  [couponReuseMethodType[4]]: '4개월 후 재이용',
  [couponReuseMethodType[5]]: '5개월 후 재이용',
  [couponReuseMethodType[6]]: '6개월 후 재이용',
};

export const couponStatusType = {
  READY: 'READY',
  WAIT: 'WAIT',
};

export const couponStatusTypes = {
  [couponStatusType.READY]: '적용',
  [couponStatusType.WAIT]: '대기',
};

export const getCoupons = (body) => {
  const endpoint = '/coupons';
  return restApi.get(endpoint, body);
};

export const getCoupon = (id) => {
  const endpoint = `/coupons/${id}`;
  return restApi.get(endpoint);
};

export const createCoupons = (body) => {
  const endpoint = '/coupons';
  return restApi.post(endpoint, body);
};

export const updateCoupons = (id, body) => {
  const endpoint = `/coupons/${id}`;
  return restApi.put(endpoint, body);
};

export const deleteCoupons = (id) => {
  const endpoint = `/coupons/${id}`;
  return restApi.delete(endpoint);
};
