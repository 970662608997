import React from 'react';
import { Button, PageHeader, Table } from 'antd';
import { restApi } from '../../../apis';
import moment from 'moment';
import { Link } from 'react-router-dom';
import TableButtonWrap from '../../../components/TableButtonWrap';

/**
 * 기타설정 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const AgreementIndex = () => {
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    const loadData = async () => {
      const { data } = await restApi.get(`/agreements`);
      setData(data);
    };
    loadData().catch(console.warn);
  }, []);

  // 테이블 컬럼 정의
  const column = [
    {
      title: '공개범위',
      dataIndex: 'target',
      align: 'center',
      render: (d) => {
        return d === 'user' ? '사용자' : d === 'postman' ? '기사' : '장인';
      },
    },

    {
      title: '종류',
      dataIndex: 'type',
      align: 'center',
    },
    {
      title: '마지막 수정일',
      dataIndex: 'created_at',
      align: 'center',
      render: (d) => {
        return moment(d).format('YYYY-MM-DD HH:mm');
      },
    },
    {
      title: '자세히보기',
      dataIndex: 'id',
      align: 'center',
      render: (d, r) => {
        return (
          <Link to={`/settings/agreement/create?id=${d}`}>
            <Button size={'small'}>수정</Button>
          </Link>
        );
      },
    },
  ];

  return (
    <div>
      <PageHeader className="site-page-header" title="약관설정" />
      <div style={{ position: 'relative' }}>
        <Table columns={column} dataSource={data} scroll={{ x: 'max-content' }} pagination={false} />
        <div style={{ paddingTop: 70 }}>
          <TableButtonWrap
            position="left"
            buttons={[
              { icon: require('../../../assets/icon_write.png'), label: '작성', link: '/settings/agreement/create' },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default AgreementIndex;
