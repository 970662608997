import {restApi} from "../index";

export const createCenter = (body) => {
  const endpoint = '/centers';
  return restApi.post(endpoint, body);
};

export const updateCenter = (id, body) => {
  const endpoint = `/centers/${id}`;
  return restApi.put(endpoint, body);
};

export const createCenterMember = (id, body) => {
  const endpoint = `/centers/${id}/members`;
  return restApi.post(endpoint, body);
};

export const getCenterMembers = (id, body) => {
  const endpoint = `/centers/${id}/members`;
  return restApi.get(endpoint, body);
};

export const deleteCenterMember = (id, uid) => {
  const endpoint = `/centers/${id}/members/${uid}`;
  return restApi.delete(endpoint);
};

export const deleteCenter = (id) => {
  const endpoint = `/centers/${id}`;
  return restApi.delete(endpoint);
};
