import React, { useState } from 'react';
import { Form, Input, Modal, PageHeader, Switch, Table, Typography } from 'antd';
import { accountSearchFields } from '../../../common/searchFields';
import SearchForm from '../../../components/datalist/SearchForm';
import TableButtonWrap from '../../../components/TableButtonWrap';
import { phoneFormat } from '../../../common/utils';
import moment from 'moment';
import { getAPIHost, restApi } from '../../../apis';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import fileDownload from 'js-file-download';
import CenterAccountForm from './details/form';

/**
 * 센터 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const AccountCenterIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);

  const [data, setData] = React.useState();

  const [formType, setFormType] = React.useState(undefined);

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      sort: 'created_at',
      ...query,
      type: 'C',
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    }

    const { data } = await restApi.get('/accounts', {
      params,
    });
    setData(data);
  }, [query]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  // 활성화 상태 변경
  const handleActiveChange = async (id, active) => {
    if (!window.confirm('변경하시겠습니까?')) {
      return;
    }
    try {
      const { data } = await restApi.put(`/accounts/${id}/active`, {
        active,
      });
      await loadData();
    } catch (e) {
      console.warn(e);
    }
  };

  // 테이블 컬럼 정의
  const columns = [
    {
      title: '번호',
      dataIndex: 'id',
      align: 'center',
      width: 90,
      render: (_, _2, i) => {
        return _;
        // return data?.total_elements - i - data?.page * 10;
      },
      // render: (_, __, index) => {
      //   return (accounts.data?.total_elements || 0) - (limit * page + index);
      // },
    },
    {
      title: '이름',
      dataIndex: 'nickname',
      width: 100,
      align: 'center',
      render: (r) => (
        <Typography.Text ellipsis={true} style={{ width: 100 }}>
          {r}
        </Typography.Text>
      ),
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      align: 'center',
      width: 150,
      render: (r) => phoneFormat(r),
    },
    {
      title: '센터(주소)',
      dataIndex: 'address',
      key: 'address',
      align: 'center',
      // width: 100,
      // render: (value) => {
      //   return value === 'C' ? '센터직원' : value;
      //   // return value ? '긴급' : '정기/하루';
      // },
    },

    // {
    //   title: '지역 (시)',
    //   dataIndex: 'sido',
    //   width: 150,
    //   align: 'center',
    // },
    // {
    //   title: '지역 (구)',
    //   dataIndex: 'gugun',
    //   width: 150,
    //   align: 'center',
    // },

    {
      title: '부서',
      dataIndex: 'corp_department',
      key: 'corp_department',
      align: 'center',
      width: 150,
    },
    {
      title: '직책',
      dataIndex: 'corp_level',
      key: 'corp_level',
      align: 'center',
      width: 150,
    },
    {
      title: '승인여부',
      dataIndex: 'active',
      key: 'active',
      width: 100,
      align: 'center',
      render: (x, row) => {
        return <Switch checked={x} onChange={(e) => handleActiveChange(row?.id, e)} />;
      },
    },

    {
      title: '가입일자',
      dataIndex: 'created_at',
      align: 'center',
      width: 200,
      render: (data) => {
        return moment(data).format('YYYY-MM-DD HH:mm');
      },
    },
    {
      key: 'pwd',
      title: '정보수정',
      dataIndex: 'id',
      align: 'center',
      width: 120,
      render: (data, row) => (
        <a style={{ color: 'black' }} onClick={() => setFormType(data)}>
          {/*<Icon source={require('../../../assets/icon_gear.png')} size={18} />*/}
          정보수정
        </a>
      ),
    },
    {
      title: '자세히보기',
      dataIndex: 'id',
      align: 'center',
      width: 120,
      render: (data) => (
        <Link to={`/accounts/center/details/${data}`}>
          <a style={{ color: 'black' }}>
            {/*<Icon source={require('../../../assets/icon_gear.png')} size={18} />*/}
            자세히보기
          </a>
        </Link>
      ),
    },
  ];

  // 테이블 페이지네이션
  const pagination = {
    position: ['bottomCenter'],
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      navigate('?' + qs.stringify({ ...query, page: p - 1 }), { replace: true });
    },
  };

  // 엑셀저장
  const xlsxSave = () => {
    const params = {
      ...query,
      type: 'C',
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    }
    restApi
      .get(`${getAPIHost()}/exports/accounts/center?${qs.stringify(params)}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `센터직원_${moment().format('YYYYMMDDHHmmss')}.xlsx`);
      });
  };

  return (
    <div>
      <CenterAccountForm
        formType={formType}
        onExit={() => setFormType(undefined)}
        onSubmitted={() => {
          setFormType(undefined);
          loadData().catch(console.warn);
        }}
      />
      <PageHeader className="site-page-header" title="센터" />
      <SearchForm fields={accountSearchFields} />
      <div style={{ position: 'relative' }}>
        <Table
          scroll={{ x: 'max-content' }}
          dataSource={data?.items}
          columns={columns}
          pagination={
            pagination
              ? {
                  ...pagination,
                  position: ['bottomCenter'],
                  showSizeChanger: false,
                }
              : false
          }
        />
        <div>
          <TableButtonWrap
            position="left"
            buttons={[
              { icon: require('../../../assets/icon_save.png'), label: '엑셀 저장', onClick: xlsxSave },
              {
                icon: require('../../../assets/user_avatar.png'),
                label: '사원 등록',
                onClick: () => setFormType(0),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountCenterIndex;
