import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {restApi} from "../../apis";

const initialState = {
  teams: [],
};

/**
 * 팀 관련 Redux
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
export const getTeamsItems = createAsyncThunk('items', async () => {
  const response = await restApi.get(`/teams`);
  return response.data;
});

const AuthSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {},
  extraReducers: {
    [getTeamsItems.fulfilled]: (state, action) => {
      console.log({state,action})
      state.teams = action.payload?.items;
    },
  },
});

export const {} = AuthSlice.actions;

export default AuthSlice.reducer;
