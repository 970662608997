import React from 'react';
import { PageHeader, Table, Typography } from 'antd';
import SearchForm from '../../../components/datalist/SearchForm';
import { getUserFrom, phoneFormat, toCommaNumber } from '../../../common/utils';
import moment from 'moment';
import qs from 'query-string';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getAPIHost, restApi } from '../../../apis';
import TableButtonWrap from '../../../components/TableButtonWrap';
import fileDownload from 'js-file-download';
import { accountSearchFields } from '../../../common/searchFields';

/**
 * 고객회원 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const VAccountUserIndex = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);

  const [data, setData] = React.useState();

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      ...query,
      type: 'U',
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    } else if (query.field === 'address') {
      params.address = query.keyword;
    }

    const { data } = await restApi.get('/accounts', {
      params,
    });
    setData(data);
  }, [query]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  //테이블 컬럼명 정의
  const columns = React.useMemo(
    () => [
      {
        title: '번호',
        align: 'center',
        key: 'id',
        dataIndex: 'id',
        width: 90,
        render: (_, _2, i) => {
          return data?.total_elements - i - data?.page * 10;
        },
      },
      {
        title: '이름',
        key: 'nickname',
        dataIndex: 'nickname',
        width: 100,
        align: 'center',
        render: (r) => (
          <Typography.Text ellipsis={true} style={{ width: 100 }}>
            {r}
          </Typography.Text>
        ),
      },
      {
        title: '연락처',
        dataIndex: 'phone',
        key: 'phone',
        width: 150,
        align: 'center',
        render: (r) => phoneFormat(r),
      },
      // {
      //   title: '가입수단',
      //   dataIndex: 'platform',
      //   key: 'platform',
      //   width: 90,
      //   align: 'center',
      //   render: (data, row) => {
      //     return getUserFrom(row.signname, data);
      //   },
      // },
      {
        title: '멤버십등급',
        dataIndex: 'membership',
        key: 'membership',
        width: 100,
        align: 'center',
        render: (_, __, index) => {
          return _?.name || '-';
        },
      },
      {
        title: '포인트',
        dataIndex: 'point',
        key: 'point',
        width: 100,
        align: 'center',
        render: (data) => toCommaNumber(data),
      },
      {
        title: '주문건수',
        dataIndex: 'purchase_count',
        key: 'purchase_count',
        width: 100,
        align: 'center',
        render: (data) => toCommaNumber(data),
      },
      {
        title: '주소',
        dataIndex: 'address',
        key: 'address',
        align: 'left',
      },

      {
        title: '가입일자',
        dataIndex: 'created_at',
        key: 'created_at',
        align: 'center',
        width: 200,
        render: (data) => {
          return moment(data).format('YYYY-MM-DD HH:mm');
        },
      },
    ],
    [data],
  );

  // 테이블 페이지네이션
  const pagination = {
    position: ['bottomCenter'],
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      navigate('?' + qs.stringify({ ...query, page: p - 1 }), { replace: true });
    },
  };



  return (
    <div>
      <PageHeader className="site-page-header" title="회원관리" />
      <SearchForm fields={accountSearchFields} />
      <div style={{ position: 'relative' }}>
        <Table
          scroll={{ x: 'max-content' }}
          dataSource={data?.items}
          columns={columns}
          pagination={
            pagination
              ? {
                  ...pagination,
                  position: ['bottomCenter'],
                  showSizeChanger: false,
                }
              : false
          }
        />
      </div>
    </div>
  );
};

export default VAccountUserIndex;
