import axios from 'axios';
import dayjs from 'dayjs';

export const TOKEN_SAVE_NAME = 'app_token_data';
export const TOKEN_RELOAD_MESSAGE_NAME = 'onTokenRefreshed';
export const ASKeyToken = 'token';
const isDev = process.env.NODE_ENV === 'development';

const _tl = {
  p: false,
  s: [],
};

export const getToken = () => {
  const promise = new Promise((resolve, reject) => {
    _tl.s.push({ resolve, reject });
  });

  if (!_tl.p) {
    _tl.p = true;

    const loadData = async () => {
      if (!localStorage.getItem(TOKEN_SAVE_NAME)) return undefined;
      const token = setToken(JSON.parse(localStorage.getItem(TOKEN_SAVE_NAME)));
      // if (token && (token.expires_at || 0) < dayjs().unix()) {
      //   const formData = new FormData();
      //   formData.append('grant_type', 'refresh_token');
      //   formData.append('refresh_token', token.refresh_token);
      //   const { data } = await authApi.post('/auth/login', formData);
      //   setToken(data);
      // }
      return token;
    };

    loadData()
      .then((r) => _tl.s.forEach((o) => o.resolve(r)))
      .catch((e) => {
        _tl.s.forEach((listener) => listener.reject(e));
        window.location.reload();
      })
      .then(() => {
        _tl.s = [];
        _tl.l = false;
      });
  }

  return promise;
};

export const getAPIHost = () => {
  return process.env.REACT_APP_API_HOST || 'https://serv.shoedocadmin.co.kr/v1';
  // return 'https://serv.shoedocadmin.co.kr/v1';
  // return 'https://6005.in.actbase.io/v1'
  // return 'http://localhost:4610/v1'
};

export const getWebURL = () => {
  return process.env.REACT_APP_API_HOST || 'https://serv.shoedocadmin.co.kr/v1';
};

export const getFileHost = () => {
  return `${getAPIHost()}/files`;
};

export const imageApi = axios.create({
  baseURL: '',
})

export const restApi = axios.create({
  baseURL: getAPIHost(),
});

restApi.interceptors.request.use(async (request) => {
  if (localStorage.getItem(TOKEN_SAVE_NAME)) {
    const token = JSON.parse(localStorage.getItem(TOKEN_SAVE_NAME));

    // Refresh Token Applyed
    if ((token.expires_at || 0) < dayjs().unix()) {
      const formData = new FormData();
      formData.append('grant_type', 'refresh_token');
      formData.append('refresh_token', token.refresh_token);

      // const { data } = await authApi.post('/auth/login', formData);
      // setToken(data);
      //
      // const { access_token, refresh_token, access_token_start_at, expires_in, expires_at } = data;
      // token.access_token = access_token;
      // token.refresh_token = refresh_token;
      // token.access_token_start_at = access_token_start_at;
      // token.expires_in = expires_in;
      // token.expires_at = expires_at;
    }

    request.headers.common.authorization = `Bearer ${token.access_token}`;
  }

  return request;
});

let meLoading = false;
restApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 403 && !meLoading) {
      // meLoading = true;
      // restApi
      //   .get(`/users/authentication`)
      //   .then(({ data }) => {
      //     if (data.uuid !== unique_id) {
      //       alert('중복 로그인이 감지되었습니다.');
      //       setToken(null);
      //       window.location.reload();
      //     }
      //   })
      //   .catch((e) => {
      //     window.location.reload();
      //   })
      //   .then(() => (meLoading = false));
    }
    return Promise.reject(error);
  },
);

export const authApi = axios.create({
  baseURL: getAPIHost(),
  auth: {
    username: '[oauth_username]',
    password: '[oauth_password]',
  },
});

// export const setToken = (cookies, setCookie) => {
//   console.log({ cookies, setCookie });
//   const { access_token, refresh_token, expires_at } = cookies;
//   if (access_token) {
//     restApi.defaults.headers.common.authorization = `Bearer ${access_token}`;
//     const now = dayjs().unix() - 1;
//     restApi.store_data = {
//       access_token,
//       refresh_token,
//       expires_at,
//     };
//
//     if (setCookie) {
//       setCookie(ASKeyToken, JSON.stringify({ access_token, refresh_token, expires_at }));
//     }
//   } else {
//     delete restApi.defaults.headers.common.authorization;
//     if (setCookie) {
//       setCookie(ASKeyToken, '');
//     }
//   }
// };
export const setToken = (cookies) => {
  if (!cookies?.access_token) {
    localStorage.setItem(TOKEN_SAVE_NAME, '');
    return '';
  }

  const { access_token, refresh_token, access_token_start_at, expires_in, expires_at } = cookies;
  if (access_token) {
    const now = dayjs().unix() - 1;
    const store_data = {
      access_token,
      refresh_token,
      access_token_start_at: access_token_start_at || now,
      expires_at: expires_at || now + expires_in,
    };
    localStorage.setItem(TOKEN_SAVE_NAME, JSON.stringify(store_data));
    if (window?.opener) {
      window?.opener?.postMessage(TOKEN_RELOAD_MESSAGE_NAME, '*');
    }
    return store_data;
  }
};
