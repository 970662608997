import React from 'react';
import { Button, Input, Modal, PageHeader, Table } from 'antd';
import useAxios from '../../../hooks/useAxios';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import CreateAccountModal from '../../../components/CreateAccountModal';
import Postcode from '@actbase/react-daum-postcode';
import { createCenter as createCenterApi, createCenterMember } from '../../../apis/centers';
import { getGeocode } from '../../../apis/thirdparty';
import LabelRow from '../../../components/LabelRow';
import { useNavigate } from 'react-router-dom';

/**
 * 센터관리 생성 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const CenterMgrCreate = () => {
  const navigate = useNavigate();
  const createCenter = useAxios();
  const [name, setName] = React.useState();
  const [address, setAddress] = React.useState();
  const [visible, setVisible] = React.useState(false);
  const [addressVisible, setAddressVisible] = React.useState(false);
  const [accountData, setAccountData] = React.useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);

  const columns = [
    { title: '사원번호', dataIndex: 'corp_serial', width: 120, align: 'center' },
    { title: '성명', dataIndex: 'nickname', width: 150, align: 'center' },
    { title: '직급', dataIndex: 'corp_level', width: 150, align: 'center' },
    { title: '연락처', dataIndex: 'phone', width: 150, align: 'center' },
    { title: '아이디', dataIndex: 'signname', width: 150, align: 'center' },
  ];

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys,
    onChange: (e) => {
      setSelectedRowKeys(e);
    },
  };

  const handleCreateCenter = async () => {
    if (!address) {
      alert('주소를 입력해주세요.');
      return;
    }
    if (createCenter.loading) return;

    const loadData = async () => {
      const { data: getcodeData } = await getGeocode(address?.address);
      if (!getcodeData) return null;
      const requestBody = {
        type: 'R',
        name,
        address: getcodeData.addr,
        lat: getcodeData.lat,
        lng: getcodeData.lng,
        sido: address.sido,
        gugun: address.sigungu,
      };
      const { data: responseData } = await createCenterApi(requestBody);
      if (responseData) {
        await Promise.all(
          accountData.map((v) => {
            return createCenterMember(responseData.id, v);
          }),
        );
      }
      return responseData;
    };

    await createCenter.loadData(loadData());
    alert('등록 완료되었습니다.');
    navigate('/settings/centermgr', { replace: true });
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleCloseModal = () => {
    setVisible(false);
  };

  const handleOpenAddressModal = () => {
    setAddressVisible(true);
  };

  const handleCloseAddressModal = () => {
    setAddressVisible(false);
  };

  const handleSelectAddress = (e) => {
    setAddress(e);
    handleCloseAddressModal();
  };

  const handleOkModal = (data) => {
    setAccountData((prevState) => [...prevState, data]);
  };

  const handleCancelModal = () => {
    handleCloseModal();
  };

  const handleSelectedDelete = () => {
    setAccountData((prevState) => prevState.filter((v) => !selectedRowKeys.includes(v.id)));
    setSelectedRowKeys([]);
  };

  return (
    <div>
      <Modal
        visible={addressVisible}
        bodyStyle={{ padding: 0 }}
        closable
        onCancel={handleCloseAddressModal}
        closeIcon={() => null}
        centered
        footer={null}
      >
        <Postcode style={{ width: '100%' }} onSelected={handleSelectAddress} />
      </Modal>
      <CreateAccountModal
        corp_department={`${String(address?.address || '').split(' ')[0]}-${name || ''}`}
        visible={visible}
        onOk={handleOkModal}
        onCancel={handleCancelModal}
      />
      <PageHeader className="site-page-header" title="센터관리" />
      <div className="labelrowWrapper">
        <LabelRow placeholder="최대 15자 이내로 입력하세요." label="센터이름">
          <Input style={{ width: '100%' }} value={name} onChange={(e) => setName(e.target.value)} />
        </LabelRow>
        <LabelRow label="주소">
          <Button
            onClick={handleOpenAddressModal}
            style={{ marginRight: 5 }}
            size="large"
            className="basicButton"
            loading={createCenter.loading}
          >
            주소 찾기
          </Button>
          <Input
            placeholder="주소를 입력해주세요."
            value={address?.address}
            contentEditable="false"
            // onFocus={handleOpenAddressModal}
            onClick={handleOpenAddressModal}
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          />
        </LabelRow>
      </div>
      <div className="labelrowButtonSet" style={{ justifyContent: 'flex-start' }}>
        <Button size="large" onClick={handleOpenModal} className="basicButton" style={{ marginRight: 10 }}>
          사원등록
        </Button>
        <Button size="large" onClick={handleSelectedDelete} className="basicButton">
          선택삭제
        </Button>
      </div>
      <div className="tableLayout" style={{ marginTop: 20, backgroundColor: '#fff', border: '1px #ddd solid' }}>
        <Table
          scroll={{ x: 'max-content' }}
          dataSource={accountData}
          columns={columns}
          rowSelection={rowSelection}
          rowKey={({ id }) => id}
          bordered
          pagination={false}
        />
      </div>
      <div>{`사원 정보관리는 "회원관리 > 센터직원"에서 수정가능합니다.`}</div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
        <Button
          onClick={handleCreateCenter}
          style={{ background: '#000', color: '#fff', width: 152, height: 40, borderRadius: 4 }}
          loading={createCenter.loading}
        >
          등록
        </Button>
      </div>
    </div>
  );
};

export default CenterMgrCreate;
