import {Select} from "antd";
import React from "react";

export const ASKeyToken = 'token';

export const PURCHASE_STATE_TEXT = {
  CANCELLED: '취소',
  BUYED: '구매완료',
  PAY_READY: '입금대기',
  FINISH: '완료',
};

export const reviewTypeGood = ['빠른서비스', '친절한 응대', '우수한 수선/광택', '단정한 직원 용모'];
export const reviewTypeBad = ['느린서비스', '불친절한 응대', '미흡한 수선/광택', '불량한 직원 용모'];

export const GrantType = {
  Password: 'password',
};

export const AccountType = {
  user: 'U',
  U: 'U',
  postman: 'T', //기존 D
  T: 'T', // ?
  emengency: 'S',
  S: 'S',
  master: 'M',
  M: 'M',
  admin: 'A',
  A: 'A',
  center: 'C',
  C: 'C',
};

export const postType = {
  SHOE_STORY: 'SHOE_STORY',
  DOC_STORY: 'DOC_STORY',
  NOTICE: 'NOTICE',
};

export const WorkState = {
  READY: 'READY', // 주문
  REGIST: 'REGIST', // 접수(결제완료)
  COLLECT: 'COLLECT', // 수거
  WAREHOUSE: 'WAREHOUSE', // 입고
  LOAD: 'LOAD', // 적재
  DIST: 'DIST', // 분배
  FIXED: 'FIXED', // 정비 완료
  RECLAIM: 'RECLAIM', // 회수
  STORAGE: 'STORAGE', // 보관
  RELEASE: 'RELEASE', // 출고
  DELIVERY: 'DELIVERY', // 배송
  FINISH: 'FINISH', // 배송완료
};

export const PaymentType = {
  SC: '정기구독',
  DT: '하루배송',
  SP: '긴급배송',
  TS: '택배배송',
};

export const NEW_RUN_STATE_MODE = {
  default: {
    NEW: '주문(수거전)',
    GET_ITEM: '수거완료',
    IN_CENTER: '입고완료',
    IN_FIXER: '분배완료',
    FIX_READY: '수리준비중',
    FIX_ING: '수리중',
    OUT_FIXER: '수리완료',
    OUT_CENTER: '회수중',
    PREV_SENDING: '배송전',
    SENDING: '배송중',
    SEND_USER: '배달완료',
    CANCEL: '취소',
  },
  speed: {
    SPEED_NEW: '주문완료(배송전)',
    SPEED_IN: '수거대기',
    SPEED_GET: '수거완료',
    SPEED_FIX: '정비준비',
    SPEED_FIX_ING: '정비중',
    SPEED_FIX_END: '정비완료',
    SPEED_OUT: '배달준비중',
    SPEED_OUT_GET: '배달중',
    SEND_USER: '배달완료',
    CANCEL: '취소',
  },
};

export const NEW_RUN_STATE = {
  NEW: '주문(수거전)',
  GET_ITEM: '수거완료',
  IN_CENTER: '입고완료',
  IN_FIXER: '분배완료',
  FIX_READY: '수리준비중',
  FIX_ING: '수리중',
  OUT_FIXER: '수리완료',
  OUT_CENTER: '회수중',
  PREV_SENDING: '배송전',
  SENDING: '배송중',
  SPEED_NEW: '주문완료(배송전)',
  SPEED_IN: '수거대기',
  SPEED_GET: '수거완료',
  SPEED_FIX: '정비준비',
  SPEED_FIX_ING: '정비중',
  SPEED_FIX_END: '정비완료',
  SPEED_OUT: '배달준비중',
  SPEED_OUT_GET: '배달중',
  SEND_USER: '배달완료',
  CANCEL: '취소',
  TRNS_BEGIN: '접수완료',
  TRNS_SEND: '배송시작'
};

export const NEW_RUN_STATE_CHG = {
  NEW: '주문(수거전) -> 수거완료',
  GET_ITEM: '수거완료 -> 입고완료',
  IN_CENTER: '입고완료 -> 분배완료',
  IN_FIXER: '분배완료 -> 수리준비중',
  FIX_READY: '수리준비중 -> 수리중',
  FIX_ING: '수리중 -> 수리완료',
  OUT_FIXER: '수리완료 -> 회수중',
  OUT_CENTER: '회수중 -> 배송전',
  PREV_SENDING: '배송전 -> 배송중',
  SENDING: '배송중 -> 배달완료',
  SEND_USER: '[표시용] 배달완료(현재)',
  CANCEL: '[표시용] 취소',
  SPEED_NEW: '주문완료(배송전) -> 수거대기',
  SPEED_IN: '수거대기 -> 수거완료',
  SPEED_GET: '수거완료 -> 정비준비',
  SPEED_FIX: '정비준비 -> 정비중',
  SPEED_FIX_ING: '정비중 -> 정비완료',
  SPEED_FIX_END: '정비완료 -> 배달준비중',
  SPEED_OUT: '배달준비중 -> 배달중',
  SPEED_OUT_GET: '배달중 -> 배달완료',
};

export const PAY_METHOD = {
  card: '카드결제',
  vbank: '가상계좌',
  phone: '모바일결제',
  trans: '계좌이체',
  point: 'KCP포인트결제',
  tosspay: '토스페이',
  payco: '페이코',
};

export const getPayname = (d) => {
  if (d?.pg_provider === 'kakaopay') {
    return '카카오페이';
  }
  if (d?.pg_provider === 'tosspay') {
    return '토스페이';
  }
  return PAY_METHOD[d?.pay_method];
};
