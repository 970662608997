import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import { deleteBanner, getBanner } from '../../../../apis/banners';
import { Button, Descriptions, PageHeader } from 'antd';
import moment from 'moment';
import { parseImageUrl } from '../../../../common/utils';
import styles from '../../../work/review/details/reviewDetails.module.less';

/**
 * 상단배너 상세 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const BannerDetails = () => {
  const navigate = useNavigate();
  const banner = useAxios();
  const { id } = useParams();

  const handleDeleteBanner = async () => {
    if (!window.confirm('삭제하시겠습니까?')) {
      return;
    }
    try {
      await deleteBanner(id);
      navigate(-1);
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
    }
  };

  const handleReadList = () => {
    navigate(-1);
  };

  // const addition = [{ label: '수정', icon: 'write', onPress: () => router.push(`/contents/banner_top/update/${id}`) }];
  //
  React.useEffect(() => {
    banner.loadData(getBanner(id)).catch((error) => {
      console.warn(error);
      alert(error?.response?.data?.message);
      navigate(-1);
    });
  }, []);

  return (
    <div>
      <PageHeader className="site-page-header" title="배너 상세" />
      <Descriptions bordered>
        <Descriptions.Item label={'배너대상'} span={4} className="nopadding" style={{ padding: '10px 20px' }}>
          {banner.data?.mode === 'MAIN_BOTTOM' ? '메인하단' : '메인상단'}
        </Descriptions.Item>
        <Descriptions.Item label={'제목'} span={4}>
          {banner.data?.title}
        </Descriptions.Item>
        <Descriptions.Item label={'배너이미지'} span={4}>
          <img
            src={parseImageUrl(banner.data?.image)}
            className="profileImg"
            style={{ height: 300, objectFit: 'cover' }}
            alt="banner"
          />
        </Descriptions.Item>
        <Descriptions.Item label={'광고 URL'} span={4} className="nopadding" style={{ padding: '10px 20px' }}>
          {banner.data?.uri}
        </Descriptions.Item>
        <Descriptions.Item label={'광고시작일'} span={4} className="nopadding" style={{ padding: '10px 20px' }}>
          {banner.data?.start_at && moment(banner.data?.start_at).format('YYYY-MM-DD HH:mm')}
        </Descriptions.Item>
        <Descriptions.Item label={'광고종료일'} span={4} className="nopadding" style={{ padding: '10px 20px' }}>
          {banner.data?.end_at && moment(banner.data?.end_at).format('YYYY-MM-DD HH:mm')}
        </Descriptions.Item>
        <Descriptions.Item label={'순번'} span={4} className="nopadding" style={{ padding: '10px 20px' }}>
          {banner.data?.order_index}
        </Descriptions.Item>
      </Descriptions>
      <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={handleDeleteBanner}
          size={'large'}
          className={styles.basicButton}
          style={{ marginRight: 5, height: 40, width: 100 }}
        >
          삭제
        </Button>
        <Button
          onClick={handleReadList}
          size={'large'}
          className={styles.basicButton}
          style={{ height: 40, width: 100, backgroundColor: '#FF7200', color: 'white', marginRight: 10 }}
        >
          목록
        </Button>
        <Button size="large" className="basicButton dark" style={{ height: 40, width: 100 }}>
          <Link to={`/contents/banner_top/update/${id}`}>수정</Link>
        </Button>
      </div>
    </div>
  );
};

export default BannerDetails;
