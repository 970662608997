import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * 로딩시 컴포넌트
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const LoadingIndicator = () => {
  return (
    <Container>
      <Spin />
    </Container>
  );
};

export default LoadingIndicator;
