import React from 'react';
import styles from './stats.module.less';
import { addComma, pointCut } from '../../common/utils';

/**
 * 통계 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const StatItem = ({ title, nowCount, beforeCount, nowPrice, beforePrice, className }) => {
  return (
    <div className={styles.canvas}>
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.count}>
        {nowCount}
        <small style={{ fontSize: 14, fontWeight: 'normal', marginLeft: 20 }}>{addComma(String(nowPrice))} 원</small>
      </p>
      <p className={styles.prevCount}>
        {beforeCount}
        <small style={{ fontWeight: 'normal', marginLeft: 20 }}>{addComma(String(beforePrice))} 원</small>
      </p>
      <div className={styles.compare}>
        {nowCount - beforeCount > 0 ? (
          <img src={require('../../assets/arrow_rank_up.png')} style={{ margin: 8, marginBottom: 12 }} />
        ) : (
          <img src={require('../../assets/arrow_rank_down.png')} style={{ margin: 8, marginBottom: 12 }} />
        )}
        {Math.abs(Number(nowCount || 0) - Number(beforeCount))}(
        {pointCut(((Number(nowCount || 0) - Number(beforeCount || 0)) / Number(beforeCount || 1)) * 100 || '-', 2)}
        %)
        <small>[{addComma(String(Number(nowPrice || 0) - Number(beforePrice)))}원]</small>
      </div>
    </div>
  );
};

export default StatItem;
