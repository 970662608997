import { restApi } from '../index';

export const getHolidays = (body) => {
  const endpoint = '/holidays/setting';
  return restApi.get(endpoint, body);
};

export const getholiday = (id) => {
  const endpoint = `/holidays/setting/${id}`;
  return restApi.get(endpoint);
};

export const createHoliday = ({ date, text }) => {
  const endpoint = '/holidays/setting';
  const requestBody = {
    date,
    text,
  };
  return restApi.post(endpoint, requestBody);
};

export const updateHoliday = ({ id, date, text }) => {
  const endpoint = `/holidays/setting/${id}`;
  const requestBody = {
    date,
    text,
  };
  return restApi.put(endpoint, requestBody);
};

export const deleteHoliday = (id) => {
  const endpoint = `/holidays/setting/${id}`;
  return restApi.delete(endpoint);
};

/*
export const putHolidayActive = ({ id, active }) => {
  const endpoint = `/holidays/${id}/active`;
  const requestBody = {
    active,
  };
  return restApi.put(endpoint, requestBody);
};
*/
