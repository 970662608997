import React from 'react';
import { PageHeader, Table } from 'antd';
import { QnASearchField } from '../../../common/searchFields';
import SearchForm from '../../../components/datalist/SearchForm';
import TableButtonWrap from '../../../components/TableButtonWrap';
import moment from 'moment';
import { getAPIHost, restApi } from '../../../apis';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import fileDownload from 'js-file-download';

/**
 * 고객문의 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const QnAIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);

  const [data, setData] = React.useState();

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      ...query,
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'title') {
      params.title = query.keyword;
    }

    const { data } = await restApi.get('/mypages/questions', {
      params,
    });
    setData(data);
  }, [query]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  const columns = React.useMemo(
    () => [
      {
        title: '번호',
        dataIndex: 'number',
        align: 'center',
        width: 90,
        render: (_, __, index) => {
          return (data?.total_elements || 0) - (10 * data?.page + index);
        },
      },
      {
        title: '제목',
        dataIndex: 'title',
        align: 'left',
        render: (data, record) => (
          <Link to={`/work/qna/details/${record.id}`}>
            <a style={{ color: 'black' }}>{data}</a>
          </Link>
        ),
      },
      {
        title: '질문자이름',
        dataIndex: 'account',
        width: 100,
        align: 'center',
        render: (data) => data?.nickname,
      },
      {
        title: '날짜',
        dataIndex: 'created_at',
        width: 200,
        align: 'center',
        render: (data) => moment(data).format('YYYY-MM-DD HH:mm'),
      },
      {
        title: '상태',
        dataIndex: 'state',
        width: 120,
        align: 'center',
        render: (data) => (data === 'FINISH' ? '답변완료' : '답변대기중'),
      },
      {
        title: '답변하기',
        align: 'center',
        width: 120,
        render: (_, record) => (
          <Link to={`/work/qna/details/${record.id}`}>
            <a style={{ color: '#df1d1d' }}>확인</a>
          </Link>
        ),
      },
    ],
    [data],
  );

  // 테이블 페이지네이션
  const pagination = {
    position: ['bottomCenter'],
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      navigate('?' + qs.stringify({ ...query, page: p - 1 }), { replace: true });
    },
  };

  const xlsxSave = () => {
    const args = {
      ...query,
    };
    restApi
      .get(`${getAPIHost()}/exports/qnas?${qs.stringify(args)}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `고객문의${moment().format('YYYYMMDDHHmmss')}.xlsx`);
      });
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="고객문의" />
      <SearchForm fields={QnASearchField} />
      <div style={{ position: 'relative' }}>
        <Table
          scroll={{ x: 'max-content' }}
          dataSource={data?.items}
          columns={columns}
          pagination={
            pagination
              ? {
                  ...pagination,
                  position: ['bottomCenter'],
                  showSizeChanger: false,
                }
              : false
          }
        />
        <div>
          <TableButtonWrap
            position="left"
            buttons={[{ icon: require('../../../assets/icon_save.png'), label: '엑셀 저장', onClick: xlsxSave }]}
          />
        </div>
      </div>
    </div>
  );
};

export default QnAIndex;
