import React from 'react';
import useInput from '../../../../hooks/useInput';
import useAxios from '../../../../hooks/useAxios';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { getholiday, updateHoliday as updateHolidayApi } from '../../../../apis/restdays';
import { Button, DatePicker, Descriptions, Input, PageHeader } from 'antd';
import styles from '../../../work/review/details/reviewDetails.module.less';

/**
 * 휴일관리 수정 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const RestdayUpdate = () => {
  const restday = useAxios();
  const { id } = useParams();
  const navigate = useNavigate();
  const memo = useInput('');
  const [holiday, setHoliday] = React.useState(null);

  const handleReadList = () => {
    navigate('/settings/restday', { replace: true });
  };

  const handleUpdateHoliday = async () => {
    try {
      await restday.loadData(
        updateHolidayApi({
          id,
          date: holiday,
          text: memo.value,
        }),
      );
      alert('수정 완료되었습니다.');
      navigate('/settings/restday', { replace: true });
    } catch (error) {
      console.warn(error);
      alert(error?.response?.data?.message);
    }
  };

  React.useEffect(() => {
    restday
      .loadData(getholiday(id))
      .then(({ data: responseData }) => {
        memo.onChange(responseData.text);
        responseData?.date && setHoliday(moment(responseData?.date));
      })
      .catch((error) => {
        console.warn(error);
        alert(error?.response?.data?.message);
        navigate('/settings/restday', { replace: true });
      });
  }, []);
  return (
    <div>
      <PageHeader className="site-page-header" title="휴일수정" />
      <Descriptions bordered>
        <Descriptions.Item label={'휴일'} span={4} className="nopadding" style={{ padding: '10px 20px' }}>
          <DatePicker
            value={holiday}
            onChange={setHoliday}
            style={{ width: 200, fontSize: 12 }}
            placeholder="날짜선택"
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={'메모'}
          span={4}
          className="nopadding"
          style={{ verticalAlign: 'top', padding: '10px 20px' }}
        >
          <Input {...memo} placeholder="메모를 입력해주세요." />
        </Descriptions.Item>
      </Descriptions>
      {/*<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>*/}
      {/*  <Button onClick={handleReadList} style={{ width: 152, height: 40, borderRadius: 4 }}>*/}
      {/*    취소*/}
      {/*  </Button>*/}
      {/*  <Button*/}
      {/*    onClick={handleUpdateHoliday}*/}
      {/*    style={{ background: '#000', color: '#fff', width: 152, height: 40, borderRadius: 4 }}*/}
      {/*  >*/}
      {/*    수정*/}
      {/*  </Button>*/}
      {/*</div>*/}
      <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={handleReadList}
          size={'large'}
          className={styles.basicButton}
          style={{ marginRight: 5, height: 40, width: 100 }}
        >
          취소
        </Button>
        <Button
          onClick={handleUpdateHoliday}
          size="large"
          className="basicButton"
          style={{ height: 40, width: 100, backgroundColor: '#FF7200', color: 'white', marginRight: 5 }}
        >
          수정
        </Button>
      </div>
    </div>
  );
};

export default RestdayUpdate;
