import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import styles from './Login.module.less';
import { restApi, setToken } from '../../apis';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { me } from '../../data/auth';
import { AccountType, GrantType } from '../../common/defines';
import useInput from '../../hooks/useInput';

/**
 * 로그인 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const Login = () => {
  const [cookie, setCookie] = useCookies(['token']);
  const dispatch = useDispatch();

  const { username: initialUsername } = cookie || {};

  const [loading, setLoading] = useState(false);
  const username = useInput(process.env.NODE_ENV === 'development' ? 'admin' : initialUsername);
  const password = useInput(process.env.NODE_ENV === 'development' ? 'string' : '');

  // 로그인 로직
  const handleSubmit = async () => {
    if (loading) return;
    try {
      setLoading(true);

      const requestBody = {
        username: username.value,
        password: password.value,
        grant_type: GrantType.Password,
        filter: [AccountType.admin, 'V'],
      };
      console.log(requestBody)
      localStorage.setItem('auth', JSON.stringify(requestBody));
      const { data: responseData } = await restApi.post(`/auth/login`, requestBody);
      setToken(responseData, setCookie);
      await dispatch(me());
      // await dispatch(getTeamsItems());
    } catch (e) {
      console.warn(e);
      alert(e?.response?.data?.message);
    }
    setLoading(false);
  };

  return (
    <div className={styles.login_wrapper}>
      <Form onFinish={handleSubmit}>
        <div style={{ alignItem: 'center', display: 'flex', flexDirection: 'column' }}>
          <h2>LOGIN</h2>
          <Form.Item noStyle name={'username'}>
            <Input prefix={'아이디'} className={styles.input} allowClear {...username} />
          </Form.Item>
          <Form.Item noStyle name={'password'}>
            <Input.Password prefix={'비밀번호'} className={styles.input} allowClear {...password} />
          </Form.Item>
          <Button block className={styles.submit} type={'primary'} htmlType={'submit'} loading={loading}>
            로그인
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Login;
