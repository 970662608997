import React, { useEffect, useRef } from 'react';
import { Button, Checkbox, Col, DatePicker, Form, Input, Radio, Row, Select } from 'antd';
import styles from './SearchForm.module.less';
import { useLocation, useNavigate } from 'react-router-dom';
import * as qs from 'query-string';
import moment from 'moment';
import {isEmpty} from "lodash";

/**
 * 목록 페이지 검색폼 입력 컴포넌트
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const SearchForm = ({ fields, extra }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const form = useRef();

  const handleSubmit = (data) => {
    const args = {};
    for (const key of Object.keys(data).filter((v) => !!data[v])) {
      const field = fields.find((v) => v.key === key);
      if (field.type === 'datepicker') {
        args[key] = data[key].format('YYYY-MM-DD');
      } else if (field.type === 'daterange') {
        for (let i = 0; i < field.keys?.length; i++) {
          const subkey = field.keys[i];
          args[subkey] = data[key][i].format('YYYY-MM-DD');
        }
      } else {
        args[key] = data[key];
      }
    }
    navigate('?' + qs.stringify(args), { replace: true });
  };

  useEffect(() => {
    const data = {};
    const args = qs.parse(location?.search);
    for (const key of Object.keys(args).filter((v) => !!args[v])) {
      const field = fields.find((v) => v.key === key);
      if (!field) continue;
      if (field.type === 'datepicker') {
        data[key] = moment(args[key]);
      } else if (field.type === 'daterange') {
        const o = [];
        for (let i = 0; i < field.keys?.length; i++) {
          const subkey = field.keys[i];
          o[i] = moment(args[subkey]);
        }
        data[key] = o;
      } else {
        data[key] = args[key];
      }
    }
    form.current.setFieldsValue(data);
  }, [location?.search]);

  // fields에 defaultValue가 있을경우 set
  React.useEffect(() => {
    // query가 있으면 안함
    const args = qs.parse(location?.search);
    if (!isEmpty(args)) return;
    for (let i = 0; i < fields?.length; i++) {
      if (!!fields[i]?.defaultValue) {
        const o = {};
        o[`${fields[i].key}`] = fields[i].defaultValue;
        form.current.setFieldsValue(o);
      }
    }
  }, [fields, location]);

  const handleSearch = (field) => {
    if (field?.key === 'sort' || !!field?.isSort) {
      form.current.submit();
    }
  };

  return (
    <div className={styles.search_form}>
      <Form onFinish={handleSubmit} ref={form}>
        <Row gutter={[40, 10]}>
          {fields?.map((field, index) => {
            const onChange = field.submit ? () => form.current.submit() : () => handleSearch(field);
            return (
              <Col xs={24} sm={24} lg={24 * (field?.width ?? 1)} key={`${index}`}>
                <Form.Item name={field.key} label={field.label}>
                  {field.type === 'radio' ? (
                    <Radio.Group onChange={onChange}>
                      {field?.items?.map((item) => (
                        <Radio value={item.value} key={String(item.value)}>
                          {item.label}
                        </Radio>
                      ))}
                    </Radio.Group>
                  ) : field.type === 'radiobutton' ? (
                    <Radio.Group onChange={onChange}>
                      {field?.items?.map((item) => (
                        <Radio.Button value={item.value} key={String(item.value)}>
                          {item.label}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  ) : field.type === 'checkbox' ? (
                    <Checkbox.Group onChange={onChange}>
                      {field?.items?.map((item) => (
                        <Checkbox value={item.value} key={String(item.value)}>
                          {item.label}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  ) : field.type === 'datepicker' ? (
                    <DatePicker />
                  ) : field.type === 'daterange' ? (
                    <DatePicker.RangePicker />
                  ) : field.type === 'select' ? (
                    <Select onChange={onChange} allowClear={!field?.defaultValue}>
                      {field?.items?.map((item) => (
                        <Select.Option value={item.value} key={String(item.value)}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Input allowClear />
                  )}
                </Form.Item>
              </Col>
            );
          })}
        </Row>
        <Row gutter={[5, 10]} className={styles.footer}>
          <Col flex={'1'}></Col>
          <Col>
            <Button htmlType={'submit'} type={'primary'}>
              검색
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => {
                form.current.resetFields();
                form.current.submit();
              }}
            >
              초기화
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default React.memo(SearchForm);
