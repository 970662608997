import React from 'react';
import { PageHeader, Table } from 'antd';
import TableButtonWrap from '../../../components/TableButtonWrap';
import { toCommaNumber } from '../../../common/utils';
import moment from 'moment';
import { getAPIHost, restApi } from '../../../apis';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import fileDownload from 'js-file-download';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * 정산내역 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const VCalcIndex = ({ vendorId, details }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);
  const { principal } = useSelector((s) => s.auth, shallowEqual);

  const [data, setData] = React.useState();

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      vendor_id: principal?.id,
      ...query,
      limit: 10,
    };

    const { data } = await restApi.get('/calcs', {
      params,
    });
    setData(data);
  }, [query]);

  // 초기 데이터 패ㅇ
  React.useEffect(() => {
    loadData();
  }, [loadData]);

  // 테이블 컬럼 정의
  const columns = [
    {
      title: '번호',
      dataIndex: 'number',
      align: 'center',
      width: 90,
      render: (_, __, index) => {
        return (data?.total_elements || 0) - (10 * data?.page + index);
      },
    },
    {
      title: '정산일자',
      dataIndex: 'date',
      align: 'center',
      width: 200,
      render: (data) => {
        return data;
      },
    },
    {
      title: '정산 대상건수',
      dataIndex: 'count',
      align: 'center',
    },
    {
      title: '입금(예정) 금액',
      dataIndex: 'amount',
      align: 'center',
      render: (d) => toCommaNumber(d) ?? 0,
    },
    // {
    //   title: '정산 상태',
    //   dataIndex: 'state',
    //   align: 'center',
    //   render: (data) => (data == 'READY' ? '정산대기' : data == 'FINISH' ? '정산완료' : data),
    // },
    // {
    //   title: '자세히보기',
    //   dataIndex: 'id',
    //   align: 'center',
    //   width: 100,
    //   render: (data, r) => (
    //     <Link to={details ? details?.(data) : `/work/calc/details/${data}`}>
    //       <a
    //         style={{ color: 'black' }}
    //         onClick={(e) => {
    //           if (moment(r.data).isAfter(moment())) {
    //             alert('정산시기가 아닙니다.');
    //             e.preventDefault();
    //           }
    //         }}
    //       >
    //         자세히보기
    //       </a>
    //     </Link>
    //   ),
    // },
  ];

  // 테이블 페이지네이션
  const pagination = {
    position: ['bottomCenter'],
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      navigate('?' + qs.stringify({ ...query, page: p - 1 }), { replace: true });
    },
  };


  return (
    <div>
      <PageHeader className="site-page-header" title="정산내역" />
      <div style={{ position: 'relative' }}>
        <Table
          scroll={{ x: 'max-content' }}
          dataSource={data?.items}
          columns={columns}
          pagination={
            pagination
              ? {
                  ...pagination,
                  position: ['bottomCenter'],
                  showSizeChanger: false,
                }
              : false
          }
        />
        {/*<div>*/}
        {/*  <TableButtonWrap*/}
        {/*    position="left"*/}
        {/*    buttons={[{ icon: require('../../../assets/icon_save.png'), label: '엑셀 저장', onClick: xlsxSave }]}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default VCalcIndex;
