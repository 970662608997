import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { getStats } from '../../../apis/stats';
import { Button, DatePicker, Radio, Select, Space } from 'antd';
import ReactToPrint from 'react-to-print';
import Icon from '../../../components/Icon';
import RequestChart from '../../../components/Chart/RequestChart';
import DoughnutChart from '../../../components/Chart/DoughnutChart';
import styles from '../stats.module.less';
import { addComma, pointCut } from '../../../common/utils';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import StatItem from '../../../components/stats/StatItem';
import { restApi } from '../../../apis';
import { shallowEqual, useSelector } from 'react-redux';
import axios from 'axios';

/**
 * 하루배송 통계 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const CancelToken = axios.CancelToken;

const VStatsUser = ({ vendorId }) => {
  const printComponentRef = React.useRef();
  const type = 'DT';

  const [method, setMethod] = useState('month');
  const [state, setState] = useState();
  const [date, setDate] = useState(moment());

  const [asIsData, setAsIsData] = useState();
  const [toBeData, setToBeData] = useState();

  const stats = {
    loading: false,
    data: toBeData,
  };

  const beforeStats = {
    loading: false,
    data: asIsData,
  };

  //method
  const start_at = date
    .clone()
    .startOf(method ?? 'day')
    .format('YYYY-MM-DD');
  const end_at = date
    .clone()
    .endOf(method ?? 'day')
    .format('YYYY-MM-DD');

  const dateRange = [moment(start_at), moment(end_at)];

  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const [vendorCode, setVendorCode] = useState();
  const [codes, setCodes] = useState();
  const cancelRef = useRef();

  // 검색 함수
  const handleSearch = async (keyword) => {
    if (cancelRef.current) {
      cancelRef.current.cancel('');
      cancelRef.current = undefined;
    }
    cancelRef.current = CancelToken.source();
    const { data } = await restApi.get(`/vendors/${vendorId ?? principal?.id}/codes`, {
      params: { keyword },
      cancelToken: cancelRef.current.token,
    });
    cancelRef.current = undefined;
    setCodes(data.items);
  };

  const [count, setCount] = useState(0);
  // 카운트
  useEffect(() => {
    const tk = setInterval(() => {
      setCount((x) => x + 1);
    }, 5000);
    return () => clearInterval(tk);
  }, []);

  // 초기 데이터 조회 후 통계 데이터 세팅
  React.useEffect(() => {
    if (!start_at || !end_at) return;
    if (count > 100) {
      window.location.reload();
    }

    const loadData = async () => {
      const params = {
        state,
        vendor_id: vendorId,
        vendor_code: vendorCode,
      };

      const before_end_at = date
        .clone()
        .startOf(method ?? 'day')
        .add(-1, 'days');

      const before_start_at = before_end_at.clone().startOf(method ?? 'day');

      {
        const { data } = await getStats({
          ...params,
          start_at,
          end_at,
        });
        setToBeData(data);
      }

      {
        const { data } = await getStats({
          ...params,
          start_at: before_start_at.format('YYYY-MM-DD'),
          end_at: before_end_at.format('YYYY-MM-DD'),
        });
        setAsIsData(data);
      }
    };
    loadData().catch(console.warn);
  }, [method, date, state, count, vendorCode, vendorId]);

  // pdf 다운로드 로직
  const handlePdfDownload = async () => {
    const canvas = await html2canvas(printComponentRef.current);
    let imgData = canvas.toDataURL('image/png');
    let margin = 10; // 출력 페이지 여백설정
    let imgWidth = 210 - 10 * 2; // 이미지 가로 길이(mm) A4 기준
    let pageHeight = imgWidth * 1.414; // 출력 페이지 세로 길이 계산 A4 기준
    let imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let doc = new jsPDF('p', 'mm');
    let position = margin; // 첫 페이지 출력
    doc.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
    heightLeft -= pageHeight; // 한 페이지 이상일 경우 루프 돌면서 출력
    while (heightLeft >= 20) {
      position = heightLeft - imgHeight;
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      doc.addPage();
      heightLeft -= pageHeight;
    }

    // 파일 저장
    doc.save(`하루배송_데이터_관리_${moment().format('YYYYMMDD_HHmmss')}.pdf`);
  };

  // if (stats.loading) {
  //   return <Loading />;
  // }

  return (
    <>
      <div className={styles.pageHeader}>
        <h2>주문 통계</h2>
        <div className={styles.addition}>
          <Select
            showSearch
            value={vendorCode}
            onChange={(v) => setVendorCode(v)}
            placeholder={'아파트 전체'}
            style={{ minWidth: 300 }}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            notFoundContent={null}
            allowClear
          >
            {codes?.map((v) => (
              <Select.Option value={v.code} key={v.code}>
                {v.name}
              </Select.Option>
            ))}
          </Select>
          <ReactToPrint
            trigger={() => (
              <Button>
                <Icon size={14} source={require('../../../assets/icon_save.png')} />
                인쇄
              </Button>
            )}
            content={() => printComponentRef.current}
          />
          <Button onClick={handlePdfDownload}>
            <Icon size={14} source={require('../../../assets/icon_save.png')} />
            PDF 저장
          </Button>
          {/*<Button>*/}
          {/*  <Icon size={14} source="save" />*/}
          {/*  엑셀저장*/}
          {/*</Button>*/}
        </div>
      </div>
      <div ref={printComponentRef}>
        <div className={styles.toolbar}>
          <Space>
            <Radio.Group value={method} onChange={(e) => setMethod(e.target.value)}>
              <Radio.Button value={'year'}>연간</Radio.Button>
              <Radio.Button value={'month'}>월간</Radio.Button>
              <Radio.Button value={'week'}>주간</Radio.Button>
              <Radio.Button value={undefined}>일간</Radio.Button>
            </Radio.Group>
            <Radio.Group value={state} onChange={(e) => setState(e.target.value)}>
              <Radio.Button value={undefined}>전체</Radio.Button>
              <Radio.Button value={'FINISH'}>구매완료</Radio.Button>
              <Radio.Button value={'BUYED'}>배송완료</Radio.Button>
            </Radio.Group>
          </Space>

          <div style={{ flex: 1 }} />

          <DatePicker
            style={{ width: 200, fontSize: 12 }}
            placeholder="날짜선택"
            value={date}
            onChange={(a, b) => setDate(a)}
            picker={method}
            allowClear={false}
          />
        </div>
        <div style={{ marginBottom: 25 }}>
          <div className={styles.flexrow}>
            <StatItem
              title={'총 의뢰수'}
              nowCount={stats?.data?.total}
              nowPrice={stats?.data?.total_money}
              beforeCount={beforeStats?.data?.total}
              beforePrice={beforeStats?.data?.total_money}
            />
            <StatItem
              title={'수선의뢰수'}
              nowCount={stats?.data?.fix}
              nowPrice={stats?.data?.fix_money}
              beforeCount={beforeStats?.data?.fix}
              beforePrice={beforeStats?.data?.fix_money}
            />
            <StatItem
              title={'수선의뢰수'}
              nowCount={stats?.data?.clean}
              nowPrice={stats?.data?.clean_money}
              beforeCount={beforeStats?.data?.clean}
              beforePrice={beforeStats?.data?.clean_money}
            />
          </div>
          <p style={{ marginTop: 10 }}>
            ※ 등락 비교일 {dateRange[0].format('YYYY.MM.DD')} - {dateRange[1].format('YYYY.MM.DD')}
          </p>
        </div>
        <div className={styles.flexrow} style={{ marginBottom: 60, overflowX: 'auto' }}>
          <div style={{ minWidth: 1023 }}>
            <RequestChart title={`의뢰 그래프`} dateRange={dateRange} stats={stats} />
          </div>
        </div>
        <div className={styles.pageHeader}>
          <h2>남자/여자 구두 의뢰</h2>
        </div>
        <div className={styles.flexrow} style={{ marginBottom: 60 }}>
          <DoughnutChart type={type} gender="M" title="남자 구두" dateRange={dateRange} stats={stats} />
          <DoughnutChart type={type} gender="F" title="여자 구두" dateRange={dateRange} stats={stats} />
        </div>
      </div>
    </>
  );
};

export default VStatsUser;
