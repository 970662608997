import { restApi } from '../index';

export const getAccounts = (body) => {
  const endpoint = `/accounts`;
  return restApi.get(endpoint, body);
};

export const getCenters = (body) => {
  const endpoint = `/centers`;
  return restApi.get(endpoint, body);
};

export const getCenter = (id) => {
  const endpoint = `/centers/${id}`;
  return restApi.get(endpoint);
};

export const getAccount = (id) => {
  const endpoint = `/accounts/${id}`;
  return restApi.get(endpoint);
};

export const editAccount = (id, body) => {
  const endpoint = `/accounts/${id}`;
  return restApi.put(endpoint, body);
};

export const join = ({ method, type, phone, password }) => {
  const endpoint = '/accounts';
  const requestBody = {
    method,
    type,
    phone,
    password,
  };
  return restApi.post(endpoint, requestBody);
};
