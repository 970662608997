import React, { useEffect, useMemo, useRef, useState } from 'react';
import { PageHeader, Table } from 'antd';
import { restApi } from '../../../apis';
import NaverMap, { Marker } from 'react-naver-map';

/**
 * 주소 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const MapsIndex = () => {
  const navermaps = window?.naver?.maps;

  const open = (id) => {
    if (id?.startsWith('m')) {
      window.open(`/accounts/expert/details/${id.substring(2)}`);
    }
    if (id?.startsWith('c')) {
      window.open(`/settings/centermgr/details/${id.substring(2)}`);
    }
  };

  const columns = [
    {
      key: 'id',
      title: '종류',
      dataIndex: 'id',
      align: 'center',
      width: 80,
      ellipsis: true,
      render: (d) => {
        return d.startsWith('c') ? '센터' : '장인';
      },
    },
    {
      key: 'name',
      title: '이름',
      dataIndex: 'name',
      align: 'center',
      width: 150,
      ellipsis: true,
    },
    {
      key: 'address',
      title: '주소',
      dataIndex: 'address',
    },
    {
      title: '자세히보기',
      width: 120,
      dataIndex: 'id',
      align: 'center',
      render: (data) => (
        <a style={{ color: 'black' }} onClick={() => open(data)}>
          자세히보기
        </a>
      ),
    },
  ];

  //this.map.getBounds();

  const map = useRef();
  const [bounds, setBounds] = useState();
  const [data, setData] = React.useState();

  useEffect(() => {
    const loadData = async () => {
      const { data } = await restApi.get(`/positions/others`, { params: bounds });
      setData(data);
    };
    loadData().catch(console.warn);
  }, [bounds]);

  const dataSource = useMemo(() => {
    return data?.reduce((a, b) => a.concat(b.items), []) ?? [];
  }, [data]);

  const handleBoundsChanged = (e) => {
    setBounds({
      lat2: e.getNE().lat(),
      lat1: e.getSW().lat(),
      lng2: e.getNE().lng(),
      lng1: e.getSW().lng(),
    });
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="지도관리" />
      <div style={{ height: 400, border: `1px solid #ccc` }}>
        <NaverMap
          ncp
          clientId={'07jxlkf4vb'}
          mapDivId={'maps-getting-started-uncontrolled'} // default: react-naver-map
          style={{
            width: '100%',
            height: '400px',
          }}
          initialPosition={{ lat: 37.4951801, lng: 127.0139108 }}
          defaultZoom={15}
          onInit={(nmap, naver) => {
            map.current = nmap;
            // loadData().catch(console.warn);
          }}
          onBoundChange={handleBoundsChanged}
        >
          {data?.map((item) => {
            return item.items?.map((o) => (
              <Marker
                key={o.id}
                title={o.name}
                lat={o.lat}
                lng={o.lng}
                // position={new navermaps.LatLng(o.lat, o.lng)}
                onClick={() => {
                  open(o.id);
                }}
              />
            ));
          })}
        </NaverMap>
      </div>
      {!bounds ? (
        <div style={{ padding: 10, textAlign: 'center', fontWeight: 'bold' }}>
          <span>지도를 움직이면 로딩을 시작합니다.</span>
        </div>
      ) : (
        <div style={{ position: 'relative', marginTop: 20 }}>
          <Table
            scroll={{ x: 'max-content' }}
            dataSource={dataSource}
            columns={columns}
            emptyText={() => '지도를 움직이면 데이터를 불러옵니다.'}
          />
        </div>
      )}
    </div>
  );
};

export default MapsIndex;
