import React, { useEffect, useState } from 'react';
import moment from 'moment';
import useAxios from '../../../hooks/useAxios';
import { getStats } from '../../../apis/stats';
import Loading from '../../../components/Loading';
import { Button, DatePicker, Radio, Space } from 'antd';
import ReactToPrint from 'react-to-print';
import Icon from '../../../components/Icon';
import RequestChart from '../../../components/Chart/RequestChart';
import DoughnutChart from '../../../components/Chart/DoughnutChart';
import styles from '../stats.module.less';
import { addComma, pointCut } from '../../../common/utils';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {shallowEqual, useSelector} from "react-redux";
import DoughnutChart3 from "../../../components/Chart/DoughnutChart3";

/**
 * 정기구독 통계 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const SubscribeIndex = () => {
  const printComponentRef = React.useRef();
  const { categories } = useSelector((s) => s.product, shallowEqual);
  const type = 'SC';

  const [method, setMethod] = useState('month');
  const [target, setTarget] = useState();
  const [state, setState] = useState();
  const [date, setDate] = useState(moment());

  const [asIsData, setAsIsData] = useState();
  const [toBeData, setToBeData] = useState();

  const stats = {
    loading: false,
    data: toBeData,
  };

  const beforeStats = {
    loading: false,
    data: asIsData,
  };

  //method
  const start_at = date
    .clone()
    .startOf(method ?? 'day')
    .format('YYYY-MM-DD');
  const end_at = date
    .clone()
    .endOf(method ?? 'day')
    .format('YYYY-MM-DD');

  const dateRange = [moment(start_at), moment(end_at)];

  const [count, setCount] = useState(0);
  useEffect(() => {
    const tk = setInterval(() => {
      setCount((x) => x + 1);
    }, 5000);
    return () => clearInterval(tk);
  }, []);

  React.useEffect(() => {
    if (!type) return;
    if (count > 100) {
      window.location.reload();
    }

    const loadData = async () => {
      const params = {
        type,
        target,
        state,
      };

      const before_end_at = date
        .clone()
        .startOf(method ?? 'day')
        .add(-1, 'days');

      const before_start_at = before_end_at.clone().startOf(method ?? 'day');

      {
        const { data } = await getStats({
          ...params,
          start_at,
          end_at,
        });
        setToBeData(data);
      }

      {
        const { data } = await getStats({
          ...params,
          start_at: before_start_at.format('YYYY-MM-DD'),
          end_at: before_end_at.format('YYYY-MM-DD'),
        });
        setAsIsData(data);
      }
    };
    loadData().catch(console.warn);
  }, [method, target, state, date, count]);

  const handlePdfDownload = async () => {
    const canvas = await html2canvas(printComponentRef.current);
    let imgData = canvas.toDataURL('image/png');
    let margin = 10; // 출력 페이지 여백설정
    let imgWidth = 210 - 10 * 2; // 이미지 가로 길이(mm) A4 기준
    let pageHeight = imgWidth * 1.414; // 출력 페이지 세로 길이 계산 A4 기준
    let imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let doc = new jsPDF('p', 'mm');
    let position = margin; // 첫 페이지 출력
    doc.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
    heightLeft -= pageHeight; // 한 페이지 이상일 경우 루프 돌면서 출력
    while (heightLeft >= 20) {
      position = heightLeft - imgHeight;
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      doc.addPage();
      heightLeft -= pageHeight;
    }

    // 파일 저장
    doc.save(`정기구독_데이터_관리_${moment().format('YYYYMMDD_HHmmss')}.pdf`);
  };

  if (stats.loading) {
    return <Loading />;
  }

  return (
    <>
      <div className={styles.pageHeader}>
        <h2>정기구독 데이터 관리</h2>
        <div className={styles.addition}>
          <ReactToPrint
            trigger={() => (
              <Button>
                <Icon size={14} source={require('../../../assets/icon_save.png')} />
                인쇄
              </Button>
            )}
            content={() => printComponentRef.current}
          />
          <Button onClick={handlePdfDownload}>
            <Icon size={14} source={require('../../../assets/icon_save.png')} />
            PDF 저장
          </Button>
        </div>
      </div>
      <div ref={printComponentRef} style={{ background: '#fff' }}>
        <div className={styles.toolbar}>
          <Space>
            <Radio.Group value={method} onChange={(e) => setMethod(e.target.value)}>
              <Radio.Button value={'year'}>연간</Radio.Button>
              <Radio.Button value={'month'}>월간</Radio.Button>
              <Radio.Button value={'week'}>주간</Radio.Button>
              <Radio.Button value={undefined}>일간</Radio.Button>
            </Radio.Group>

            <Radio.Group value={target} onChange={(e) => setTarget(e.target.value)}>
              <Radio.Button value={undefined}>전체</Radio.Button>
              <Radio.Button value={'n'}>일반주문</Radio.Button>
              <Radio.Button value={'b'}>기업주문</Radio.Button>
            </Radio.Group>

            <Radio.Group value={state} onChange={(e) => setState(e.target.value)}>
              <Radio.Button value={undefined}>전체</Radio.Button>
              <Radio.Button value={'BUYED'}>구매완료</Radio.Button>
              <Radio.Button value={'FINISH'}>배송완료</Radio.Button>
            </Radio.Group>
          </Space>

          <div style={{ flex: 1 }} />

          <DatePicker
            style={{ width: 200, fontSize: 12 }}
            placeholder="날짜선택"
            value={date}
            onChange={(a, b) => setDate(a)}
            picker={method}
            allowClear={false}
          />
        </div>
        <div style={{ marginBottom: 25 }}>
          <div className={styles.flexrow}>
            <div className={styles.canvas}>
              <h4 className={styles.title}>총 의뢰수</h4>
              <p className={styles.count}>
                {stats?.data?.total}
                <small style={{ fontSize: 14, fontWeight: 'normal', marginLeft: 20 }}>
                  {addComma(String(stats?.data?.total_money))} 원
                </small>
              </p>
              <p className={styles.prevCount}>
                {beforeStats?.data?.total}
                <small style={{ fontWeight: 'normal', marginLeft: 20 }}>
                  {addComma(String(beforeStats?.data?.total_money))} 원
                </small>
              </p>
              <div className={styles.compare}>
                {stats?.data?.total - beforeStats?.data?.total > 0 ? (
                  <img src={require('../../../assets/arrow_rank_up.png')} style={{ margin: 8, marginBottom: 12 }} />
                ) : (
                  <img src={require('../../../assets/arrow_rank_down.png')} style={{ margin: 8, marginBottom: 12 }} />
                )}
                {Math.abs(Number(stats?.data?.total || 0) - Number(beforeStats?.data?.total))}(
                {pointCut(
                  ((Number(stats?.data?.total || 0) - Number(beforeStats?.data?.total || 0)) /
                    Number(beforeStats?.data?.total || 1)) *
                    100 || '-',
                  2,
                )}
                %)
                <small>
                  [{addComma(String(Number(stats?.data?.total_money || 0) - Number(beforeStats?.data?.total_money)))}원]
                </small>
              </div>
            </div>
            <div className={styles.canvas}>
              <h4 className={styles.title}>수선의뢰수</h4>
              <p className={styles.count}>
                {stats?.data?.fix}
                <small style={{ fontSize: 14, fontWeight: 'normal', marginLeft: 20 }}>
                  {addComma(String(stats?.data?.fix_money))} 원
                </small>
              </p>
              <p className={styles.prevCount}>
                {beforeStats?.data?.fix}
                <small style={{ fontWeight: 'normal', marginLeft: 20 }}>
                  {addComma(String(beforeStats?.data?.fix_money))} 원
                </small>
              </p>
              <div className={styles.compare}>
                {stats?.data?.fix - beforeStats?.data?.fix > 0 ? (
                  <img src={require('../../../assets/arrow_rank_up.png')} style={{ margin: 8, marginBottom: 12 }} />
                ) : (
                  <img src={require('../../../assets/arrow_rank_down.png')} style={{ margin: 8, marginBottom: 12 }} />
                )}
                {Math.abs(Number(stats?.data?.fix || 0) - Number(beforeStats?.data?.fix))}(
                {pointCut(
                  ((Number(stats?.data?.fix || 0) - Number(beforeStats?.data?.fix || 0)) /
                    Number(beforeStats?.data?.fix || 1)) *
                    100 || '-',
                  2,
                )}
                %)
                <small>
                  [{addComma(String(Number(stats?.data?.fix_money || 0) - Number(beforeStats?.data?.fix_money)))}원]
                </small>
              </div>
            </div>
            <div className={styles.canvas}>
              <h4 className={styles.title}>광택의뢰수</h4>
              <p className={styles.count}>
                {stats?.data?.clean}
                <small style={{ fontSize: 14, fontWeight: 'normal', marginLeft: 20 }}>
                  {addComma(String(stats?.data?.clean_money))} 원
                </small>
              </p>
              <p className={styles.prevCount}>
                {beforeStats?.data?.clean}
                <small style={{ fontWeight: 'normal', marginLeft: 20 }}>
                  {addComma(String(beforeStats?.data?.clean_money))} 원
                </small>
              </p>
              <div className={styles.compare}>
                {stats?.data?.clean - beforeStats?.data?.clean > 0 ? (
                  <img src={require('../../../assets/arrow_rank_up.png')} style={{ margin: 8, marginBottom: 12 }} />
                ) : (
                  <img src={require('../../../assets/arrow_rank_down.png')} style={{ margin: 8, marginBottom: 12 }} />
                )}
                {Math.abs(Number(stats?.data?.clean || 0) - Number(beforeStats?.data?.clean))}(
                {pointCut(
                  ((Number(stats?.data?.clean || 0) - Number(beforeStats?.data?.clean || 0)) /
                    Number(beforeStats?.data?.clean || 1)) *
                    100 || '-',
                  2,
                )}
                %)
                <small>
                  [{addComma(String(Number(stats?.data?.clean_money || 0) - Number(beforeStats?.data?.clean_money)))}원]
                </small>
              </div>
            </div>
          </div>
          <p style={{ marginTop: 10 }}>
            ※ 등락 비교일 {start_at} - {end_at}
          </p>
        </div>
        <div className={styles.flexrow} style={{ marginBottom: 60, overflowX: 'auto' }}>
          <div style={{ minWidth: 1023 }}>
            <RequestChart title={`정기구독 의뢰 그래프`} dateRange={dateRange} type={type} stats={stats} />
          </div>
        </div>
        <div className={styles.pageHeader}>
          <h2>남자/여자 구두 의뢰</h2>
        </div>
        <div className={styles.flexrow} style={{ marginBottom: 60 }}>
          {categories[type]?.map((v) => (
              <DoughnutChart type={type} gender={v.code} title={v.name} dateRange={dateRange} stats={stats} />
          ))}
        </div>
        <div className={styles.flexrow} style={{ marginBottom: 60 }}>
          <DoughnutChart3 type={type} title="성별단위" dateRange={dateRange} stats={stats} />
        </div>
      </div>
    </>
  );
};

export default SubscribeIndex;
