import React from 'react';
import VendorDetailsMenu, { useGetVendor } from './menu';
import AccountUserIndex from '../../accounts/user';
import OrderIndex from '../../work/order';

/**
 * 기업 메뉴
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const VendorsDetailsPurchases = (props) => {
  const vendor = useGetVendor();
  return (
    <div>
      <VendorDetailsMenu index={2} />
      {!!vendor?.id && <OrderIndex vendorId={vendor?.id} />}
    </div>
  );
};

export default VendorsDetailsPurchases;
