import { useCallback, useState } from 'react';
import {restApi} from "../apis";

/**
 * 파일 업로드를 위한 커스텀 훅스
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const useFiles = () => {
  const [state, setState] = useState({
    loading: false,
    error: null,
    data: null,
  });

  const loadData = useCallback(async (file) => {
    try {
      setState((prevData) => ({
        ...prevData,
        loading: true,
      }));
      const fileData = new FormData();
      const config = {
        header: {
          accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      };

      fileData.append('filedata', file);
      const responseData = await restApi.put('/files', fileData, config);
      setState((prevData) => ({
        ...prevData,
        loading: false,
        data: responseData.data,
      }));
      return responseData;
    } catch (error) {
      console.error('fileData ERROR', error);
      throw error;
    }
  }, []);

  const clear = () => {
    setState({ data: null, error: null, loading: false });
  };

  return { ...state, loadData, clear };
};

export default useFiles;
