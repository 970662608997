import React, { useEffect, useState } from 'react';
import { Button, Modal, PageHeader, Radio, Table, Typography } from 'antd';
import { paymentSearchField } from '../../../common/searchFields';
import SearchForm from '../../../components/datalist/SearchForm';
import TableButtonWrap from '../../../components/TableButtonWrap';
import { phoneFormat, toCommaNumber } from '../../../common/utils';
import moment from 'moment';
import { getAPIHost, restApi } from '../../../apis';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import fileDownload from 'js-file-download';

const PAY_METHOD = {
  card: '카드결제',
  vbank: '가상계좌',
  phone: '모바일결제',
  trans: '계좌이체',
  point: 'KCP포인트결제',
  tosspay: '토스페이',
  payco: '페이코',
};

const ORDER_STATES = {
  paid: '결제 성공',
  ready: '결제 대기',
  failed: '결제 실패',
};

const getPayname = (d) => {
  if (d?.pg_provider === 'kakaopay') {
    return '카카오페이';
  }
  if (d?.pg_provider === 'tosspay') {
    return '토스페이';
  }
  return PAY_METHOD[d?.pay_method];
};

const ProductRemoveView = ({ column, loadParent }) => {
  const [data, setData] = useState();

  const loadData = async (page = 0) => {
    const { data } = await restApi.get('/payments', {
      params: {
        hide_only: true,
        page,
        limit: 10,
      },
    });
    setData(data);
  };

  useEffect(() => {
    loadData().catch(console.warn);
  }, []);

  const pagination = {
    position: ['bottomCenter'],
    showSizeChanger: false,
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      await loadData(p - 1);
    },
  };

  const [rowKeys, setRowKeys] = useState([]);
  const handleChange = (selectedRowKeys, selectedRows) => {
    setRowKeys(selectedRowKeys);
  };
  const handleRecover = async () => {
    if (rowKeys?.length === 0) {
      window.alert('복구할 내역을 선택하세요');
      return;
    }
    await restApi.put(`/payments/hide`, {
      ids: rowKeys?.map((v) => parseInt(v, 10)),
      hide: false,
    });
    await loadParent?.();
  };

  return (
    <div style={{ position: 'relative' }}>
      <Table
        rowSelection={{
          type: 'checkbox',
          onChange: handleChange,
          selectedRowKeys: rowKeys,
        }}
        dataSource={data?.items}
        columns={column}
        pagination={pagination}
        scroll={{ x: 'max-content' }}
        rowKey={'id'}
      />
      <TableButtonWrap position="left" buttons={[{ label: '복원', onClick: handleRecover }]} />
    </div>
  );
};

/**
 * 결제내역 목록 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const PaymentIndex = ({ vendorId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = React.useMemo(() => ({ ...qs.parse(location?.search), vendor_id: vendorId }), [location?.search]);

  const [data, setData] = React.useState();

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      ...query,
      state: query?.state === '-' ? undefined : query?.state,
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'title') {
      params.title = query.keyword;
    }

    const { data } = await restApi.get('/payments', {
      params,
    });
    setData(data);
  }, [query]);

  const handleChangeItem = async (id, value) => {
    const { data } = await restApi.put(`/payments/${id}/b2b`, { state: value });
    await loadData();
  };

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  const columns = React.useMemo(
    () => [
      {
        title: '번호',
        dataIndex: 'number',
        align: 'center',
        width: 90,
        render: (_, __, index) => {
          return (data?.total_elements || 0) - (10 * data?.page + index);
        },
      },
      {
        title: '결제명',
        dataIndex: 'payed_json',
        align: 'center',
        width: 150,
        render: (data) => {
          return data?.name;
        },
      },
      {
        title: '결제 방식',
        dataIndex: 'payed_json',
        align: 'center',
        render: (data, row) => {
          return !data && row.username ? 'B2B결제' : getPayname(data);
        },
        width: 150,
      },
      {
        title: '고객이름',
        align: 'center',
        dataIndex: 'account_nickname',
        render: (r, row) => (
          <Typography.Text ellipsis={true} style={{ width: 100 }}>
            {row.account_nickname}
          </Typography.Text>
        ),
      },
      {
        title: '고객연락처',
        dataIndex: 'account_phone',
        align: 'center',
        render: (data, row) => !row.username && phoneFormat(data),
      },

      {
        title: '결제일시',
        dataIndex: 'payed_at',
        align: 'center',
        render: (data) => moment(data)?.format('YYYY-MM-DD HH:mm'),
      },
      {
        title: '결제상태',
        dataIndex: 'payed_json',
        align: 'center',
        width: 100,
        render: (data, row) => {
          if (row.cancelled_json || row.cancelled_at) {
            return <span style={{ color: '#db2929' }}>결제 취소</span>;
          }
          if (row.payed_json?.status) {
            return row.payed_json?.status === 'ready' ? (
              '결제대기'
            ) : row.payed_json?.status === 'paid' ? (
              '결제완료'
            ) : (
              <span style={{ color: '#db2929' }}>결제 취소</span>
            );
          }
          return row.active ? '결제완료' : '결제대기'; //ORDER_STATES[data?.status];
        },
      },
      {
        title: '상태변경',
        dataIndex: 'id',
        align: 'center',
        width: 300,
        render: (data, row) => {
          if (row.payed_json || !row.username) return;
          return (
            <Radio.Group
              value={row.finish ? 'FINISH' : row.active ? 'BUYED' : 'PAY_READY'}
              onChange={(e) => {
                const value = e.target.value;
                handleChangeItem(row.id, value).catch(console.warn);
              }}
            >
              <Radio.Button value={'PAY_READY'}>결제대기</Radio.Button>
              <Radio.Button value={'BUYED'}>결제완료</Radio.Button>
              <Radio.Button value={'FINISH'}>배달완료</Radio.Button>
            </Radio.Group>
          );
        },
      },

      {
        title: '결제금액',
        dataIndex: 'payed_price',
        align: 'center',
        width: 110,
        render: (data) => toCommaNumber(data),
      },
      {
        title: '자세히보기',
        dataIndex: 'id',
        align: 'center',
        width: 110,
        render: (data) => (
          <Link to={`/work/payment/details/${data}`}>
            <a style={{ color: 'black' }}>자세히보기</a>
          </Link>
        ),
      },
    ],
    [data],
  );

  // 테이블 페이지네이션
  const pagination = {
    position: ['bottomCenter'],
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      navigate('?' + qs.stringify({ ...query, page: p - 1 }), { replace: true });
    },
  };

  const xlsxSave = () => {
    const params = {
      ...query,
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'title') {
      params.title = query.keyword;
    }
    restApi
      .get(`${getAPIHost()}/exports/payments?${qs.stringify(params)}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `결제내역_${moment().format('YYYYMMDDHHmmss')}.xlsx`);
      });
  };

  const [rowKeys, setRowKeys] = useState([]);
  const [showModal, setShowModal] = useState();
  const handleDelete = async () => {
    if (rowKeys?.length === 0) {
      window.alert('삭제할 내역을 선택하세요');
      return;
    }
    if (!window.confirm('삭제하시겠습니끼?')) return;
    await restApi.put(`/payments/hide`, {
      ids: rowKeys?.map((v) => parseInt(v, 10)),
      hide: true,
    });
    await loadData();
  };

  const handleDeleteModal = () => {
    setShowModal(true);
  };

  const handleChange = (selectedRowKeys, selectedRows) => {
    setRowKeys(selectedRowKeys);
  };

  return (
    <div>
      <Modal visible={showModal} width={1200} title={'삭제된 항목'} onCancel={() => setShowModal(false)}>
        {showModal && (
          <ProductRemoveView
            column={columns}
            loadParent={() => {
              loadData();
              setShowModal(false);
            }}
          />
        )}
      </Modal>
      <PageHeader className="site-page-header" title="결제내역" />
      <SearchForm fields={paymentSearchField} />
      <div style={{ position: 'relative' }}>
        <Table
          rowSelection={{
            type: 'checkbox',
            onChange: handleChange,
            selectedRowKeys: rowKeys,
            getCheckboxProps: (r) => {
              if (!r.cancelled_at && r.active) {
                return { disabled: true };
              }
            },
          }}
          rowKey={'id'}
          scroll={{ x: 'max-content' }}
          dataSource={data?.items}
          columns={columns}
          pagination={
            pagination
              ? {
                  ...pagination,
                  position: ['bottomCenter'],
                  showSizeChanger: false,
                }
              : false
          }
        />
        <div className={'tableWrapCover'}>
          <TableButtonWrap
            position="left"
            buttons={[{ icon: require('../../../assets/icon_save.png'), label: '엑셀 저장', onClick: xlsxSave }]}
          />
          <TableButtonWrap
            position="right"
            buttons={[
              { label: '삭제항목보기', onClick: handleDeleteModal },
              { label: '삭제', onClick: handleDelete },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentIndex;
