import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  PageHeader,
  Radio,
  Row,
  Space,
  Spin,
  Table,
} from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { restApi } from '../../../apis';
import produce from 'immer';
import { isEqual } from 'lodash';
import { getProducts } from '../../../data/products';
import DraggableEditableTableLastIndex from '../../../components/DraggableEditableTableLastIndex';
import FileUpload from '../../../components/FileUpload';
import { useNavigate, useParams } from 'react-router-dom';
import RateFormButton from './RateForm';
import useWindowDimension from '../../../hooks/useWindowDimension';

const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);

const METHOD_NAME = {
  CLEANING: '광택서비스',
  REPAIR: '수선서비스',
  ETC: '기타',
};

const parseCenterPrice = (record) => {
  let price = record.price;

  price -= record.master_price * record.repeat;
  price -= record.delivery_price * record.repeat; // * 2;
  return price;
};

/**
 * 상품 설정 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const ProductsCategory3 = () => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const [preview, setPreview] = useState();
  const [showManualText, setShowManualText] = useState();

  const { items, categories, types } = useSelector((s) => s.product, shallowEqual);

  const params = useParams();
  const type = params.type;
  const gender = params.code;

  const item = useMemo(() => {
    const items = categories?.[params.type];
    return items?.find?.((v) => String(v.code) === params.code);
  }, [params]);

  const getOrigin = useMemo(() => {
    return items?.[`${type}-${gender}`]?.filter((v) => String(v.category_id) === params?.id) ?? [];
  }, [items, type, gender, params?.id]);

  useEffect(() => {
    setData([...getOrigin]);
  }, [getOrigin]);

  const handleDelete = async (id) => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    await restApi.delete(`/products/items/${id}`);
    message.success('삭제되었습니다.');
    dispatch(getProducts());
  };

  const handleSubmit = async (v, i) => {
    await restApi.put(`/products/items/${v}`, {
      name: data[i].name,
      guides: data[i].guides,
      price: data[i].price,
      active: data[i].active,
      master_price: data[i].master_price,
      delivery_price: data[i].delivery_price,
      guide_text: data[i].guide_text,
    });
    //data[i]
    message.success('저장되었습니다.');
    dispatch(getProducts());
  };

  const handleImgChange = useCallback((index, images) => {
    setData(
      produce((draft) => {
        draft[index].guides = images;
      }),
    );
  }, []);

  const column = useMemo(() => {
    return [
      {
        key: 'name',
        title: '이름',
        dataIndex: 'name',
        align: 'left',
        width: 150,
        render: (v, record, index) => (
          <Input
            defaultValue={v}
            onBlur={(e) => {
              const v = e.target.value;
              setData(
                produce(data, (draft) => {
                  draft[index].name = v;
                }),
              );
            }}
          />
        ),
      },
      {
        key: 'guide_img',
        title: '이미지',
        dataIndex: 'guides',
        render: (guides, row, index) => (
          <FileUpload
            maxCount={30}
            values={guides}
            onChange={(images) => {
              handleImgChange(index, images);
            }}
          />
        ),
      },
      {
        title: '설명글',
        dataIndex: 'guide_text',
        align: 'center',
        width: 80,
        render: (v, r, i) => {
          return (
            <Button danger={!isEqual(data[i], getOrigin[i])} onClick={() => setShowManualText(r)}>
              상세보기
            </Button>
          );
        },
      },
      {
        title: '선택가능',
        dataIndex: 'active',
        align: 'center',
        width: 80,
        render: (v, r, index) => {
          return (
            <Checkbox
              checked={v}
              onClick={() => {
                setData(
                  produce(data, (draft) => {
                    draft[index].active = !draft[index].active;
                  }),
                );
              }}
            />
          );
        },
      },
      {
        title: '분배금액',
        dataIndex: 'master_rate',
        align: 'center',
        width: 100,
        render: (v, record, index) => {

          console.log(record);
          const p1 = record.base_price;
          const p2 = record.price - record.base_price;

          const ma = Math.floor(p1 * (record.master_per / 100));
          const ta = Object.values(record.rate_prices || {});
          const ts = ta.length == 0 ? 0 : ta?.reduce((x, y) => x + y); //Object.values(record.rate_prices || {})?.reduce((x, y) => x + y) ?? 0;
          const tt = record.price - ma - ts;

          return (
              <div>
                <div>
                  <label>장인</label>
                  <InputNumber
                      value={ma}
                      disabled
                  />
                </div>
                <div style={{ marginTop: 5 }}>
                  <label>기사(전체)</label>
                  <InputNumber
                      value={ts}
                      disabled
                  />
                </div>
                <div style={{ marginTop: 5 }}>
                  <label>본사</label>
                  <InputNumber value={tt} disabled />
                </div>
              </div>
          )
        },
      },
      {
        title: '가격',
        dataIndex: 'price',
        align: 'center',
        width: 100,
        render: (v, record, index) => (
          <InputNumber
            value={v}
            onChange={(v) => {
              setData(
                produce(data, (draft) => {
                  draft[index].price = v;
                }),
              );
            }}
          />
        ),
      },
      {
        title: '',
        dataIndex: 'id',
        align: 'center',
        width: 80,
        // fixed: 'right',
        render: (v, r, i) => {
          return (
            <>
              <Button onClick={() => handleSubmit(v, i)} disabled={isEqual(data[i], getOrigin[i])}>
                저장
              </Button>
              <br />
              <Button danger onClick={() => handleDelete(v)} size={'small'} style={{ marginTop: 10 }}>
                삭제
              </Button>
            </>
          );
        },
      },
    ];
  }, [data]);

  const [addForm, setAddForm] = useState();
  const handleSubmit2 = async () => {
    const body = {
      ...addForm,
      type: params.type,
      gender: params.code,
      category_id: params.id,
      subject: METHOD_NAME[addForm.method],
      repeat: 1,
      active: false,
      enabled: true,
      order_index: -1,
      guides: [],
      guide_text: '',
    };

    await restApi.post(`/products/items`, body);
    message.success('저장되었습니다.');
    dispatch(getProducts());
    setAddForm(undefined);
  };

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const handleSort = useCallback(async (dragIndex, hoverIndex, data) => {
    setLoading(true);
    try {
      const ids = data?.map((v) => v.id);
      await restApi.put(`/products/items/sorts`, { ids });
      message.success('저장되었습니다.');
      dispatch(getProducts());
    } catch (e) {
      alert(e?.message);
    }
    setLoading(false);
  }, []);
  const width = useWindowDimension();

  const currItem = !showManualText ? undefined : data.find((x) => x.id === showManualText?.id);
  const [sortEnabled, setSortEnabled] = useState();

  return (
    <div>
      <Modal
        visible={!!showManualText}
        onCancel={() => {
          setData(
            produce((draft) => {
              const ix = data.indexOf(currItem);
              draft[ix].guide_text = showManualText.guide_text;
            }),
          );
          setShowManualText(undefined);
        }}
        title={'설명글 수정하기'}
        footer={false}
      >
        {currItem && (
          <Input.TextArea
            style={{ height: 500 }}
            value={showManualText.guide_text}
            onChange={(e) => {
              const value = e.target.value;
              setShowManualText((x) => ({ ...x, guide_text: value }));
            }}
          />
        )}
      </Modal>
      {!!loading && (
        <div
          style={{
            zIndex: 100,
            position: 'fixed',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'center',
            textAlign: 'center',
            background: 'rgba(255,255,255,0.5)',
          }}
        >
          <Spin style={{ margin: '0px auto' }} />
        </div>
      )}

      <Modal
        visible={!!addForm}
        title={'새상품 추가'}
        onCancel={() => setAddForm(undefined)}
        destroyOnClose
        onOk={handleSubmit2}
      >
        <Form>
          <Form.Item label={'이름'} style={{ marginBottom: 10 }}>
            <Input
              value={addForm?.name}
              onChange={(e) => {
                const v = e.target.value;
                setAddForm((x) => ({ ...x, name: v }));
              }}
            />
          </Form.Item>
          <Form.Item label={'코드'}>
            <Input
              value={addForm?.alias}
              placeholder={'묶일 코드입력 예) 남성-뒷굽갈이'}
              onChange={(e) => {
                const v = e.target.value;
                setAddForm((x) => ({ ...x, alias: v }));
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal visible={!!preview} title={'이미지 확대보기'} footer={() => null} onCancel={() => setPreview(undefined)}>
        <img src={preview} style={{ maxWidth: '100%' }} />
      </Modal>
      <PageHeader className="site-page-header" title="상품 설정" />
      <div style={{ marginBottom: 20 }}>
        {width < 1023 ? (
          <div>
            <div style={{ marginBottom: 10 }}>
              <Radio.Group
                value={type}
                onChange={(e) => {
                  navigate(`/settings/products/${e.target.value}/${params?.code}`, { replace: true });
                }}
              >
                {types?.map((type) => (
                  <Radio.Button value={type.type} key={type.type}>
                    {type.name}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </div>
            <div style={{ marginBottom: 10 }}>
              <Radio.Group
                value={params?.code}
                onChange={(e) => {
                  navigate(`/settings/products/${type}/${e.target.value}`, { replace: true });
                }}
              >
                {categories[type]?.map((item) => (
                  <Radio.Button key={item.code} value={String(item.code)}>
                    {item.name} ({item.code})
                  </Radio.Button>
                ))}
              </Radio.Group>
            </div>
            <div style={{ marginBottom: 10 }}>
              <Radio.Group
                value={params?.id}
                onChange={(e) => {
                  navigate(`/settings/products/${type}/${params.code}/${e.target.value}`, { relace: true });
                }}
              >
                {item?.items?.map((item) => (
                  <Radio.Button key={item.id} value={String(item.id)}>
                    {item.name}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </div>
            <div style={{ marginBottom: 10 }}>
              <Space>
                <RateFormButton type={type} data={data} setData={setData} />
                <Button onClick={() => setAddForm({})}>추가</Button>
              </Space>
            </div>
          </div>
        ) : (
          <Row>
            <Col flex={'1'}>
              <Space>
                <Radio.Group
                  value={type}
                  onChange={(e) => {
                    navigate(`/settings/products/${e.target.value}/${params?.code}`, { replace: true });
                  }}
                >
                  {types?.map((type) => (
                    <Radio.Button value={type.type} key={type.type}>
                      {type.name}
                    </Radio.Button>
                  ))}
                </Radio.Group>
                <Radio.Group
                  value={params?.code}
                  onChange={(e) => {
                    navigate(`/settings/products/${type}/${e.target.value}`, { replace: true });
                  }}
                >
                  {categories[type]?.map((item) => (
                    <Radio.Button key={item.code} value={String(item.code)}>
                      {item.name} ({item.code})
                    </Radio.Button>
                  ))}
                </Radio.Group>

                <Radio.Group
                  value={params?.id}
                  onChange={(e) => {
                    navigate(`/settings/products/${type}/${params.code}/${e.target.value}`, { relace: true });
                  }}
                >
                  {item?.items?.map((item) => (
                    <Radio.Button key={item.id} value={String(item.id)}>
                      {item.name}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Space>
            </Col>
            <Col>
              <Space>
                {width > 1023 && (
                  <Button onClick={() => setSortEnabled((x) => !x)}>{sortEnabled ? '정렬 완료' : '정렬 시작'}</Button>
                )}
                <RateFormButton type={type} data={data} setData={setData} />
                <Button onClick={() => setAddForm({})}>추가</Button>
              </Space>
            </Col>
          </Row>
        )}
      </div>

      {width < 1023 ? (
        <Table scroll={width < 1023 ? { x: 'max-content' } : undefined} dataSource={data} columns={column} />
      ) : (
        <DraggableEditableTableLastIndex
          onMoveRow={handleSort}
          style={{ width: '100%' }}
          originData={data}
          originColumns={column}
          editable={sortEnabled}
        />
      )}
    </div>
  );
};

export default ProductsCategory3;
