import React, { useState } from 'react';
import { Avatar, Button, Descriptions, Divider, Input, Modal, PageHeader, Select, Space, Table, Tabs } from 'antd';
import { NEW_RUN_STATE, NEW_RUN_STATE_CHG, PaymentType } from '../../../../common/defines';
import moment from 'moment';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import qs from 'query-string';
import { accountDetailSearchFields } from '../../../../common/searchFields';
import SearchForm from '../../../../components/datalist/SearchForm';
import { getAPIHost, restApi } from '../../../../apis';
import fileDownload from 'js-file-download';
import TableButtonWrap from '../../../../components/TableButtonWrap';
import { parseImageUrl, phoneFormat, toCommaNumber } from '../../../../common/utils';
import CalcView from '../../../../components/CalcView';

/**
 * 정기/하루기사 상세 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const AccountDeliveryDetails = () => {
  // 미리보기 모달 상태
  const [preview, setPreview] = useState();
  // 테이블 컬럼 정의
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
      width: 80,
    },
    {
      title: '서비스',
      dataIndex: 'purchase',
      align: 'center',
      width: 120,
      // render: (data) => data?.reduce((a, c) => [_, c.subject], []),
      render: (data) => PaymentType[data?.type],
    },
    {
      title: '주문번호',
      dataIndex: 'purchase',
      align: 'center',
      width: 120,
      render: (data) => data?.id,
    },
    // {
    //   title: '배송상태',
    //   dataIndex: 'state',
    //   align: 'center',
    //   render: (data) => RUN_STATES[data] ?? data,
    // },
    {
      title: '주문일시',
      dataIndex: 'purchase',
      align: 'center',
      width: 160,
      render: (data) => {
        return moment(data?.created_at).format('YYYY-MM-DD HH:mm');
      },
    },
    {
      title: '작업일',
      dataIndex: 'created_at',
      align: 'center',
      width: 160,
      render: (data) => {
        return moment(data).format('YYYY-MM-DD HH:mm');
      },
    },
    {
      title: '진행현황',
      dataIndex: 'state',
      align: 'center',
      width: 150,
      render: (data) => NEW_RUN_STATE[data] ?? data,
    },
    // {
    //   title: '주문금액',
    //   dataIndex: 'purchase',
    //   align: 'center',
    //   width: 150,
    //   render: (data) => toCommaNumber(data?.sale_price),
    // },
    {
      title: '정산금액',
      dataIndex: 'return_money',
      align: 'center',
      width: 150,
      render: (data) => toCommaNumber(data),
    },
    {
      title: '작업이미지',
      dataIndex: 'images',
      align: 'center',
      render: (data) => {
        if (!data?.length) return null;
        return (
          <div onClick={() => setPreview(data)}>
            <Space>
              {data?.map((v) => (
                <Avatar shape={'square'} src={parseImageUrl(v)} size={80} key={String(v)} />
              ))}
            </Space>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'purchase',
      align: 'center',
      width: 100,
      render: (data) => (
        <Link to={`/work/order/details/${data?.id}`}>
          <a style={{ color: 'black' }}>자세히 보기</a>
        </Link>
      ),
    },
  ];

  const { id: accountId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);

  const [areaLimit, setAreaLimit] = useState([]);
  // 제량수한 조절
  const handleSubmitAreaLimit = async () => {
    const { data } = await restApi.put(`/accounts/${accountId}`, { area_limit: areaLimit });
    alert('저장되었습니다.');
  };

  const [data, setData] = React.useState();
  const [accountData, setAccountData] = React.useState();
  const userData = [
    { label: '성명', content: accountData?.nickname },
    { label: '구분', content: accountData?.type === 'T' ? '정기/하루' : accountData?.type },
    // { label: '아이디', content: accountData?.signname },
    { label: '연락처', content: phoneFormat(accountData?.phone) },
    { label: '가입일자', content: moment(accountData?.created_at).format('YYYY-MM-DD HH:mm') },
    {
      label: '가능지역',
      content: (
        <div>
          <Input.Group compact>
            <Select value={areaLimit} onChange={(v) => setAreaLimit(v)} mode={'tags'} style={{ width: 300 }} />
            <Button onClick={handleSubmitAreaLimit}>저장</Button>
          </Input.Group>
          <span>"서울 강서" 혹은 "서울" 같이 단순시작주소로 해주세요</span>
        </div>
      ),
    },
  ];

  // 회원상세정보 조회
  const getAccountInfo = async (id) => {
    const { data } = await restApi.get(`/accounts/${id}`);
    setAccountData(data);
    setAreaLimit(data.area_limit);
  };

  React.useEffect(() => {
    if (!accountId) return;
    getAccountInfo(accountId);
  }, [accountId]);

  // 테이블 페이지네이션
  const pagination = {
    position: ['bottomCenter'],
    total: data?.total_elements,
    pageSize: 10,
    current: data?.page + 1,
    onChange: async (p) => {
      navigate('?' + qs.stringify({ ...query, page: p - 1 }), { replace: true });
    },
  };

  // query 바뀔 때 마다 데이터 조회
  const loadData = React.useCallback(async () => {
    const params = {
      ...query,
      account_id: accountId,
      limit: 10,
    };

    params.dir = query.sort === 'id' ? 'asc' : 'desc';

    if (query.field === 'name') {
      params.name = query.keyword;
    } else if (query.field === 'phone') {
      params.phone = query.keyword;
    }

    const { data } = await restApi.get('/purchases/histories', {
      params,
    });
    setData(data);
  }, [query]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  // 탈퇴처리
  const handleLeave = async () => {
    if (!window.confirm('탈퇴 처리하시겠습니까?\n복구가 불가능합니다.')) return;
    try {
      await restApi.delete(`/accounts/admin/${accountData.id}`);
      alert('삭제되었습니다.');
      window.history.back();
    } catch (e) {
      alert(e?.response?.data?.message || '서버와 연결이 올바르지 않습니다.');
    }
  };

  // 엑셀 저장
  const xlsxSave = () => {
    const args = {
      ...query,
      account_id: accountId,
    };
    restApi
      .get(`${getAPIHost()}/exports/purchase?${qs.stringify(args)}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `정기/하루기사_상세_${moment().format('YYYYMMDDHHmmss')}.xlsx`);
      });
  };

  return (
    <div>
      <Modal
        visible={!!preview}
        title={'이미지 크게보기'}
        onCancel={() => setPreview(undefined)}
        footer={() => null}
        bodyStyle={{ padding: 0 }}
      >
        {preview?.map((v) => (
          <img src={parseImageUrl(v)} style={{ maxWidth: '100%' }} key={`${v}`} />
        ))}
      </Modal>
      <PageHeader
        className="site-page-header"
        title="정기/하루 회원정보"
        extra={[<Button onClick={() => navigate(-1)}>목록</Button>]}
      />
      <Descriptions bordered>
        {userData.map((data, index) => {
          return (
            <Descriptions.Item key={`${data.label}-${index}`} label={data.label} span={3}>
              {data.content}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
      <div style={{ marginTop: 20 }}>
        <Space>
          <Button onClick={handleLeave} danger>
            회원탈퇴
          </Button>
        </Space>
      </div>
      <Divider />
      <Tabs>
        <Tabs.TabPane tab={`배송내역(총 ${data?.total_elements || 0}개)`} key={'works'}>
          <div style={{ position: 'relative' }}>
            <Table
              scroll={{ x: 'max-content' }}
              title={() => (
                <>
                  <SearchForm fields={accountDetailSearchFields} />
                </>
              )}
              dataSource={data?.items}
              columns={columns}
              pagination={
                pagination
                  ? {
                      ...pagination,
                      position: ['bottomCenter'],
                      showSizeChanger: false,
                    }
                  : false
              }
            />
            <div>
              <TableButtonWrap
                position="left"
                buttons={[{ icon: require('../../../../assets/icon_save.png'), label: '엑셀 저장', onClick: xlsxSave }]}
              />
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={'정산내역'} key={'calcs'}>
          <CalcView id={accountId} detColumns={columns} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default AccountDeliveryDetails;
