import React from 'react';
import { Button, Divider, Input, PageHeader } from 'antd';
import useInput from '../../../hooks/useInput';
import { useNavigate } from 'react-router-dom';
import useAxios from '../../../hooks/useAxios';
import { createPost as createPostApi } from '../../../apis/posts';
import FileUpload from '../../../components/FileUpload';
import styles from '../../work/review/details/reviewDetails.module.less';
import LabelRow from '../../../components/LabelRow';
import { postType } from '../../../common/defines';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

/**
 * 슈닥이야기 생성 페이지
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const ShoedocCreate = () => {
  const navigate = useNavigate();
  const createPost = useAxios();
  const title = useInput('');
  const [contents, setContents] = React.useState(null);
  const [mainImage, setMainImage] = React.useState();
  const [images, setImages] = React.useState([]);

  const handleSubmit = async () => {
    if (!title.value.length > 0) {
      alert('제목을 입력해주세요.');
      return;
    }
    if (!mainImage) {
      alert('대표 이미지를 선택해주세요.');
      return;
    }
    try {
      const requestBody = {
        type: postType.DOC_STORY,
        title: title.value,
        contents: contents,
        mainImage,
        images,
      };
      await createPost.loadData(createPostApi(requestBody));
      alert('등록 완료되었습니다.');
      navigate('/contents/shoedoc', { replace: true });
    } catch (error) {
      alert(error?.response?.data?.message ?? error);
    }
  };

  return (
    <div>
      <PageHeader className="site-page-header" title="슈닥이야기 작성" />
      <div className="labelrowWrapper">
        <LabelRow label="제목">
          <Input placeholder="최대 30자 이내로 입력하세요." {...title} style={{ width: 500 }} />
        </LabelRow>
        <LabelRow label="대표이미지" heightOver>
          <div>
            <FileUpload
              values={mainImage ? [mainImage] : []}
              maxCount={1}
              onChange={(images) => {
                setMainImage(images[0]);
              }}
              className="profileImg"
            />
            {/*<span style={{ fontSize: 12, color: '#3d3d3d', margin: '6px 0px', display: 'block' }}>*/}
            {/*  추가이미지는 최대 9개까지 설정가능.*/}
            {/*</span>*/}
          </div>
        </LabelRow>
        <LabelRow
          label={`추가이미지(${images.length}/9)`}
          maxCount={9}
          hideUpload={(images) => images.length >= 9}
          heightOver
        >
          <div>
            <FileUpload
              values={images}
              maxCount={100}
              onChange={(images) => {
                setImages(images);
              }}
            />
            <span style={{ fontSize: 12, color: '#3d3d3d', margin: '6px 0px', display: 'block' }}>
              권장 크기 : 1000 x 1000 (윈도대상 750 x 1000)
            </span>
            <span style={{ fontSize: 12, color: '#3d3d3d', margin: '6px 0px', display: 'block' }}>
              추가이미지는 최대 9개까지 설정가능.
            </span>
          </div>
        </LabelRow>
      </div>
      <Divider />
      {/*<h4 className="partTitle">상세설명</h4>*/}
      {/*<Editor*/}
      {/*    toolbarClassName="toolbar-class"*/}
      {/*    onChange={(value) => setContents(draftToHtml(value))}*/}
      {/*    editorStyle={{ height: 350 }}*/}
      {/*    toolbar={{*/}
      {/*      options: [*/}
      {/*        'inline',*/}
      {/*        'blockType',*/}
      {/*        'fontSize',*/}
      {/*        'fontFamily',*/}
      {/*        'list',*/}
      {/*        'textAlign',*/}
      {/*        'colorPicker',*/}
      {/*        'link',*/}
      {/*        'emoji',*/}
      {/*        'remove',*/}
      {/*        'history',*/}
      {/*      ],*/}
      {/*    }}*/}
      {/*    // toolbarHidden*/}
      {/*    placeholder="내용을 작성해주세요."*/}
      {/*    // 한국어 설정*/}
      {/*    localization={{*/}
      {/*      locale: 'ko',*/}
      {/*    }}*/}
      {/*/>*/}
      <div className={styles.labelrowButtonSet} style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={() => navigate('/contents/shoedoc', { replace: true })}
          size={'large'}
          className={styles.basicButton}
          style={{ marginRight: 5, height: 40, width: 100 }}
        >
          취소
        </Button>
        <Button
          onClick={handleSubmit}
          size={'large'}
          className={styles.basicButton}
          style={{ height: 40, width: 100, backgroundColor: '#FF7200', color: 'white' }}
        >
          등록
        </Button>
      </div>
    </div>
  );
};

export default ShoedocCreate;
