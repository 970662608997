import React from 'react';
import styles from './TableButtonWrap.module.css';
import {Link} from "react-router-dom";

/**
 * 테이블 하단 버튼 컴포넌트
 * @author 윤창현 <hyeon_dev@actbase.io>
 * */
const TableButtonWrap = ({ onClick, buttons, position, children }) => {
  const inputRef = React.useRef(null);
  const aRef = React.useRef(null);
  console.log(aRef.current,'@@')
  return (
    <div className={styles.container} style={position === 'left' ? { left: 0, right: 'auto' } : { left: 'auto', right: 0 }}>
      {buttons ? (
        <>
          {buttons.map((buttons) => {
            return (
              <React.Fragment key={buttons.key ?? buttons.label}>
                {buttons.link ? (
                  <Link to={buttons.link}>
                    <a
                      className={styles.button}
                      style={
                        buttons.buttonColor
                          ? { backgroundColor: buttons.buttonColor, borderColor: buttons.buttonColor }
                          : { backgroundColor: '#fff' }
                      }
                    >
                      {buttons.icon ? <img className={styles.icon} src={buttons.icon} /> : null}
                      <span className={styles.text} style={buttons.buttonColor ? { color: '#fff' } : { color: '#333' }}>
                        {buttons.label}
                      </span>
                    </a>
                  </Link>
                ) : buttons.download ? (
                    <button
                        onClick={() => aRef.current.click()}
                        className={styles.button}
                        style={
                          buttons.buttonColor
                              ? { backgroundColor: buttons.buttonColor, borderColor: buttons.buttonColor }
                              : { backgroundColor: '#fff' }
                        }
                    >
                      <a href={buttons.download} download ref={aRef}/>
                      {buttons.icon ? <img className={styles.icon} src={buttons.icon} /> : null}
                      <span className={styles.text} style={buttons.buttonColor ? { color: '#fff' } : { color: '#333' }}>
                      {buttons.label}
                    </span>
                    </button>
                ) :buttons.onChange ? (
                  <button
                    className={styles.button}
                    style={
                      buttons.buttonColor
                        ? { backgroundColor: buttons.buttonColor, borderColor: buttons.buttonColor }
                        : { backgroundColor: '#fff' }
                    }
                    onClick={() => inputRef.current.click()}
                  >
                    <input
                      ref={inputRef}
                      id="accountProfile"
                      type="file"
                      onChange={(e) => buttons.onChange(e)}
                      style={{ display: 'none' }}
                    />
                    {buttons.icon ? <img className={styles.icon} src={buttons.icon} /> : null}
                    <span className={styles.text} style={buttons.buttonColor ? { color: '#fff' } : { color: '#333' }}>
                      {buttons.label}
                    </span>
                  </button>
                ) : (
                  <button
                    onClick={buttons.onClick}
                    className={styles.button}
                    style={
                      buttons.buttonColor
                        ? { backgroundColor: buttons.buttonColor, borderColor: buttons.buttonColor }
                        : { backgroundColor: '#fff' }
                    }
                  >
                    {buttons.icon ? <img className={styles.icon} src={buttons.icon} /> : null}
                    <span className={styles.text} style={buttons.buttonColor ? { color: '#fff' } : { color: '#333' }}>
                      {buttons.label}
                    </span>
                  </button>
                )}
              </React.Fragment>
            );
          })}
        </>
      ) : (
        children
      )}
    </div>
  );
};

export default TableButtonWrap;
